import React from 'react'
import Text from '@atoms/Text/Text'
import styles from './RemovableElement.module.scss'
import { ReactComponent as CloseIcon } from '@assets/svg/close.svg'

const RemovableElement = ({ text, handleDeleteElement }) => {
  return (
    <div className={styles.container}>
      <Text tag='p' size='m'>
        {text}
      </Text>
      <div className={styles.delete} onClick={() => handleDeleteElement(text)}>
        <CloseIcon className={styles.icon} />
      </div>
    </div>
  )
}

export default RemovableElement
