import React, { useEffect, useState } from "react";
import ContactPro from "@molecules/ContactPro/ContactPro";
import ModalTitle from "@atoms/ModalTitle/ModalTitle";
import { useDispatch, useSelector } from "react-redux";
import { setModal, postRequestContact } from "@actions/";
import styles from "./FirstLoginModal.module.scss";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/es";
import {
  getHorariosContacto,
  getFirstLogin,
  setReadyText,
  footerOnboarding,
  finishedVideos,
  updateDataOnboarding,
} from "@actions/generalActions";
import { getIdFromLS } from "@utils/tokenService";
import Step from "./Steps/Steps";
import { axiosGet, axiosPost, axiosPut } from "@config/api";
import video1 from "../../../assets/onboarding/video1.mp4";
import video2 from "../../../assets/onboarding/video2.mp4";
import video3 from "../../../assets/onboarding/video3.mp4";
import video4 from "../../../assets/onboarding/video4.mp4";
import { toast } from "react-toastify";
import LabelStories from "@atoms/Label/Label.stories";

const FirstLoginModal = ({
  isMobile,
  modalType,
  history,
  professionalProfile,
  withoutServices = false,
}) => {
  const dispatch = useDispatch();
  let firstLogin = useSelector((state) => state["general"]["firstLogin"]);

  let dataOnboarding = useSelector(
    (state) => state["general"]["dataOnboarding"]
  );

  let textReady = useSelector((state) => state["general"]["textReady"]);

  const stateFinishedVideos = useSelector(
    (state) => state["general"]["finishedVideos"]
  );

  let footerOnb = useSelector((state) => state["general"]["footerOnboarding"]);

  let watchedVideo = useSelector((state) => state["general"]["watchedVideo"]);

  let [allWatched, setAllWatched] = useState(false);

  let [data, setData] = useState([
    {
      step: 1,
      title: "Paso 1",
      subTitle: "Completá tu tarjeta de presentación",
      show: false,
      viewed: false,
      video: video1,
      deployStep: false,
    },
    {
      step: 2,
      title: "Paso 2",
      subTitle: "Agregá un mensaje personalizado para tus clientes",
      show: false,
      viewed: false,
      video: video2,
      deployStep: false,
    },
    {
      step: 3,
      title: "Paso 3",
      subTitle: "¿Qué servicios querés ofrecer?",
      show: false,
      viewed: false,
      video: video3,
      deployStep: false,
    },
    {
      step: 4,
      title: "Paso 4",
      subTitle: "Pedí recomendaciones y mejorá tu posicionamiento",
      show: false,
      viewed: false,
      video: video4,
      deployStep: false,
    },
  ]);

  useEffect(() => {
    dispatch(updateDataOnboarding());
  }, []);

  useEffect(() => {
    dispatch(updateDataOnboarding(data));
    toast.dismiss("profileServicesIncomplete");
    toast.dismiss("profilePhoneIncomplete");
    localStorage.setItem("toast", false);
    axiosGet("/users/me").then((response) => {
      let res = response.data;

      if (
        !footerOnb &&
        res.stepOne &&
        res.stepTwo &&
        res.stepThree &&
        res.stepFourth
      ) {
        dispatch(setReadyText(true));
        axiosPut(`/users/closeOnboarding`, {}).then(() => {
          dispatch(getFirstLogin(false));
        });
        dispatch(setModal(false));
      } else {
        dispatch(getFirstLogin(res.firstLogin));

        if (res.stepOne && res.stepTwo && res.stepThree && res.stepFourth) {
          dispatch(setReadyText(true));
          dispatch(finishedVideos(true));
        }

        for (let key in res) {
          if (key === "stepOne")
            setData([...data, (data[0]["viewed"] = res[key])]);
          if (key === "stepTwo")
            setData([...data, (data[1]["viewed"] = res[key])]);
          if (key === "stepThree")
            setData([...data, (data[2]["viewed"] = res[key])]);
          if (key === "stepFourth")
            setData([...data, (data[3]["viewed"] = res[key])]);
        }

        if (data.every((step) => step.viewed === true)) setAllWatched(true);

        let newData = data.map((step) => {
          if (step !== false || step !== true) return step;
        });

        let count = 0;

        newData = newData.map((step) => {
          if (!step.viewed && count === 0) {
            count = count + 1;
            return { ...step, deployStep: true };
          } else {
            return step;
          }
        });

        setData(newData);
        dispatch(updateDataOnboarding(newData));
      }
    });

    return () => {
      axiosPut(`/users/closeOnboarding`, {}).then((response) => {
        dispatch(getFirstLogin(false));
      });
      dispatch(footerOnboarding(false));
      dispatch(setModal(false, null));
      localStorage.setItem("oneTime", false);
      localStorage.setItem("toast", true);
    };
  }, []);

  useEffect(() => {
    axiosGet("/users/me").then((response) => {
      let res = response.data;
      dispatch(getFirstLogin(res.firstLogin));

      for (let key in res) {
        if (key === "stepOne")
          setData([...data, (data[0]["viewed"] = res[key])]);
        if (key === "stepTwo")
          setData([...data, (data[1]["viewed"] = res[key])]);
        if (key === "stepThree")
          setData([...data, (data[2]["viewed"] = res[key])]);
        if (key === "stepFourth")
          setData([...data, (data[3]["viewed"] = res[key])]);
      }

      let newData = data.map((step) => {
        if (step !== false || step !== true) return step;
      });

      let count = 0;

      // newData = newData.filter((step) => {
      //   return { ...step, deployStep: false };
      // });

      newData = data.map((step) => {
        return { ...step, deployStep: false, show: false };
      });

      newData = newData.map((step) => {
        if (!step.viewed && count === 0) {
          count = count + 1;
          return { ...step, deployStep: true };
        } else {
          return step;
        }
      });

      setData(newData);
      dispatch(updateDataOnboarding(newData));
    });
  }, [watchedVideo]);

  useEffect(() => {
    axiosGet("/users/me").then((response) => {
      let res = response.data;
      if (res.stepOne && res.stepTwo && res.stepThree && res.stepFourth) {
        let newData = data.map((step) => {
          return { ...step, show: false };
        });
        setData(newData);
        dispatch(updateDataOnboarding(newData));
        setAllWatched(true);
      }
    });
  }, [textReady]);

  const show = (e, st) => {
    e.preventDefault();
    // setData(...dataOnboarding, dataOnboarding[0]:);
    // dispatch(updateDataOnboarding(newData));

    let newData = dataOnboarding.map((step) => {
      if (st === step.step) {
        return { ...step, deployStep: false, show: !step.show };
      } else {
        return { ...step, deployStep: false, show: false };
      }
    });
    setData(newData);
    dispatch(updateDataOnboarding(newData));
  };

  useEffect(() => {
    let element = document.getElementById("divSc");
    if (element) {
      element.focus();
      element.tabIndex = 999;
    }
  }, [dataOnboarding]);

  return (
    <>
      {firstLogin && (
        <div className={styles.container}>
          <ModalTitle
            title={"Bienvenido a tu perfil Profesional!"}
            firstLogin
            customStyles={{ marginBottom: "var(--spacing-small)" }}
            isMobile={isMobile}
          />
          <div className={`${styles.divScroll} divSc`} id="divSc" >
            <section className={styles.steps}>
              {dataOnboarding.map((step) => (
                <Step
                  className="steps"
                  step={step.step}
                  title={step.title}
                  subTitle={step.subTitle}
                  show={step.show}
                  viewed={step.viewed}
                  video={step.video}
                  changeStep={(e) => show(e, step.step)}
                  deployStep={step.deployStep}
                />
              ))}
            </section>
          </div>
          <div className={`${styles.info}`}>
            <section>
              <span
                style={
                  stateFinishedVideos || allWatched
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                Listo! Completá tu perfil ahora y comenzá a recibir nuevos
                clientes ya mismo.
              </span>
              <span
                style={isMobile ? { display: "block" } : { display: "none" }}
              >
                Volvé a ver este tutorial cuando quieras en nuestra sección de
                Ayuda {">"} Profesionales {">"} Onboarding.
              </span>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(FirstLoginModal);
