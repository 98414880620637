import React, { useEffect, useRef } from "react";
import styles from "./Steps.module.scss";
import ReactPlayer from "react-player";
import { axiosGet, axiosPost, axiosPut } from "@config/api";
import visto from "../../../../assets/svg/done.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReadyText, watchedVideo, finishedVideos } from "@actions/generalActions";
import { findDOMNode } from 'react-dom'
import { toast } from 'react-toastify';
import useScreenDetect from "@hooks/useScreenDetect";

const Step = ({ step, title, subTitle, show, changeStep, viewed, video, deployStep }) => {
  let dataOnboarding = useSelector((state) => state["general"]["dataOnboarding"]);
  let [localViewed, setLocalViewed] = useState(false);
  let watchVideo = useSelector((state) => state["general"]["watchedVideo"]);
  let dispatch = useDispatch();
  let [currentShow, setCurrentShow] = useState(show);
  const { isMobile } = useScreenDetect();

  const watched = async (step) => {
    await axiosPut(`/users/watchedStep/${step}`, {});
    setLocalViewed(true);
    await axiosGet("/users/me").then((response) => {
      let res = response.data;
      if (res.stepOne && res.stepTwo && res.stepThree && res.stepFourth) {
        dispatch(setReadyText(true));
        dispatch(finishedVideos(true));  
      }
    });
    setCurrentShow(false)
    dispatch(watchedVideo());
  };
  
  useEffect(() => {
    setLocalViewed(viewed);
  }, [viewed, watchVideo]);

  useEffect(() => {
    setLocalViewed(localViewed);
  }, [localViewed, watchVideo]);

  const onStart = (step) => {
    let video = document.querySelectorAll('.reactPlayer')[step-1];
    video.style.width = '100%';
  }

  return (
    <div className={`${styles.stepContainer}`}>
      <div className={`${styles.sectionFirst}`} onClick={changeStep} >
        {!localViewed && <span>{step}</span>}
        {localViewed && (
          <img className={`${styles.visto}`} src={visto} alt="" />
        )}
      </div>
      <div className={`${styles.sectionTwo}`} onClick={changeStep}>
        <span>{title}</span>
        <span>{subTitle}</span>
      </div>
      {(deployStep || show) && (
        <div className={`${styles.sectionThree}`}>
          <div></div>
        </div>
      )}
      {(deployStep || show) && (
        <div className={`${styles.sectionFourth}`}>
          <ReactPlayer
            className={`${styles.reactPlayer} reactPlayer`}
            url={video}
            width={isMobile ? "100%":"90%"}
            // height="40%"
            controls
            // onStart={() => onStart(step)}
            onEnded={() => watched(step)}
          />
        </div>
        
      )}
    </div>
  );
};

export default Step;
