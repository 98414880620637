import React, { useEffect, useState } from 'react'
import styles from './StarReview.module.scss'
import { ReactComponent as StarEmpty } from '@assets/svg/star-empty.svg'
import { ReactComponent as StarFilled } from '@assets/svg/star-filled.svg'
import Text from '@atoms/Text/Text'
import starData from '@assets/lotties/star.json'
import Lottie from 'react-lottie'
import { motion } from 'framer-motion'
const STARS = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 }
]

const StarReview = ({ handleSelectValue }) => {
  const [starHover, setStarHover] = useState(0)
  const [starText, setStarText] = useState('')
  const [valueSelect, setValueSelect] = useState(false)

  const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const handleStarHover = (value) => {
    setValueSelect(false)
    handleSelectValue(0)
    setStarHover(value)
  }

  const handleCleanStarHover = () => {
    if (valueSelect) return
    setStarText('')
    setStarHover(0)
  }

  const handleSelectStar = (value) => {
    setValueSelect(value)
    handleSelectValue(value)
  }

  const qualificationValue = (value) => {
    if (value) {
      switch (value) {
        case 1:
          return 'Mala'

        case 2:
          return 'Regular'

        case 3:
          return 'Buena'

        case 4:
          return 'Muy buena'

        case 5:
          return 'Excelente'

        default:
          return ''
      }
    }
  }

  useEffect(() => {
    if (starHover > 0) {
      setStarText(qualificationValue(starHover))
    }
  }, [starHover])

  useEffect(() => {
    if (valueSelect > 0) setStarText(qualificationValue(valueSelect))
  }, [valueSelect])

  return (
    <div className={styles.container}>
      <div
        className={styles.starsContainer}
        onMouseLeave={() => handleCleanStarHover()}
      >
        {STARS.map((star) => (
          <div
            className={styles.star}
            key={star.value}
            onMouseEnter={() => handleStarHover(star.value)}
            onClick={() => handleSelectStar(star.value)}
            style={{
              margin: valueSelect === 5 && star.value === 5 ? '0' : '0 1rem'
            }}
          >
            {star.value <= starHover || star.value <= valueSelect
              ? (
                  valueSelect === 5 && star.value === 5
                    ? (
                      <Lottie
                        options={{ animationData: starData, ...defaultOptions }}
                        height={43}
                        width={50}
                      />
                      )
                    : (
                      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                        <StarFilled
                          className={`${styles.starIcon} ${
                      styles[`starIcon-${star.value}`]
                    }`}
                        />
                      </motion.div>
                      )
                )
              : (
                <StarEmpty />
                )}
          </div>
        ))}
      </div>
      <Text tag='p' weight='bold' size='l-1' customStyles={{ height: '3rem' }}>
        {starText}
      </Text>
    </div>
  )
}
export default StarReview
