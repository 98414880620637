import { setRegisterUserInfo, setStep } from "@actions/";
import { loginError, logOut } from "@actions/authActions";
import { footerOnboarding } from "@actions/generalActions";
import {
  addNotification,
  addNotificationForPro,
  getUserWorksRequests,
  getWorksRequests,
  setProfileMode,
} from "@actions/profileActions";
import * as actionTypes from "@actions/types";
import ClosePro from "@assets/svg/closePro.svg";
import NewCloseCross from "@assets/svg/new_close_cross.svg";
import { ReactComponent as ChevronIcon } from "@assets/svg/down-chevron.svg";
import { ReactComponent as Logo } from "@assets/svg/logo.svg";
import { ReactComponent as LogoPro } from "@assets/svg/logoPro.svg";
import { ReactComponent as LogoUser } from "@assets/svg/logoUser.svg";
import MoneySVG from "@assets/svg/money_white.svg";
import PersonSVG from "@assets/svg/person_white.svg";
import ProfileMenu from "@assets/svg/profileIcon.svg";
import ProfileIconUser from "@assets/svg/profileIconUser.svg";
import SolicitudesMenu from "@assets/svg/solicitudesPro.svg";
import Avatar from "@atoms/Avatar";
import Button from "@atoms/Button/Button";
import GoBackButton from "@atoms/GoBackButton/GoBackButton";
import MenuIcon from "@atoms/MenuIcon/MenuIcon";
import Separator from "@atoms/Separator/Separator";
import SlideCheckbox from "@atoms/SlideCheckbox/SlideCheckbox";
import Text from "@atoms/Text/Text";
import { ENVIRONMENT_URL } from "@config/api";
import useScreenDetect from "@hooks/useScreenDetect";
import {
  deleteToken,
  getIsProFromLS,
  getRole,
  getToken,
  setAdminP,
  setFromSwitchInLS,
} from "@utils/tokenService";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fade from "react-reveal/Fade";
import {
  Link,
  NavLink,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom";
import socketIOClient from "socket.io-client";
import styles from "./Header.module.scss";
import Slider from "./Slider/Slider";

// DESKTOP
// -CLICK SOLICITUDES -> SOLO CERRAR SESION
// MOBILE
// -PERFIL Y MIS SOLICITUDES
// ->CLICK PERFIL -> VER PERFIL PUBLICO Y CERRAR SESION
// -> CLICK SOLICITUDES -> CERRAR SESION

const Profile = React.forwardRef(
  ({
    isLoggedIn,
    isMobile,
    profileData,
    profileRef,
    setShowOptions,
    showOptions,
    worksRedirect,
    worksTitle,
    history,
    unreadNotifications,
    unreadNotificationsForPro,
  }) => {
    const dispatch = useDispatch();
    const [menuOpened, setMenuOpened] = useState(false);
    const handleOpenMenu = (value) => {
      setMenuOpened(value);
    };
    const handleSlider = () => {
      // SLIDER DEL MENU
      if (profileData.originalProfileType === "USER") {
        const value = {
          name: profileData.name,
          lastName: profileData.lastName,
          email: profileData.email,
        };
        dispatch(setRegisterUserInfo(value));
        setFromSwitchInLS();
        dispatch(setStep(2));
        return history.push("/registro-profesionales-vertical");
      } else {
        if (profileData.profileMode === "PRO") {
          dispatch(setProfileMode("USER", history));
        } else {
          dispatch(setProfileMode("PRO", history));
        }
      }
      setShowOptions(false); // sigue dejando visible al cambiar de Modo
    };

    const [alias, setAlias] = useState(null);
    const { privateProfile } = useSelector((state) => state.profile);

    useEffect(() => {
      if (localStorage.getItem("admin")) {
        setAdminP();
      }
    }, []);

    useEffect(() => {
      if (privateProfile) setAlias(privateProfile.fantasy);
    }, [privateProfile]);

    const close = (e, history) => {
      e.preventDefault();
      dispatch(footerOnboarding(false));
      logOut(history);
    };

    return (
      isLoggedIn && (
        <>
          {isLoggedIn &&
          unreadNotificationsForPro?.length > 0 &&
          localStorage.getItem("role") !== "ADMIN" &&
          localStorage.getItem("isPro") === "true" ? (
  <NavLink to={worksRedirect}>
              <span
                className={styles.worksLength}
                style={
                  unreadNotificationsForPro?.length > 0
                    ? { backgroundColor: "var(--border-error)" }
                    : null
                }
              >
                {unreadNotificationsForPro?.length > 9
                  ? "9+"
                  : unreadNotificationsForPro?.length ?? 0}
              </span>
            </NavLink>
          ) : (
            isLoggedIn &&
            unreadNotifications?.length > 0 &&
            localStorage.getItem("role") !== "ADMIN" &&
            profileData.profileMode === "USER" ? (
    <NavLink to={worksRedirect}>
                <span
                  className={styles.worksLength}
                  style={
                    unreadNotifications?.length > 0
                      ? { backgroundColor: "var(--border-error)" }
                      : null
                  }
                >
                  {unreadNotifications?.length > 9
                    ? "9+"
                    : unreadNotifications?.length ?? 0}
                </span>
              </NavLink>
            ) : (
              <span
                className={styles.worksLength}
                style={{ backgroundColor: "transparent" }}
              ></span>
            )
          )}
                  
          <div
            className={styles.profile}
            onClick={() => setShowOptions((prev) => !prev)}
            ref={profileRef}
          >
            {!isMobile && (
              <>
                {/* <figure className={styles.separator} /> */}
                <Avatar image={profileData.photo} fromHeader />
                <Text
                  color="white"
                  tag="span"
                  size="m"
                  align="left"
                  customStyles={{ fontWeight: "bold" }}
                >
                  {alias || `${profileData.name} ${profileData.lastName}`}
                </Text>
                <ChevronIcon
                  className={`${styles.icon} ${showOptions && styles.open}`}
                />{" "}
              </>
            )}
            {showOptions && (
              <Fade>
                <div
                  className={styles.profileMenu}
                  style={{
                    backgroundColor:
                      localStorage.getItem("isPro") === "false"
                        ? "#ff8671"
                        : "#320fcc",
                  }}
                >
                  {localStorage.getItem("role") !== "ADMIN" && (
                    <div className={styles.profileMenu_item}>
                      <SlideCheckbox
                        name={
                          <span>
                            <b
                              style={{
                                marginLeft: "5px",
                                color:
                                  localStorage.getItem("isPro") === "false"
                                    ? "#C4C4C4"
                                    : "#FFFFFF",
                              }}
                            >
                              Ofrecé
                            </b>
                          </span>
                        }
                        name2={
                          <span>
                            <b
                              style={{
                                color:
                                  localStorage.getItem("isPro") === "false"
                                    ? "#FFFFFF"
                                    : "#C4C4C4",
                              }}
                            >
                              Contratá
                            </b>
                          </span>
                        }
                        isOn={profileData.profileMode === "PRO"}
                        reverse
                        onChange={handleSlider}
                        backgroundColor={
                          profileData.profileMode === "PRO"
                            ? "var(--secondary-blue)"
                            : "var(--color-item)"
                        }
                        uncheckedBackgroundOpacity={1}
                        customStyles={{
                          marginTop: isMobile ? "1.6rem" : "0",
                          paddingTop: "1.5rem",
                        }}
                        beforeIcon={MoneySVG}
                        afterIcon={PersonSVG}
                        style={{ margin: "0px !important" }}
                      />
                    </div>
                  )}

                  <NavLink
                    to={
                      isLoggedIn
                        ? profileData.isPro
                          ? "/perfil-profesional"
                          : "/perfil-usuario"
                        : "/registro"
                    }
                    className={`${styles.link} ${styles.spacing} ${styles.perfilMenu}`}
                    activeClassName={isLoggedIn && !isMobile && styles.active}
                    onClick={() => handleOpenMenu(false)}
                  >
                    {isLoggedIn && localStorage.getItem("role") !== "ADMIN" && (
                      <span
                        className={styles.labelProfileMenu}
                        style={
                          isLoggedIn
                            ? localStorage.getItem("isPro") === "true"
                              ? {
                                  backgroundColor: "white",
                                  paddingLeft: !menuOpened && "0",
                                  color: "#320fcc",
                                }
                              : localStorage.getItem("isPro") === "false"
                              ? {
                                  backgroundColor: "white",
                                  paddingLeft: !menuOpened && "0",
                                  color: "#ff8671",
                                }
                              : {
                                  backgroundColor: "white",
                                  paddingLeft: !menuOpened && "0",
                                  color: "white",
                                }
                            : {
                                paddingLeft: !menuOpened && "0",
                                color: "white",
                              }
                        }
                      >
                        <img
                          src={
                            localStorage.getItem("isPro") === "true"
                              ? ProfileMenu
                              : ProfileIconUser
                          }
                          alt=""
                        />
                        <span
                          style={{
                            color:
                              localStorage.getItem("isPro") === "true"
                                ? "#320fcc"
                                : "#ff8671",
                          }}
                        >
                          Perfil
                        </span>
                      </span>
                    )}
                  </NavLink>

                  <NavLink
                    to={isLoggedIn ? worksRedirect : "/iniciar-sesion"}
                    className={`${styles.link} ${styles.spacing}  ${styles.perfilMenu}`}
                    activeClassName={isLoggedIn && !isMobile && styles.active}
                    onClick={() => handleOpenMenu(false)}
                  >
                    {localStorage.getItem("role") !== "ADMIN" && (
                      <span className={styles.labelSolicitudesMenu}>
                        <img src={SolicitudesMenu} alt="" />
                        <span
                          className={`${styles.loginText} ${
                            !isLoggedIn ? styles.iniciar : null
                          }`}
                          style={
                            isLoggedIn
                              ? {
                                  backgroundColor: "transparent",
                                  color: "white",
                                }
                              : {}
                          }
                        >
                          {isLoggedIn ? worksTitle : "Iniciar sesión"}
                        </span>
                      </span>
                    )}
                  </NavLink>
                  <div
                    className={`${styles.profileMenu_item} ${styles.labelCloseSession}`}
                    onClick={(e) => close(e, history)}
                    style={
                      localStorage.getItem("role") !== "ADMIN"
                        ? localStorage.getItem("isPro") === "false"
                          ? {
                              backgroundColor: "#320FCC",
                              borderRadius: "20px",
                              padding: "8px 15px",
                              color: "white",
                              width: "100%",
                            }
                          : localStorage.getItem("isPro") === "true"
                          ? {
                              backgroundColor: "#FF6E5D",
                              borderRadius: "20px",
                              padding: "8px 15px",
                              color: "white",
                              width: "100%",
                            }
                          : null
                        : { backgroundColor: "gray" }
                    }
                  >
                    <img src={ClosePro} alt="" />

                    <Text
                      tag="span"
                      size="m"
                      align="left"
                      style={{ color: "white", marginLeft: "0.5rem" }}
                    >
                      Cerrar Sesión
                    </Text>
                  </div>
                  <div className={styles.menuInfo}>
                    <span>¿Consultas o sugerencias?</span>
                    <span>info@zenpros.work</span>
                  </div>
                </div>
              </Fade>
            )}
          </div>
        </>
      )
    );
  }
);

const Header = () => {
  useEffect(() => {
    if (window.location.href.includes("web-dev")) {
      window.location.href = "https://www.zenpros.work";
    }
  }, []);

  const [showOptions, setShowOptions] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { isMobile } = useScreenDetect();

  const [menuOpened, setMenuOpened] = useState(false);
  const handleOpenMenu = (value) => {
    setMenuOpened(value);
  };
  const { noDistractionsMode, headerMobileGoBack, modal } = useSelector(
    (state) => state.general
  );
  const isAuth = useSelector((state) => state.auth.isAuth);
  const { profileData, unreadNotifications, unreadNotificationsForPro } = useSelector(
    (state) => state.profile
  );
  
  const isLoggedIn = !!(isAuth && profileData && profileData.name);

  const dispatch = useDispatch();
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (isAuth && window.location.pathname !== "/iniciar-sesion") {
      if (getIsProFromLS() ?? profileData?.isPro) {
        dispatch(getWorksRequests());
      } else {
        dispatch(getUserWorksRequests());
      }
    }
  }, [isAuth]);

  const worksTitle = profileData?.isPro
    ? "Solicitudes de trabajo"
    : "Trabajos solicitados";
  const worksRedirect = profileData?.isPro ? "/trabajos" : "/trabajos-usuario";
  const isInWorks = location.pathname.includes("trabajos");
  const isInProfile =
    location.pathname === "/perfil-profesional" ||
    location.pathname === "/perfil-usuario";

  const menuRef = useRef(null);
  const closeIconRef = useRef(null);
  const profileRef = useRef(null);

  const socketRef = useRef();

  useEffect(() => {
    if (isLoggedIn && connected) {
      window.interval = setInterval(() => {
        if (
          window.location.pathname.includes("chat") ||
          window.location.pathname.includes("registro") ||
          modal === true ||
          !isLoggedIn ||
          getRole() === "ADMIN"
        ) {
          return null;
        } else {
          if (getIsProFromLS() ?? profileData?.isPro) {
            dispatch(getWorksRequests());
          } else {
            dispatch(getUserWorksRequests());
          }
        }
      }, 5000);
      return () => clearInterval(window.interval);
    }
  }, [isLoggedIn, connected, profileData?.isPro, modal]);

  useEffect(() => {
    if (isAuth) {
      if (getRole() === "ADMIN") {
        history.push("/panel-administracion");
        return null;
      }
      socketRef.current = socketIOClient(ENVIRONMENT_URL, {
        query: {
          token: getToken(),
        },
      });

      socketRef.current.on("invalid_token", () => {
        dispatch(loginError());
        deleteToken();
        localStorage.setItem("goback-url", window.location.pathname);
        if (window.location.pathname !== "/iniciar-sesion") {
          window.location.href = "/iniciar-sesion";
        }
      });

      socketRef.current.on("connect", () => {
        socketRef.current.emit("request_all_messages");
      });

      socketRef.current.on("send_all_jobs", (data) => {
        dispatch({
          type: actionTypes.SET_USER_WORKS_LIST,
          payload: data,
        });
      });

      socketRef.current.on("send_all_jobs_by_pro", (data) => {
        dispatch({
          type: actionTypes.SET_WORKS_LIST,
          payload: data,
        });
      });

      socketRef.current.on("connected", (id) => {
        setConnected(true);
      });

      socketRef.current.on("disconnect", (reason) => {
        setConnected(false);
      });

      socketRef.current.on("send_all_messages", (messages) => {
        messages.map((message) => {
          if (profileData.profileMode == "PRO"){
          dispatch(addNotificationForPro("message", `mid${message.id}`));
        } else {
          dispatch(addNotification("message", `mid${message.id}`));
        }
        
        })
      });

      socketRef.current.on("receive_message_notification", (message) => {
        if (window.location.pathname !== `/chat/${message.room.id}`) {
          if (profileData.profileMode == "PRO"){
            dispatch(addNotificationForPro("message", `mid${message.id}`));
          }else {
            dispatch(addNotification("message", `mid${message.id}`));
          }
          
        };
      });
      // socketRef.current.on("send_all_messages_for_pro", (messages) => {
      //   messages.map((message) => {
      //     dispatch(addNotificationForPro("message", `mid${message.id}`));
      //   });
      // });

      // socketRef.current.on("receive_message_notification_for_pro", (message) => {
      //   if (window.location.pathname !== `/chat/${message.room.id}`) {
      //     dispatch(addNotificationForPro("message", `mid${message.id}`));
      //   }
      // });
    }
  }, [socketRef, isAuth]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, []);

  const handleClick = (e) => {
    if (
      menuRef.current.contains(e.target) ||
      closeIconRef.current.contains(e.target) ||
      profileRef?.current?.contains(e.target)
    ) {
      return;
    }
    setMenuOpened(false);
    setShowOptions(false);
  };

  const refrescar = async (e) => {
    e.preventDefault();
    if (window.location.pathname === "/panel-administracion") {
      localStorage.clear();
    }
    window.location.href = "/";
  };

  const handleSlider = () => {
    // Handler del header
    if (profileData.originalProfileType === "USER") {
      const value = {
        name: profileData.name,
        lastName: profileData.lastName,
        email: profileData.email,
      };
      dispatch(setRegisterUserInfo(value));
      sessionStorage.setItem("registerStep", "2");
      sessionStorage.setItem("registerData", JSON.stringify(value));
      setFromSwitchInLS();
      dispatch(setStep(2));
      window.location.href = "/registro-profesionales-vertical";
      return;
    } else {
      if (profileData.profileMode === "PRO") {
        dispatch(setProfileMode("USER", history));
      } else {
        dispatch(setProfileMode("PRO", history));
      }
    }
    setShowOptions(false); // sigue dejando visible al cambiar de Modo
  };

  return (
    <header
      className={`${styles.container} ${noDistractionsMode &&
        !headerMobileGoBack &&
        styles.noDistractionsMode} ${isLoggedIn && styles.loggedIn}`}
      style={
        localStorage.getItem("role") !== "ADMIN"
          ? localStorage.getItem("isPro") === "false"
            ? { backgroundColor: "#FF6E5D" }
            : localStorage.getItem("isPro") === "true"
            ? { backgroundColor: "#320FCC" }
            : null
          : { backgroundColor: "grey" }
      }
    >
      <div
        ref={closeIconRef}
        style={isMobile ? { display: "block" } : { display: "none" }}
      >
        {headerMobileGoBack && (
          <GoBackButton mobile={isMobile} history={history} />
        )}

        {isMobile && window.location.pathname === "/" ? (
          <div className={styles.menuContainer}>
            <div
              onClick={() => handleOpenMenu(!menuOpened)}
              className={styles.menu}
            >
              <MenuIcon
                open={menuOpened}
                clickHandler={handleOpenMenu}
                style={{
                  backgroundColor: isLoggedIn
                    ? "transparent"
                    : "var(--color-item)",
                }}
              />
            </div>

            {isLoggedIn &&
              unreadNotifications?.length > 0 &&
              localStorage.getItem("role") !== "ADMIN" && (
                <NavLink to={worksRedirect}>
                  <span
                    className={styles.worksLength}
                    style={
                      unreadNotifications?.length > 0
                        ? { backgroundColor: "var(--border-error)" }
                        : null
                    }
                  >
                    {unreadNotifications?.length > 9
                      ? "9+"
                      : unreadNotifications?.length ?? 0}
                  </span>
                </NavLink>
              )}
          </div>
        ) : (
          <>
            <div onClick={() => handleOpenMenu(!menuOpened)}>
              <MenuIcon
                open={menuOpened}
                clickHandler={handleOpenMenu}
                style={{
                  backgroundColor: isLoggedIn
                    ? "transparent"
                    : "var(--color-item)",
                }}
              />
            </div>
            {isLoggedIn &&
          unreadNotificationsForPro?.length > 0 &&
          localStorage.getItem("role") !== "ADMIN" &&
          localStorage.getItem("isPro") === "true" ? (
  <NavLink to={worksRedirect}>
              <span
                className={styles.worksLength}
                style={
                  unreadNotificationsForPro?.length > 0
                    ? { backgroundColor: "var(--border-error)" }
                    : null
                }
              >
                {unreadNotificationsForPro?.length > 9
                  ? "9+"
                  : unreadNotificationsForPro?.length ?? 0}
              </span>
            </NavLink>
          ) : (
            <span
              className={styles.worksLength}
              style={{ backgroundColor: "transparent" }}
            ></span>
          )}
                    {isLoggedIn &&
          unreadNotifications?.length > 0 &&
          localStorage.getItem("role") !== "ADMIN" &&
          localStorage.getItem("isPro") === "false" ? (
  <NavLink to={worksRedirect}>
              <span
                className={styles.worksLength}
                style={
                  unreadNotifications?.length > 0
                    ? { backgroundColor: "var(--border-error)" }
                    : null
                }
              >
                {unreadNotifications?.length > 9
                  ? "9+"
                  : unreadNotifications?.length ?? 0}
              </span>
            </NavLink>
          ) : (
            <span
              className={styles.worksLength}
              style={{ backgroundColor: "transparent" }}
            ></span>
          )}
          </>
        )}
      </div>

      <div className={styles.logoContainer} onClick={(e) => refrescar(e)}>
        {localStorage.getItem("isPro") === "true" ? (
          <LogoUser className={styles.logo} />
        ) : localStorage.getItem("isPro") === "false" ? (
          <LogoPro className={styles.logo} />
        ) : (
          <Logo className={styles.logo} />
        )}
      </div>

      {isMobile && isLoggedIn && (
        <div className={styles.avatar}>
          <Avatar image={profileData.photo} fromHeader />
        </div>
      )}
      <div className={`${menuOpened && styles.background}`} />
      <div
        ref={menuRef}
        className={`${styles.rightContent} ${menuOpened && styles.opened}`}
      >
        {menuOpened && (
          <img
            className={styles.closeCrossMobile}
            src={NewCloseCross}
            onClick={() => handleOpenMenu(false)}
          />
        )}
        {!isLoggedIn && localStorage.getItem("role") !== "ADMIN" && (
          <Link
            to="/registro-profesionales"
            className={`${styles.buttonLink} ${styles.link} ${styles.spacing}`}
          >
            <Button
              customStyles={{
                width: isMobile ? "197px" : "",
                height: isMobile ? "39px" : "4rem",
                lineHeight: isMobile ? "10px" : "",
                borderRadius: isMobile ? "10px" : "10rem",
              }}
            >
              Ofrecé tus servicios
            </Button>
          </Link>
        )}
        {isLoggedIn && (
          <Separator
            type="big"
            margin="4"
            styles={
              menuOpened && {
                width: "240px",
                height: "2px",
                margin: "3rem auto 2rem",
              }
            }
          />
        )}
        {isMobile && isLoggedIn ? null : (
          <>
            <NavLink
              to={
                isLoggedIn
                  ? profileData.isPro
                    ? "/perfil-profesional"
                    : "/perfil-usuario"
                  : "/registro"
              }
              className={`${styles.link} ${styles.spacing}`}
              activeClassName={isLoggedIn && !isMobile && styles.active}
              onClick={() => handleOpenMenu(false)}
            >
              {!isLoggedIn && localStorage.getItem("role") !== "ADMIN" && (
                <Button
                  customStyles={{
                    background: "#ff6e5d",
                    width: isMobile ? "197px" : "",
                    height: isMobile ? "39px" : "4rem",
                    lineHeight: isMobile ? "10px" : "",
                    borderRadius: isMobile ? "10px" : "10rem",
                    transform: isMobile ? "translateY(10px)" : "",
                  }}
                >
                  Contratá servicios
                </Button>
              )}
            </NavLink>
            {localStorage.getItem("role") !== "ADMIN" && (
              <div>
                {isLoggedIn && profileData.isPro && (
                  // Slider del header
                  <Slider handleSlider={handleSlider} isOn />
                )}
                {isLoggedIn && !profileData.isPro && (
                  // Slider del header
                  <Slider handleSlider={handleSlider} isOn={false} />
                )}
              </div>
            )}
            <NavLink
              to={isLoggedIn ? worksRedirect : "/iniciar-sesion"}
              className={`${styles.link} ${styles.spacing}`}
              activeClassName={isLoggedIn && !isMobile && styles.active}
              onClick={() => handleOpenMenu(false)}
            >
              {localStorage.getItem("role") !== "ADMIN" && (
                <div>
                  <span
                    className={`${styles.loginText} ${
                      !isLoggedIn ? styles.iniciar : null
                    }`}
                    style={
                      isLoggedIn
                        ? { backgroundColor: "transparent", color: "white" }
                        : null
                    }
                  >
                    {!isLoggedIn && "Iniciar sesión"}
                  </span>
                </div>
              )}
            </NavLink>
            {isLoggedIn && localStorage.getItem("role") !== "ADMIN" && (
              <NavLink to={isLoggedIn ? worksRedirect : "/iniciar-sesion"} />
            )}
          </>
        )}
        <Profile
          isLoggedIn={isLoggedIn}
          isMobile={isMobile}
          profileData={profileData}
          isInProfile={isInProfile}
          profileRef={profileRef}
          showOptions={isMobile ? menuOpened : showOptions}
          setShowOptions={isMobile ? setMenuOpened : setShowOptions}
          worksTitle={localStorage.getItem("role") !== "ADMIN" && worksTitle}
          worksRedirect={worksRedirect}
          isInWorks={isInWorks}
          history={history}
          unreadNotifications={unreadNotifications}
          unreadNotificationsForPro={unreadNotificationsForPro}
        />
      </div>
    </header>
  );
};

export default withRouter(Header);
