import React, { useEffect } from "react";
import ProfileContainer from "@organism/Profile/Profile";
import styles from "./styles.module.scss";
import { userMe, professionalMe } from "@actions/generalActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const ProfessionalProfile = () => {
  const dispatch = useDispatch();
  let footerOnb = useSelector((state) => state["general"]["footerOnboarding"]);
  let firstLogin = useSelector((state) => state["general"]["firstLogin"]);
  const history = useHistory();
  
  useEffect(() => {
    dispatch(userMe());
  }, []);

  useEffect(() => {
    professionalMe(history, footerOnb, firstLogin);
  }, []);

  return (
    <div className={styles.profileContainer}>
      <ProfileContainer />
    </div>
  );
};

export default ProfessionalProfile;
