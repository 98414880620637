import * as React from 'react'

function ErrorModalSvg (props) {
  return (
    <svg
      width={110}
      height={97}
      viewBox='0 0 110 97'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#prefix__clip0)'>
        <path
          d='M54.087 81.998c21.538 0 39-17.461 39-39.077 0-21.615-17.539-39.076-39-39.076-21.539 0-39 17.461-39 39.076 0 21.616 17.461 39.077 39 39.077z'
          fill='#DEDEDE'
        />
        <path
          opacity={0.6}
          d='M95.164 4.998a2.154 2.154 0 100-4.308 2.154 2.154 0 000 4.308zM17.164 17.23a2.154 2.154 0 100-4.308 2.154 2.154 0 000 4.307zM4.01 58.768a4 4 0 100-8 4 4 0 000 8z'
          fill='#DEDEDE'
        />
        <g filter='url(#prefix__filter0_d)'>
          <path
            d='M91.25 22.396v45.26a2.456 2.456 0 01-2.435 2.434H20.04c-1.328.056-2.435-1.051-2.435-2.379V22.396a2.42 2.42 0 012.435-2.434h68.775a2.42 2.42 0 012.434 2.434z'
            fill='url(#prefix__paint0_linear)'
          />
        </g>
        <path
          d='M91.25 22.396v3.486H17.604v-3.486a2.42 2.42 0 012.435-2.434h68.775a2.42 2.42 0 012.434 2.434z'
          fill='#FF6E5D'
        />
        <path
          opacity={0.5}
          d='M44.274 43.421a2.047 2.047 0 100-4.094 2.047 2.047 0 000 4.094zM64.635 43.421a2.047 2.047 0 100-4.094 2.047 2.047 0 000 4.094zM57.775 56.866h-6.419c-.996 0-1.826-.83-1.826-1.826 0-.995.83-1.825 1.826-1.825h6.363c.996 0 1.826.83 1.826 1.825.055.996-.775 1.826-1.77 1.826z'
          fill='#0B1A32'
        />
        <path
          d='M20.87 23.945a.83.83 0 100-1.66.83.83 0 000 1.66zM23.47 23.945a.83.83 0 100-1.66.83.83 0 000 1.66zM26.126 23.945a.83.83 0 100-1.66.83.83 0 000 1.66z'
          fill='#F9FAFB'
        />
        <path
          d='M88.261 14.097a.992.992 0 01-.996-.996V1.979c0-.553.443-.996.996-.996.554 0 .996.443.996.996v11.122a.991.991 0 01-.996.995zM96.34 22.617c0-.553.442-.996.995-.996h11.122c.553 0 .995.443.995.996a.991.991 0 01-.995.996H97.335a.992.992 0 01-.996-.996zM95.454 15.424a1.037 1.037 0 010-1.438l7.912-7.857a1.036 1.036 0 011.439 0 1.036 1.036 0 010 1.439l-7.857 7.856c-.443.388-1.106.388-1.494 0z'
          fill='#FF6E5D'
        />
      </g>
      <defs>
        <linearGradient
          id='prefix__paint0_linear'
          x1={54.403}
          y1={18.802}
          x2={54.403}
          y2={70.633}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FDFEFF' />
          <stop offset={0.996} stopColor='#ECF0F5' />
        </linearGradient>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' d='M0 0h110v96.154H0z' />
        </clipPath>
        <filter
          id='prefix__filter0_d'
          x={-4.395}
          y={8.962}
          width={117.644}
          height={94.131}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy={11} />
          <feGaussianBlur stdDeviation={11} />
          <feColorMatrix values='0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default ErrorModalSvg
