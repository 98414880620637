import React, { useState, useEffect } from "react";
import styles from "./AdministrationPanelNew.module.scss";
import Usuarios from "./assets/usuarios.svg";
import UsuariosSelect from "./assets/usuariosSelect.svg";
import Profesionales from "./assets/profesionales.svg";
import Trabajos from "./assets/trabajos.svg";
import TrabajosSelect from "./assets/trabajosSelect.svg";
import Exportar from "./assets/exportar.svg";
import Users from "./Tables/Users";
import Professional from "./Tables/Professional";
import { axiosGet, axiosPost, setTokenInAxios } from "@config/api";
import Select from "@atoms/Select/Select";
import { options } from "./functions/functions";
import useScreenDetect from "@hooks/useScreenDetect";
import { jobs } from "./functions/functions";
import Jobs from "@pages/AdministrationPanelNew/Tables/Jobs";
import { Spin as Hamburger } from "hamburger-react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Slide from "react-reveal/Slide";
import { CSVLink } from "react-csv";
import { useParams, useHistory } from "react-router-dom";
import DetailPro from "./DetailPro";
import { professional } from '@actions/panelActions';
import { useDispatch, useSelector } from 'react-redux';

const AdministrationPanelNew = () => {
  const [data, setData] = useState([]);
  const params = useParams();
  const history = useHistory();
  const { isMobile } = useScreenDetect();
  const [categorias, setCategorias] = useState([]);
  const [profesionales, setProfesionales] = useState([]);
  const [trabajos, setTrabajos] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [count, setCount] = useState({});
  const [loading, setLoading] = useState();
  const [title, setTitle] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [menu, setMenu] = useState({
    usuarios: true,
    profesionales: {
      mode: false,
      subTodos: false,
      subCategoria: false,
      subProfesion: false,
      subZona: false,
    },
    trabajos: false,
  });
  
  const dispatch = useDispatch();
  let user = useSelector(state => state.panel.professional);

  useEffect(() => {
    if (params.id) {
      axiosGet(`/admin/users/${params.id}`).then((response) => {
        dispatch(professional(response.data));
      });
    }
  }, [params.id]);

  useEffect(() => {
    usuariosMode();
    getCategorias();
    getProfesionales();
    getZonas();
    setTrabajos(jobs);
  }, []);

  const selectMenu = (e) => {
    e.preventDefault();
    let name = e.target.name;

    switch (name) {
      case "usuarios":
        usuariosMode();
        break;
      case "profesionales":
        profesionalesMode();
        break;
      case "trabajos":
        trabajosMode();
        break;
      default:
        break;
    }
  };

  const usuariosMode = async () => {
    history.push("/panel-administracion");
    adminPanelUs();
    adminPanelCountUs();

    setLoading(false);
    setMenu({
      usuarios: true,
      profesionales: {
        mode: false,
        todos: false,
        categoria: false,
        profesion: false,
        zona: false,
      },
      trabajos: { mode: false },
    });
  };

  const adminPanelUs = async () => {
    await axiosGet("admin/panel/us")
      .then((response) => {
        if (response.data.length === 0)
          setData("No hay usuarios para mostrar.");
        setData(response.data);
      })
      .catch((error) => console.error(error));
  };

  const adminPanelCountUs = async () => {
    await axiosGet("admin/panel/countUs")
      .then((response) => {
        setCount(response.data);
        setTitle(`Usuarios (${response.data})`);
      })
      .catch((error) => console.error(error));
  };

  const profesionalesMode = () => {
    history.push("/panel-administracion");
    setLoading(false);
    setMenu({
      usuarios: false,
      profesionales: {
        mode: true,
        todos: false,
        categoria: false,
        profesion: false,
        zona: false,
      },
      trabajos: { mode: false },
    });
    adminPanelPro();
    adminPanelCountPros();
  };

  const trabajosMode = async () => {
    history.push("/panel-administracion");
    setLoading(false);
    setMenu({
      usuarios: false,
      profesionales: {
        mode: false,
        todos: false,
        categoria: false,
        profesion: false,
        zona: false,
      },
      trabajos: { mode: true },
    });

    await axiosGet("admin/panel/allJobs").then((response) => {
      parseDate(response.data);
      setTitle(`Trabajos - Todos (${response.data.length})`);
    });
  };

  const todosPros = async (e) => {
    e.preventDefault();
    adminPanelPro();
    adminPanelCountPros();
  };

  const adminPanelPro = async () => {
    await axiosGet("admin/panel/pro").then((response) => {
      setData(response.data);
      setMenu({
        usuarios: false,
        profesionales: {
          mode: true,
          todos: true,
          categoria: false,
          profesion: false,
          zona: false,
        },
        trabajos: { mode: false },
      });
    });
  };

  const adminPanelCountPros = async (title) => {
    await axiosGet("admin/panel/countPros")
      .then((response) => {
        setCount(response.data);
        switch (title) {
          case "categorias":
            return setTitle(
              `Profesionales - Por Categorias (${response.data})`
            );
          case "profesion":
            return setTitle(`Profesionales - Por Profesión (${response.data})`);
          case "zona":
            return setTitle(`Profesionales - Por Zona (${response.data})`);
          default:
            return setTitle(`Profesionales - Todos (${response.data})`);
        }
      })
      .catch((error) => console.error(error));
  };

  const subPros = (e) => {
    e.preventDefault();
    let name = e.target.name;
    if (name === "categoria") return subProsCategories(e);
    if (name === "profesion") return subProsProfessionals(e);
    if (name === "zona") return subProsZonas(e);
  };

  const subProsCategories = async (e) => {
    setMenu({
      usuarios: false,
      profesionales: {
        mode: true,
        todos: false,
        categoria: !menu.profesionales.categoria,
        profesion: false,
        zona: false,
      },
      trabajos: { mode: false },
    });

    if (
      menu.profesionales.categoria ||
      (!menu.profesionales.categoria &&
        (menu.profesionales.profesion || menu.profesionales.zona))
    ) {
      await axiosGet("admin/panel/pro").then((response) => {
        setData(response.data);
      });
    }
    adminPanelCountPros("categorias");
  };

  const subProsProfessionals = async (e) => {
    setMenu({
      usuarios: false,
      profesionales: {
        mode: true,
        todos: false,
        categoria: false,
        profesion: !menu.profesionales.profesion,
        zona: false,
      },
      trabajos: { mode: false },
    });

    if (
      menu.profesionales.profesion ||
      (!menu.profesionales.profesion &&
        (menu.profesionales.categoria || menu.profesionales.zona))
    ) {
      await axiosGet("admin/panel/pro").then((response) => {
        setData(response.data);
      });
    }
    adminPanelCountPros("profesion");
  };

  const subProsZonas = async (e) => {
    setMenu({
      usuarios: false,
      profesionales: {
        mode: true,
        todos: false,
        categoria: false,
        profesion: false,
        zona: !menu.profesionales.zona,
      },
      trabajos: { mode: false },
    });

    if (
      menu.profesionales.zona ||
      (!menu.profesionales.zona &&
        (menu.profesionales.categoria || menu.profesionales.profesion))
    ) {
      await axiosGet("admin/panel/pro").then((response) => {
        setData(response.data);
      });
    }
    adminPanelCountPros("zona");
  };

  const getCategorias = async () => {
    await axiosGet("admin/panel/getCategories")
      .then((response) => {
        let dataCategories = response.data.map((categoria) => categoria.name);
        setCategorias(dataCategories);
      })
      .catch((error) => console.error("Error al obtener las categorías"));
  };

  const selectCategories = async (e) => {
    history.push("/panel-administracion");
    let value = e.target.value;
    if (value !== "-") {
      await axiosGet(`admin/panel/filterCategory/${value}`)
        .then((response) => {
          setData(response.data);
          setTitle(`Profesionales - ${value} (${response.data.length})`);
        })
        .catch((error) => console.error(error));
    } else {
      await axiosGet("admin/panel/pro").then((response) => {
        setData(response.data);
        setTitle(`Profesionales - Por Categorías (${response.data.length})`);
      });
    }
  };

  const getProfesionales = async () => {
    await axiosGet("admin/panel/getProfessions")
      .then((response) => {
        let dataProfesionales = response.data.map(
          (profesional) => profesional.name
        );
        setProfesionales(dataProfesionales);
      })
      .catch((error) => console.error("Error al obtener los profesionales"));
  };

  const getZonas = async () => {
    await axiosGet("admin/panel/getAreas")
      .then((response) => {
        let dataZonas = response.data.map((zona) => zona.name);
        setZonas(dataZonas);
      })
      .catch((error) => console.error("Error al obtener las zonas"));
  };

  const selectProfessionals = async (e) => {
    // e.preventDefault();
    history.push("/panel-administracion");
    const value = e.target.value;
    const valueQuery = e.target.value.replace("/", "-");
    if (value !== "-") {
      await axiosGet(`admin/panel/filterProfession/${valueQuery}`)
        .then((response) => {
          setData(response.data);
          setTitle(`Profesionales - ${value} (${response.data.length})`);
        })
        .catch((error) => console.error(error));
    } else {
      await axiosGet("admin/panel/pro").then((response) => {
        setData(response.data);
        setTitle(`Profesionales - Por Profesión (${response.data.length})`);
      });
    }
  };

  const selectZonas = async (e) => {
    // e.preventDefault();
    history.push("/panel-administracion");
    const value = e.target.value;

    if (value !== "-") {
      await axiosGet(`admin/panel/filterZonas/${value}`)
        .then((response) => {
          setData(response.data);
          setTitle(`Profesionales - ${value} (${response.data.length})`);
        })
        .catch((error) => console.error(error));
    } else {
      await axiosGet("admin/panel/pro").then((response) => {
        setData(response.data);
        setTitle(`Profesionales - Por Zona (${response.data.length})`);
      });
    }
  };

  const selectJobs = async (e) => {
    // e.preventDefault();
    history.push("/panel-administracion");
    const value = e.target.value;

    const name =
      value === "approved"
        ? "Aprobados"
        : value === "work_completed"
        ? "Completados"
        : value === "work_not_completed"
        ? "No Completados"
        : value === "pending"
        ? "Pendientes"
        : value === "rejected"
        ? "Rechazados"
        : value === "finished"
        ? "Finalizados"
        : value === "all"
        ? "Todos"
        : null;

    if (value !== "-" && value !== "all") {
      await axiosGet(`admin/panel/filterJobs/${value.toUpperCase()}`)
        .then((response) => {
          parseDate(response.data);
          setTitle(`Trabajos - ${name} (${response.data.length})`);
        })
        .catch((error) => console.error(error));
    } else {
      await axiosGet("admin/panel/allJobs").then((response) => {
        parseDate(response.data);
        setTitle(`Trabajos - Todos (${response.data.length})`);
      });
    }
  };

  const parseDate = (data) => {
    let resultData = data.map((job) => {
      let created = job.created.replace("T", " ");
      let index = created.indexOf(".");
      created = created.slice(0, index);

      return { ...job, created };
    });

    setData(resultData);
  };

  // =================   Exportar data a 'csv' =============================
  let headersUsers;
  let headersPros;
  let headersJobs;
  let dataPros;
  let dataJobs;

  let csvReport;

  headersUsers = [
    { label: "Nombre", key: "name" },
    { label: "Apellido", key: "lastName" },
    { label: "Email", key: "email" },
    { label: "Teléfono", key: "phone" },
  ];

  dataPros = data.map((pro) => {
    let professional = pro;
    if (pro.professionalProfileCategory?.length > 2) {
      professional = { ...professional, servicios: "SI" };
    } else {
      professional = { ...professional, servicios: "NO" };
    }

    if (pro.description) {
      professional = { ...professional, sobreMi: "SI" };
    } else {
      professional = { ...professional, sobreMi: "NO" };
    }

    professional = { ...professional, reviews: professional['reviews']?.length };
    
    return professional;
  });

  headersPros = [
    { label: "Nombre", key: "user.name" },
    { label: "Apellido", key: "user.lastName" },
    { label: "Alias", key: "fantasy" },
    { label: "Email", key: "user.email" },
    { label: "Teléfono", key: "user.phone" },
    {
      label: "Profesión",
      key: "professionalProfileCategory[1].category.name",
    },
    { label: "Residencia", key: "residenceArea" },
    { label: "Valoraciones", key: "reviews" },
    { label: "Promedio", key: "avgReviews" },
    { label: "Servicios", key: "servicios" },
    { label: "Sobre mí", key: "sobreMi" },
  ];

  dataJobs = data.map((job) => {
    let jobsData = job;
    let nameProComplete;
    let categoria;
    let profesion;
    let cliente;

    nameProComplete = `${job.professionalProfile?.user.name} ${job.professionalProfile?.user.lastName}`;
    categoria =
      job.professionalProfile?.professionalProfileCategory?.[0].category.name;
    profesion =
      job.professionalProfile?.professionalProfileCategory?.[1].category.name;
    cliente = `${job.client?.name} ${job.client?.lastName}`;

    jobsData = {
      ...jobsData,
      professionalNameComplete: nameProComplete,
      categoria,
      profesion,
      cliente,
    };

    return jobsData;
  });

  headersJobs = [
    { label: "Fecha Creacion", key: "created" },
    { label: "Categoría", key: "categoria" },
    { label: "Profesión", key: "profesion" },
    { label: "Cliente", key: "cliente" },
    { label: "Email (Cli)", key: "client.email" },
    { label: "Teléfono (Cli)", key: "client.phone" },
    { label: "PROFESIONAL (Pro)", key: "professionalNameComplete" },
    { label: "Email (Pro)", key: "professionalProfile.user.email" },
    { label: "Teléfono (Pro)", key: "professionalProfile.user.phone" },
    { label: "Residencia (Pro)", key: "professionalProfile.residenceArea" },
    { label: "Estado", key: "jobStatus" },
    { label: "¿Leído?", key: "jobStatusNewRead" },
  ];

  csvReport = {
    filename: "Zenpros.csv",
    headers: menu.usuarios
      ? headersUsers
      : menu.profesionales.mode
      ? headersPros
      : headersJobs,
    data: menu.usuarios ? data : menu.profesionales.mode ? dataPros : dataJobs,
  };

  // ======================================================================

  // setMenu({
  //   usuarios: false,
  //   profesionales: {
  //     mode: true,
  //     todos: false,
  //     categoria: false,
  //     profesion: !menu.profesionales.profesion,
  //     zona: false,
  //   },
  //   trabajos: { mode: false },
  // });

  return (
    <div className={styles.panelContainer}>
      <div className={`${styles.hamburguer}`}>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
      {isOpen && (
        <Slide top>
          <section className={styles.hamburguerContainer}>
            <section className={styles.sectionFirstMobile}>
              <a href="" onClick={(e) => selectMenu(e)}>
                <img
                  src={menu.usuarios ? UsuariosSelect : Usuarios}
                  alt=""
                  name="usuarios"
                />
              </a>
              <a href="" onClick={(e) => selectMenu(e)} id="profesionales">
                <img
                  src={menu.profesionales.mode ? Profesionales : Profesionales}
                  alt=""
                  name="profesionales"
                />
              </a>
              {menu.profesionales.mode && (
                <ul>
                  <li>
                    <a
                      href=""
                      onClick={(e) => todosPros(e)}
                      id="todos"
                      name="todos"
                    >
                      Todos
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      onClick={(e) => subPros(e)}
                      id="categoria"
                      name="categoria"
                    >
                      Por Categoría
                    </a>
                  </li>
                  {menu.profesionales.mode && menu.profesionales.categoria && (
                    <div className={styles.select}>
                      <select
                        className={styles.select}
                        onChange={(e) => selectCategories(e)}
                      >
                        <option key="-" value="-">
                          -
                        </option>
                        {categorias.map((categoria, index) => {
                          return (
                            <option key={index} value={categoria}>
                              {categoria}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <li>
                    <a
                      href=""
                      onClick={(e) => subPros(e)}
                      id="profesion"
                      name="profesion"
                    >
                      Por Profesión
                    </a>
                  </li>
                  {menu.profesionales.mode && menu.profesionales.profesion && (
                    <div className={styles.select}>
                      <select
                        className={styles.select}
                        onChange={(e) => selectProfessionals(e)}
                      >
                        <option key="-" value="-">
                          -
                        </option>
                        {profesionales.map((profesional, index) => {
                          return (
                            <option key={index} value={profesional}>
                              {profesional}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <li>
                    <a
                      href=""
                      onClick={(e) => subPros(e)}
                      id="zona"
                      name="zona"
                    >
                      Por Zona
                    </a>
                  </li>
                  {menu.profesionales.mode && menu.profesionales.zona && (
                    <div className={styles.select}>
                      <select
                        className={styles.select}
                        onChange={(e) => selectZonas(e)}
                      >
                        <option key="-" value="-">
                          -
                        </option>
                        {zonas.map((zona, index) => {
                          return (
                            <option key={index} value={zona}>
                              {zona}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                </ul>
              )}
              <a href="" onClick={(e) => selectMenu(e)}>
                <img
                  src={menu.trabajos.mode ? TrabajosSelect : Trabajos}
                  alt=""
                  name="trabajos"
                />
              </a>
              {menu.trabajos.mode && (
                <div className={styles.select}>
                  <select
                    className={styles.select}
                    onChange={(e) => selectJobs(e)}
                  >
                    <option key="-" value="-">
                      -
                    </option>
                    {trabajos.map((trabajo, index) => {
                      return (
                        <option key={index} value={trabajo.value}>
                          {trabajo.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </section>
          </section>
        </Slide>
      )}
      <section className={styles.sectionFirst}>
        <a href="" onClick={(e) => selectMenu(e)}>
          <img
            src={menu.usuarios ? UsuariosSelect : Usuarios}
            alt=""
            name="usuarios"
          />
        </a>
        <a href="" onClick={(e) => selectMenu(e)} id="profesionales">
          <img
            src={menu.profesionales.mode ? Profesionales : Profesionales}
            alt=""
            name="profesionales"
          />
        </a>
        {menu.profesionales.mode && (
          <ul>
            <li>
              <a href="" onClick={(e) => todosPros(e)} id="todos" name="todos">
                Todos
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={(e) => subPros(e)}
                id="categoria"
                name="categoria"
              >
                Por Categoría
              </a>
            </li>
            {menu.profesionales.mode && menu.profesionales.categoria && (
              <div className={styles.select}>
                <select
                  className={styles.select}
                  onChange={(e) => selectCategories(e)}
                >
                  <option key="-" value="-">
                    -
                  </option>
                  {categorias.map((categoria, index) => {
                    return (
                      <option key={index} value={categoria}>
                        {categoria}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <li>
              <a
                href=""
                onClick={(e) => subPros(e)}
                id="profesion"
                name="profesion"
              >
                Por Profesión
              </a>
            </li>
            {menu.profesionales.mode && menu.profesionales.profesion && (
              <div className={styles.select}>
                <select
                  className={styles.select}
                  onChange={(e) => selectProfessionals(e)}
                >
                  <option key="-" value="-">
                    -
                  </option>
                  {profesionales.map((profesional, index) => {
                    return (
                      <option key={index} value={profesional}>
                        {profesional}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <li>
              <a href="" onClick={(e) => subPros(e)} id="zona" name="zona">
                Por Zona
              </a>
            </li>
            {menu.profesionales.mode && menu.profesionales.zona && (
              <div className={styles.select}>
                <select
                  className={styles.select}
                  onChange={(e) => selectZonas(e)}
                >
                  <option key="-" value="-">
                    -
                  </option>
                  {zonas.map((zona, index) => {
                    return (
                      <option key={index} value={zona}>
                        {zona}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </ul>
        )}
        <a href="" onClick={(e) => selectMenu(e)}>
          <img
            src={menu.trabajos.mode ? TrabajosSelect : Trabajos}
            alt=""
            name="trabajos"
          />
        </a>
        {menu.trabajos.mode && (
          <div className={styles.select}>
            <select className={styles.select} onChange={(e) => selectJobs(e)}>
              <option key="-" value="-">
                -
              </option>
              {trabajos.map((trabajo, index) => {
                return (
                  <option key={index} value={trabajo.value}>
                    {trabajo.name}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </section>

      {!params.id && (
        <section className={styles.sectionTwo}>
          <div className={styles.firstLine}>
            <span>{title}</span>
            <a href="">
              <CSVLink {...csvReport}>
                <img src={Exportar} alt="" />
              </CSVLink>
            </a>
          </div>
          <div className={styles.secondLine}></div>
          <div className={styles.thirdLine}>
            {data.length > 0 && menu.usuarios && <Users data={data} panel />}
            {data.length > 0 &&
              (menu.profesionales.todos ||
                menu.profesionales.categoria ||
                menu.profesionales.profesion ||
                menu.profesionales.zona) && <Professional data={data} panel />}
            {data.length > 0 && menu.trabajos.mode && (
              <Jobs data={data} panel />
            )}
          </div>
        </section>
      )}
      {params.id && (
        <h1>
          <DetailPro user={user} />
        </h1>
      )}
    </div>
  );
};

export default AdministrationPanelNew;
