import React, { useState } from 'react'
import styles from './SlideCheckbox.module.scss'

const SlideCheckbox = ({
  name = '',
  name2 = '',
  onChange,
  customStyles,
  reverse,
  isOn,
  backgroundColor,
  uncheckedBackgroundOpacity,
  beforeIcon,
  afterIcon
}) => {
  const [checked, setChecked] = useState(isOn)
  const handleClick = () => {
    onChange()
    setChecked((isOn) => !isOn)
  }
  const customCss = {
    '--background-before': `url(${beforeIcon}) no-repeat`,
    '--background-after': `url(${afterIcon}) no-repeat`
  }
  return (
    <div
      className={`${styles.slideCheckboxContainer} ${reverse && styles.reverse}`}
      onClick={handleClick}
      style={customStyles}
    >
      <p className={styles.slideCheckboxName}>{name}</p>
      <div className={`${styles.slideCheckbox} ${checked && styles.checked} ${!checked && styles.unchecked}`} style={{ backgroundColor: '#ccc', opacity: uncheckedBackgroundOpacity }}>
        <span className={styles.slideCheckboxSelect} style={customCss} />
      </div>
      <p className={styles.slideCheckboxName}>{name2}</p>
      
    </div>
  )
}

export default SlideCheckbox
