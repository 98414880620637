import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import RadioButtonGroup from '@molecules/RadioButtonGroup/RadioButtonGroup'
import ShadowBox from '@atoms/ShadowBox/ShadowBox'
import Button from '@atoms/Button/Button'
import Separator from '@atoms/Separator/Separator'
import useScreenDetect from '@hooks/useScreenDetect'
import { useDispatch, useSelector } from 'react-redux'
import { cleanCategorySons, getProsProfessions } from '@actions/generalActions'
import { setRegisterArea, setRegisterCategory } from '@actions/registerActions'
let loading = true

const Step4 = ({ handleClick, suggestAction }) => {
  const [selectedValue, setSelectedValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const { registerInfo } = useSelector(
    (state) => state.register
  )

  const { allCategories } = useSelector((state) => state.general)

  const dispatch = useDispatch()

  const { isMobile } = useScreenDetect()

  const handleSelectValue = (value) => {
    if (value.name === 'Otro') {
      return setSelectedValue({ name: inputValue, id: value.id })
    }
    setSelectedValue(value)

    dispatch(setRegisterCategory(value.parentcategory))
    dispatch(setRegisterArea(value))
  }

  useEffect(() => {
    localStorage.setItem('selectedValue', JSON.stringify(selectedValue))
  }, [selectedValue])

  useEffect(() => {
    if (allCategories.length === 0) {
      dispatch(getProsProfessions())
    }
  }, [allCategories])

  const handleInputChange = (value) => {
    setInputValue(value)
    setSelectedValue({ name: value, id: selectedValue.id })
  }
  const handleNextClick = () => {
    if (selectedValue && selectedValue.id === null) { return suggestAction(selectedValue) }

    dispatch(cleanCategorySons())
    handleClick(selectedValue)
    // setRegisterServicesValues(registerInfo)
  }

  const handleBackClick = () => {
    dispatch(cleanCategorySons())
    handleClick(false)
  }

  useEffect(() => {
    if (registerInfo.area.name) setSelectedValue(registerInfo.area)
    if (allCategories.length < 1)
    // dispatch(getCategorySons(registerInfo.category.id));
    { dispatch(getProsProfessions()) }
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line no-extend-native
  Array.prototype.order = function (prop, methods = {}) {
    if (prop?.constructor === Object) {
      methods = prop
      prop = null
    }
    const [orderType_a, orderType_b] = methods.reverse ? [1, -1] : [-1, 1]

    const $ = x => prop
      ? methods.insensitive
          ? String(x[prop]).toLowerCase()
          : x[prop]
      : methods.insensitive
        ? String(x).toLowerCase()
        : x

    const fn = (a, b) => $(a) < $(b) ? orderType_a : $(b) < $(a) ? orderType_b : 0
    return this.sort(fn)
  }

  return (
    allCategories.length > 0 && (
      <motion.div
        initial={{ x: loading ? 500 : 0 }}
        animate={{ x: 0 }}
        transition={{ duration: loading ? 0.4 : 0 }}
      >
        {loading = false}
        <ShadowBox
          noShadow={isMobile}
          customStyles={{
            minWidth: isMobile ? '' : '1100px',
            maxWidth: isMobile ? '' : '1400px'
          }}
        >
          <RadioButtonGroup
            title='Seleccioná de la lista el área en la cual te desempeñás.'
            options={allCategories.order('name')}
            handleSelect={handleSelectValue}
            selectedValue={selectedValue}
            allowSuggest
            handleInputChange={handleInputChange}
            mode={isMobile ? 'noMaxHeight' : ''}
          />
          <Separator large='100%' horizontal margin='2' />
          <div style={{ display: 'inline' }}>
            <div style={{ width: '12.4rem', display: 'none' }} />
            <Button
              isDisabled={
                !selectedValue ||
                (selectedValue &&
                  (selectedValue.name === '' || selectedValue.name === 'Otro'))
              }
              customStyles={{
                maxWidth:
                  selectedValue && selectedValue.id === null
                    ? '17.2rem'
                    : '12.4rem',
                whiteSpace: 'nowrap',
                marginBottom: 'var(--spacing-extrasmall)',
                marginLeft: 'auto',
                marginRight: '0'
              }}
              onClickHandler={() => handleNextClick()}
            >
              {selectedValue && selectedValue.id === null
                ? 'Sugerir área'
                : 'Siguiente'}
            </Button>
          </div>
        </ShadowBox>
      </motion.div>
    )
  )
}

export default Step4
