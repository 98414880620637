import Button from '@atoms/Button/Button'
import ModalTitle from '@atoms/ModalTitle/ModalTitle'
import RadioButtonGroup from '@molecules/RadioButtonGroup/RadioButtonGroup'
import React, { useEffect, useState } from 'react'
import { setMobileFilter } from '@actions/profileActions'
import { useDispatch } from 'react-redux'
import styles from './WorksModals.module.scss'
import { setModal } from '@actions/generalActions'
const WorkSort = ({ mobileFilter, type }) => {
  const [sortSelected, setSortSelected] = useState('')

  const dispatch = useDispatch()

  const handleSortWorks = (filter) => {
    dispatch(setMobileFilter(filter))
    dispatch(setModal(false))
  }
  const handleSelectSort = (value) => {
    setSortSelected(value)
  }
  const options = () => {
    switch (type) {
      case 'sortFilterPending':
        return [
          { name: 'Todas', id: 0, idValue: '' },
          { name: 'Nuevo', id: 1, idValue: 'NEW' },
          { name: 'Leído', id: 2, idValue: 'READ' }
        ]
      case 'sortFilterFinished':
        return [
          { name: 'Pendiente de calificación', id: 1 },
          { name: 'Calificado', id: 2 }
        ]
      default:
        break
    }
  }
  useEffect(() => {
    if (mobileFilter?.length > 0) handleSelectSort(mobileFilter[0])
  }, [])

  return (
    <div className={styles.sortModal}>
      <ModalTitle title='Ordenar' />
      <RadioButtonGroup
        options={options()}
        selectedValue={sortSelected}
        handleSelect={handleSelectSort}
        customStyles={{
          border: 'unset',
          paddingBottom: 'var(--spacing-small)',
          marginBottom: '0'
        }}
      />
      <Button
        isDisabled={!sortSelected}
        onClickHandler={() => handleSortWorks(sortSelected)}
        customStyles={{
          maxWidth: '100%'
        }}
      >
        Aplicar
      </Button>
    </div>
  )
}
export default WorkSort
