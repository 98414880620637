import React from 'react'
import styles from './BorderedItem.module.scss'

const BorderedItem = ({ children, customStyles, onClick, mode }) => {
  return (
    <div className={`${styles.borderedItem} ${styles[mode]}`} style={customStyles} onClick={onClick}>
      {children}
    </div>
  )
}

export default BorderedItem
