import React, { useEffect, useState } from "react";
// import { useSelector } from 'react-redux';
import ConfirmEmail from "@organism/ConfirmEmail/ConfirmEmail";
import styles from "./styles.module.scss";
import { getFromSwitchFromLS } from "@utils/tokenService";
import { successRegister } from '@actions/generalActions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const RegisterSuccess = () => {
  // const { loggedByFaceOrGoogle } = useSelector(
  //   (state) => state.register
  // );
  const [loggedByFaceOrGoogle, setLoggedByFaceOrGoogle] = useState(false);
  const success = useSelector(state => state['general']['successRegister']);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if(!success) history.push('/');
    return () => {
      dispatch(successRegister(false));
    }
  }, [])
  
  useEffect(() => {
    const fromGoogleOrFacebook = sessionStorage.getItem("authGoogleOrFacebook");
    if (fromGoogleOrFacebook) {
      setLoggedByFaceOrGoogle(true);
    }
  }, []);

  useEffect(() => {
    const scriptPro = document.createElement("script");
    scriptPro.innerHTML =
      // "gtag('event', 'conversion', {'send_to': 'AW-417421308/g1BmCIb06I0DEPyvhccB'});";
      //"gtag('event', 'conversion', {'send_to': 'AW-10870553377/aycoCLysirUDEKH2vb8o'});";
      "gtag('event', 'conversion', {'send_to': 'AW-417421308/g1BmCIb06I0DEPyvhccB'});";
    document.body.appendChild(scriptPro);
  }, []);

  return success ? (
    <div className={styles.registerSteps}>
      {/* FB PIXEL */}
      {getFromSwitchFromLS() ? (
        <h1>¡Listo!</h1>
      ) : (
        <>
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            src="https://www.facebook.com/tr?id=303991264632997&ev=CompleteRegistration"
            alt="FB PIXEL"
          />
          <h1>
            {loggedByFaceOrGoogle ? "Registro finalizado" : "Validá tu cuenta"}
          </h1>
        </>
      )}
      <ConfirmEmail fromProRegister />
    </div>
  ):null;
};

export default RegisterSuccess;

// gtag('event', 'conversion', {'send_to': 'AW-417421308/g1BmCIb06I0DEPyvhccB'});
