import React, { useState } from "react";
import { ReactComponent as Logo } from "@assets/svg/logo.svg";
// import { ReactComponent as Linkedin } from "@assets/svg/linkedin.svg";
// import { ReactComponent as Instagram } from "@assets/svg/instagram.svg";
// import { ReactComponent as Facebook } from "@assets/svg/facebook.svg";

import { ReactComponent as Linkedin } from "@assets/svg/lkdCircle.svg";
import { ReactComponent as Instagram } from "@assets/svg/igCircle.svg";
import { ReactComponent as Facebook } from "@assets/svg/fbCircle.svg";

// import Linkedin from "@assets/svg/li_white.svg";
// import Instagram from "@assets/svg/ig_white.svg";
// import Facebook from "@assets/svg/fb_white.svg";



import { ReactComponent as Paisanos } from "@assets/svg/logo_paisanos.svg";
import DimeMas from './DimeMas';
import styles from "./Footer.module.scss";
import { useSelector } from "react-redux";
import { setModal } from "@actions/";
import { useDispatch } from "react-redux";
import {
  getFirstLogin,
  footerOnboarding,
  setReadyText,
} from "@actions/generalActions";
import { axiosGet, axiosPost, axiosPut } from "@config/api";
import useScreenDetect from "@hooks/useScreenDetect";

const Footer = () => {
  const [openLists, setOpenLists] = useState([]);
  const { noDistractionsMode } = useSelector((state) => state.general);
  const actualYear = new Date().getFullYear();
  const dispatch = useDispatch();
  let textReady = useSelector((state) => state["general"]["textReady"]);
  const { isMobile } = useScreenDetect();

  const linkConfig = { target: "_blank", rel: "noopener noreferrer" };

  const handleOpenList = (list) => {
    if (openLists.includes(list)) {
      return setOpenLists(openLists.filter((i) => i !== list));
    }
    return setOpenLists([...openLists, list]);
  };

  const showModal = async (e) => {
    e.preventDefault();
    dispatch(footerOnboarding(true));
    localStorage.setItem("oneTime", true);
    // TODO: Se repite a proposito 2 veces la funcion "setReadyText"
    dispatch(setReadyText(!textReady));
    dispatch(setReadyText(!textReady));
    await axiosPut(`/users/restartFirstLogin`, {});
    await axiosGet("/users/me").then((response) => {
      dispatch(getFirstLogin(response.data.firstLogin));
    });
    dispatch(setModal(true, "firstLogin"));
  };

  return (
    !isMobile &&
    localStorage.getItem("role") !== "ADMIN" && (
      <footer
        className={`${styles.container} ${noDistractionsMode &&
          styles.noDistractionsMode}`}
      >
        <div className={styles.smallContent}>
          <Logo />
          <div className={styles.redes}>
            <a
              href="https://www.linkedin.com/company/zenpros-work"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin />
            </a>
            <a
              href="https://www.facebook.com/Zenpros-107952351385216"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook />
            </a>
            <a
              href="https://www.instagram.com/zenproswork/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </a>
          </div>
          <div className={styles.firstUlColumn}>
            <ul className={`${styles.list} ${styles.opened}`}>
              <li>
                <a
                  className={styles.footerLink}
                  href="http://conditions.zenpros.work/Terminos-Condiciones-Zenpros.pdf"
                  {...linkConfig}
                >
                  <h3>Términos y Condiciones</h3>
                </a>
              </li>
              <li>
                <a
                  className={styles.footerLink}
                  href="http://conditions.zenpros.work/assets/Politica-de-Privacidad-Zenpros.pdf"
                  {...linkConfig}
                >
                  <h3>Privacidad</h3>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.bigContent}>
          <div
            className={`${styles.column} ${openLists.includes(
              "profesionales"
            ) && styles.opened}`}
          >
            <h2 id={styles.titlePros} onClick={() => handleOpenList("profesionales")}>
              Profesionales
            </h2>
            <ul
              className={`${styles.list} ${openLists.includes(
                "profesionales"
              ) && styles.opened}`}
            >
              <li>
                <a
                  className={styles.footerLink}
                  href="https://www.notion.so/Centro-de-ayuda-para-Profesionales-3464103393c5436097362dd93d9ddcde"
                  {...linkConfig}
                >
                  <h3>Centro de ayuda para Profesionales</h3>
                </a>
              </li>
              <li>
                <a
                  className={styles.footerLink}
                  href="https://www.notion.so/C-mo-funciona-Zenpros-ddaf7b894385459a83e46fcfffae54f0"
                  {...linkConfig}
                >
                  <h3>¿Cómo funciona Zenpros?</h3>
                </a>
              </li>
              <li>
                <a
                  className={styles.footerLink}
                  href="https://www.notion.so/Registrate-f02e26a7fb2e451ab71fe64aa4de9bff"
                  {...linkConfig}
                >
                  <h3>¿Cómo me registro?</h3>
                </a>
              </li>
              <li>
                <a
                  className={styles.footerLink}
                  href="https://www.notion.so/Preguntas-Frecuentes-16edf8ff244141d6b5845db7d1f50d0f"
                  {...linkConfig}
                >
                  <h3>Preguntas frecuentes</h3>
                </a>
              </li>
              {localStorage.getItem("isPro") === "true" && (
                <li>
                  <a
                    className={styles.footerLink}
                    href="#"
                    onClick={(e) => showModal(e)}
                    {...linkConfig}
                  >
                    <h3>Onboarding</h3>
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div
            className={`${styles.column} ${openLists.includes("usuarios") &&
              styles.opened}`}
          >
            <h2 id={styles.titleUsers} onClick={() => handleOpenList("usuarios")}>Clientes</h2>
            <ul
              className={`${styles.list} ${openLists.includes("usuarios") &&
                styles.opened}`}
            >
              <li>
                <a
                  className={styles.footerLink}
                  href="https://www.notion.so/Centro-de-ayuda-para-Usuarios-b3691eb5da0f4042b64ddc114d2683e7"
                  {...linkConfig}
                >
                  <h3>Centro de ayuda para Clientes</h3>
                </a>
              </li>
              <li>
                <a
                  className={styles.footerLink}
                  href="https://www.notion.so/C-mo-contacto-a-un-profesional-ad4e81c1f92b45998fc03708e4da1202"
                  {...linkConfig}
                >
                  <h3>¿Cómo contacto a un profesional?</h3>
                </a>
              </li>
              <li>
                <a
                  className={styles.footerLink}
                  href="https://www.notion.so/Registrarse-como-usuario-ea7aef8ab4304170ba1f1fea51b460e2"
                  {...linkConfig}
                >
                  <h3>¿Cómo me registro?</h3>
                </a>
              </li>
              <li>
                <a
                  className={styles.footerLink}
                  href="https://www.notion.so/Preguntas-Frecuentes-6c6773d5626842fea7cecbc87803a377"
                  {...linkConfig}
                >
                  <h3>Preguntas frecuentes</h3>
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.column}>
            <h2 className={styles.contactoTitle}>Nosotros</h2>
            <DimeMas />
          </div>
        </div>
        <div className={styles.bottomContent}>
          <h4>© {actualYear} - Todos los derechos reservados a Zenpros</h4>
        </div>
        <div className="none" />
      </footer>
    )
  );
};

export default Footer;
