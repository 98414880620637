import React, { useEffect, useState } from 'react'
import CheckboxGroup from '@molecules/CheckboxGroup/CheckboxGroup'
import styles from './SearchService.module.scss'
import BorderedItem from '@atoms/BorderedItem/BorderedItem'
import SlideCheckbox from '@atoms/SlideCheckbox/SlideCheckbox'

const ServiceCategory = ({
  categories,
  category,
  selectedCategories,
  disabledItems,
  disabledMode,
  search,
  handleSelectSubService
}) => {
  const [selectAll, setSelectAll] = useState(false)

  let subItemsToList
  let subSelectedItems = []
  categories.map((auxCategory) => {
    if (auxCategory.id === category.id) {
      return (subItemsToList = auxCategory.sons || auxCategory.neighborhood)
    }
    return false
  })

  selectedCategories.map((selectedCategory) => {
    if (selectedCategory.id === category.id) {
      return (subSelectedItems =
        selectedCategory.sons || selectedCategory.neighborhood)
    }

    return false
  })

  const filteredItems = subItemsToList?.filter((subItem) =>
    subItem.name.toLowerCase().includes(search.toLowerCase())
  )
  const handleSelectItem = (value) => {
    handleSelectSubService(value, category.id)
  }

  const handleChange = () => {
    setSelectAll(selectAll => !selectAll)
  }

  useEffect(() => {
    handleSelectSubService(selectAll ? filteredItems : [], category.id)
  }, [selectAll])

  return (
    <div className={styles.serviceCategory}>
      {filteredItems?.length > 0 && (
        <div style={{ height: '3.5rem' }}>
          <h6 className={styles.serviceCategoryName}>{category.name}</h6>
          <BorderedItem
            mode='switchContainer' customStyles={{
              height: '4rem'
            }}
          >
            <SlideCheckbox
              name='Todas'
              customStyles={{ marginLeft: '1rem' }}
              onChange={() => handleChange()}
              backgroundColor='var(--color-item)'
            />
          </BorderedItem>
        </div>
      )}
      <CheckboxGroup
        options={filteredItems}
        selectedItems={subSelectedItems}
        disabledItems={disabledItems}
        disabledMode={disabledMode}
        selectItemAction={handleSelectItem}
        mode='editProfileSubservices'
        checkAll={selectAll}
      />
    </div>
  )
}

export default ServiceCategory
