import GeneralSearch from '@molecules/GeneralSearch/GeneralSearch'
import React, { useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import homeWeb1 from '@assets/img/HomeCarouselWeb1.jpg'
import homeWeb2 from '@assets/img/HomeCarouselWeb2.jpg'
import homeMobile1 from '@assets/img/HomeCarouselMobile1.jpg'
import homeMobile2 from '@assets/img/HomeCarouselMobile2.jpg'
import useScreenDetect from '@hooks/useScreenDetect'
import { useDispatch } from 'react-redux'
import { getMostSearched } from '@actions/generalActions'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

const HeroSection = () => {
  const { isMobile } = useScreenDetect()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getMostSearched())
  }, [])

  const [imagenes, setImagenes] = useState([])

  useEffect(() => {
    if (isMobile) {
      setImagenes([homeMobile1, homeMobile2])
    } else {
      setImagenes([homeWeb1, homeWeb2])
    }
  }, [isMobile])

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    // speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  return (
    <div className={styles.heroContent}>
      <div
        className={styles.rightContent}
        style={isMobile ? { order: 2 } : { order: 1 }}
      >
        <Slider {...settings}>
          {imagenes.map((image, i) => (
            <div className={styles.heroCarousel}>
              <img src={image} />
            </div>
          ))}
        </Slider>
      </div>
      <div
        className={styles.leftContent}
        style={isMobile ? { order: 1, marginBottom: '2rem' } : { order: 2 }}
      >
        <div className={styles.searchContainer}>
          {isMobile
            ? (
              <>
                <GeneralSearch />
              </>
            )
            : (
              <>
                <GeneralSearch />
              </>
            )}
        </div>
      </div>
    </div>
  )
}

function SampleNextArrow(props) {
  const { isMobile } = useScreenDetect()
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={
        !isMobile
          ? {
            ...style,
            display: 'block',
            backgroundColor: 'transparent',
            marginRight: '55px',
            filter: 'drop-shadow( 0px 0px 7px rgba(0, 0, 0, .7))'
          }
          : {
            ...style,
            display: 'block',
            backgroundColor: 'transparent',
            marginRight: '28px',
            filter: 'drop-shadow( 0px 0px 7px rgba(0, 0, 0, .7))'
          }
      }
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { isMobile } = useScreenDetect()
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={
        !isMobile
          ? {
            ...style,
            display: 'block',
            backgroundColor: 'transparent',
            marginLeft: '40px',
            zIndex: '5',
            filter: 'drop-shadow( 0px 0px 7px rgba(0, 0, 0, .7))'
          }
          : {
            ...style,
            display: 'block',
            backgroundColor: 'transparent',
            marginLeft: '25px',
            zIndex: '5',
            filter: 'drop-shadow( 0px 0px 7px rgba(0, 0, 0, .7))'
          }
      }
      onClick={onClick}
    />
  )
}

export default HeroSection
