import React from 'react'
import { setModal } from '@actions/generalActions'
import Button from '@atoms/Button/Button'
import Text from '@atoms/Text/Text'
import { useDispatch } from 'react-redux'
import styles from './ContactZenPros.module.scss'

const ContactZenPros = () => {
  const dispatch = useDispatch()
  const handleContactZenProsModal = () => {
    dispatch(setModal(true, 'contactZenPros'))
  }
  return (
    <div className={styles.questionsBlock}>
      <Text tag='p' size='l' weight='bold'>
        ¿Tenés alguna pregunta?
      </Text>
      <Text
        tag='p'
        size='m'
        customStyles={{ margin: 'var(--spacing-small) 0' }}
      >
        Escribinos y nos pondremos en contacto para asesorarte
      </Text>
      <Button
        buttonStyle='terciary'
        customStyles={{
          maxWidth: 'max-content',
          background: 'transparent'
        }}
        onClickHandler={() => handleContactZenProsModal()}
      >
        Envianos tu consulta
      </Button>
    </div>
  )
}

export default ContactZenPros
