import React, {useEffect} from 'react'
import RadioButton from '@atoms/RadioButton/RadioButton'
import styles from './RadioButtonGroup.module.scss'

const RadioButtonGroup = ({
  title,
  options,
  handleSelect,
  handleInputChange,
  selectedValue,
  mode,
  allowSuggest,
  suggestPlaceholder,
  customStyles,
  fromRegister = false,
  oneSelect,
  steps
}) => {
  
  useEffect(() => {
    if(steps === 2 && JSON.parse(localStorage.getItem('selectItems')).length === 1) {
      if(JSON.parse(localStorage.getItem('optionsTwo'))?.[0]?.categories){
        options = JSON.parse(localStorage.getItem('optionsTwo'))[0].categories;
      }
      
    }
  }, [options, localStorage])
  
  return (
    <div className={styles.container} style={customStyles}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <div className={`${styles.radioButtonGroup} ${styles[mode]}`}>
        {options?.map((option, index) => (
          <RadioButton
            value={option}
            selected={selectedValue && selectedValue.id === option.id}
            handleClick={handleSelect}
            oneSelect={oneSelect}
            key={index}
            steps={steps}
          />
        ))}
        {allowSuggest && (
          <RadioButton
            value={{ name: 'Otro', id: null }}
            selected={selectedValue && selectedValue.id === null}
            handleClick={handleSelect}
          />
        )}
      </div>
      {allowSuggest && (
        <div className={styles.otherField}>
          <input
            type='text'
            placeholder={
              suggestPlaceholder || 'Ej: Costureras y Sastres, Serigrafía, etc'
            }
            className={styles.inputOther}
            onChange={(e) => handleInputChange(e.target.value)}
            disabled={!selectedValue || selectedValue.id !== null}
            autoFocus
          />
        </div>
      )}
    </div>
  )
}
export default RadioButtonGroup
