import React from "react";
import styles from "./jobs.module.scss";
import Fade from "react-reveal/Fade";
import visto from "../../../assets/svg/done.svg";
import cruz from "../../../assets/svg/cruz.svg";

const Jobs = ({ data }) => {
  const crearRowsPros = () => {
    return (
      <>
        {data.map((job, index) => (
          <tr key={index}>
            <td>{job.created}</td>
            <td>
              {
                job.professionalProfile?.professionalProfileCategory?.[0]
                  .category.name
              }
            </td>
            <td>
              {
                job.professionalProfile?.professionalProfileCategory?.[1]
                  .category.name
              }
            </td>
            <td>{`${job.client?.name} ${job.client?.lastName}`}</td>
            <td>{job.client?.email}</td>
            <td>{job.client?.phone}</td>
            <td>
              {`${job.professionalProfile?.user.name} ${job.professionalProfile?.user.lastName}`}
              {job.professionalProfile?.fantasy
                ? ` (${job.professionalProfile?.fantasy})`
                : null}
            </td>
            <td>{job.professionalProfile?.user.email}</td>
            <td>{job.professionalProfile?.user.phone}</td>
            <td>{job.professionalProfile?.residenceArea}</td>
            <td>
              {job.jobStatus === "WORK_NOT_COMPLETED"
                ? "NO COMPLETADO"
                : job.jobStatus === "APPROVED"
                ? "APROBADO"
                : job.jobStatus === "REJECTED"
                ? "RECHAZADO"
                : job.jobStatus === "PENDING"
                ? "PENDIENTE"
                : job.jobStatus === "WORK_COMPLETED"
                ? "COMPLETADO"
                : job.jobStatus === "FINISHED"
                ? "FINALIZADO"
                : null}
            </td>
            <td>
              {job.jobStatusNewRead === "READ" ? (
                <img className={styles.done} src={visto} />
              ) : (
                <img src={cruz} />
              )}
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <table className={styles.tableJobs}>
      <thead>
        <tr>
          <th>Fecha Creación</th>
          <th>Categoría</th>
          <th>Profesión</th>
          <th>Cliente</th>
          <th>Email (Cli)</th>
          <th>Teléfono (Cli)</th>
          <th>PROFESIONAL (Pro)</th>
          <th>Email (Pro)</th>
          <th>Teléfono (Pro)</th>
          <th>Residencia (Pro)</th>
          <th>Estado</th>
          <th>¿Leído?</th>
        </tr>
      </thead>
      <tbody>{crearRowsPros()}</tbody>
    </table>
  );
};

export default Jobs;
