import React, { useEffect } from 'react'
import styles from './UserProfileContainer.module.scss'
import ProfileInfoCard from '@molecules/ProfileInfoCard/ProfileInfoCard'
import ProfileValorations from '@molecules/ProfileValorations/ProfileValorations'
import { useDispatch, useSelector } from 'react-redux'
import { getProfileData } from '@actions/profileActions'
import { setModal } from '@actions/generalActions'
import useScreenDetect from '@hooks/useScreenDetect'
import ShadowBox from '@atoms/ShadowBox/ShadowBox'
import Text from '@atoms/Text/Text'
import { useHistory } from 'react-router'
import { deleteToken } from '@utils/tokenService'

const UserProfileContainer = () => {
  const { userProfileData } = useSelector((state) => state.profile)
  const { isAuth } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const history = useHistory()

  const { isMobile } = useScreenDetect()

  const handleEditAction = (value) => {
    dispatch(setModal(true, value))
  }

  const calcAverageReputation = (comments) => {
    const arrLength = comments.length
    if (arrLength < 1) return false
    let totSumReputation = 0
    if (arrLength > 0) {
      comments.map((comment) => {
        const aux = totSumReputation
        totSumReputation = aux + comment.evaluation
      })
    }
    const avg = totSumReputation / arrLength
    return avg
  }

  useEffect(() => {
    if (isAuth) {
      dispatch(getProfileData(true))
    } else {
      deleteToken()
      history.push('/iniciar-sesion')
    }
    // eslint-disable-next-line
  }, []);

  return (
    userProfileData && (
      <div className={styles.container}>
        <div className={styles.section}>
          <ProfileInfoCard
            handleEditAction={handleEditAction}
            proProfileData={{
              ...userProfileData,
              avgReviews: calcAverageReputation(
                userProfileData.evaluated_user_reviews
              ),
              reviews: userProfileData.evaluated_user_reviews
            }}
            isMobile={isMobile}
            userMode
          />
        </div>
        <div className={styles.section}>
          <ShadowBox
            width={isMobile ? '91.5vw' : '64rem'}
            customStyles={{
              minWidth: isMobile ? '100%' : '80rem',
              padding: isMobile
                ? 'var(--spacing-small)'
                : 'var(--spacing-medium)',
              border: '1px solid rgba(11, 26, 50, 0.1)',
              boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px'
            }}
          >
            <div className={styles.header}>
              <Text
                tag='p'
                size={isMobile ? 'm' : 'l'}
                weight='bold'
                align='left'
              >
                Valoraciones
              </Text>
            </div>
            <div className={styles.children}>
              <ProfileValorations
                comments={userProfileData.evaluated_user_reviews}
                professionalProfile={{
                  avgReviews: calcAverageReputation(
                    userProfileData.evaluated_user_reviews
                  )
                }}
                isMobile={isMobile}
              />
            </div>
          </ShadowBox>
        </div>
      </div>
    )
  )
}

export default UserProfileContainer
