import React from 'react'
import { toast } from 'react-toastify'
import popNotification from '@assets/sounds/pop-notification.mp3'

const showNotification = (text, id, type, timer) => {
  const audio = new Audio(popNotification)
  const Msg = () => (
    <>{text}</>
  )

  const toastOptions = {
    toastId: id,
    autoClose: timer || 0,
    // onOpen: () => {
    //   audio.play()
    // },
    // style: {

    // }
  }

  switch (type) {
    case 'info':
      toast.info(<Msg />, {
        ...toastOptions,
        style: {
          ...toastOptions.style
        }
      })
      break
    case 'error':
      toast.error(<Msg />, {
        ...toastOptions,
        style: {
          ...toastOptions.style
        }
      })
      break
    case 'success':
      toast.success(<Msg />, {
        ...toastOptions,
        style: {
          ...toastOptions.style
        }
      })
      break
    case 'warning':
      toast.warning(<Msg />, {
        ...toastOptions,
        style: {
          ...toastOptions.style
        }
      })
      break
    default:
      toast.info(<Msg />, {
        ...toastOptions,
        style: {
          ...toastOptions.style
        }
      })
      break
  }
}

export default showNotification
