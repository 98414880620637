import React, { useState, useEffect } from "react";
import styles from "./Filters.module.scss";
import { ReactComponent as FilterIcon } from "@assets/svg/filters.svg";
import Filter from "@atoms/Filter/Filter";
import FiltersMenuMobile from "@molecules/FiltersMenuMobile/FiltersMenuMobile";
import { paymentMethods } from "@utils/mock-data";
import useScreenDetect from "@hooks/useScreenDetect";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { setProfessionalResult, getProsProfessionsSearch } from "@actions/generalActions";
import { axiosGet, axiosPost, axiosPut } from "@config/api";

const Filters = ({
  barrios,
  categories,
  handleFilter,
  handleMobileFilter,
  setSearchValues,
}) => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);
  const [selectedWorkModality, setSelectedWorkModality] = useState([]);
  const [selectedNeighborhoods, setSelectedNeighborhoods] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const dispatch = useDispatch();

  const { categorySearchId } = useParams();
  const { professionalsResult, currentSearch, allCategoriesSearch } = useSelector(
    (state) => state.general
  );

  const { isMobile } = useScreenDetect();
  const [localProfessionalsResult, setLocalProfessionalsResult] = useState([]);
  const [localCategories, setLocalCategories] = useState([]);
  let [value, setValue] = useState([]);

  useEffect(() => {
    setLocalProfessionalsResult(professionalsResult);
    indexCategories();
  }, [categories]);

  const indexCategories = () => {
    let arrCategories = categories.map((category) => {
      let obj = { select: false, name: category.name };
      return obj;
    });
    setLocalCategories(arrCategories);
  };

  useEffect(() => {
    let value
    if (localStorage.getItem("currentSearch")) { value = JSON.parse(localStorage.getItem("currentSearch")); }



    if (value) {
      handleSelectItem(
        value,
        selectedCategories,
        setSelectedCategories,
        "categoryIds"
      );
    }
  }, []);

  const handleSelectItem = (value, array, action, key) => {
    setValue(value);
    let included = false;
    array.map((service) => {
      if (service.id === value.id) return (included = true);
      return false;
    });

    if (included && localStorage.getItem("currentSearch") !== null) {
      let selIts = JSON.parse(localStorage.getItem("selectItems"));
      let cs = JSON.parse(localStorage.getItem("currentSearch"));
      if (selIts && selIts.length === 1) {
        let allCurrent = [cs.id]
        handleFilter(key, allCurrent);

      }
      handleFilterValue(key, [...array.filter((i) => i.id !== value.id)]);
      return action([...array.filter((i) => i.id !== value.id)]);

    }
    handleFilterValue(key, [...array, value]);

    action([...array, value]);

  };

  // TODO: esto es necesario para que funcione bien el buscador y los botones de "Enviar mensaje" y "Quiero que me llamen"
  let [selectId, setSelectId] = useState([]);

  useEffect(() => {
    axiosGet('/guests/category-verticals').then(response => {
      let data = response.data.map(item => item.id);
      setSelectId(data);
    })
  }, [localStorage])
  // TODO:======================================================================================================

  useEffect(() => {
    if (
      selectedCategories &&
      selectedCategories.length > 0 &&
      !selectId.includes(selectedCategories[0].parentcategory?.id) // si es un NIVEL 1, entonces...
    ) {
      value = [value];
      localStorage.setItem("selectItems", JSON.stringify(selectedCategories));
    } else {
      // si es una VERTICAL, entonces...
      let selIts = selectedCategories.filter((element, index) => index > 0);
      localStorage.setItem("selectItems", JSON.stringify(selIts));
    }
  }, [selectedCategories, localStorage, selectId]);

  const mobileFilter = (data) => {
    handleMobileFilter(data);
    handleOpenFilter(false);
  };

  const handleFilterValue = (key, value) => {
    switch (key) {
      case "categoryIds":
        handleFilter(key, [
          Number(categorySearchId),
          ...value.map((val) => val.id),
        ]);
        break;
      case "neighborhoodIds":
        handleFilter(key, [...value.map((val) => val.id)]);
        break;
      case "paymentMethod":
        handleFilter(key, [...value.map((val) => val.value)]);
        break;
      case "workType":
        handleFilter(key, [...value.map((val) => val.value)]);
        break;
      default:
        return null;
    }
  };

  const handleOpenFilter = (value) => {
    if (value) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    setIsFilterMenuOpen(value);
  };

  useEffect(() => {
    dispatch(getProsProfessionsSearch());
  }, [professionalsResult]);

  return (
    <div className={styles.filtersContainer}>
      {isMobile ? (
        <>
          <div className={styles.mobileButtons}>
            <div
              className={styles.mobileButton}
              onClick={() => handleOpenFilter(true)}
            >
              <FilterIcon />
              <p>Filtros</p>
            </div>
          </div>
          <FiltersMenuMobile
            opened={isFilterMenuOpen}
            handleOpenFilter={handleOpenFilter}
            sendFilter={mobileFilter}
            handleFilter={handleFilter}
            filters={[
              { name: "Especialización", options: categories },
              { name: "Zona de cobertura", options: barrios },
              { name: "Medio de pago", options: paymentMethods },
              {
                name: "Modalidad",
                options: [
                  { name: "Presencial", id: 1, value: "FACETOFACE" },
                  { name: "Remoto", id: 2, value: "REMOTE" },
                ],
              },
            ]}
          />
        </>
      ) : (
        <>
          <Filter
            name="Especialización"
            options={categories || []}
            handleSelect={(selectedItem) =>
              handleSelectItem(
                selectedItem,
                selectedCategories,
                setSelectedCategories,
                "categoryIds"
              )
            }
            selectedOptions={selectedCategories}
            checkbox
            optionsWidthAuto
          />
          <Filter
            name="Zona de cobertura"
            options={barrios}
            checkbox
            optionsWidthAuto
            enableSoon
            selectedOptions={selectedNeighborhoods}
            handleSelect={(selectedItem) =>
              handleSelectItem(
                selectedItem,
                selectedNeighborhoods,
                setSelectedNeighborhoods,
                "neighborhoodIds"
              )
            }
          />
          <Filter
            name="Medio de pago"
            options={paymentMethods}
            optionsWidthAuto
            checkbox
            selectedOptions={selectedPaymentMethods}
            handleSelect={(selectedItem) =>
              handleSelectItem(
                selectedItem,
                selectedPaymentMethods,
                setSelectedPaymentMethods,
                "paymentMethod"
              )
            }
          />
          <Filter
            name="Modalidad"
            options={[
              { name: "Presencial", id: 1, value: "FACETOFACE" },
              { name: "Remoto", id: 2, value: "REMOTE" },
            ]}
            selectedOptions={selectedWorkModality}
            handleSelect={(selectedItem) =>
              handleSelectItem(
                selectedItem,
                selectedWorkModality,
                setSelectedWorkModality,
                "workType"
              )
            }
            checkbox
            customStyles={{ marginLeft: "auto", marginRight: "0" }}
          />
        </>
      )}
    </div>
  );
};

export default Filters;
