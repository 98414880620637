import React, { useEffect } from 'react'
import Login from '@organism/Login/Login'
import styles from './styles.module.scss'

const LoginPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' })
  }, [])

  return (
    <>
      <main className={styles.loginContainer}>
        <h1 className={styles.loginTitle}>Ingresá a Zenpros</h1>
        <Login />
      </main>
    </>
  )
}

export default LoginPage
