import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './ProfileEditModal.module.scss'
import ModalTitle from '@atoms/ModalTitle/ModalTitle'
import ProfileEditInfoCard from '@molecules/ProfileEditModal/ProfileEditInfoCard'
import {
  deleteProfessionalFile,
  editProfessionProfile,
  uploadProfessionalFile
} from '@actions/profileActions'
import ProfileEditAboutMe from './ProfileEditAboutMe'
import ProfileEditServices from './ProfileEditServices'
import ProfileEditAddService from './ProfileEditAddService'
import ProfileEditWorkDone from './ProfileEditWorkDone'
import ProfileEditZones from './ProfileEditZones'
import ProfileEditPayments from './ProfileEditPayments'
import {
  addCategoriesToProfessional,
  addNeighborhoodToProfessional,
  addPaymentMethodsToProfessional
} from '@actions/registerActions'
import ProfileEditValorations from './ProfileEditValorations'
import { useEffect } from '@storybook/addons'
import { toast } from "react-toastify";

const ProfileEditModal = ({ modalType, profileData, isMobile }) => {
  const dispatch = useDispatch()
  const pData = useSelector((state) => state.profile.profileData)

  const handleSubmitInfo = (data) => {
    dispatch(editProfessionProfile(profileData.id, data, false, true, pData.id))
  }
  
  const handleDispatchAddCategories = (cats, fromAddCat) => {
    if (fromAddCat) {
      return dispatch(
        addCategoriesToProfessional(
          {
            id: profileData.id,
            professionalCategories: cats
          },
          true
        )
      )
    }
    return dispatch(
      addCategoriesToProfessional(
        {
          id: profileData.id,
          professionalCategories: cats
        },
        false,
        true
      )
    )
  }

  const handleDispatchAddNeighborhood = (zones) => {
    return dispatch(
      addNeighborhoodToProfessional(
        {
          id: profileData.id,
          professionalNeighborhoods: zones
        },
        true
      )
    )
  }

  const handleAddCategories = (categories) => {
    let catsToAdd = []
    categories.map((cat) => {
      cat.sons.map((catSon) => {
        return (catsToAdd = [
          ...catsToAdd,
          {
            categoryId: catSon.id,
            paymentTypeId: catSon.paymentType ? catSon.paymentType.id : 3,
            value: catSon.value ? Number(catSon.value) : 0
          }
        ])
      })
    })
    handleDispatchAddCategories(catsToAdd, true)
  }

  const handleAddPaymentMethods = (payments) => {
    dispatch(
      addPaymentMethodsToProfessional(
        {
          id: profileData.id,
          professionalPaymentMethods: payments
        },
        true
      )
    )
  }

  const handleUpdateFile = (file) => {
    dispatch(uploadProfessionalFile(profileData.id, file))
  }
  const handleDeleteFile = (files) => {
    dispatch(deleteProfessionalFile(profileData.id, files))
  }

  const ModalComponent = () => {
    switch (modalType) {
      case 'generalInfo':
        return (
          <>
            <ModalTitle title='Datos personales' />
            <ProfileEditInfoCard
              profileData={profileData}
              isMobile={isMobile}
              handleSubmit={handleSubmitInfo}
            />
          </>
        )
      case 'aboutme':
        return (
          <>
            <ModalTitle title='Más sobre mí' />
            <ProfileEditAboutMe
              isMobile={isMobile}
              profileData={profileData}
              handleSubmitDesc={handleSubmitInfo}
            />
          </>
        )
      case 'services':
        return (
          <>
            <ModalTitle title='Seleccioná qué servicios querés ofrecer' />
            <ProfileEditServices
              isMobile={isMobile}
              profileData={profileData}
              handleSubmitData={handleDispatchAddCategories}
            />
          </>
        )
      case 'addService':
        return (
          <>
            <ModalTitle title='¿Qué Servicios querés ofrecer?' />
            <ProfileEditAddService
              isMobile={isMobile}
              profileData={profileData}
              handleSubmit={handleAddCategories}
            />
          </>
        )
      case 'paymentMethods':
        return (
          <>
            <ModalTitle title='Medios de pago' />
            <ProfileEditPayments
              profileData={profileData}
              isMobile={isMobile}
              handleSubmit={handleAddPaymentMethods}
            />
          </>
        )
      case 'zones':
        return (
          <>
            <ModalTitle title='Zona de cobertura' />
            <ProfileEditZones
              profileData={profileData}
              handleSubmit={handleDispatchAddNeighborhood}
            />
          </>
        )
      case 'workDone':
        return (
          <>
            <ModalTitle title='Trabajos realizados' />
            <ProfileEditWorkDone
              profileData={profileData}
              handleSubmit={handleUpdateFile}
              handleDeleteFile={handleDeleteFile}
            />
          </>
        )
      case 'externalReviews':
        return (
          <>
            <ModalTitle title='Editá las valoraciones' />
            <ProfileEditValorations
              profileData={profileData}
              handleSubmit={handleUpdateFile}
            />
          </>
        )
      default:
        break
    }
  }
  return (
    <div className={styles.profileModal}>
      <ModalComponent />
    </div>
  )
}

export default ProfileEditModal
