import { existToken, getToken } from "@utils/tokenService";
import axios from "axios";
import environment from "environment";
const API_URL = environment.baseURL;

export const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const ENVIRONMENT_URL = API_URL;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (error.response.data.message.includes("User not active.")) {
        return Promise.reject(error);
      }
      localStorage.setItem("goback-url", window.location.pathname);
      window.location.href = "/iniciar-sesion";
    }
    return Promise.reject(error);
  }
);

export const axiosGet = (url, headers) => {
  return axiosInstance.get(url, headers);
};

export const axiosPost = (url, data) => {
  return axiosInstance.post(url, data);
};

export const axiosPut = (url, data) => {
  return axiosInstance.put(url, data);
};

export const axiosDelete = (url, data) => {
  return axiosInstance.delete(url, data);
};

export const setTokenInAxios = (token) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

// export const axiosGetFiles = (url) => {
//   return axios({
//     url: environment.api.dashboard + url.slice(1),
//     method: "GET",
//     headers: { Authorization: `Bearer ${getToken()}` },
//     responseType: "blob",
//   });
// };

if (existToken()) {
  setTokenInAxios(getToken());
}
