import { setProfessionalWorkStatus } from '@actions/profileActions'
import Button from '@atoms/Button/Button'
import Text from '@atoms/Text/Text'
import useScreenDetect from '@hooks/useScreenDetect'
import React, { useState } from 'react'
import { ReactComponent as FinishIcon } from '@assets/svg/finished-work.svg'
import { useDispatch } from 'react-redux'
import styles from './WorksModals.module.scss'
import RadioButtonGroup from '@molecules/RadioButtonGroup/RadioButtonGroup'
const WorkFinished = ({ currentWork }) => {
  const [step, setStep] = useState(false)
  const [reason, setReason] = useState('')
  const [inputText, setInputText] = useState('')
  const dispatch = useDispatch()
  const { isMobile } = useScreenDetect()

  const handleNextStep = () => {
    setStep(true)
  }

  const handleReason = (value) => {
    setReason(value)
  }
  const handleInputChange = (value) => {
    setInputText(value)
  }

  const handleFinishWork = () => {
    dispatch(setProfessionalWorkStatus(currentWork.id, 'WORK_COMPLETED', undefined, 'NOTIFY_USER'))
  }
  const handleNotFinishWork = () => {
    const auxReason = reason.id ? reason.name : inputText
    dispatch(
      setProfessionalWorkStatus(currentWork.id, 'WORK_NOT_COMPLETED', auxReason, 'NOTIFY_USER')
    )
  }

  return (
    <div className={`${styles.workModal} ${step && styles.step}`}>
      {!step
        ? (
          <>
            <FinishIcon className={styles.workIcon} />
            <Text tag='p' size='l' weight='bold'>
              ¿Completaste el trabajo?
            </Text>
            <Text
              tag='p'
              size='m'
              customStyles={{ marginBottom: 'var(--spacing-small)' }}
            >
              Una vez que lo hagas el cliente podrá calificarte.
            </Text>
          </>
          )
        : (
          <>
            <Text
              tag='p'
              size='l'
              weight='bold'
              customStyles={{
                margin: 'var(--spacing-medium) 0 var(--spacing-small)'
              }}
            >
              ¿Contanos por qué?
            </Text>
            <RadioButtonGroup
              options={[
                {
                  name:
                  'El usuario decidió no contratarme para hacer el trabajo.',
                  id: 1
                },
                {
                  name: 'El trabajo requería un servicio que yo no ofrezco.',
                  id: 2
                }
              ]}
              handleSelect={handleReason}
              selectedValue={reason}
              allowSuggest
              suggestPlaceholder='Contanos brevemente por qué no realizaste el trabajo.'
              handleInputChange={handleInputChange}
            />
          </>
          )}
      <div className={styles.workButtons}>
        {!step
          ? (
            <>
              <Button
                buttonStyle='terciary'
                onClickHandler={() => handleNextStep()}
                customStyles={{
                  marginRight: isMobile ? '0' : 'var(--spacing-small)',
                  maxWidth: '100%',
                  marginBottom: isMobile ? '0.8rem' : '0'
                }}
              >
                No
              </Button>
              <Button
                onClickHandler={() => handleFinishWork()}
                customStyles={{
                  maxWidth: '100%'
                }}
              >
                Si
              </Button>
            </>
            )
          : (
            <Button
              isDisabled={!reason || (reason.name === 'Otro' && !inputText)}
              onClickHandler={() => handleNotFinishWork()}
              customStyles={{
                maxWidth: isMobile ? '100%' : '14.8rem',
                margin: '0 auto'
              }}
            >
              Enviar
            </Button>
            )}
      </div>
    </div>
  )
}
export default WorkFinished
