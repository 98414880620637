const paymentMethods = {
  CREDIT_CARD: 'Tarjeta de Crédito',
  DEBIT_CARD: 'Tarjeta de Débito',
  BANK_TRANSFER: 'Transferencia Bancaria',
  PAYMENT_SUCURSAL: 'Rapipago/Pago fácil',
  CASH: 'Efectivo',
  TO_BE_AGREED: 'A convenir',
  MERCADO_PAGO: 'MercadoPago',
  PAYPAL: 'PayPal'
}

const paymentIcons = {
  CREDIT_CARD: 'credit-card',
  DEBIT_CARD: 'credit-card',
  BANK_TRANSFER: 'handshake',
  PAYMENT_SUCURSAL: 'handshake',
  CASH: 'bill',
  TO_BE_AGREED: 'handshake',
  MERCADO_PAGO: 'mercadopago',
  PAYPAL: 'paypal'
}

export const getPaymentMethodName = (method) => paymentMethods[method] || ''
export const getPaymentPaymentIcon = (method) => paymentIcons[method] || ''
