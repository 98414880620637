import * as actionTypes from './types'
import AuthService from '@services/AuthService'
import { axiosGet, axiosPost, setTokenInAxios } from '@config/api'
import { setToken, deleteToken, getIsProFromLS } from '@utils/tokenService'


// Login
export const loginSuccess = (user) => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload: user
})

export const loginError = () => ({ type: actionTypes.LOGIN_ERROR })

export const getProfessionals = () => {
  return(dispatch) => {
    return axiosGet('users/profes')
    .then( (response) => {
      dispatch({
        type: actionTypes.GET_PROFESSIONALS,
        payload: []
      })
    })
    .catch( (error) => {
      console.error(error)
    })
  }
}

export const professional = (professional) => ({
  type: actionTypes.GET_PROFESSIONAL,
  payload: professional
})

export const setPanelModal = (value) => {
  return {
    type: actionTypes.SET_PANEL_MODAL,
    payload: value,
  };
};