import { useState, useEffect } from 'react'

/**
 * @param  {string} image - imagen por default
 * @param {function} fileHandler - Funcion que setea en el state local del componente la imagen
 *  @returns {Array.<{imageFile: string, imageChangeHandler: function, fileName: string}>} - Retorna un array con la imagen en base64, el handler de nuestro evento, el nombre del archivo
 */

export default function useFileUpload(image, fileHandler, pdf) {
  const [imageFile, setImageFile] = useState(image)
  const [fileName, setFileName] = useState('')
  const [error, setError] = useState(false)

  const acceptedTypes = pdf ? ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'] : ['image/jpeg', 'image/jpg', 'image/png']

  const imageChangeHandler = (event) => {
    if (
      event.target.files &&
      event.target.files[0] &&
      event.target.files[0].size < 4200000
    ) {
      if (
        acceptedTypes.includes(event.target.files[0].type)
      ) {

        const reader = new FileReader()
        reader.onload = (e) => {
          setImageFile(e.target.result)
        }
        reader.readAsDataURL(event.target.files[0])
        setError(false)
        setFileName(event.target.files[0].name)
        fileHandler(event.target.files[0])
      } else {
        setError(
          `Solo se permiten archivos .jpg, .jpeg, .png${pdf ? ', .pdf' : ''
          }`
        )
      }
    } else {
      setError('El archivo no puede superar los 5MB')
    }
  }

  useEffect(() => {
    return () => {
      setImageFile(null)
    }
  }, [])

  return [imageFile, imageChangeHandler, fileName, error]
}
