import React from "react";
import styles from "./tables.module.scss";
import Select from "@atoms/Select/Select";
import useScreenDetect from "@hooks/useScreenDetect";
import { useHistory } from "react-router-dom";
import { axiosGet } from "@config/api";
import { setModal } from "@actions/generalActions";
import { useDispatch } from 'react-redux';
import { professional, setPanelModal } from '@actions/panelActions';

const Users = ({ data, panel }) => {
  const { isMobile } = useScreenDetect();
  const options = [{ name: "Ver detalle" }, { name: "Eliminar" }];
  const history = useHistory();
  
  const detailFunction = async (proId) => {
    history.push(`/panel-administracion/${proId}`);
  };
  const dispatch = useDispatch();

  const delProFunction = async ({id, email}) => {
    await axiosGet(`/admin/users/${id}`).then((response) => {
      dispatch(professional(response.data));
    });
    dispatch(setModal(true, 'modalPanel'));
    dispatch(setPanelModal({
      title: 'Eliminar cuenta',
      modalPanel: 'eliminar',
      question: '¿Estás seguro de eliminar definitivamente la cuenta de ',
      email
    }));
  }

  const crearRowsUs = () => {
    return data.map((user) => (
      <tr key={user.id} className={styles.tr}>
        <td>{user.name}</td>
        <td>{user.lastName}</td>
        <td>{user.email}</td>
        <td>{user.phone}</td>
        <td>
          <Select
            panel
            placeholder="Modalidad"
            options={options}
            defaultSelected="Selecciona"
            isMobile={isMobile}
            selectOption={() => detailFunction(user.id)}
            delProOption={() => delProFunction(user)}
            customStyles={{
              marginRight: "0.8rem",
              height: !panel
                ? isMobile
                  ? "3rem"
                  : "5.6rem"
                : isMobile
                ? "3rem"
                : "4rem",
            }}
          />
        </td>
      </tr>
    ));
  };

  return (
    <table>
      <thead>
        <tr className={styles.tr}>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Email</th>
          <th>Teléfono</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody className={styles.tbody}>{crearRowsUs()}</tbody>
    </table>
  );
};

export default Users;
