import React from 'react'
import { Link } from 'react-router-dom'
import Text from '@atoms/Text/Text'
import styles from '../styles.module.scss'
import { sumCategory } from '@utils/functions/sumCategory'
import { useSelector } from "react-redux";

export default function ServiceBanner ({ banner }) {

  const { allCategoriesSearch } = useSelector((state) => state.general);
  const filterInMostSearch = (title) => {
    let cs = allCategoriesSearch.filter(item => item.name === title);
    localStorage.setItem('currentSearch', JSON.stringify(cs[0] || {}));
    sumCategory(title);
  };

  return (
    <div className={styles.serviceBanner}>
      <Link to={banner.url} onClick={()=>filterInMostSearch(banner.title)}>
        <img src={banner.img} alt={banner.title} style={{ width: '100%' }} />
        <Text tag='h4' size='m' align='left' weight='regular'>
          {banner.title}
        </Text>
      </Link>
    </div>
  )
}
