import { useEffect, useState } from 'react'

export default function useScreenDetect () {
  const [isMobile, setMobile] = useState(false)

  useEffect(() => {
    const mobile = window.screen.availWidth < 835
    setMobile(mobile);
  }, [])

  return { isMobile }
}
