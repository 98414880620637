import * as actionTypes from '../actions/types'

const defaultRegister = {
  type: {},
  userInfo: {},
  category: {},
  area: {},
  speciality: {},
  payment: {},
  workZones: [],
  workModality: {}
}

const INITIAL_STATE = {
  registerInfo: { ...defaultRegister },
  currentStep: 1
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CLEAN:
      return {
        ...state,
        ...INITIAL_STATE
      }
    case actionTypes.SET_NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1
      }
    case actionTypes.SET_PREVIOUS_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1
      }
    case actionTypes.SET_STEP:
      return {
        ...state,
        currentStep: action.payload
      }
    case actionTypes.SET_REGISTER_TYPE:
      return {
        ...state,
        registerInfo: { ...state.registerInfo, type: action.payload }
      }
    case actionTypes.SET_REGISTER_USER_INFO:
      return {
        ...state,
        registerInfo: {
          ...state.registerInfo,
          userInfo: { ...state.userInfo, ...action.payload }
        }
      }
    case actionTypes.SET_REGISTER_VALUES:
      return {
        ...state,
        registerInfo: {
          ...state.registerInfo,
          ...action.payload
        }
      }
    case actionTypes.SET_REGISTER_CATEGORY:
      return {
        ...state,
        registerInfo: { ...state.registerInfo, category: action.payload }
      }
    case actionTypes.SET_REGISTER_AREA:
      return {
        ...state,
        registerInfo: { ...state.registerInfo, area: action.payload }
      }
    case actionTypes.SET_REGISTER_CATEGORIES_VALUES:
      return {
        ...state,
        registerInfo: {
          ...state.registerInfo,
          categoriesValues: action.payload
        }
      }
    case actionTypes.SET_REGISTER_SPECIALITY:
      return {
        ...state,
        registerInfo: { ...state.registerInfo, speciality: action.payload }
      }
    case actionTypes.SET_REGISTER_SPECIALITY_ATTEMPT:
      return {
        ...state,
        registerInfo: {
          ...state.registerInfo,
          specialityAttempt: action.payload
        }
      }
    case actionTypes.SET_REGISTER_PROFESSIONAL_INFO:
      return {
        ...state,
        registerInfo: {
          ...state.registerInfo,
          professionalInfo: action.payload
        }
      }
    case actionTypes.SET_REGISTER_REVIEW_REQUEST_EMAILS:
      return {
        ...state,
        registerInfo: {
          ...state.registerInfo,
          reviewRequestEmails: action.payload
        }
      }
    case actionTypes.RESET_REGISTER_STEPS:
      return {
        ...state,
        currentStep: 1
      }
    case actionTypes.SET_LOGGED_BY_FACE_OR_GOOGLE:
      return {
        ...state,
        loggedByFaceOrGoogle: true
      }
    case actionTypes.CLEAN_PRO_REGISTER:
      return {
        ...state,
        currentStep: 1,
        loggedByFaceOrGoogle: false,
        registerInfo: { ...defaultRegister }
      }
    default:
      return state
  }
}
