import React from 'react'
import OptionCard from '@atoms/OptionCard/OptionCard'

const Step1 = ({ handleClick }) => {
  return (
    <div>
      <OptionCard
        iconType='person'
        title='Persona'
        subtitle='Seleccioná esta opción si sos Autónomo/Freelance'
        customStyles={{ margin: '3.2rem auto 2.4rem' }}
        onClick={handleClick}
        dataForClick={{ name: 'persona', id: 1 }}
      />
      <OptionCard
        iconType='company'
        title='Empresa'
        subtitle='Seleccioná esta opción si sos una Empresa/Agencia'
        onClick={handleClick}
        dataForClick={{ name: 'empresa', id: 2 }}
      />
    </div>
  )
}

export default Step1
