import React, { useState, useEffect, useRef } from 'react'
import Text from '@atoms/Text/Text'
import GoBackButton from '@atoms/GoBackButton/GoBackButton'
import useScreenDetect from '@hooks/useScreenDetect'
import ShadowBox from '@atoms/ShadowBox/ShadowBox'
import styles from './Chat.module.scss'
import Form from '@atoms/Form/Form'
import Button from '@atoms/Button/Button'
import ChatMessage from '@molecules/ChatMessage/ChatMessage'
import socketIOClient from 'socket.io-client'
import { ENVIRONMENT_URL } from '@config/api'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  setHeaderMobileGoBack,
  setNoDistractionMode
} from '@actions/generalActions'
import { deleteToken, getToken } from '@utils/tokenService'
import { removeNotification,removeNotificationForPro } from '@actions/profileActions'
import socket from 'socket.io-client/lib/socket'
import { loginError } from '@actions/'
import {
  getWorkDetail,
  getUserWorkDetail,
  setCurrentDetailWork,
  setUserWorkStatus,
  setJobReadStatus,
  getUserWorksRequests
} from '@actions/profileActions'


const Chat = () => {
  const [yourID, setYourID] = useState()
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState('')

  const { isAuth } = useSelector((state) => state.auth)

  const { isMobile } = useScreenDetect()
  const dispatch = useDispatch()
  const history = useHistory()

  const socketRef = useRef()
  const messagesRef = useRef(null)
  const { id } = useParams()
  const jobId = id

  useEffect(() => {
    if (!isAuth || !getToken()) {
      // if (window.location.pathname !== '/iniciar-sesion') {
      deleteToken()
      dispatch(loginError())
      localStorage.setItem('goback-url', window.location.pathname)
      window.location.href = '/iniciar-sesion'
      // }
    }

    socketRef.current = socketIOClient(ENVIRONMENT_URL, {
      query: {
        jobId: jobId,
        token: getToken()
      }
    })

    socketRef.current.on('invalid_token', () => {
      deleteToken()
      dispatch(loginError())
      localStorage.setItem('goback-url', window.location.pathname)
      window.location.href = '/iniciar-sesion'
    })

    socketRef.current.on('connect', () => {
      socketRef.current.emit('request_all_messages_by_room', { room: jobId })
    })

    socketRef.current.on('send_all_messages_by_room', (messages) => {
      messages.map((message) => {
        const messageObject = {
          id: message.author.id,
          name: `${message.author.name} ${message.author.lastName}`,
          body: message.content
        }
        if (profileData.profileMode == "PRO"){
          dispatch(removeNotificationForPro('message', `mid${message.id}`))
        }else {
          dispatch(removeNotification('message', `mid${message.id}`));
        }

        
        return receivedMessage(messageObject)
      })
    })

    socketRef.current.on('connected', (id) => {
      setYourID(id)
    })

    socketRef.current.on('receive_message', (message) => {
      // Se comenta porque genera error, guarda automaticamente todos los mensajes como leidos. (Queda funcionando OK el sistema de chat)
      // socketRef.current.emit("see_message", message.id);
      // dispatch(removeNotification())
      const messageObject = {
        id: message.author.id,
        name: `${message.author.name} ${message.author.lastName}`,
        body: message.content
      }
      receivedMessage(messageObject)
    })

    if (messagesRef) {
      messagesRef.current.addEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' })
      })
    }

    dispatch(setNoDistractionMode(true))
    dispatch(setHeaderMobileGoBack(true))

    return () => {
      dispatch(setNoDistractionMode(false))
      dispatch(setHeaderMobileGoBack(false))
    }
    // eslint-disable-next-line
  }, [isAuth]);

  useEffect(() => {
    if (!workDetails) {
      profileData.isPro
        ? dispatch(getWorkDetail(id))
        : dispatch(getUserWorkDetail(id))
    }
    dispatch(setHeaderMobileGoBack(true))
    return () => {
      dispatch(setCurrentDetailWork(false))
      dispatch(setHeaderMobileGoBack(false))
    }
  }, [])

  const { workDetails, profileData } = useSelector((state) => state.profile)
  const [alias, setAlias] = useState(null);

  useEffect( () => {
    if(workDetails) setAlias(workDetails.professionalProfile.fantasy);
  }, [workDetails])


  function receivedMessage (message) {
    setMessages((oldMsgs) => [...oldMsgs, message])
  }

  function sendMessage (e) {
    e.preventDefault()
    setMessage('')
    if (message) socketRef.current.emit('send_message', message)
  }

  function handleChange (e) {
    setMessage(e.target.value)
  }

  function makeConversation () {
    let lastSender = null
    return (
      <>
        {messages.map((message, index) => {
          const isMe = message.id === yourID
          if (message.id === lastSender) {
            return (
              <ChatMessage
                key={index}
                body={message.body}
                customStyles={{
                  marginLeft: isMe ? 'auto' : '0',
                  marginRight: isMe ? '0' : 'auto',
                  alignItems: isMe ? 'flex-end' : 'flex-start'
                }}
              />
            )
          } else {
            lastSender = message.id
            return (
              <ChatMessage
                key={index}
                name={isMe ? 'Yo' : (alias ? alias:message.name)}
                body={message.body}
                customStyles={{
                  marginTop:
                    index === 0 ? '0' : 'calc(var(--spacing-small) * 4)',
                  marginLeft: isMe ? 'auto' : '0',
                  marginRight: isMe ? '0' : 'auto',
                  alignItems: isMe ? 'flex-end' : 'flex-start'
                }}
              />
            )
          }
        })}
      </>
    )
  }

  // useEffect(() => {
  //   if (!workDetails) {
  //     profileData.isPro
  //       ? dispatch(getWorkDetail(id))
  //       : dispatch(getUserWorkDetail(id))
  //   }
  //   dispatch(setHeaderMobileGoBack(true))
  //   return () => {
  //     dispatch(setCurrentDetailWork(false))
  //     dispatch(setHeaderMobileGoBack(false))
  //   }
  // }, [])

  // const { workDetails, profileData } = useSelector((state) => state.profile)
  
  return (
    <div className={styles.chatPage}>
      {!isMobile && <GoBackButton />}
      <div className={styles.content}>
        <ShadowBox
          width='100%'
          noShadow={isMobile}
          customStyles={{
            padding: isMobile ? '0' : 'var(--spacing-medium)',
            borderRadius: '8px'
          }}
        >
          <Text
            tag='p'
            size={isMobile ? 'xl' : 'xl-1'}
            weight='bold'
            align='left'
            customStyles={{ marginBottom: 'var(--spacing-small)' }}
          >
            Chat
          </Text>
          <div className={styles.chatContainer}>
            <div className={styles.messagesContainer} ref={messagesRef}>
              {makeConversation()}
            </div>
            <Form onSubmit={sendMessage}>
              <div className={styles.bottom}>
                <input
                  className={styles.input}
                  value={message}
                  onChange={handleChange}
                  placeholder='Escribí acá tu mensaje'
                />
                <Button
                  type='submit'
                  customStyles={{ maxWidth: isMobile ? '100%' : 'max-content' }}
                  isDisabled={!!(message.length === 0 || message.length > 255)}
                >
                  Enviar mensaje
                </Button>
                <Text
                  tag='p'
                  size={isMobile ? 's' : 'm'}
                  weight='bold'
                  align='right'
                  customStyles={{ marginLeft: 'var(--spacing-small)' }}
                >
                  {message.length}/255
                </Text>
              </div>
            </Form>
          </div>
        </ShadowBox>
      </div>
    </div>
  )
}

export default Chat
