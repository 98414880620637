import React, { useState } from 'react'
import styles from './ProfileServices.module.scss'
import ServiceList from './ServiceList'
import Text from '@atoms/Text/Text'
import { useSelector } from 'react-redux'

const ProfileServices = ({
  services,
  isMobile,
  editMode,
  deleteServiceAction,
  addServiceAction,
  register,
  handleChangeItem,
  values,
  onModal
}) => {
  const [openLists, setOpenLists] = useState([])
  const handleOpenList = (list) => {
    if (openLists.includes(list)) { return setOpenLists(openLists.filter((i) => i !== list)) }
    return setOpenLists([...openLists, list])
  }
  const { professionalProfile } = useSelector((state) => state.profile)

  return (
    <div className={`${styles.container} ${editMode && styles.editMode}`}>
      {editMode && (
        <p className={styles.addService} onClick={() => addServiceAction()}>
          <span>+</span> Agregar servicio
        </p>
      )}
      {services &&
        services.map((service, index) => {
          return (
            <ServiceList
              key={service.id}
              serviceCategory={service}
              handleOpenList={handleOpenList}
              opened={openLists.includes(service)}
              indexFromParent={index}
              isMobile={isMobile}
              editMode={editMode}
              deleteServiceAction={deleteServiceAction}
              register={register}
              handleChangeItem={handleChangeItem}
              values={values}
              onModal={onModal}
            />
          )
        })}
      {!editMode && professionalProfile && professionalProfile.professionalProfileCategory?.length < 3 && window.location.pathname !== '/perfil-profesional' && (
        <div>
          <Text tag='p' size='m' align='left'>
            Este profesional aún no especificó que servicios ofrece.
          </Text>
        </div>
      )}
    </div>
  )
}

export default ProfileServices
