import React, { Suspense, useEffect } from "react";
import CategoriesSliderSection from "./sections/CategoriesSliderSection";
import HeroSection from "./sections/HeroSection";
import HowItWorks from "./sections/HowItWorks";
import MobileHowItWorks from "./sections/HowItWorks/mobile";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import useScreenDetect from "@hooks/useScreenDetect";
import { MobileCategoriesSection } from "./sections/MobileCategoriesSection";
import { getFromSwitchFromLS } from "@utils/tokenService";
import useNearScreen from "@hooks/useNearScreen";
import Skeleton from "react-loading-skeleton";
import { axiosGet, axiosPost, axiosPut, setTokenInAxios } from "@config/api";
import { useDispatch } from "react-redux";
import { setModal, getFirstLogin, userMe, userMeIsAuth } from "@actions/generalActions";
import Process from './sections/Process';
import Kpi from './sections/Kpi';
import Testimonials from "./sections/Testimonials";

const SliderSection = React.lazy(() => import("./sections/SliderSection"));
const Benefits = React.lazy(() => import("./sections/Benefits"));

const Home = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { profileData, unreadNotifications,unreadNotificationsForPro } = useSelector(
    (state) => state.profile
  );
  // const isLoggedIn = !!(isAuth && profileData && profileData.name);

  const history = useHistory();
  const { isMobile } = useScreenDetect();
  const { isNearScreen, fromRef } = useNearScreen({
    distance: "250px",
  });
  const dispatch = useDispatch();


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleEmailProfessional();
    handleContactProfessional();
    handleGobackFromRedirect();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getFromSwitchFromLS()) {
      localStorage.removeItem("fromSwitch");
    }
  }, []);

  useEffect(() => {
    if(isAuth) {
      dispatch(userMe());
    }
  }, []);

  useEffect(() => {
    if (isAuth) {
      dispatch(userMeIsAuth(history));
    }
  }, [isAuth]);

  function handleEmailProfessional() {
    const userHasEmailed = localStorage.getItem("email-info");
    if (!userHasEmailed) return;
    const { professionalUserId } = JSON.parse(
      localStorage.getItem("email-info")
    );
    if (!isAuth) {
      localStorage.removeItem("email-info");
      return;
    }
    history.push(`perfil/${professionalUserId}`);
  }

  function handleContactProfessional() {
    const hasUserContacted = localStorage.getItem("contact-info");
    if (!hasUserContacted) return;
    const { professionalProfileUserId } = JSON.parse(hasUserContacted);
    if (!isAuth) {
      localStorage.removeItem("contact-info");
      return;
    }
    history.push(`perfil/${professionalProfileUserId}`);
  }

  function handleGobackFromRedirect() {
    const hasUserGobackUrl = localStorage.getItem("goback-url");
    localStorage.removeItem("goback-url");
    if (!hasUserGobackUrl) return;
    history.push(`${hasUserGobackUrl}`);
  }

  return (
    <>
    {!localStorage.getItem('admin') &&
      <main>
        <HeroSection />
        <Process />
        {!isMobile && <CategoriesSliderSection />}
        {isMobile && <MobileCategoriesSection />}
        <Kpi />
        <Testimonials />
      </main>
      }
    </>
  );
};

export default Home;
