import React from 'react'
import styles from './IconTooltip.module.scss'
import { ReactComponent as InfoIcon } from '@assets/svg/info.svg'

const IconTooltip = ({ text, customStyles }) => {
  return (
    <div className={styles.container} style={customStyles}>
      <InfoIcon className={styles.icon} />
      <div className={styles.tooltip}>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default IconTooltip
