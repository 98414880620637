import useScreenDetect from '@hooks/useScreenDetect'
import ValorationComment from '@molecules/ValorationComment/ValorationComment'
import Text from '@atoms/Text/Text'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './ProfileEditModal.module.scss'
import Button from '@atoms/Button/Button'
import { editExternalValorations } from '@actions/profileActions'
const ProfileEditValorations = ({ profileData }) => {
  const [selectedItems, setSelectedItems] = useState([])
  const [deletedItems, setDeletedItems] = useState([])
  const [disabledItems, setDisabledItems] = useState([])
  const { proExternalReviews } = useSelector((state) => state.profile)

  const { isMobile } = useScreenDetect()

  const dispatch = useDispatch()

  const handleSave = () => {
    const mergedComments = [
      ...selectedItems.map((selectedItem) => {
        return { id: selectedItem.id, show: selectedItem.show }
      }),
      ...deletedItems.map((deletedItem) => {
        return { id: deletedItem.id, show: deletedItem.show }
      })
    ]
    const objectData = {
      professionalProfileId: profileData.id,
      externalReviews: mergedComments
    }
    dispatch(editExternalValorations(objectData))
  }

  const handleSelectItem = (comment) => {
    let included = false
    selectedItems.map((service) => {
      if (service.id === comment.id) return (included = true)
      return false
    })
    if (included) {
      setDeletedItems([...deletedItems, { ...comment, show: false }])
      return setSelectedItems([
        ...selectedItems.filter((i) => i.id !== comment.id)
      ])
    }
    setDeletedItems([...deletedItems.filter((i) => i.id !== comment.id)])
    return setSelectedItems([...selectedItems, { ...comment, show: true }])
  }

  useEffect(() => {
    if (selectedItems.length >= 10) setDisabledItems(deletedItems)
    if (selectedItems.length < 10) setDisabledItems([])
  }, [selectedItems])

  useEffect(() => {
    if (proExternalReviews.length > 0) {
      const selecteds = proExternalReviews.filter((i) => i.show)
      const notSelecteds = proExternalReviews.filter((i) => !i.show)
      setSelectedItems(selecteds)
      setDeletedItems(notSelecteds)
    }
  }, [proExternalReviews])

  return (
    <div className={styles.valorationModal}>
      <div className={styles.header}>
        <Text
          tag='p'
          size='s'
          color='secondary-blue'
          weight='bold'
          align='right'
        >
          {selectedItems.length}/10
        </Text>
      </div>
      <div className={styles.body}>
        {proExternalReviews.map((externalReview, index) => {
          let selected
          let disabled = false

          selectedItems.map((item) => {
            if (externalReview.id === item.id) return (selected = true)
            return false
          })
          disabledItems &&
            disabledItems.map((item) => {
              if (externalReview.id === item.id) return (disabled = true)
              return false
            })

          return (
            <div
              key={index}
              className={`${styles.valorationItem} ${disabled &&
                styles.disabled}`}
            >
              <div
                onClick={() => handleSelectItem(externalReview)}
                className={`${styles.box} ${selected && styles.checked}`}
              />
              <ValorationComment
                comment={externalReview}
                charLimit={110}
                isMobile={isMobile}
              />
            </div>
          )
        })}
      </div>
      <div className={styles.buttonContent}>
        <Button
          customStyles={{ maxWidth: isMobile ? '100%' : 'max-content' }}
          onClickHandler={() => handleSave()}
        >
          Agregar
        </Button>
      </div>
    </div>
  )
}
export default ProfileEditValorations
