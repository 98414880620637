import React from 'react';
import styles from './kpi.module.scss';
import kpi_1 from '@assets/img/kpi_1.jpg';
// import kpi_2 from '@assets/img/kpi_2.jpg';
import kpi_3 from '@assets/img/kpi_3.jpg';
import kpi_5 from '@assets/img/kpi_5.jpg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import useScreenDetect from '@hooks/useScreenDetect';

const Kpi = () => {
  // const imagenes = [kpi_1, kpi_2, kpi_3, kpi_5]
  const imagenes = [kpi_1, kpi_3, kpi_5]

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  return (
    <>
      <div className={styles.containerKpi}>
        <div className={styles.fondo}>
          <Slider {...settings}>
            {imagenes.map((item, i) => (
              <HowItem className={styles.carouselItem} item={item} key={i} />
            ))}
          </Slider>
        </div>
      </div>
    </>
  )
};

export const HowItem = ({ item }) => {
  return (
    <div className={styles.sonsCarousel}>
      <img src={item} />
    </div>
  )
};

function SampleNextArrow(props) {
  const { isMobile } = useScreenDetect()
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={
        !isMobile
          ? {
              ...style,
              display: 'block',
              background: 'transparent',
              marginRight: '33rem',
              borderRadius: '10px',
              backgroundColor: 'transparent',
              filter: 'drop-shadow( 0px 0px 7px rgba(0, 0, 0, .7))'
            }
          : {
              ...style,
              display: 'block',
              background: 'transparent',
              marginRight: '30px',
              backgroundColor: 'transparent',
              borderRadius: '10px',
              filter: 'drop-shadow( 0px 0px 7px rgba(0, 0, 0, .7))'
            }
      }
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { isMobile } = useScreenDetect()
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={
        !isMobile
          ? {
              ...style,
              display: 'block',
              background: 'transparent',
              marginLeft: '33rem',
              zIndex: '5',
              borderRadius: '10px',
              backgroundColor: 'transparent',
              filter: 'drop-shadow( 0px 0px 7px rgba(0, 0, 0, .7))'
            }
          : {
              ...style,
              display: 'block',
              background: 'transparent',
              marginLeft: '30px',
              backgroundColor: 'transparent',
              borderRadius: '10px',
              zIndex: '5',
              filter: 'drop-shadow( 0px 0px 7px rgba(0, 0, 0, .7))'
            }
      }
      onClick={onClick}
    />
  )
}

export default Kpi
