import Button from '@atoms/Button/Button'
import CheckboxGroup from '@molecules/CheckboxGroup/CheckboxGroup'
import { paymentMethods } from '@utils/mock-data'
import React, { useState } from 'react'
import styles from './ProfileEditModal.module.scss'

const makePaymentsCompatible = (payments) => {
  const formatedPayments = [
    ...payments.map((payment) => {
      return paymentMethods.find(
        (paymentMeth) => paymentMeth.value === payment.paymentMethod
      )
    })
  ]
  return formatedPayments
}

const ProfileEditPayments = ({ profileData, isMobile, handleSubmit }) => {
  const [paymentsSelecteds, setPaymentsSelecteds] = useState(
    makePaymentsCompatible(profileData.professionalProfilePaymentMethod)
  )

  const handleCheckboxItems = (value) => {
    let included = false
    paymentsSelecteds.map((payment) => {
      if (payment.id === value.id) return (included = true)
      return false
    })
    if (included) {
      return setPaymentsSelecteds([
        ...paymentsSelecteds.filter((i) => i.id !== value.id)
      ])
    }
    return setPaymentsSelecteds([...paymentsSelecteds, value])
  }

  const handleSave = () => {
    const formatedArray = [
      ...paymentsSelecteds.map((payment) => {
        return { value: payment.value }
      })
    ]
    handleSubmit(formatedArray)
  }

  return (
    <div className={styles.container}>
      <CheckboxGroup
        options={paymentMethods}
        selectedItems={paymentsSelecteds}
        selectItemAction={handleCheckboxItems}
      />
      <div className={styles.modalFooter}>
        <Button
          customStyles={{
            maxWidth: isMobile ? '100%' : '11.5rem'
          }}
          onClickHandler={() => handleSave()}
        >
          Guardar
        </Button>
      </div>
    </div>
  )
}
export default ProfileEditPayments
