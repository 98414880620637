import React, { useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import styles from './RecoverPassword.module.scss'
import { useForm } from 'react-hook-form'
import Field from '@molecules/InputTextBox/InputTextBox'
import Form from '@atoms/Form/Form'
import Button from '@atoms/Button/Button'
import { useParams } from 'react-router'
import { changePasswordHandler } from '@actions/authActions'
import { setLoading } from '@actions/generalActions'

const RecoverPassword = ({ success }) => {
  const { loading } = useSelector((state) => state.general)
  const dispatch = useDispatch()
  const { token } = useParams()

  const { register, handleSubmit, errors, watch } = useForm()
  const password = useRef({})
  password.current = watch('contrasena', '')

  const onSubmit = (values) => {
    dispatch(setLoading(true))
    const data = {
      token,
      password: values.contrasena
    }
    dispatch(changePasswordHandler(data))
  }

  const isDisabled =
    errors.contrasena ||
    errors.repeat_contrasena ||
    !watch('repeat_contrasena') ||
    !watch('contrasena') ||
    loading

  return (
    <div className={styles.recoverPassword}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.recoverPassword_main}>
          <Field
            type='password'
            name='contrasena'
            label='Nueva contraseña'
            placeholder='Ej: C8oP6asI0ñC3'
            inputRef={register({
              required: 'Campo obligatorio',
              minLength: {
                value: 8,
                message: 'La contraseña debe tener al menos 8 caracteres'
              },
              pattern: {
                value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/,
                message: 'La contraseña debe tener caracteres alfamuméricos'
              }
            })}
            error={errors.contrasena}
          />
          <Field
            type='password'
            name='repeat_contrasena'
            label='Repetir nueva contraseña'
            placeholder='Ej: C8oP6asI0ñC3'
            inputRef={register({
              validate: (value) =>
                value === password.current || 'Las contraseñas no coinciden'
            })}
            error={errors.repeat_contrasena}
            customStyles={{ marginTop: '2.4rem' }}
          />

          <span className={styles.passwordMessage}>
            La contraseña deberá tener al menos 8 caracteres alfamuméricos
          </span>
          <Button
            isDisabled={isDisabled}
            isLoading={loading}
            customStyles={{ maxWidth: 'unset' }}
            type='submit'
          >
            Guardar
          </Button>
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = (state) => ({ success: state.general.success })

export default connect(mapStateToProps)(RecoverPassword)
