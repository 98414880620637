import React from 'react'
import styles from './styles.module.scss'
import ChatContent from '@organism/Chat/Chat'

const Chat = () => {
  return (
    <div className={styles.container}>
      <ChatContent />
    </div>
  )
}

export default Chat
