import Spinner from '@atoms/Spinner'
import React from 'react'
import styles from './Button.module.scss'

const Button = ({
  type = 'button',
  children,
  isLoading,
  isDisabled,
  onClickHandler,
  buttonStyle,
  customStyles,
  buttonSearch
}) => {
  return (
    <button
      type={type}
      disabled={isDisabled}
      onClick={onClickHandler || null}
      className={`${buttonStyle && styles[buttonStyle]}  ${styles.button}`}
      style={!buttonSearch ? customStyles: {...customStyles, padding: '0px', fontSize: '2.2rem'}}
      id={styles.input}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  )
}

export default Button
