import React from 'react'
import styles from './Input.module.scss'
const input = ({
  register,
  name,
  id,
  placeholder,
  type,
  hidden,
  disabled,
  accept,
  value,
  error,
  customStyles,
  onChangeHandler
}) => {
  return (
    <input
      className={`${styles.input} ${error ? styles.error : ''}`}
      ref={register}
      name={name}
      id={id}
      value={value}
      type={type}
      hidden={hidden}
      placeholder={placeholder}
      onChange={(e) => onChangeHandler(e)}
      disabled={disabled}
      accept={accept}
      style={{
        ...customStyles
      }}
    />
  )
}

export default input
