import { getProfileData } from '@actions/profileActions'
import { ReactComponent as Step13Icon } from '@assets/svg/step13.svg'
import AlertMessage from '@atoms/AlertMessage/AlertMessage'
import Button from '@atoms/Button/Button'
import ShadowBox from '@atoms/ShadowBox/ShadowBox'
import Text from '@atoms/Text/Text'
import useScreenDetect from '@hooks/useScreenDetect'
import FalseProfile from '@molecules/FalseProfile/FalseProfile'
import { deleteToken, getFromSwitchFromLS } from '@utils/tokenService'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

const ConfirmEmail = ({ fromProRegister }) => {
  const { isMobile } = useScreenDetect()
  const history = useHistory()
  const dispatch = useDispatch()
  const [loggedByFaceOrGoogle, setLoggedByFaceOrGoogle] = useState(false)

  const handleClick = () => {
    if (getFromSwitchFromLS()) {
      history.push('/perfil-profesional')
      localStorage.removeItem('fromSwitch')
    } else {
      history.push('/')
      window.location.reload()
    }
  }

  useEffect(() => {
    if (fromProRegister && !getFromSwitchFromLS()) {
      deleteToken()
    }
    if (getFromSwitchFromLS()) {
      dispatch(getProfileData())
    }
  }, [])

  useEffect(() => {
    const fromGoogleOrFacebook = sessionStorage.getItem('authGoogleOrFacebook')
    if (fromGoogleOrFacebook) {
      setLoggedByFaceOrGoogle(true)
    }
  }, [])

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <ShadowBox
        noShadow={isMobile}
        customStyles={{ minWidth: isMobile ? '100%' : '64rem' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Step13Icon />

          {getFromSwitchFromLS()
            ? (
              <>
                <Text
                  tag='p'
                  weight='semibold'
                  size='l-1'
                  customStyles={{
                    margin: 'var(--spacing-medium) 0 var(--spacing-small)'
                  }}
                >
                  ¡Ya podés usar nuestra plataforma como profesional!
                </Text>
                <Text tag='p' weight='regular' size='m'>
                  Cambiá entre los modos <b style={{ color: 'var(--color-item)' }}>Contratá</b> y <b style={{ color: 'var(--secondary-blue)' }}>Ofrecé</b> clickeando en el siguiente botón, ubicado en el menú:
                </Text>
                <FalseProfile />
              </>)
            : (
              <>
                <Text
                  tag='p'
                  weight='semibold'
                  size='l-1'
                  customStyles={{
                    margin: 'var(--spacing-medium) 0 var(--spacing-small)'
                  }}
                >
                  ¡Listo, cargaste tus datos correctamente!
                </Text>
                {!loggedByFaceOrGoogle && (
                  <>
                    <Text tag='p' weight='regular' size='m'>
                      Solo falta que valides tu email. <br /> Dirígete a tu casilla de
                      correo y hacé click en “Validar email”
                    </Text>
                    <AlertMessage
                      message='Si no recibiste el mail, revisá tu casilla de Spam.'
                      icon='alert'
                      customStyles={{
                        width: 'max-content',
                        marginTop: isMobile ? '1.6rem' : '0.8rem',
                        marginBottom: isMobile ? '0rem' : '1.6rem'
                      }}
                    />
                  </>
                )}
              </>
              )}

          <Button
            customStyles={{
              maxWidth: 'max-content',
              marginTop: 'calc(var(--spacing-small) * 2)'
            }}
            onClickHandler={() => handleClick()}
          >
            {getFromSwitchFromLS() ? 'Ir al perfil' : 'Ir al inicio'}
          </Button>
        </div>
      </ShadowBox>
    </motion.div>
  )
}

export default ConfirmEmail
