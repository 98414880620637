
import styles from './styles.module.scss'
import WorksLayout from '@organism/Works/Works'
import { useSelector } from "react-redux";
import React, {  useEffect } from "react";
import { useHistory } from "react-router-dom";





const Works = () => {
  const isAuth  = useSelector((state) => state.auth);
const history = useHistory();

  useEffect(() => {
    if (!isAuth.isAuth) {
      history.push('/iniciar-sesion')
    }
    // eslint-disable-next-line
  }, []);

  return (
    
    <div className={styles.container}>
      <WorksLayout />
    </div>
  )
}

export default Works
