import React, { useEffect, useState } from "react";
import ContactPro from "@molecules/ContactPro/ContactPro";
import ModalTitle from "@atoms/ModalTitle/ModalTitle";
import { useDispatch, useSelector } from "react-redux";
import { setModal, postRequestContact } from "@actions/";
import styles from "./ShareProfileModal.module.scss";
import { withRouter } from "react-router-dom";
// import moment from 'moment'
// import 'moment/locale/es'
import dayjs from "dayjs";
import "dayjs/locale/es";
import { getHorariosContacto } from "@actions/generalActions";
import { getIdFromLS } from "@utils/tokenService";
import { FacebookShareButton } from "react-share";
import { WhatsappShareButton } from "react-share";
import { EmailShareButton } from "react-share";
import { FacebookIcon } from "react-share";
import { WhatsappIcon } from "react-share";
import { EmailIcon } from "react-share";
import { AiOutlineCopy } from "react-icons/ai";

const ShareProfileModal = ({
  isMobile,
  modalType,
  history,
  professionalProfile,
  withoutServices = false,
}) => {
  let urlProfile = window.location.href;

  const copy = (e, value) => {
    e.preventDefault();
    let copy = document.querySelector(".copiar");
    copy.style.display = "block";

    var aux = document.createElement("input");
    aux.setAttribute("value", value);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");

    setTimeout(() => {
      copy.style.display = "none";
    }, 1000);

    document.body.removeChild(aux);
  };

  const [url, setUrl] = useState("");
  const [path, setPath] = useState("");

  useEffect(() => {
    setUrl(window.location.href);
    setPath(window.location.pathname);
  }, []);

  return (
    <div className={styles.container}>
      <ModalTitle
        title={"Compartí este Perfil"}
        customStyles={{ marginBottom: "var(--spacing-small)" }}
      />
      <section className={styles.shareButtons}>
        <div>
          <FacebookShareButton
            url={`https://development.zenpros.work${path}`}
            quote={`Mirá este perfil! #Zenpros ${url}`}
            hashtag="#Zenpros"
          >
            <FacebookIcon logoFillColor="white" round={true}></FacebookIcon>
          </FacebookShareButton>
        </div>
        <div>
          <WhatsappShareButton url={url} title={"Mirá este perfil! #Zenpros"}>
            <WhatsappIcon logoFillColor="white" round={true}></WhatsappIcon>
          </WhatsappShareButton>
        </div>
        <div>
          <EmailShareButton url={url} subject="Zenpros - Mirá este perfil!" >
            <EmailIcon logoFillColor="white" round={true}></EmailIcon>
          </EmailShareButton>
        </div>
        <div>
          <AiOutlineCopy
            className={`${styles.iconCopy}`}
            onClick={(e) => copy(e, urlProfile)}
          />
          <span
            id="copiar"
            className={`${styles.copySpan} copiar`}
            style={{ display: "none" }}
          >
            copiado!
          </span>
        </div>
      </section>
    </div>
  );
};

export default withRouter(ShareProfileModal);
