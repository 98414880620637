import * as actionTypes from "../actions/types";
import { axiosGet, axiosPost } from "@config/api";
import gtag from 'ga-gtag';
import ReactGA from 'react-ga';

export const successRegister = (success) => {
  return {
    type: actionTypes.SUCCESS_REGISTER,
    payload: success,
  };
};

export const getProsCategories = () => {
  return (dispatch) => {
    axiosGet("/guests/category-tree")
      .then((response) => {
        dispatch({
          type: actionTypes.GET_PROS_CATEGORIES,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const getProsProfessions = () => {
  return (dispatch) => {
    axiosGet("/guests/category-professions")
      .then((response) => {
        dispatch({
          type: actionTypes.GET_PROS_PROFESSIONS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const getProsProfessionsSearch = () => {
  return (dispatch) => {
    axiosGet("/guests/category-professions-search")
      .then((response) => {
        let vertAndLevelOne = response.data.filter((item) => {
          if (
            item.parentcategory !== null &&
            item.parentcategory.parentcategory !== null &&
            item.parentcategory.parentcategory.parentcategory !== null &&
            item.parentcategory.parentcategory.parentcategory.question === null
          ) {
            return undefined;
          } else {
            return item;
          }
        });
        dispatch({
          type: actionTypes.GET_PROS_PROFESSIONS_SEARCH,
          payload: vertAndLevelOne,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const setProfessionalResult = (value, array, arrCategories) => {
  let filterArray;
  let pushArray = [];

  let filtrado = arrCategories.map((category) => category.name);

  console.log(filtrado, "FILTRADO");

  if (filtrado.length > 0) {
    filterArray = array.pros?.filter((nivelOne) => {
      if (nivelOne.professionalProfileCategory[2] === undefined) {
        return false;
      }
      let ok = true;
      for (let i = 0; i < filtrado.length && ok; i++) {
        // Corta cuando no hay mas following o cuando ya se encontró uno
        let following = filtrado[i];

        for (
          let o = 0;
          o < nivelOne.professionalProfileCategory?.length - 1;
          o++
        ) {
          if (
            following !==
            nivelOne.professionalProfileCategory[o]?.category?.name
          ) {
            ok = false;
          } else {
            ok = true;
            break;
          }
        }
        if (ok) break;
      }
      return ok;
    });
  }

  let newFilterObject = {
    count: filterArray?.length,
    pros: filterArray,
  };

  console.log(array, "DE NUEVO array");
  console.log(newFilterObject, "DE NUEVO filter");

  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_PROFESSIONAL_RESULTS,
      payload: filtrado.length > 0 ? newFilterObject : array,
    });
  };
};

export const getProsVerticals = () => {
  return (dispatch) => {
    axiosGet("/guests/category-verticals")
      .then((response) => {
        dispatch({
          type: actionTypes.GET_PROS_VERTICALS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const postRequestContact = (
  data,
  professionId,
  professionalUserName
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    gtag('event', 'conversion', {'send_to': 'AW-417421308/nQbfCJeMno4DEPyvhccB'});
    const scriptPro = document.createElement("script");
    scriptPro.innerHTML =
    "gtag('event', 'conversion', {'send_to': 'AW-417421308/nQbfCJeMno4DEPyvhccB'})";  
    document.body.appendChild(scriptPro);
    axiosPost("/users/job", data)
      .then((response) => {
        dispatch(setModal(true, "successModal"));
        dispatch(
          setSuccessModal({
            title: "¡Listo!",
            subtitle: `Se envió tu información con éxito al profesional ${professionalUserName} y a otros perfiles similares que creemos que pueden ayudarte con tu pedido.`,
            buttonText: "Ir a la Galeria",
            urlToRedirect:
              professionId !== null
                ? `/search/${professionId}`
                : `/perfil-usuario`,
          })
        );
      })
      .catch((error) => {
        console.log({ error });
        setModal(true, "errorModal");
        dispatch(
          setSuccessModal({
            title: "¡Lo siento!",
            subtitle: "Ha ocurrido un error",
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
        localStorage.removeItem("contact-info");
        
      });
  };
};

export const postBulkRequestContact = (data, professionId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
   gtag('event', 'conversion', {'send_to': 'AW-417421308/nQbfCJeMno4DEPyvhccB'});
    const scriptPro = document.createElement("script");
    scriptPro.innerHTML =
    "gtag('event', 'conversion', {'send_to': 'AW-417421308/nQbfCJeMno4DEPyvhccB'})";  
    document.body.appendChild(scriptPro);

    axiosPost("/users/job", data)
      .then((response) => {
        dispatch(setModal(true, "successModal"));
        dispatch(
          setSuccessModal({
            title: "¡Listo!",
            subtitle: "En breve te contactarán los mejores expertos.",
            buttonText: "Ir a la Galeria",
            urlToRedirect:
              professionId !== null
                ? `/search/${professionId}`
                : `/perfil-usuario`,
          })
        );
      })
      .catch((error) => {
        console.log({ error });
        setModal(true, "errorModal");
        dispatch(
          setSuccessModal({
            title: "¡Lo siento!",
            subtitle: "Ha ocurrido un error",
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
        localStorage.removeItem("contact-info");
      });
  };
};

export const sendEmailToPro = (message, professionalId, userId) => {
  const data = {
    message: message,
  };
  return (dispatch) => {
    axiosPost(
      `/professional/send-email-to-pro/${professionalId}/${userId}`,
      data
    )
      .then((response) => {
        dispatch(setModal(true, "successModal"));
        dispatch(
          setSuccessModal({
            title: "¡Listo!",
            subtitle: "Se envió tu email con éxito al profesional",
          })
        );
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        setModal(true, "errorModal");
        dispatch(
          setSuccessModal({
            title: "¡Lo siento!",
            subtitle: "Ha ocurrido un error",
          })
        );
      })
      .finally(() => {
        const userHasEmailed = localStorage.getItem("email-info");
        if (userHasEmailed) {
          localStorage.removeItem("email-info");
        }
        dispatch(setLoading(false));
      });
  };
};

export const getBarrios = () => {
  return (dispatch) => {
    axiosGet("/guests/neighborhood")
      .then((response) => {
        dispatch({ type: actionTypes.GET_BARRIOS, payload: response.data });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const getHorariosContacto = () => {
  return (dispatch) => {
    axiosGet("/guests/contacttimes")
      .then((response) => {
        dispatch({ type: actionTypes.GET_HORARIOS, payload: response.data });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const getCategorySons = (id) => {
  return (dispatch) => {
    axiosGet("/guests/category", {
      data: null,
      params: { id: id },
    })
      .then((response) => {
        dispatch({
          type: actionTypes.GET_CATEGORY_SONS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const getMultiCategorySons = (ids) => {
  const objectValue = {
    ids: ids,
  };
  return (dispatch) => {
    axiosPost("/guests/multi-category-childs", objectValue)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_MULTI_CATEGORY_SONS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const cleanCategorySons = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CATEGORY_SONS,
      payload: [],
    });
  };
};

export const setSuggestValue = (suggestValue) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SUGGEST_VALUE,
      payload: suggestValue,
    });
  };
};

export const getDataFromHash = (token) => {
  return (dispatch) => {
    axiosPost(`/guests/verify-hash?hash=${token}`)
      .then((response) => {
        dispatch({
          type: actionTypes.SET_HASH_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const sendExternalReview = (data, hash) => {
  return (dispatch) => {
    axiosPost(`/guests/review?hash=${hash}`, data)
      .then((response) => {
        dispatch(setModal(true, "successModal"));
        dispatch(
          setSuccessModal({
            title: "¡Felicitaciones!",
            subtitle: "La valoración fue enviada con éxito",
            buttonText: "Ir a la pantalla principal",
            urlToRedirect: "/",
          })
        );
      })
      .catch((error) => {
        dispatch(setModal(true, "errorModal"));
        dispatch(
          setSuccessModal({
            title: "¡Lo siento!",
            subtitle: "Ya calificaste al usuario",
            buttonText: "Ir a la pantalla principal",
            urlToRedirect: "/",
          })
        );
      });
  };
};

export const getCategoryBranch = (id) => {
  return (dispatch) => {
    axiosGet(`/guests/category-tree-branch/${id}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_CATEGORY_BRANCH,
          payload: response.data.categories,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};
export const cleanCategoryBranch = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CATEGORY_BRANCH,
      payload: [],
    });
  };
};

export const requestHelpZenPros = (message) => {
  const objectBody = {
    description: message,
  };
  return (dispatch) => {
    axiosPost("/users/help", objectBody)
      .then((response) => {
        dispatch(setModal(true, "successModal"));
        dispatch(
          setSuccessModal({
            title: "¡Listo!",
            subtitle: "Se envió tu consulta con éxito",
            buttonText: "Volver a solicitudes",
            withoutRedirect: true,
          })
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const getProfesionalResults = (data) => {
  let { categoryIds } = data;
  if (categoryIds.length >1) { (categoryIds = [...new Set(categoryIds)])}

  let objectBody = {
    skip: 0,
    take: 1000,
    categoryIds,
  };

  let sonsVertical;

  if (data.neighborhoodIds && data.neighborhoodIds.length > 0) {
    objectBody = { ...objectBody, neighborhoodIds: data.neighborhoodIds };
  }
  if (data.paymentMethod && data.paymentMethod.length > 0) {
    objectBody = { ...objectBody, paymentMethod: data.paymentMethod };
  }
  if (data.workType && data.workType.length > 0) {
    objectBody = { ...objectBody, workType: data.workType };
  }
  return (dispatch) => {
    axiosPost("/guests/professional-profile/search", objectBody)
      .then(async (response) => {
        let responseAux = response.data;
        const professionalsCount = responseAux.pop();

        let newData = [];

        let idVertical =
          responseAux[0]?.professionalProfileCategory[1]?.category.id;

        if (idVertical) {
          const objectValue = {
            ids: [idVertical],
          };

          // TODO: FUNCION A MANO "getMultiCategorySons"

          await axiosPost("/guests/multi-category-childs", objectValue)
            .then((response) => {
              console.log(response.data, " VER");
              sonsVertical = response.data[0].sons;
            })
            .catch((error) => {
              console.log(error.response);
            });
          // TODO: =====================================
        }

        if (categoryIds.length > 1) {
          newData = responseAux.map((item) => {
            let ok = false;
            for (
              let i = 2;
              i < item.professionalProfileCategory.length - 1;
              i++
            ) {
              if (
                !sonsVertical.includes(
                  item.professionalProfileCategory[i].category.id
                ) // SI ES NIVEL 1, ENTRA EN ACA
              ) {
                if (
                  categoryIds.includes(
                    item.professionalProfileCategory[i].category.id
                  )
                ) {
                  return item;
                }
              }
            }
          });
        }

        newData = newData.filter((pro) => pro !== undefined);

        dispatch({
          type: actionTypes.SET_PROFESSIONAL_RESULTS,
          payload: {
            count: categoryIds.length > 1 ? newData.length : professionalsCount,
            pros: categoryIds.length > 1 ? newData : responseAux,
          },
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const setGlobalValuesSelected = (prosCategory) => {
  return {
    type: actionTypes.SET_GLOBAL_VALUES_SELECTED,
    payload: prosCategory,
  };
};

export const setNewValues = (professionalsResult) => {
  let newValues = [];
  newValues.push(professionalsResult);

  let newIds = [];

  let arreglo = newValues?.[0].pros?.map((pro) => {
    let ids = pro.professionalProfileCategory.map((id, index) => {
      if (index > 0) {
        if (id.category.question) return id.category.id;
      }
    });
    newIds = [...newIds, ...ids];
  });

  let goIds = [...new Set(newIds)];

  goIds = goIds.filter((item) => item !== undefined);

  let categoryIds = {
    categoryIds: goIds,
  };
  return {
    type: actionTypes.SET_NEW_VALUES,
    payload: categoryIds,
  };
};

export const clearProfessionalResults = () => {
  return {
    type: actionTypes.SET_PROFESSIONAL_RESULTS,
    payload: { count: 0, pros: [] },
  };
};

export const setHeaderMobileGoBack = (value) => {
  return {
    type: actionTypes.SET_HEADER_MOBILE_GO_BACK,
    payload: value,
  };
};

export const setCurrentSearch = (value) => {
  return {
    type: actionTypes.SET_CURRENT_SEARCH,
    payload: value,
  };
};

export const setNoDistractionMode = (value) => {
  return {
    type: actionTypes.SET_NO_DISTRACTION_MODE,
    payload: value,
  };
};

export const setSuccessModal = (value) => {
  return {
    type: actionTypes.SET_SUCCESS_MODAL,
    payload: value,
  };
};

export const setReadyText = (value) => {
  return {
    type: actionTypes.SET_READY_TEXT,
    payload: value,
  };
};

export const footerOnboarding = (value) => {
  return {
    type: actionTypes.SET_FOOTER_ONBOARDING,
    payload: value,
  };
};

export const watchedVideo = () => {
  return {
    type: actionTypes.WATCHED_VIDEO,
  };
};

export const finishedVideos = (value) => {
  return {
    type: actionTypes.FINISHED_VIDEOS,
    payload: value,
  };
};

export const updateDataOnboarding = (value) => {
  return {
    type: actionTypes.UPDATE_ONBOARDING,
    payload: value,
  };
};

export const setMobileDevice = () => {
  return {
    type: actionTypes.SET_MOBILE_DEVICE,
  };
};

export const setModal = (value, modalType) => {
  return {
    type: actionTypes.SET_MODAL,
    payload: value,
    modalType: modalType || null,
  };
};

export const setLoading = (value) => {
  return {
    type: actionTypes.SET_LOADING,
    payload: value,
  };
};

export const getMostSearched = () => {
  return (dispatch) => {
    axiosGet("/guests/mostSearched")
      .then((response) => {
        dispatch({
          type: actionTypes.MOST_SEARCHED,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const getFirstLogin = (firstLogin) => {
  return {
    type: actionTypes.SET_FIRSTLOGIN,
    payload: firstLogin,
  };
};

export const userMe = () => {
  return (dispatch) => {
    axiosGet("/users/me")
      .then((response) => {
        let res = response.data;
        dispatch(getFirstLogin(res.firstLogin));
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const userMeIsAuth = (history) => {
  return (dispatch) => {
    axiosGet("/users/me")
      .then((response) => {
        let res = response.data;
        if (res.firstLogin === true && res.isPro === true)
          history.push("/perfil-profesional");
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const professionalMe = (history, footerOnb, firstLogin) => {
  return (dispatch) => {
    axiosGet("/professional/me")
      .then((response) => {
        let data = response.data;
        let phone = data["user"]["phone"];
        let about = data["description"];
        let services = data["professionalProfileCategory"];
        let photo = data["user"]["photo"];
        let reviews = data["reviewRequest"];

        if (
          !phone ||
          !about ||
          services.length <= 2 ||
          !photo ||
          reviews.length === 0
        ) {
          dispatch(userMeThenProfessional(history, footerOnb, firstLogin));
        }
      })
      .catch((error) => {
        console.error("NO SE PUDE OBTENER EL PROFESSIONAL");
      });
  };
};

export const userMeThenProfessional = (history, footerOnb, firstLogin) => {
  return (dispatch) => {
    axiosGet("/users/me").then(async (response) => {
      let res = response.data;
      // await axiosPut(`/users/restartFirstLogin`, {});
      // dispatch(getFirstLogin(res.firstLogin));
      if (footerOnb) {
        dispatch(setModal(true, "firstLogin"));
        return;
      }
      if (
        (res.stepOne && res.stepTwo && res.stepThree && res.stepFourth) ||
        !firstLogin
      ) {
        history.push("/perfil-profesional");
        dispatch(getFirstLogin(false));
        return;
      } else if (!firstLogin) {
        history.push("/perfil-profesional");
        return;
      } else {
        if (localStorage.getItem("oneTime") !== false) {
          history.push("/perfil-profesional");
          dispatch(setModal(true, "firstLogin"));
        }
      }
    }); // bien cierro then
  };
};
