const BAD = 'Mala'
const REGULAR = 'Regular'
const GOOD = 'Buena'
const VERY_GOOD = 'Muy buena'
const EXCELENT = 'Excelente'
export function getReputationFromRate (rate) {
  if (rate < 2) return BAD
  else if (rate >= 2 && rate < 3) return REGULAR
  else if (rate >= 3 && rate < 4) return GOOD
  else if (rate >= 4 && rate < 4.5) return VERY_GOOD
  else if (rate > 4.5) return EXCELENT
}
