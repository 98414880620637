import React from 'react'
import styles from './TextArea.module.scss'

const TextArea = ({
  register,
  name,
  placeholder,
  value,
  defaultValue,
  onChangeValue,
  customStyles,
  maxLength
}) => {
  return (
    <textarea
      placeholder={placeholder}
      name={name}
      ref={register}
      defaultValue={defaultValue}
      className={styles.textarea}
      value={value}
      onChange={(e) => (onChangeValue ? onChangeValue(e.target.value) : false)}
      style={customStyles}
      maxLength={maxLength || ''}
    />
  )
}

export default TextArea
