import { axiosInstance } from '@config/api'

const AUTH_PATH = '/auth'

export const login = (user) => axiosInstance.post(`${AUTH_PATH}/login`, user)
export const googleLogin = () => axiosInstance.get(`${AUTH_PATH}/google`)
export const facebookLogin = () => axiosInstance.get(`${AUTH_PATH}/facebook`)
export const forgotPassword = (data) =>
  axiosInstance.post(`${AUTH_PATH}/passwordRecovery`, null, {
    params: {
      email: data.email
    }
  })

export const changePassword = (data) =>
  axiosInstance.post(`${AUTH_PATH}/changePassword`, null, {
    params: {
      token: data.token,
      password: data.password
    }
  })

export default {
  login,
  googleLogin,
  facebookLogin,
  forgotPassword,
  changePassword
}
