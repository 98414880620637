import React from "react";
import Button from "@atoms/Button/Button";
import Text from "@atoms/Text/Text";
import { ReactComponent as DeclineIcon } from "@assets/svg/decline.svg";
import styles from "./WorksModals.module.scss";
import { useDispatch } from "react-redux";
import { setModal } from "@actions/generalActions";
import { setProfessionalWorkStatus } from "@actions/profileActions";
import useScreenDetect from "@hooks/useScreenDetect";

const WorkDecline = ({ currentWork }) => {
  const dispatch = useDispatch();
  const { isMobile } = useScreenDetect();
  const handleCloseModal = () => {
    dispatch(setModal(false));
  };

  const handleDecline = () => {
    dispatch(
      setProfessionalWorkStatus(
        currentWork.id,
        "REJECTED",
        undefined,
        "NOTIFY_USER"
      )
    );
  };

  return (
    <div className={styles.workModal}>
      <DeclineIcon className={styles.workIcon} />
      <Text tag="p" size="m">
        ¿Estás seguro que querés descartar este trabajo? Una vez lo hagas no lo
        podrás volver a visualizar.
      </Text>
      <div className={styles.workButtons}>
        <Button
          buttonStyle="terciary"
          onClickHandler={() => handleCloseModal()}
          customStyles={{
            marginRight: isMobile ? "0" : "var(--spacing-small)",
            maxWidth: "100%",
            marginBottom: isMobile ? "0.8rem" : "0",
          }}
        >
          No
        </Button>
        <Button
          onClickHandler={() => handleDecline()}
          customStyles={{
            maxWidth: "100%",
          }}
        >
          No me interesa
        </Button>
      </div>
    </div>
  );
};
export default WorkDecline;
