import ShadowBox from "@atoms/ShadowBox/ShadowBox";
import React, { useEffect, useState } from "react";
import styles from "./WorkRequest.module.scss";
import { ReactComponent as ZenProsCoin } from "@assets/svg/zencoins.svg";
import { ReactComponent as CalendarIcon } from "@assets/svg/calendar.svg";
import { ReactComponent as PhoneIcon } from "@assets/svg/phone.svg";
import { ReactComponent as EmailIcon } from "@assets/svg/envelope.svg";
import { ReactComponent as ChevronIcon } from "@assets/svg/down-chevron.svg";
import BorderedItem from "@atoms/BorderedItem/BorderedItem";
// import { AnimatePresence, motion } from 'framer-motion'
import Button from "@atoms/Button/Button";
import IconTooltip from "@atoms/IconTooltip/IconTooltip";
// import moment from 'moment'
// import 'moment/locale/es'
import dayjs from "dayjs";
import "dayjs/locale/es";
import ViewMoreText from "@atoms/ViewMoreText/ViewMoreText";
import { useDispatch, useSelector } from "react-redux";
import { removeUnreadMessage, setJobReadStatus } from "@actions/profileActions";
import Text from "@atoms/Text/Text";
// import ChatContent from "@organism/Chat/Chat";

const WorkRequestAccepted = ({
  work,
  acceptAction,
  replyMessageAction,
  opened,
  isMobile,
  handleOpenWork,
}) => {
  const { unreadMessages, profileData } = useSelector((state) => state.profile);
  const [messages, setMessages] = useState({});
  let loading = true;
  const dispatch = useDispatch();
  const {
    client,
    categoryTreeBranch,
    description,
    contactTime,
    contactDate,
  } = work;
  const fullName = `${client.name} ${client.lastName}`;

  const servicesToHire = () => {
    if (categoryTreeBranch) {
      const auxServices = [...categoryTreeBranch.categories];
      const aux2 = auxServices.splice(2);
      return aux2;
    }
    return null;
  };

  const servicesToMap = servicesToHire();

  const spanishDate = () => {
    dayjs.locale("es");
    const dateCreated = dayjs(work.created).format(
      "dddd D [de] MMMM HH:mm [hs]"
    );
    return dateCreated;
  };

  const messageToHtml = description?.split("\n").map((messagePart, index) => (
    <span key={index}>
      {messagePart}
      {!(index === description?.split("\n").length - 1) && (
        <>
          <br />
          <br />
        </>
      )}
    </span>
  ));
  useEffect(() => {
    const alreadyIncluded = unreadMessages.find(
      (message) => message.jobId === work.id
    );
    setMessages(alreadyIncluded);
  }, [messages, unreadMessages, work.id]);

  useEffect(() => {
    if (work.jobStatusNewRead === "NOTIFY_PRO") {
      dispatch(setJobReadStatus(work.id, "READ"));
    }
  }, []);
  return (
    // <motion.div initial={{ opacity: loading ? 1 : 1 }} animate={{ opacity: 1 }}>
    <div>
      <ShadowBox
        width="100%"
        customStyles={{
          margin: "var(--spacing-medium) 0",
          padding: isMobile
            ? "var(--spacing-small)"
            : "3.2rem 3.2rem 1.5rem 3.2rem",
        }}
      >
        <div className={styles.container}>
          <div className={styles.topRow}>
            <div className={styles.nameContactType}>
              <h5 className={styles.name}>{fullName}</h5>
              {contactTime.length > 0 ? (
                <span
                  className={`${styles.contactType} ${styles["contacto-telefonico"]}`}
                >
                  CONTACTO TELEFÓNICO
                </span>
              ) : (
                <span
                  className={`${styles.contactType} ${styles["contacto-por-mensaje"]}`}
                >
                  CONTACTO POR MENSAJE
                </span>
              )}
            </div>
            <div className={`${styles.row} ${styles.zenCoin}`}>
              <ZenProsCoin className={styles.coin} />
              <p className={styles.coinValue}>{1} contacto</p>
            </div>
          </div>
          <div className={styles.row}>
            <CalendarIcon className={styles.icon} />
            <p className={styles.dataText}>{spanishDate()}</p>
          </div>
          <div className={styles.row}>
            <PhoneIcon className={styles.icon} />
            <p className={styles.dataText}>
              {client.phone ?? "El usuario no indicó su número de teléfono."}
            </p>
          </div>
          <div className={styles.row}>
            <EmailIcon className={styles.icon} />
            <p className={styles.dataText}>{client.email}</p>
          </div>
          {opened && (
            <div>
              {contactDate && contactTime.length > 0 && (
                <div className={styles.servicesList}>
                  <p>Días y horarios en que prefiero ser contactado:</p>
                  <div className={`${styles.row} ${styles.wrap}`}>
                    <BorderedItem customStyles={{ width: "max-content" }}>
                      <p className={styles.service}>
                        {dayjs(contactDate).format("dddd D [de] MMMM")}
                      </p>
                    </BorderedItem>
                    {contactTime.map((time, index) => (
                      <div key={index}>
                        <BorderedItem customStyles={{ width: "max-content" }}>
                          <p className={styles.service}>{time.name}</p>
                        </BorderedItem>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className={styles.servicesList}>
                <p>Servicios a contratar:</p>
                {categoryTreeBranch ? (
                  <>
                    <div className={`${styles.row} ${styles.wrap}`}>
                      {servicesToMap.map((serviceToHire, index) => (
                        <div key={index}>
                          <BorderedItem customStyles={{ width: "max-content" }}>
                            <p className={styles.service}>
                              {serviceToHire.name}
                            </p>
                          </BorderedItem>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <Text tag="p" size="m" weight="bold" align="left">
                      {profileData.isPro
                        ? "No especificaste que servicios ofrecés."
                        : "El profesional no especificó qué servicios ofrece."}
                    </Text>
                  </>
                )}
              </div>
              {description && (
                <div className={styles.messages}>
                  <p>Mensaje:</p>
                  <div className={styles.messageContent}>
                    <ViewMoreText text={description} />
                  </div>
                </div>
              )}
            </div>
          )}
          <div className={`${styles.row} ${styles.buttons}`}>
            {isMobile && contactTime.length > 0 && client.phone && (
              <a
                href={`tel:${client.phone
                  .replaceAll(" ", "")
                  .replaceAll("-", "")}`}
                className={styles.phoneCall}
              >
                Llamar
              </a>
            )}
            <Button
              buttonStyle="terciary"
              customStyles={{
                marginLeft: "auto",
                marginRight: isMobile ? "0" : "var(--spacing-small)",
                maxWidth: isMobile ? "100%" : "max-content",
                marginBottom: isMobile ? "var(--spacing-small)" : "0",
              }}
              onClickHandler={() => {
                dispatch(removeUnreadMessage(work.id));
                replyMessageAction(work);
              }}
            >
              {opened ? "Responder" : "Ir al chat"}
              {messages?.messages > 0 && (
                <span className={styles.worksLength}>
                  {messages?.messages ?? ""}
                </span>
              )}
            </Button>
            <Button
              customStyles={{
                maxWidth: isMobile ? "100%" : "max-content",
              }}
              onClickHandler={() => acceptAction(work)}
            >
              Completé el trabajo
            </Button>
            {opened && (
              <div
                style={{
                  marginLeft: isMobile ? "0" : "var(--spacing-small)",
                }}
              >
                <IconTooltip text="Es muy importante para la Comunidad de Zenpros que notifiques cuando finalizas un trabajo para que el usuario pueda calificarte." />
              </div>
            )}
          </div>
          <div
            className={styles.openController}
            onClick={() => handleOpenWork(work)}
          >
            <p>{opened ? "Ocultar" : "Mostrar"} detalle</p>
            <ChevronIcon
              className={`${styles.icon} ${opened && styles.opened}`}
            />
          </div>
        </div>
      </ShadowBox>
      {(loading = false)}
    </div>
  );
};

export default WorkRequestAccepted;
