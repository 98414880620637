import React, { useState } from 'react'
import styles from './TextInput.module.scss'
import { ReactComponent as ViewPasswordIcon } from '@assets/svg/view-password.svg'
import { ReactComponent as ViewPasswordIconOpen } from '@assets/svg/view-password-open.svg'
const TextInput = ({
  name,
  inputRef,
  isValid,
  type = 'text',
  placeholder,
  hasIcon,
  autoFocus,
  ...props
}) => {
  const [isValidAux, setIsValidAux] = useState(false)
  const [typeInput, setTypeInput] = useState(type)
  const handleBlurInput = (value) => {
    if (isValid && value) {
      setIsValidAux(true)
    }
  }
  const handleViewPassword = () => {
    if (typeInput === 'password') return setTypeInput('text')
    return setTypeInput('password')
  }
  return (
    <>
      <input
        name={name}
        id={name}
        autoFocus={autoFocus}
        type={typeInput}
        ref={inputRef}
        placeholder={placeholder}
        className={`${styles.input} ${!isValid &&
          styles.invalid} ${isValidAux && isValid && styles.valid} ${hasIcon &&
          styles.hasIcon}`}
        {...props}
        onBlur={(e) => handleBlurInput(e.target.value)}
      />
      {type === 'password' &&
        (typeInput === 'password'
          ? (
            <ViewPasswordIcon
              className={styles.viewPassword}
              onClick={() => handleViewPassword()}
            />
            )
          : (
            <ViewPasswordIconOpen
              className={styles.viewPassword}
              onClick={() => handleViewPassword()}
            />
            ))}
    </>
  )
}

export default TextInput
