import Button from '@atoms/Button/Button'
import CheckboxGroup from '@molecules/CheckboxGroup/CheckboxGroup'
import React, { useEffect, useState } from 'react'
import { ReactComponent as ChevronIcon } from '@assets/svg/down-chevron.svg'
import { useSelector } from 'react-redux'
import styles from './ProfileEditModal.module.scss'
import { AnimatePresence, motion } from 'framer-motion'
const ProfileEditZones = ({ profileData, isMobile, handleSubmit }) => {
  const [zonesSelecteds, setZonesSelecteds] = useState([])
  const [openLists, setOpenLists] = useState([0])
  const { barriosLocalidades } = useSelector((state) => state.general)

  const handleOpenList = (list) => {
    if (openLists.includes(list)) { return setOpenLists(openLists.filter((i) => i !== list)) }
    return setOpenLists([...openLists, list])
  }

  const makeProAvailableNeighborhood = (neighborhoods) => {
    let aux = []
    neighborhoods.map((neighborhoodAux) => {
      aux = [...aux, neighborhoodAux.neighborhood]
    })

    return aux
  }

  const handleCheckboxItems = (value) => {
    let included = false
    zonesSelecteds.map((zone) => {
      if (zone.id === value.id) return (included = true)
      return false
    })
    if (included) {
      return setZonesSelecteds([
        ...zonesSelecteds.filter((i) => i.id !== value.id)
      ])
    }
    return setZonesSelecteds([...zonesSelecteds, value])
  }

  const handleClick = () => {
    handleSubmit(zonesSelecteds)
  }

  useEffect(() => {
    setZonesSelecteds(
      makeProAvailableNeighborhood(
        profileData.professionalAvailableNeighborhood
      )
    )
  }, [])

  return (
    <div>
      <div className={styles.listContainer}>
        {barriosLocalidades.map((localidad, index) => {
          return (
            <div key={index} className={styles.optionsContainer}>
              <div className={styles.optionsHeader}>
                <h6 className={styles.optionName}>{localidad.name}</h6>
                <div
                  onClick={() => handleOpenList(index)}
                  className={styles.optionsOpenHandler}
                >
                  <p>{openLists.includes(index) ? 'Ocultar' : 'Ver'} barrios</p>
                  <ChevronIcon
                    className={`${styles.icon} ${openLists.includes(index) &&
                      styles.open}`}
                  />
                </div>
              </div>
              <AnimatePresence>
                {openLists.includes(index) && (
                  <motion.div
                    initial={{ height: 0, overflow: 'hidden' }}
                    animate={{
                      height:
                        localidad.neighborhood.length > 6
                          ? '200px'
                          : 'max-content',
                      overflow: 'auto'
                    }}
                    exit={{ height: 0, overflow: 'hidden' }}
                  >
                    <div
                      className={`${styles.subServiceList} ${openLists.includes(
                        index
                      ) && styles.open}`}
                    >
                      <CheckboxGroup
                        options={localidad.neighborhood}
                        selectedItems={zonesSelecteds}
                        selectItemAction={handleCheckboxItems}
                        mode='noMaxHeight'
                      />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )
        })}
      </div>
      <div className={styles.modalFooter}>
        <Button
          customStyles={{
            maxWidth: isMobile ? '100%' : '11.5rem'
          }}
          onClickHandler={() => handleClick()}
        >
          Guardar
        </Button>
      </div>
    </div>
  )
}
export default ProfileEditZones
