import React, { useEffect } from "react";
import Text from "@atoms/Text/Text";
import Button from "@atoms/Button/Button";
import Lottie from "react-lottie";
import checkData from "@assets/lotties/check.json";
import { motion } from "framer-motion";
import useScreenDetect from "@hooks/useScreenDetect";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ErrorModalSvg from "@assets/svg/ErrorModalSvg";
import { setModal } from "@actions/generalActions";

const SuccessModal = ({ error }) => {
  const { successModal } = useSelector((state) => state.general);
  const { isMobile } = useScreenDetect();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    title,
    subtitle,
    buttonText,
    urlToRedirect,
    withoutRedirect,
    panel,
  } = successModal;

  const handleNextClick = () => {
    if (withoutRedirect) {
      return dispatch(setModal(false));
    }

    if (panel) {
      if (window.location.pathname.includes("panel-administracion/"))
        history.go(-1);
      window.location.reload();
      return dispatch(setModal(false));
    }

    history.push(urlToRedirect);
    return dispatch(setModal(false));
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(setModal(false));
    }, 2000);
    
    return () => {
      if (window.location.pathname.includes("panel-administracion/")) {
        history.go(-1);
      }
      window.location.reload();
      return dispatch(setModal(false));
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{
        minWidth: isMobile ? "80vw" : "38rem",
        maxWidth: isMobile ? "100%" : "42rem",
        textAlign: "center",
      }}
    >
      {!error ? (
        <Lottie
          options={{ animationData: checkData, ...defaultOptions }}
          style={{
            marginTop: "0px",
            marginBottom: "1.6rem",
            paddingTop: "0px",
            paddinBottom: "0px",
          }}
          height={150}
          width={150}
        />
      ) : (
        <ErrorModalSvg />
      )}
      <Text
        tag="p"
        size="l-1"
        weight="semibold"
        customStyles={{ marginBottom: "1.6rem" }}
      >
        {title}
      </Text>
      <Text tag="p" size="m" customStyles={{ marginBottom: "3.2rem" }}>
        {subtitle}
      </Text>
      {buttonText && (
        <Button
          customStyles={{
            maxWidth: "max-content",
            whiteSpace: "nowrap",
            display: "block",
            margin: "auto",
          }}
          onClickHandler={() => handleNextClick()}
        >
          {buttonText}
        </Button>
      )}
    </motion.div>
  );
};

export default SuccessModal;
