import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Calendar as Cal } from 'react-dayjs-picker'
import 'react-dayjs-picker/dist/index.css'
import './Calendar.scss'
dayjs.locale('es')

const Calendar = ({ handleDateChange, dateSelected }) => {
  const [date, setDate] = useState(dayjs(dateSelected) || null)
  const handleDateChangeLocal = (date) => {
    setDate(date)
    handleDateChange(date)
  }
  return <Cal style={{ zIndex: 100000 }} dateSelected={date} onSelect={(date) => handleDateChangeLocal(date)} disableBeforeToday date={date} colors={{ active: 'var(--secondary-blue)' }} />
}

export default Calendar
