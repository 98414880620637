import React, { useEffect, useState } from "react";
import ContactPro from "@molecules/ContactPro/ContactPro";
import ModalTitle from "@atoms/ModalTitle/ModalTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  setModal,
  postRequestContact,
  postBulkRequestContact,
} from "@actions/";
import styles from "./ContactProModal.module.scss";
import { withRouter } from "react-router-dom";
// import moment from 'moment'
// import 'moment/locale/es'
import dayjs from "dayjs";
import "dayjs/locale/es";
import { getHorariosContacto } from "@actions/generalActions";
import { getIdFromLS } from "@utils/tokenService";
import { getProfesionalResults } from "@actions/";
import ReactGA from 'react-ga';

const ContactProModal = ({
  isMobile,
  modalType,
  history,
  professionalProfile,
  withoutServices = false,
}) => {
  const [activeStep, setActiveStep] = useState(withoutServices ? 3 : 1);
  const [message, setMessage] = useState("");
  const { isAuth } = useSelector((state) => state.auth);
  const {
    horariosContacto,
    loading,
    currentSearch,
    professionalsResult,
  } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };
  const professionalIds = React.useMemo(
    () =>
      modalType === "messageBulk"
        ? professionalsResult.pros.slice(0, 10).map((pro) => pro.id)
        : [
            ...new Set(
              professionalsResult.pros
                .slice(0, 5)
                .map((pro) => pro.id)
                .concat([professionalProfile.id])
            ),
          ],
    [professionalsResult]
  );

  useEffect(() => {
    return () => {
      localStorage.removeItem("optionsTwo");
    };
  }, []);

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSetMessage = (value) => {
    setMessage(value);
  };

  const handleSaveForm = (data) => {
    if (
      data.time.length > 0 ||
      modalType === "messageProRequest" ||
      modalType === "messageProWithoutServices" ||
      modalType === "messageBulk"
    ) {
      const idFromLS = Number(getIdFromLS());
      if (!isAuth || !idFromLS) {
        dispatch(setModal(false));
        localStorage.setItem(
          "contact-info",
          JSON.stringify({
            professionalProfileId: professionalIds, // profesionalId al que queremos contactar
            professionalProfileUserId: professionalProfile.user.id,
            description:
              modalType === "messageProRequest" ||
              modalType === "messageProWithoutServices" ||
              modalType === "messageBulk"
                ? data.message
                : message,
            categoryId: data.subService.id,
            contactDate: data.date ? dayjs(data.date).format("YYYY-MM-DD") : "",
            contactHour: [...data.time.map((time) => time.id)],
          })
        );
        return history.push("/iniciar-sesion");
      }
      {
        modalType !== "messageBulk"
          ? dispatch(
              postRequestContact(
                {
                  clientId: Number(getIdFromLS()),
                  // professionalProfileId: Number(professionalProfile.id),
                  professionalProfileId: professionalIds,
                  professionalProfileUserId: professionalProfile.user.id,
                  description:
                    modalType === "messageProRequest" ||
                    modalType === "messageProWithoutServices" ||
                    modalType === "messageBulk"
                      ? data.message
                      : message,
                  categoryId:
                    modalType === "messageProWithoutServices" ||
                    modalType === "messageBulk"
                      ? null
                      : Number(data.subService.id),
                  jobStatus: "PENDING",
                  contactDate: data.date
                    ? dayjs(data.date).format("YYYY-MM-DD")
                    : "",
                  contactHour: [...data.time.map((time) => time.id)],
                },
                modalType === "messageProWithoutServices" ||
                  modalType === "callProRequestWithoutServices" ||
                  modalType === "messageBulk"
                  ? null
                  : professionalProfile.services[2].id,
                professionalProfile.user.name +
                  " " +
                  professionalProfile.user.lastName
              )
            )
          : dispatch(
              postBulkRequestContact(
                {
                  clientId: Number(getIdFromLS()),
                  // professionalProfileId: Number(professionalProfile.id),
                  professionalProfileId: professionalIds,
                  professionalProfileUserId: professionalProfile.user.id,
                  description: data.message,
                  categoryId: null,
                  jobStatus: "PENDING",
                  contactDate: data.date
                    ? dayjs(data.date).format("YYYY-MM-DD")
                    : "",
                  contactHour: [...data.time.map((time) => time.id)],
                },
                null
              )
            );
            const scriptPro = document.createElement("script");
            scriptPro.innerHTML =
              // "gtag('event', 'conversion', {'send_to': 'AW-417421308/nQbfCJeMno4DEPyvhccB'});";
              //"gtag('event', 'conversion', {'send_to': 'AW-10870553377/EK7MCL-sirUDEKH2vb8o'})";
            "gtag('event', 'conversion', {'send_to': 'AW-417421308/nQbfCJeMno4DEPyvhccB'})";  
            document.body.appendChild(scriptPro);
      }
    }
  };

  const headerText = () => {
    switch (activeStep) {
      case 1:
        return professionalProfile.services[1].question;

      case 2:
        return professionalProfile.services[2].question;

      case 3:
        return "Describí brevemente el detalle de tu consulta";

      case 4:
        return "¿Qué día preferís coordinar la llamada?";

      case 5:
        return "¿En qué horario querés que te llamen?";

      default:
        return "";
    }
  };

  const modifyServices = (services) => {
    const auxServices = [...services];
    const aux2 = auxServices.splice(2);
    let aux3 = [];
    aux2.map((auxService) => {
      aux3 = [
        ...aux3,
        {
          name: auxService.name,
          id: auxService.id,
          question: auxService.question,
          categories: [...auxService.categories.map((aux) => aux.category)],
        },
      ];
    });
    return aux3;
  };

  useEffect(() => {
    dispatch(getHorariosContacto());
  }, []);

  return (
    <div className={styles.container}>
      <ModalTitle
        title={headerText()}
        customStyles={{ marginBottom: "var(--spacing-small)" }}
      />
      <ContactPro
        proServices={modifyServices(professionalProfile.services)}
        steps={activeStep}
        handleSaveForm={handleSaveForm}
        handlePreviousStep={handlePreviousStep}
        handleNextStep={handleNextStep}
        isMobile={isMobile}
        isLoading={loading}
        setMessage={handleSetMessage}
        modalType={modalType}
        messageFromParent={message}
        horariosContacto={horariosContacto}
      />
    </div>
  );
};

export default withRouter(ContactProModal);
