import React, { useState } from 'react'
import ImageUpload from '@atoms/ImageUpload/ImageUpload'
import styles from './ProfileWorkDone.module.scss'
import { useDispatch } from 'react-redux'
import { uploadProfessionalFile } from '@actions/profileActions'

const ProfileWorkDone = ({ works, profileData }) => {
  const [translateX, setTranslateX] = useState(0)

  const dispatch = useDispatch()

  const handleImageChange = (file) => {
    const form = new FormData()
    form.append('file', file)
    dispatch(uploadProfessionalFile(profileData.id, form))
  }

  const handleNextWork = () => {
    if (translateX + 136 === works.length * 136 - 136 * 4) {
      return setTranslateX(0)
    }
    setTranslateX(translateX + 136)
  }
  return (
    <div className={styles.profileWorkDoneContainer}>
      {works && works.length > 0
        ? (
          <>
            <div className={styles.worksWrapper}>
              {works.map((work, index) => (
                <div
                  key={index}
                  style={{ transform: `translateX(-${translateX}px)` }}
                  className={styles.workItem}
                >
                  {work.file_url.includes('.pdf')
                    ? (
                      <embed
                        src={work.file_url}
                        width='120'
                        height='120'
                        type='application/pdf'
                      />
                      )
                    : (
                      <a
                        href={work.file_url}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img
                          src={work.file_url}
                          alt={`${profileData.user.name} ${profileData.user.name} trabajo - ${index}`}
                          className={styles.image}
                        />
                      </a>
                      )}
                </div>
              ))}
            </div>
            {works.length > 5 && (
              <span className={styles.chevron} onClick={() => handleNextWork()} />
            )}
          </>
          )
        : (
          <ImageUpload
            handleImageChange={handleImageChange}
            acceptPDF
            keyId='workDone'
          />
          )}
    </div>
  )
}

export default ProfileWorkDone
