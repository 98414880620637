import React from 'react'
import styles from './styles.module.scss'
import SuggestCategoryContainer from '@organism/SuggestCategoryContainer/SuggestCategoryContainer'
const SuggestCategory = () => {
  return (
    <div className={styles.container}>
      <SuggestCategoryContainer />
    </div>
  )
}
export default SuggestCategory
