import Button from '@atoms/Button/Button'
import ModalTitle from '@atoms/ModalTitle/ModalTitle'
import TextArea from '@atoms/TextArea/TextArea'
import React, { useEffect, useState } from 'react'
import styles from './EmailToPro.module.scss'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { sendEmailToPro, setModal } from '@actions/generalActions'
import showNotification from '@services/NotificationService'
import { getIdFromLS } from '@utils/tokenService'
import Text from '@atoms/Text/Text'

const EmailToPro = ({
  isMobile,
  history
}) => {
  const dispatch = useDispatch()
  const { isAuth } = useSelector((state) => state.auth)
  const { profileData, professionalProfile } = useSelector((state) => state.profile)

  const [includesContact, setIncludesContact] = useState(false)

  const { user } = professionalProfile
  const { name, lastName } = user

  const { watch, register, setValue } = useForm({
    defaultValues: {
      emailMessage: ''
    }
  })
  const values = watch(['emailMessage'])

  useEffect(() => {
    register({ name: 'emailMessage' })
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const hasEmail = values.emailMessage.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
    const hasPhone = values.emailMessage.match(/(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/img)

    if ((hasEmail != null && hasEmail.length) || (hasPhone != null && hasPhone.length)) {
      setIncludesContact(true)
      const Msg = () => (
        <Text
          tag='p' size='s' align='justify' customStyles={{
            fontFamily: 'Montserrat'
          }}
        >No se pueden incluir medios de contacto dentro del mensaje.
        </Text>
      )
      showNotification(<Msg />, 'includesContact', 'error', 5000)
    } else {
      setIncludesContact(false)
    }
  }, [values.emailMessage])

  const handleSend = (value) => {
    const idFromLS = Number(getIdFromLS())
    if (!isAuth || !idFromLS) {
      dispatch(setModal(false))
      localStorage.setItem(
        'email-info',
        JSON.stringify({
          professionalId: professionalProfile.id,
          professionalUserId: professionalProfile.user.id,
          userId: '',
          message: values.emailMessage
        })
      )
      const Msg = () => (
        <Text
          tag='p' size='s' align='justify' customStyles={{
            fontFamily: 'Montserrat'
          }}
        >El mensaje se enviará automáticamente una vez que hayas <Link to='/iniciar-sesion'><b style={{ color: 'var(--secondary-blue)' }}>iniciado sesión</b></Link>.
        </Text>
      )
      showNotification(<Msg />, 'notLogged', 'warning', 5000)
      return history.push('/iniciar-sesion')
    }
    if (values?.emailMessage?.trim().length > 0) {
      setValue('emailMessage', value)
      dispatch(sendEmailToPro(values.emailMessage, professionalProfile.id, profileData.id))
      dispatch(setModal(false))
    }
  }

  return (
    <div className={styles.container}>
      <ModalTitle
        title={`Enviale un mensaje a ${name} ${lastName}`}
        customStyles={{ marginBottom: 'var(--spacing-small)' }}
      />
      <TextArea
        placeholder={`Ej: Hola ${name}, necesito asesoría por un accidente de tránsito que tuve hace unos días.`}
        name='emailMessage'
        register={register({ required: true })}
      />
      <div className={styles.footer}>
        <Button
          customStyles={{
            maxWidth: isMobile ? '100%' : 'max-content',
            marginLeft: 'auto'
          }}
          buttonStyle='primary'
          onClickHandler={() => handleSend()}
          isDisabled={!(values?.emailMessage?.trim().length > 0 && !includesContact)}
        >
          Enviar
        </Button>
      </div>
    </div>
  )
}

export default withRouter(EmailToPro)
