import React, { useState } from 'react'
import styles from './SearchService.module.scss'
import ServiceCategory from './ServiceCategory'

const SearchService = ({
  categories,
  selectedCategories,
  disabledItems,
  disabledMode,
  handleSelectSubService
}) => {
  const [search, setSearch] = useState('')
  const handleSearchChange = (value) => {
    setSearch(value)
  }
  const sortedList = (list) => {
    const sortedListById = list?.slice().sort((a, b) => {
      if (a.id < b.id) {
        return -1
      }
      if (a.id > b.id) {
        return 1
      }
      return 0
    })
    return sortedListById
  }
  return (
    <div className={styles.container}>
      <input
        name='search'
        className={styles.searchInput}
        placeholder='Buscar'
        value={search}
        autoComplete='off'
        onChange={(e) => handleSearchChange(e.target.value)}
      />
      {selectedCategories.map((selectedCategory, index) => {
        const disabledSubServices = disabledItems?.find(
          (disabledItem) => disabledItem.id === selectedCategory.id
        )?.sons
        return (
          <ServiceCategory
            key={selectedCategory.id}
            categories={sortedList(categories)}
            selectedCategories={sortedList(selectedCategories)}
            category={selectedCategory}
            disabledItems={sortedList(disabledSubServices)}
            disabledMode={disabledMode}
            search={search}
            handleSelectSubService={handleSelectSubService}
          />
        )
      })}
    </div>
  )
}

export default SearchService
