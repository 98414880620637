import React from "react";
import styles from "./process.module.scss";

const HowItem = ({ img }) => {
  return (
    <div className={styles.sonsCarousel}>
      <img src={img} />
    </div>
  );
};

export default HowItem;
