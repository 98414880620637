import { editProfileData } from '@actions/profileActions'
import Button from '@atoms/Button/Button'
import Form from '@atoms/Form/Form'
import ModalTitle from '@atoms/ModalTitle/ModalTitle'
import InputTextBox from '@molecules/InputTextBox/InputTextBox'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import styles from './UserProfileEdit.module.scss'
import { toast } from "react-toastify";

const UserProfileEdit = ({ profileData }) => {
  const { register, errors, handleSubmit, setValue, watch } = useForm()
  const dispatch = useDispatch()
  const handleSave = (data) => {
    dispatch(editProfileData(data, true))
  }

  const values = watch()

  const checkDisabled = () => {
    let disabled = true
    if (values.phone !== profileData.phone) disabled = false
    if (values.email !== profileData.email) disabled = false
    return disabled
  }

  useEffect(() => {
    setValue('phone', profileData.phone)
    setValue('email', profileData.email)
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.container}>
      <Form onSubmit={handleSubmit(handleSave)}>
        <ModalTitle title='Datos personales' />
        <InputTextBox
          phonenumber
          label='Número de teléfono'
          placeholder='Ej: 1112345678'
          name='phone'
          type='number'
          inputRef={register({ required: 'Campo obligatorio' })}
          error={errors.phone}
          customStyles={{ marginBottom: 'var(--spacing-medium)' }}
        />
        <InputTextBox
          label='Email'
          placeholder='Ej: tuemail@mail.com'
          name='email'
          type='text'
          inputRef={register({ required: 'Campo obligatorio' })}
          error={errors.email}
          customStyles={{ marginBottom: 'var(--spacing-medium)' }}
          disabled
        />
        <Button
          isDisabled={profileData && checkDisabled()}
          type='submit'
          customStyles={{ maxWidth: 'max-content', marginLeft: 'auto' }}
        >
          Guardar
        </Button>
      </Form>
    </div>
  )
}

export default UserProfileEdit
