import React from 'react'
import styles from './styles.module.scss'
import UserWorksLayout from '@organism/UserWorks/UserWorks'

const UserWorks = () => {
  return (
    <div className={styles.container}>
      <UserWorksLayout />
    </div>
  )
}

export default UserWorks
