import React from 'react'
import styles from './AlertMessage.module.scss'

const AlertMessage = ({ message, icon = 'alert', customStyles }) => {
  return (
    <span
      className={`${styles.alertMessage} ${styles[icon]}`}
      style={customStyles}
    >
      {message}
    </span>
  )
}

export default AlertMessage
