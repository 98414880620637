import React, { useState } from 'react'
import Login from '@organism/Login/Login'
import Text from '@atoms/Text/Text'
import styles from './styles.module.scss'

const RegisterPage = () => {
  const [step, setStep] = useState(1)

  const handleNextStep = (value) => {
    setStep(value)
  }
  return (
    <>
      <main className={styles.loginContainer}>
        <div className={styles.titleSteps}>
          <h1 className={styles.loginTitle}>
            Creá tu perfil para poder{' '}
            <span style={{ color: 'rgb(255, 110, 93)' }}>contratar</span> el
            profesional o experto ideal
          </h1>
          <Text
            tag='p'
            color='secondary-blue'
            size='m'
            weight='bold'
            customStyles={{ marginLeft: 'auto' }}
          >
            {step}/2
          </Text>
        </div>
        <Login
          type='register'
          currentStep={step}
          handleNextStep={handleNextStep}
        />
      </main>
    </>
  )
}

export default RegisterPage
