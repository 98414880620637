export const validateCUILCUIT = (cuit) => {
  if (cuit.length !== 11) return 0

  let rv = false
  let resultado = 0
  const cuit_nro = cuit
  const codes = '6789456789'
  const verificador = parseInt(cuit_nro[cuit_nro.length - 1])
  let x = 0

  while (x < 10) {
    let digitoValidador = parseInt(codes.substring(x, x + 1))
    if (isNaN(digitoValidador)) digitoValidador = 0
    let digito = parseInt(cuit_nro.substring(x, x + 1))
    if (isNaN(digito)) digito = 0
    const digitoValidacion = digitoValidador * digito
    resultado += digitoValidacion
    x++
  }
  resultado = resultado % 11
  rv = resultado === verificador
  return rv
}
