import React from "react";
import { ReactComponent as DimeMasImg } from "@assets/svg/dimeMas.svg";
import { ReactComponent as DimeMasMobileImg } from "@assets/svg/dimeMasMobile.svg";
import styles from "./DimeMas.module.scss";
import useScreenDetect from "@hooks/useScreenDetect";

const DimeMas = () => {
  const { isMobile } = useScreenDetect();
  return (
    <div
      className={styles.containerDimeMas}
    >
      {!isMobile ? <DimeMasImg /> : <DimeMasMobileImg />}
      <section className={styles.sectionDimeMas}>
        <span>¿Consultas o</span>
        <span>sugerencias?</span>
        <span>
          <strong>info@zenpros.work</strong>
        </span>
      </section>
    </div>
  );
};

export default DimeMas;
