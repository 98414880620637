import React from 'react'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'
import UserIcon from '@assets/svg/user-empty.svg'
import NoAvatar from '@assets/img/noavatar.webp'

const Avatar = ({
  // image = "https://t4.ftcdn.net/jpg/01/28/56/33/240_F_128563397_RyDWoPr3xAlEcV7iFoGVuHkHI9NZSzyj.jpg",
  image = NoAvatar,
  fromHeader
}) => {
  return <img src={image || UserIcon} alt='avatar' className={`${styles.avatar} ${fromHeader && styles.header}`} />
}

Avatar.propTypes = {
  image: PropTypes.string,
  fromHeader: PropTypes.bool
}

export default Avatar
