import Icon from '@atoms/Icon/Icon'
import React from 'react'
import styles from './SocialButton.module.scss'

const SocialButton = ({ type, handleClick }) => {
  return (
    <div className={styles.socialButton} onClick={handleClick}>
      <Icon icon={type.toLowerCase()} />
      <p className={styles.socialButtonText}>Ingresar con {type}</p>
    </div>
  )
}

export default SocialButton
