import { axiosPost, axiosPut } from '@config/api'
import { setProfileMode } from '.'
import * as actionTypes from '../actions/types'
import { setLoading, setModal, setSuccessModal } from './generalActions'
import { editProfileData, getPrivateProfile } from './profileActions'

export const setNextStep = () => {
  return { type: actionTypes.SET_NEXT_STEP }
}

export const setPrevStep = () => {
  return { type: actionTypes.SET_PREVIOUS_STEP }
}

export const setStep = (value) => {
  return { type: actionTypes.SET_STEP, payload: value }
}

export const resetRegisterSteps = () => {
  return { type: actionTypes.RESET_REGISTER_STEPS }
}

export const setRegisterType = (value) => {
  return { type: actionTypes.SET_REGISTER_TYPE, payload: value }
}

export const setRegisterValues = (value) => {
  return { type: actionTypes.SET_REGISTER_VALUES, payload: value }
}

export const setRegisterUserInfo = (value) => {
  return { type: actionTypes.SET_REGISTER_USER_INFO, payload: value }
}

export const setRegisterCategory = (value) => {
  return { type: actionTypes.SET_REGISTER_CATEGORY, payload: value }
}

export const setRegisterArea = (value) => {
  return { type: actionTypes.SET_REGISTER_AREA, payload: value }
}

export const setRegisterSpeciality = (value) => {
  return { type: actionTypes.SET_REGISTER_SPECIALITY, payload: value }
}

export const setRegisterCategoriesValues = (value) => {
  return { type: actionTypes.SET_REGISTER_CATEGORIES_VALUES, payload: value }
}

export const setRegisterSpecialityAttempt = (value) => {
  return { type: actionTypes.SET_REGISTER_SPECIALITY_ATTEMPT, payload: value }
}

export const setRegisterProfessionalInfo = (value) => {
  return { type: actionTypes.SET_REGISTER_PROFESSIONAL_INFO, payload: value }
}

export const setRegisterReviewRequestEmails = (value) => {
  return {
    type: actionTypes.SET_REGISTER_REVIEW_REQUEST_EMAILS,
    payload: value
  }
}

export const setLoggedByFaceOrGoogle = () => {
  return {
    type: actionTypes.SET_LOGGED_BY_FACE_OR_GOOGLE
  }
}

export const cleanProRegister = () => {
  return {
    type: actionTypes.CLEAN_PRO_REGISTER
  }
}

export const requestValorations = (value, fromProfile) => {
  const objectValue = {
    professionalProfileId: value.id,
    reviewRequests: [
      ...value.emails.map((val) => {
        return { email: val }
      })
    ]
  }
  return (dispatch) => {
    axiosPost('/professional/review/request', objectValue)
      .then((response) => {
        if (fromProfile) {
          dispatch(setModal(true, 'successModal'))
          dispatch(
            setSuccessModal({
              title: '¡Listo!',
              subtitle: 'Enviaste correctamente la solicitud de calificación'
            })
          )
        } else {
          dispatch(setNextStep())
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const suggestCategory = (value, successModalTexts) => {
  return (dispatch) => {
    axiosPost('/guests/category/availableSoon', value)
      .then((response) => {
        dispatch(setModal(true, 'successModal'))
        dispatch(
          setSuccessModal({
            ...successModalTexts,
            urlToRedirect: '/'
          })
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const setRegisterServicesValues = (value) => {
  return (dispatch, getState) => {
    const registerInfo = getState().register.registerInfo
    dispatch({ type: actionTypes.SET_REGISTER_VALUES, payload: value })
    if (registerInfo.professionalInfo) {
      return dispatch(editProfessionUser())
    }
    return dispatch(createProfessionToUser())
  }
}

export const setUserAfterCreate = (value) => {
  return (dispatch) => {
    dispatch(setNextStep())
  }
}
export const setImageForProfessional = (value) => {
  return (dispatch) => {
    dispatch(editProfileData(value, false, false, true))
  }
}

export const setTaxConditionToUser = (userId, value) => {
  return (dispatch) => {
    axiosPut(`/users/professions/${userId}`, {
      taxConditionId: value.id
    })
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const createProfessionToUser = () => {
  return (dispatch, getState) => {
    dispatch(setLoading(true))
    const value = getState().register.registerInfo
    const objectValue = {
      title: value.area.name,
      category: value.area.id,
      address: null,
      taxConditionId: 5,
      workType: 'REMOTE',
      reviews: [],
      companyName: value.userInfo.nombreEmpresa,
      referencePrice: 1
    }
    axiosPost('/users/professions', objectValue)
      .then((response) => {
        dispatch(setRegisterProfessionalInfo(response.data))
        dispatch(
          addCategoriesToProfessional({
            id: response.data.id,
            professionalCategories:
            [{
              cats: [{
                categoryId: value.category.id,
                value: '$1',
                paymentTypeId: 3
              }, {
                categoryId: value.area.id,
                value: '$1',
                paymentTypeId: 3
              }]
            }]
          })
        )
        dispatch(setProfileMode('PRO', undefined, true))
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}
export const editProfessionUser = () => {
  return (dispatch, getState) => {
    const value = getState().register.registerInfo
    const objectValue = {
      title: value.area.name,
      address: value.direccionFiscal,
      taxConditionId: value.ivaCondition.id,
      cuit: value.cuil,
      residenceArea: value.residenceArea.name,
      workType: value.workModality.idValue,
      reviews: [],
      referencePrice: Number(value.categoriesValues.valueGeneral.split('$')[1])
    }
    axiosPut(`/users/professions/${value.professionalInfo.id}`, objectValue)
      .then((response) => {
        // console.log(response, "editProfessionUser");
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const addCategoriesToProfessional = (
  value,
  fromProfile,
  fromServices
) => {
  let auxCats = []
  !fromProfile &&
    value.professionalCategories.map((proCat) => {
      return proCat.cats.map((cat) => {
        return (auxCats = [
          ...auxCats,
          {
            ...cat,
            value: Number(cat.value.replace('$', '').replaceAll('.', ''))
          }
        ])
      })
    })

  const objectValue = {
    professionalProfileId: value.id,
    professionalCategories: fromProfile
      ? value.professionalCategories
      : auxCats
  }
  return (dispatch) => {
    axiosPost('/professional/professional-category', objectValue)
      .then((response) => {
        if (fromProfile) {
          dispatch(getPrivateProfile())
          dispatch(setModal(true, 'services'))
        }
        if (fromServices) {
          dispatch(getPrivateProfile())
          dispatch(setModal(true, 'successModal'))
          dispatch(
            setSuccessModal({
              title: '¡Listo!',
              subtitle: 'Modificaste correctamente tus servicios'
            })
          )
        }
        // dispatch(setNextStep());
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const addPaymentMethodsToProfessional = (value, fromProfile) => {
  let auxPaymentMethods = []
  value.professionalPaymentMethods.map((pm) => {
    return (auxPaymentMethods = [
      ...auxPaymentMethods,
      { paymentMethod: pm.value }
    ])
  })

  const objectValue = {
    professionalProfileId: value.id,
    professionalPaymentMethods: auxPaymentMethods
  }

  return (dispatch) => {
    axiosPost('/professional/professional-payment-method', objectValue)
      .then((response) => {
        if (fromProfile) {
          dispatch(getPrivateProfile())
          dispatch(setModal(true, 'successModal'))
          dispatch(
            setSuccessModal({
              title: '¡Listo!',
              subtitle: 'Modificaste correctamente tus medios de pago'
            })
          )
        }
        // dispatch(setNextStep());
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const addNeighborhoodToProfessional = (value, fromProfile) => {
  const objectValue = {
    professionalProfileId: value.id,
    professionalNeighborhoods: [
      ...value.professionalNeighborhoods.map((val) => {
        return { neighborhoodId: val.id }
      })
    ]
  }
  return (dispatch) => {
    axiosPost('/professional/professional-available-neighborhood', objectValue)
      .then((response) => {
        if (fromProfile) {
          dispatch(getPrivateProfile())
          dispatch(setModal(true, 'successModal'))
          dispatch(
            setSuccessModal({
              title: '¡Listo!',
              subtitle: 'Modificaste correctamente tus zonas de cobertura'
            })
          )
        }
        // dispatch(setNextStep());
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
