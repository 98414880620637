import ShadowBox from "@atoms/ShadowBox/ShadowBox";
import React, { useEffect } from "react";
import styles from "./WorkRequest.module.scss";
import { ReactComponent as ZenProsCoin } from "@assets/svg/zencoins.svg";
import { ReactComponent as CalendarIcon } from "@assets/svg/calendar.svg";
import { ReactComponent as PhoneIcon } from "@assets/svg/phone.svg";
import { ReactComponent as EmailIcon } from "@assets/svg/envelope.svg";
import BorderedItem from "@atoms/BorderedItem/BorderedItem";
// import { motion } from 'framer-motion'
import Button from "@atoms/Button/Button";
// import moment from 'moment'
// import 'moment/locale/es'
import dayjs from "dayjs";
import "dayjs/locale/es";
import { useDispatch, useSelector } from "react-redux";
import { setJobReadStatus } from "@actions/profileActions";
import Text from "@atoms/Text/Text";

const WorkRequest = ({
  work,
  accepted,
  acceptAction,
  declineAction,
  opened,
  isMobile,
  handleOpenWork,
}) => {
  let loading = true;
  const { client, categoryTreeBranch, jobStatusNewRead } = work;
  const { isLoading } = useSelector((state) => state.general);
  const { profileData } = useSelector((state) => state.profile);
  const servicesToHire = () => {
    if (categoryTreeBranch) {
      const auxServices = [...categoryTreeBranch.categories];
      const aux2 = auxServices.splice(2);
      return aux2;
    }

    return null;
  };

  const servicesToMap = servicesToHire();

  const spanishDate = () => {
    dayjs.locale("es");
    const dateCreated = dayjs(work.created).format(
      "dddd D [de] MMMM HH:mm [hs]"
    );
    return dateCreated;
  };

  const hashedData = {
    name: `${client.name} ${client.lastName.charAt(0)}.`,
    phone: client.phone ? `...${client.phone.slice(-5)}` : "-",
    email: `...@...${
      client.email.split(".")[client.email.split(".").length - 1]
    }`,
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (work.jobStatusNewRead === "NOTIFY_PRO") {
      dispatch(setJobReadStatus(work.id, "READ"));
    }
  }, []);

  return (
    // <motion.div initial={{ opacity: loading ? 1 : 1 }} animate={{ opacity: 1 }}>
    <div>
      <ShadowBox
        width="100%"
        customStyles={{
          margin: "var(--spacing-medium) 0",
          padding: isMobile ? "var(--spacing-small)" : "3.2rem",
        }}
      >
        <div
          className={styles.container}
          onClick={() => (opened || !isMobile ? false : handleOpenWork(work))}
        >
          <div className={styles.topRow}>
            <div className={styles.nameContactType}>
              <h5 className={styles.name}>{hashedData.name}</h5>
              <span
                className={`${styles.status} ${
                  styles[jobStatusNewRead.toLowerCase()]
                }`}
              >
                {jobStatusNewRead === "READ" ? "Leído" : "Nuevo"}
              </span>
            </div>
            <div className={`${styles.row}`} style={{ marginLeft: "auto" }}>
              <ZenProsCoin className={styles.coin} />
              <p className={styles.coinValue}>{1} contacto</p>
            </div>
          </div>
          <div className={styles.row}>
            <CalendarIcon className={styles.icon} />
            <p className={styles.dataText}>{spanishDate()}</p>
          </div>
          <div className={styles.row}>
            <PhoneIcon className={styles.icon} />
            <p className={styles.dataText}>{hashedData.phone}</p>
          </div>
          <div className={styles.row}>
            <EmailIcon className={styles.icon} />
            <p className={styles.dataText}>{hashedData.email}</p>
          </div>
          {(!isMobile || opened) && (
            <div>
              <div className={styles.servicesList}>
                <p>Servicios a contratar:</p>
                {categoryTreeBranch ? (
                  <div className={styles.row}>
                    {servicesToMap.map((serviceToHire, index) => (
                      <div key={index}>
                        <BorderedItem customStyles={{ width: "max-content" }}>
                          <p className={styles.service}>{serviceToHire.name}</p>
                        </BorderedItem>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    <Text tag="p" size="m" weight="bold" align="left">
                      {profileData.isPro
                        ? "No especificaste que servicios ofrecés."
                        : "El profesional no especificó qué servicios ofrece."}
                    </Text>
                  </>
                )}
              </div>
              <div className={styles.row}>
                <Button
                  buttonStyle="terciary"
                  customStyles={{
                    marginLeft: "auto",
                    marginRight: isMobile ? "0" : "var(--spacing-small)",
                    maxWidth: isMobile ? "100%" : "max-content",
                    marginBottom: isMobile ? "var(--spacing-small)" : "0",
                  }}
                  onClickHandler={() => declineAction(work)}
                >
                  No me interesa
                </Button>
                <Button
                  isLoading={isLoading}
                  customStyles={{
                    maxWidth: isMobile ? "100%" : "max-content",
                  }}
                  onClickHandler={() => acceptAction(work)}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          )}
        </div>
      </ShadowBox>
      {(loading = false)}
    </div>
  );
};

export default WorkRequest;
