import { setModal } from '@actions/generalActions'
import Button from '@atoms/Button/Button'
import Form from '@atoms/Form/Form'
import ProfileServices from '@molecules/ProfileServices/ProfileServices'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import styles from './ProfileEditModal.module.scss'

const ProfileEditServices = ({ profileData, isMobile, handleSubmitData }) => {
  const [selectedServices, setSelectedServices] = useState([])
  const [deletedServices, setDeletedServices] = useState([])
  const dispatch = useDispatch()

  const { handleSubmit, register, watch, setValue } = useForm()

  const values = watch()

  const sortedList = (list) => {
    const sortedListById = list?.slice().sort((a, b) => {
      if (a.id < b.id) {
        return -1
      }
      if (a.id > b.id) {
        return 1
      }
      return 0
    })
    return sortedListById
  }

  const handleDeleteService = (service) => {
    const auxSelectedServices = [...selectedServices]
    const parentCategory = auxSelectedServices.find(
      (auxSer) => auxSer.id === service.parentcategory.id
    )
    const auncleCategories = auxSelectedServices.filter(
      (i) => i.id !== parentCategory.id
    )
    const parentCategoryFiltered = parentCategory.categories.filter(
      (i) => i.id !== service.id
    )
    setDeletedServices([...deletedServices, service])
    setSelectedServices([
      ...auncleCategories,
      {
        id: parentCategory.id,
        name: parentCategory.name,
        categories: parentCategoryFiltered
      }
    ])
  }

  const handleAddService = () => {
    dispatch(setModal(true, 'addService'))
  }

  const modifyServices = (services) => {
    const auxServices = [...services]
    const aux2 = auxServices.splice(2)
    let aux3 = []
    aux2.map((auxService) => {
      aux3 = [
        ...aux3,
        {
          name: auxService.name,
          id: auxService.id,
          categories: [
            ...auxService.categories.map((aux) => {
              return { ...aux, ...aux.category }
            })
          ]
        }
      ]
    })
    return aux3
  }

  const submitForm = (data) => {
    let auxArr = []
    data.professionalCategories.map((proCat) => {
      return proCat.cats.map((cat) => {
        auxArr = [...auxArr, cat]
      })
    })
    let finalCategories = auxArr
    if (deletedServices.length > 0) {
      deletedServices.map((deletServ) => {
        finalCategories = finalCategories.filter(
          (i) => i.categoryId !== deletServ.id
        )
      })
    }
    handleSubmitData([{ cats: finalCategories }])
  }

  const handleChangeItem = (key, value) => {
    setValue(key, value)
  }

  useEffect(() => {
    setSelectedServices(modifyServices(profileData.services))
  }, [])

  return (
    <div className={styles.container}>
      <Form onSubmit={handleSubmit(submitForm)}>
        <ProfileServices
          isMobile={isMobile}
          services={sortedList(selectedServices)}
          editMode
          deleteServiceAction={handleDeleteService}
          addServiceAction={handleAddService}
          register={register}
          handleChangeItem={handleChangeItem}
          values={values}
          onModal
        />
        <div className={styles.modalFooter}>
          <Button
            type='submit'
            customStyles={{
              maxWidth: isMobile ? '100%' : '11.5rem'
            }}
          >
            Guardar
          </Button>
        </div>
      </Form>
    </div>
  )
}
export default ProfileEditServices
