import React, { useEffect, useRef, useState } from "react";
import {
  getProsProfessions,
  getProsProfessionsSearch,
  setCurrentSearch,
  getCategoryBranch,
  cleanCategoryBranch,
  getProfesionalResults,
  getProsVerticals,
} from "@actions/";
import Button from "@atoms/Button/Button";
import Icon from "@atoms/Icon/Icon";
import { useSelector, useDispatch } from "react-redux";
import styles from "./GeneralSearch.module.scss";
import Form from "@atoms/Form/Form";
import useScreenDetect from "@hooks/useScreenDetect";
import { useHistory, useParams } from "react-router";
import {
  setGlobalValuesSelected,
  setSuggestValue,
} from "@actions/generalActions";
import { sumCategory } from "@utils/functions/sumCategory";
import { axiosGet, axiosPost, axiosPut } from "@config/api";
import { GoSearch } from "react-icons/go";

const SEARCH_REGEX = /^[a-zA-Z\sáéíóúÁÉÍÓÚñÑ/]*$/;

const GeneralSearch = () => {
  const {
    allCategoriesSearch,
    currentSearch,
    categoryBranch,
    professionalsResult,
    allCategories,
    verticalProsCategories,
  } = useSelector((state) => state.general);
  const [search, setSearch] = useState("");
  const [filteredProsCategories, setFilteredProsCategories] = useState([]);
  const [valuesSelected, setValuesSelected] = useState(currentSearch);
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();

  const searchRef = useRef();

  const history = useHistory();
  const { categorySearchId } = useParams();

  const { isMobile } = useScreenDetect();

  useEffect(() => {
    if (allCategoriesSearch.length > 0) {
      setFilteredProsCategories(allCategoriesSearch);
    } else {
      dispatch(getProsProfessionsSearch());
      dispatch(getProsProfessions());
    }
  }, [allCategories, allCategoriesSearch, search]);

  useEffect(() => {
    dispatch(getProsProfessionsSearch());
    dispatch(getProsProfessions());
    if (currentSearch.name) {

      setSearch(currentSearch.name);
    }

    if (categorySearchId) dispatch(getCategoryBranch(categorySearchId));
    document.addEventListener("mousedown", handleClick);
    return () => {
      dispatch(cleanCategoryBranch());
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (categoryBranch.length > 0 && categorySearchId) {
      const categoryFiltered = categoryBranch.find(
        (category) => Number(category.id) === Number(categorySearchId)
      );
      handleSearchSelect(categoryFiltered);
    }
    // eslint-disable-next-line
  }, [categoryBranch]);

  useEffect(() => {
    const filterCats = handleFilter(search, allCategoriesSearch);
    setFilteredProsCategories(filterCats);

    // eslint-disable-next-line
  }, [search]);

  const handleFilter = (value, array) => {
    const sortedFilteredByName = array.slice().sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    let auxFiltered = sortedFilteredByName.map((item) => {
      let vertical = item?.name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replaceAll("ñ", "n")
        .includes(
          value
            ?.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .replaceAll("ñ", "n")
        );

      if (vertical) return { ...item, ver: true };
    });

    let prosFiltered = auxFiltered.filter((pro) => pro !== undefined);

    prosFiltered = prosFiltered.sort((a, b) => {
      if (a.parentcategory.parentcategory && a.parentcategory.name) return 1;
      if (a.parentcategory.parentcategory && !a.parentcategory.name) return -1;
      return 0;
    });

    prosFiltered = prosFiltered.sort((a, b) => {
      if (a.id > b.id) return 1;
      if (a.id < b.id) return -1;
      return 0;
    });

    // LO SIGUIENTE ESTA DEPRECADO (NO SE BUSCA POR CATEGORIA SINO POR "NIVEL 1")
    // ---- Lo siguiente es para unificar la búsqueda y que aparezca la categoria ----
    // let prosFiltered2 = [];

    // prosFiltered.forEach((pro) => {
    //   if(pro.ver) return prosFiltered2.push(pro);

    //   let categoria = pro.parentcategory.name;

    //   let find = prosFiltered2.find(
    //     (pro2) => pro2.parentcategory.name === categoria
    //   );

    //   if (!find) {
    //     return prosFiltered2.push(pro);
    //   } else {
    //     return;
    //   }
    // });
    // ----------------------------------------------------------------------------
    return prosFiltered;
  };

  const handleSearchChange = (value) => {
    const isValid = SEARCH_REGEX.test(value);
    if (!isValid) setHasError(true);
    else setHasError(false);
    setSearch(value);
    setValuesSelected({});
    dispatch(setGlobalValuesSelected({}));
  };

  const handleSearchSelect = (prosCategory) => {
    handleSearchChange(prosCategory?.name);
    setFilteredProsCategories(null);
    setValuesSelected(prosCategory);
    dispatch(setGlobalValuesSelected(prosCategory));
  };

  const handleClick = (e) => {
    if (searchRef.current.contains(e.target)) {
      return;
    }
    setFilteredProsCategories(null);
  };

  // TODO: esto es necesario para que funcione bien el buscador y los botones de "Enviar mensaje" y "Quiero que me llamen"
  let [selectId, setSelectId] = useState([]);

  useEffect(() => {
    axiosGet('/guests/category-verticals').then(response => {
      let data = response.data.map(item => item.id);
      setSelectId(data);
    });
    if (!localStorage.getItem("currentSearch")) {
      handleSearch()
    }
  }, []);
  // TODO:======================================================================================================

  const handleSearch = async (e, data, mostSearched) => {
    localStorage.removeItem("currentSearch");
    localStorage.removeItem("optionsTwo");
    localStorage.removeItem("selectItems");

    sumCategory(search);
    localStorage.setItem("currentSearch", JSON.stringify(valuesSelected || {}));

    dispatch(
      setCurrentSearch(JSON.parse(localStorage.getItem("currentSearch")))
    );

    let id = {
      categoryIds: [valuesSelected.id],
    };

    dispatch(getProfesionalResults(id));

    if (!selectId.includes(valuesSelected.parentcategory?.id)) {
      let selected = JSON.stringify([valuesSelected]);

      localStorage.setItem('selectItems', selected);
    }
    if (valuesSelected)
      history.push(
        `/search/${selectId.includes(valuesSelected.parentcategory.id)
          ? valuesSelected.id
          : valuesSelected.parentcategory.id
        }`
      );
  };

  const handleProsCategorySuggest = (data) => {
    dispatch(setSuggestValue(data));
    history.push("/sugerir-categoria");
  };
  const handleFocus = () => {
    searchRef.current.scrollIntoView({ behavior: "smooth", block: 'center' });
  };

  useEffect(() => {
    if (window.location.pathname.includes("search")) {
      let cs = typeof localStorage.getItem("currentSearch") === 'string' ? JSON.parse(localStorage.getItem("currentSearch")) : null;
      if (cs) setSearch(cs.name);
    }
  }, [localStorage]);

  let [idsCat, setIdsCat] = useState([]);

  useEffect(() => {
    axiosGet("/guests/category-verticals").then((response) => {
      let data = response.data; // me trae un arreglo con las "CATEGORIAS"
      data = data.map(category => category.id) // devuelve un arreglo con los "IDS" de las categorias
      setIdsCat(data);
    });
  }, []);

  const buttonSearch = true;

  return (
    <div className={styles.generalSearchContent}>
      <Form onSubmit={(e) => handleSearch(e)}>
        <div className={styles.inputsContainer} ref={searchRef}>
          <div className={styles.inputWrapper}>
            <input
              type="text"
              id={styles.generalSearch}
              className={`${styles.prosSearch} ${hasError && styles.error}`}
              placeholder={"Buscá servicios en Zenpros"}
              value={search}
              autoComplete="off"
              onChange={(e) => handleSearchChange(e.target.value)}
              name="search"
              onFocus={() => handleFocus()}
            />
            <div className={`${styles.prosSearchResultWrapper}`}>
              {filteredProsCategories?.length > 0 &&
                search &&
                search.length > 0 &&
                !valuesSelected.name
                ? filteredProsCategories.map((prosCategory, index) => (
                  <div
                    key={index}
                    className={styles.prosCategory}
                    onClick={() => handleSearchSelect(prosCategory)}
                  >
                    <h4 className={styles.prosCategoryName}>
                      {!idsCat.includes(prosCategory.parentcategory.id) ? (
                        `${prosCategory.parentcategory.name} > ${prosCategory.name}`
                      ) : (
                        <strong>{prosCategory.name}</strong>
                      )}
                    </h4>
                    {!prosCategory.available && (
                      <span className={styles.prosCategorySoon}>
                        Próximamente
                      </span>
                    )}
                  </div>
                ))
                : search &&
                search.length > 0 &&
                !valuesSelected.name &&
                !hasError && (
                  <div className={styles.prosCategory}>
                    <h4 className={styles.prosCategoryName}>{search}</h4>
                    <Button
                      onClickHandler={() => handleProsCategorySuggest(search)}
                      customStyles={{ width: "106px", marginLeft: "auto" }}
                    >
                      Sugerir
                    </Button>
                  </div>
                )}
            </div>
          </div>
          <Button
            search
            isDisabled={!valuesSelected.name}
            type="submit"
            buttonSearch={buttonSearch}
            customStyles={{
              width: buttonSearch ? (!isMobile ? "55px" : "40px") : "104px",
              height: buttonSearch ? (!isMobile ? "50px" : "35px") : "100%",
              marginLeft: "auto",
              marginRight: buttonSearch ? "1.2rem" : null,
              borderRadius: buttonSearch ? "10rem" : "0px 8px 8px 0px",
              background: buttonSearch ? "#ff6e5d" : null,
            }}
          >
            <GoSearch />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default GeneralSearch;
