import React from 'react'
import UserProfileContainer from '@organism/UserProfileContainer/UserProfileContainer'
import styles from './styles.module.scss'

const UserProfile = () => {
  return (
    <div className={styles.profileContainer}>
      <UserProfileContainer />
    </div>
  )
}

export default UserProfile
