import video1 from "../assets/onboarding/video1.mp4";
import video2 from "../assets/onboarding/video2.mp4";
import video3 from "../assets/onboarding/video3.mp4";
import video4 from "../assets/onboarding/video4.mp4";

export const onboarding = [
    {
      step: 1,
      title: "Paso 1",
      subTitle: "Completá tu tarjeta de presentación",
      show: false,
      viewed: false,
      video: video1,
      deployStep: false,
    },
    {
      step: 2,
      title: "Paso 2",
      subTitle: "Agregá un mensaje personalizado para tus clientes",
      show: false,
      viewed: false,
      video: video2,
      deployStep: false,
    },
    {
      step: 3,
      title: "Paso 3",
      subTitle: "¿Qué servicios querés ofrecer?",
      show: false,
      viewed: false,
      video: video3,
      deployStep: false,
    },
    {
      step: 4,
      title: "Paso 4",
      subTitle: "Pedí recomendaciones y mejorá tu posicionamiento",
      show: false,
      viewed: false,
      video: video4,
      deployStep: false,
    },
  ]