import * as actionTypes from "../actions/types";

const INITIAL_STATE = {
  professionals: [],
  professional: {},
  panelModal: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_PROFESSIONALS:
      return {
        ...state,
        professionals: [],
      };
    case actionTypes.GET_PROFESSIONAL:
      return {
        ...state,
        professional: action.payload,
      };
    case actionTypes.SET_PANEL_MODAL:
      return {
        ...state,
        panelModal: action.payload,
      };
    default:
      return state;
  }
};
