import React from 'react'
import styles from './Checkbox.module.scss'
import Text from '@atoms/Text/Text'
import useScreenDetect from '@hooks/useScreenDetect'

const Checkbox = ({
  option,
  checked,
  handleCheck,
  disabled,
  customStyles,
  enableSoon
}) => {
  const handleClick = () => {
    handleCheck(option)
  }
  const { isMobile } = useScreenDetect()
  return (
    <div
      className={`${styles.checkboxContainer} ${(disabled ||
        (!option.available && enableSoon)) &&
        styles.disabled}`}
      onClick={() =>
        disabled || (!option.available && enableSoon) ? false : handleClick()}
      style={customStyles}
    >
      <div className={`${styles.box} ${checked && styles.checked}`} />
      <Text
        tag='p'
        size='m'
        align='left'
        customStyles={{
          userSelect: 'none',
          width: !option.available && enableSoon ? 'max-content' : '90%'
        }}
      >
        {option.name}
      </Text>
      {!option.available && enableSoon && (
        <span className={styles.optionSoon}>
          {isMobile ? 'Prox' : 'Proximamente'}
        </span>
      )}
    </div>
  )
}

export default Checkbox
