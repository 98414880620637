import React, { useState } from 'react'
import Text from '@atoms/Text/Text'
import Input from '@atoms/Input/Input'
import styles from './SuggestCategoryContainer.module.scss'
import { ReactComponent as SuggestIcon } from '@assets/svg/suggest-category.svg'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@atoms/Button/Button'
import { suggestCategory } from '@actions/registerActions'
import useScreenDetect from '@hooks/useScreenDetect'
import { motion } from 'framer-motion'
const SuggestCategoryContainer = () => {
  const [mailInput, setMailInput] = useState('')
  const { suggestValue } = useSelector((state) => state.general)
  const dispatch = useDispatch()

  const { isMobile } = useScreenDetect()

  const handleInputSuggestChange = (value) => {
    setMailInput(value)
  }

  const handleSubmit = () => {
    const objectValue = {
      email: mailInput,
      suggestion_category: suggestValue,
      suggestion_subcategory: suggestValue
    }
    const successModalTexts = {
      title: '¡Listo!',
      subtitle: 'Sugerencia enviada'
    }
    dispatch(suggestCategory(objectValue, successModalTexts))
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div className={styles.container}>
        <SuggestIcon className={styles.icon} />
        <div className={styles.title}>
          <Text tag='p' size='l-1' weight='bold'>
            Lo sentimos
            <br /> No encontramos profesionales.
          </Text>
        </div>
        <div className={styles.subtitle}>
          <Text tag='p' size='xm'>
            Pero no te preocupes, dejanos un email de contacto y te avisaremos
            cuando se
            <br /> registren profesionales :)
          </Text>
        </div>
        <div className={styles.bottom}>
          <Input
            type='email'
            placeholder='tuemail@email.com'
            onChangeHandler={(e) => handleInputSuggestChange(e.target.value)}
            name='serviceToDoInCategory'
            customStyles={{ width: isMobile ? '90vw' : '54rem' }}
          />
          <Button
            customStyles={{
              maxWidth: isMobile ? '100%' : 'max-content',
              whiteSpace: 'nowrap'
            }}
            onClickHandler={() => handleSubmit()}
          >
            Sumate a la lista de espera
          </Button>
        </div>
      </div>
    </motion.div>
  )
}
export default SuggestCategoryContainer
