import React from 'react'
import styles from './FalseProfile.module.scss'
import Avatar from '@atoms/Avatar'
import { ReactComponent as ChevronIcon } from '@assets/svg/down-chevron.svg'
import Text from '@atoms/Text/Text'
import { useSelector } from 'react-redux'

const FalseProfile = () => {
  const { profileData } = useSelector(state => state.profile)

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Avatar image={profileData?.photo} fromHeader />
        <Text tag='span' size='m' align='left'>
          {`${profileData?.name} ${profileData?.lastName}`}
        </Text>
        <ChevronIcon
          className={`${styles.icon}`}
        />{' '}
      </div>
      <img src={require('@assets/img/SwitchScreenshot.webp')} alt='user-mode' className={styles.img} />
    </div>
  )
}

export default FalseProfile
