import { setModal } from '@actions/generalActions'
import { setUserWorkStatus } from '@actions/profileActions'
import Button from '@atoms/Button/Button'
import Text from '@atoms/Text/Text'
import useScreenDetect from '@hooks/useScreenDetect'
import React from 'react'
import { ReactComponent as FinishIcon } from '@assets/svg/finished-work.svg'
import { useDispatch } from 'react-redux'
import styles from './WorksModals.module.scss'
import ModalTitle from '@atoms/ModalTitle/ModalTitle'

const UserWorkFinished = ({ currentWork }) => {
  const dispatch = useDispatch()
  const { isMobile } = useScreenDetect()

  const handleFinishWork = () => {
    dispatch(setUserWorkStatus(currentWork.id, 'FINISHED', false, undefined, 'NOTIFY_PRO'))
    dispatch(setModal(true, 'starsReviewUser'))
  }

  const handleNotFinishWork = () => {
    const successModalValues = {
      title: '¡Gracias por tu ayuda!',
      subtitle:
        'Nos pondremos en contacto con el profesional para analizar el caso',
      buttonText: 'Volver a mis trabajos',
      urlToRedirect: '/trabajos-usuario'
    }
    dispatch(
      setUserWorkStatus(currentWork.id, 'NOT_FINISHED', successModalValues)
    )
  }

  return (
    <div className={`${styles.workModal}`}>
      <ModalTitle
        customStyles={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        title='Calificá tu experiencia'
      />
      <FinishIcon className={styles.workIcon} />
      <Text
        tag='p'
        size='m'
        customStyles={{ margin: 'var(--spacing-small) 0' }}
      >
        ¿El profesional realizó el trabajo?
      </Text>
      <div className={styles.workButtons}>
        <Button
          buttonStyle='terciary'
          onClickHandler={() => handleNotFinishWork()}
          customStyles={{
            marginRight: isMobile ? '0' : 'var(--spacing-small)',
            maxWidth: '100%',
            marginBottom: isMobile ? '0.8rem' : '0'
          }}
        >
          No
        </Button>
        <Button
          onClickHandler={() => handleFinishWork()}
          customStyles={{
            maxWidth: '100%'
          }}
        >
          Si, calificar
        </Button>
      </div>
    </div>
  )
}
export default UserWorkFinished
