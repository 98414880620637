// N&A
import Abogado from '@assets/img/NYAabogado.webp'
import Contador from '@assets/img/NYAcontador.webp'
import Gestor from '@assets/img/NYAgestor.webp'
import Tasador from '@assets/img/NYAtasador.webp'
// import ProdSeguros from '@assets/img/NYAprodseguros.webp'
import RRHH from '@assets/img/NYArecursoshumanos.webp'
// R&T
import Traductor from '@assets/img/NYAtraductor.webp'
// import Redactor from '@assets/img/RYTredactor.webp'
// S&B
import Nutricionista from '@assets/img/SYBnutricionista.webp'
import Entrenador from '@assets/img/SYBentrenadorpersonal.webp'
// import Coach from '@assets/img/SYBcoach.webp'
import Psicologo from '@assets/img/SYBpsicologo.webp'
import InstructorYoga from '@assets/img/SYByoga.webp'
// Diseño
import DiseñoInter from '@assets/img/Ddiseñointer.webp'
import DiseñoGra from '@assets/img/Ddiseñogra.webp'
import DiseñoIndum from '@assets/img/Ddiseñodeindumentaria.webp'
import DiseñoIndust from '@assets/img/Ddiseñoindustrial.webp'
import DiseñoWeb from '@assets/img/Ddiseñoweb.webp'
// Prod
// import Fotografo from '@assets/img/PAfotogra.webp'
import ProductorVideo from '@assets/img/PAprodvideo.webp'
// Marketing
import Marketing from '@assets/img/Mmd.webp'
// import PubliComu from '@assets/img/Mpyc.webp'
// Multimedia y Creatividad
import ArtistaPlastico from '@assets/img/MYCartistaplastico.webp'
import IngenieroSonido from '@assets/img/MYCingenierodesonido.webp'
import Locutor from '@assets/img/MYClocutor.webp'
import Musico from '@assets/img/MYCmusico.webp'
// Hogar
import Arquitecto from '@assets/img/Harquitecto.webp'
import ArreglosHogar from '@assets/img/Harreglos.webp'
import Electricista from '@assets/img/Helectricista.webp'
import Gasista from '@assets/img/Hgasista.webp'
// import Mudanzas from '@assets/img/Hmudanza.webp'
import Plomero from '@assets/img/Hplomero.webp'
import TecnicoAire from '@assets/img/Htecaire.webp'
import ProfLimpieza from '@assets/img/Hproflimp.webp'
import Jardineria from '@assets/img/Hjardineria.webp'
// Servicio Tecnico
// import ServComputadora from '@assets/img/STcomputadoras.webp'
import ServCelus from '@assets/img/STcelu.webp'
import { baseApi } from 'src/environments/production'

// export const CATEGORIES = baseApi.includes("prod") ? [
// NUEVO DISEÑO - REESTYLING (aquí se reemplazaron algunos de las fotos anteriores)
import Publicidad from '@assets/img/Publicidad.svg'
import Redactor from '@assets/img/Redactor.svg';
// INDUMENTARIA ¿cual de los anteriores es?
import ProdSeguros from '@assets/img/Seguros.svg'
import Mudanzas from '@assets/img/Mudanzas.svg';
import Coach from '@assets/img/Coaching.svg';
import Fotografo from '@assets/img/Fotografo.svg';
import ServComputadora from '@assets/img/Computadoras.svg'


export const CATEGORIES = baseApi.includes("prod") ? [
  {
    name: 'Negocios & Admin',
    id: 1,
    subcategories: [
      { img: Abogado, title: 'Abogado/a', url: '/search/9' },
      { img: Contador, title: 'Contador/a', url: '/search/10' },
      { img: Gestor, title: 'Gestor/a', url: '/search/11' },
      { img: ProdSeguros, title: 'Productor/a de seguros', url: '/search/1247' },
      { img: RRHH, title: 'Recursos Humanos', url: '/search/1817' },
      { img: Tasador, title: 'Tasador/a', url: '/search/12' }
    ]
  },
  {
    name: 'Redacción y Traducción',
    id: 2,
    subcategories: [
      { img: Redactor, title: 'Redactor/a', url: '/search/1838' },
      { img: Traductor, title: 'Traductor/a', url: '/search/13' }
    ]
  },
  {
    name: 'Salud y Bienestar',
    id: 3,
    subcategories: [
      { img: Coach, title: 'Coach', url: '/search/17' },
      { img: Entrenador, title: 'Entrenador/a Físico/a', url: '/search/16' },
      { img: InstructorYoga, title: 'Instructor/a de Yoga', url: '/search/1508' },
      { img: Nutricionista, title: 'Nutricionista', url: '/search/15' },
      { img: Psicologo, title: 'Psicólogo/a', url: '/search/14' }
    ]
  },
  {
    name: 'Marketing y Comunicación',
    id: 4,
    subcategories: [
      { img: Marketing, title: 'Marketing Digital', url: '/search/1143' },
      { img: Publicidad, title: 'Publicidad y Comunicación', url: '/search/1164' }
    ]
  },
  {
    name: 'Diseño',
    id: 5,
    subcategories: [
      { img: DiseñoGra, title: 'Diseñador/a Gráfico/a', url: '/search/18' },
      { img: DiseñoIndum, title: 'Diseñador/a de Indumentaria', url: '/search/1596' },
      { img: DiseñoIndust, title: 'Diseñador/a Industrial', url: '/search/1576' },
      { img: DiseñoInter, title: 'Diseñador/a de Interiores', url: '/search/19' },
      { img: DiseñoWeb, title: 'Diseñador/a Web', url: '/search/1590' }
    ]
  },
  {
    name: 'Multimedia y Creatividad',
    id: 6,
    subcategories: [
      { img: ArtistaPlastico, title: 'Artista Plástico/a', url: '/search/1916' },
      { img: Fotografo, title: 'Fotógrafo/a', url: '/search/1176' },
      { img: IngenieroSonido, title: 'Ingeniero/a en Sonido', url: '/search/1948' },
      { img: Locutor, title: 'Locutor/a y Artista Vocal', url: '/search/1924' },
      { img: Musico, title: 'Músico/a', url: '/search/1937' },
      { img: ProductorVideo, title: 'Productor/a de Video', url: '/search/22' }
    ]
  },
  {
    name: 'Hogar',
    id: 7,
    subcategories: [
      { img: Arquitecto, title: 'Arquitecto/a', url: '/search/23' },
      { img: ArreglosHogar, title: 'Arreglos del Hogar', url: '/search/24' },
      { img: Electricista, title: 'Electricista', url: '/search/25' },
      { img: Gasista, title: 'Gasista', url: '/search/30' },
      { img: Jardineria, title: 'Jardinería', url: '/search/1651' },
      { img: ProfLimpieza, title: 'Limpieza', url: '/search/27' },
      { img: Mudanzas, title: 'Mudanzas y Fletes', url: '/search/28' },
      { img: Plomero, title: 'Plomero/a', url: '/search/29' },
      { img: TecnicoAire, title: 'Técnico de Aire Acondicionado', url: '/search/26' }
    ]
  },
  {
    name: 'Servicio Técnico',
    id: 8,
    subcategories: [
      { img: ServCelus, title: 'Celulares', url: '/search/31' },
      { img: ServComputadora, title: 'Computadoras', url: '/search/32' }
    ]
  }
] : [
  {
    name: 'Negocios & Admin',
    id: 1,
    subcategories: [
      { img: Abogado, title: 'Abogado/a', url: '/search/9' },
      { img: Contador, title: 'Contador/a', url: '/search/10' },
      { img: Gestor, title: 'Gestor/a', url: '/search/11' },
      { img: Tasador, title: 'Tasador/a', url: '/search/12' },
      { img: ProdSeguros, title: 'Productor/a de seguros', url: '/search/1153' },
      { img: RRHH, title: 'Recursos Humanos', url: '/search/1692' },
    ]
  },
  {
    name: 'Redacción y Traducción',
    id: 2,
    subcategories: [
      { img: Redactor, title: 'Redactor/a', url: '/search/1713' },
      { img: Traductor, title: 'Traductor/a', url: '/search/13' }
    ]
  },
  {
    name: 'Salud y Bienestar',
    id: 3,
    subcategories: [
      { img: Psicologo, title: 'Psicólogo/a', url: '/search/14' },
      { img: Nutricionista, title: 'Nutricionista', url: '/search/15' },
      { img: Entrenador, title: 'Entrenador/a Físico/a', url: '/search/16' },
      { img: Coach, title: 'Coach', url: '/search/17' },
      { img: InstructorYoga, title: 'Instructor/a de Yoga', url: '/search/1416' },
    ]
  },
  {
    name: 'Marketing y Comunicación',
    id: 4,
    subcategories: [
      { img: Marketing, title: 'Marketing Digital', url: '/search/1143' },
      { img: Publicidad, title: 'Publicidad y Comunicación', url: '/search/1164' }
    ]
  },
  {
    name: 'Diseño',
    id: 5,
    subcategories: [
      { img: DiseñoGra, title: 'Diseñador/a Gráfico/a', url: '/search/18' },
      { img: DiseñoInter, title: 'Diseñador/a de Interiores', url: '/search/19' },
      { img: DiseñoIndust, title: 'Diseñador/a Industrial', url: '/search/1453' },
      { img: DiseñoWeb, title: 'Diseñador/a Web', url: '/search/1467' },
      { img: DiseñoIndum, title: 'Diseñador/a de Indumentaria', url: '/search/1473' },
    ]
  },
  {
    name: 'Multimedia y Creatividad',
    id: 6,
    subcategories: [
      { img: ProductorVideo, title: 'Gerente de Redes Sociales', url: '/search/20' },
      { img: ProductorVideo, title: 'Productor/a de Video', url: '/search/22' },
      { img: ArtistaPlastico, title: 'Artista Plástico/a', url: '/search/1783' },
      { img: Locutor, title: 'Locutor/a y Artista Vocal', url: '/search/1791' },
      { img: Musico, title: 'Músico/a', url: '/search/1804' },
      { img: IngenieroSonido, title: 'Ingeniero/a en Sonido', url: '/search/1815' },
      // { img: Fotografo, title: 'Fotógrafo/a', url: '/search/1176' },
    ]
  },
  {
    name: 'Hogar',
    id: 7,
    subcategories: [
      { img: Arquitecto, title: 'Arquitecto/a', url: '/search/23' },
      { img: ArreglosHogar, title: 'Arreglos del Hogar', url: '/search/24' },
      { img: Electricista, title: 'Electricista', url: '/search/25' },
      { img: TecnicoAire, title: 'Técnico de Aire Acondicionado', url: '/search/26' },
      { img: ProfLimpieza, title: 'Limpieza', url: '/search/27' },
      { img: Mudanzas, title: 'Mudanzas y Fletes', url: '/search/28' },
      { img: Plomero, title: 'Plomero/a', url: '/search/29' },
      { img: Gasista, title: 'Gasista', url: '/search/30' },
      { img: Jardineria, title: 'Jardinería', url: '/search/1529' },
    ]
  },
  {
    name: 'Servicio Técnico',
    id: 8,
    subcategories: [
      { img: ServCelus, title: 'Servicio Técnico de Celulares', url: '/search/31' },
      { img: ServComputadora, title: 'Servicio Ténico de Computadoras', url: '/search/32' }
    ]
  }
]