import React, { useEffect, useState } from "react";
import styles from "./Login.module.scss";
import { useForm } from "react-hook-form";
import Field from "@molecules/InputTextBox/InputTextBox";
import Form from "@atoms/Form/Form";
import Text from "@atoms/Text/Text";
import Button from "@atoms/Button/Button";
import { Link, useHistory } from "react-router-dom";
import SocialButton from "@atoms/SocialButton/SocialButton";
import { useDispatch, useSelector } from "react-redux";
import { zenprosLogin, zenprosRegisterUser, editProfileData } from "@actions/";
import AlertMessage from "@atoms/AlertMessage/AlertMessage";
// import InputTextBox from '@molecules/InputTextBox/InputTextBox';
import useScreenDetect from "@hooks/useScreenDetect";
import { ENVIRONMENT_URL } from "@config/api";
import { getProsProfessions, successRegister } from "@actions/generalActions";
import { setLoggedByFaceOrGoogle } from "@actions/registerActions";
import { setToken, deleteToken, getIsProFromLS } from "@utils/tokenService";
import { axiosGet, axiosPost, axiosPut, setTokenInAxios } from "@config/api";
import showNotification from "@services/NotificationService";

const Login = ({
  type,
  registerValues,
  handleRegisterComponentClick,
  currentStep,
  handleNextStep,
}) => {
  const [dataStep1, setDataStep1] = useState(null);
  const [authByFaceOrGoogle, setAuthByFaceOrGoogle] = useState(false);
  const { profileData } = useSelector((state) => state.profile);
  const { isAuth } = useSelector((state) => state.auth);
  const { allCategories } = useSelector((state) => state.general);

  const dispatch = useDispatch();
  const { isMobile } = useScreenDetect();
  const history = useHistory();

  const professionalRegisterPage = type === "professionalRegister";
  const registerPage = type === "register";

  const { register, handleSubmit, errors, watch, setValue } = useForm({
    mode: "onBlur",
  });

  const values = watch();

  let mail;

  const existsEmail = async (name) => {
    await axiosGet("/guests/mails")
      .then((response) => {
        let mailsData = response.data;
        let mailData;
        mailData = mailsData.find((mail) => mail.email === name);
        if (mailData) {
          mail = mailData.email;
        }
        return;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSubmit = async (data) => {
    if (professionalRegisterPage) {
      return handleRegisterComponentClick(data);
    }
    if (registerPage) {
      await existsEmail(data.usuario);
      if (mail) {
        showNotification(
          "El email ya se  encuentra registrado, inicia sesión.",
          "alreadyExists",
          "error",
          5000
        );
        history.push('/iniciar-sesion');
        return;
      }
      dispatch(successRegister(true));
      if (currentStep === 1) {
        setDataStep1(data);
        return handleNextStep(2);
      } else {
        if (authByFaceOrGoogle) {
          const editedValues = {
            name: profileData.name,
            lastName: profileData.lastName,
            email: profileData.email,
            phone: data.phone,
          };
          dispatch(editProfileData(editedValues));
          dispatch(setLoggedByFaceOrGoogle());
          window.scrollTo({ top: 0, behavior: "smooth" });
          return history.push("/confirmar-email");
        }
        return dispatch(
          zenprosRegisterUser(
            {
              name: dataStep1.name,
              lastName: dataStep1.lastname,
              email: dataStep1.usuario,
              phone: data.phone,
              password: dataStep1.contrasena,
              isBusiness: false,
            },
            null,
            history
          )
        );
      }
    }
    localStorage.clear();
    dispatch(
      zenprosLogin(
        {
          email: data.usuario,
          password: data.contrasena,
          validatedEmail: true,
          isMobile,
        },
        history
      )
    );
  };

  const handleGoogleLogin = () => {
    if (professionalRegisterPage) {
      localStorage.setItem(
        "professional-register-info",
        JSON.stringify({
          type: registerValues.type,
        })
      );
    }
    if (registerPage) {
      localStorage.setItem(
        "register-info",
        JSON.stringify({
          value: true,
        })
      );
    }
    window.location.href = `${ENVIRONMENT_URL}auth/google`;
  };
  const handleFacebookLogin = () => {
    if (professionalRegisterPage) {
      localStorage.setItem(
        "professional-register-info",
        JSON.stringify({
          type: registerValues.type,
        })
      );
    }
    if (registerPage) {
      localStorage.setItem(
        "register-info",
        JSON.stringify({
          value: true,
        })
      );
    }
    window.location.href = `${ENVIRONMENT_URL}auth/facebook`;
  };
  const handlePrevStep = () => {
    // handleNextStep(1);
    history.push("/");
  };

  const handleClickBottomLink = () => {
    if (registerPage) return history.push("/iniciar-sesion");
    history.push("/registro");
  };

  useEffect(() => {
    if (currentStep === 2) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentStep]);

  useEffect(() => {
    const LSfromRegister = JSON.parse(localStorage.getItem("register-info"));
    if (LSfromRegister && isAuth) {
      setAuthByFaceOrGoogle(true);
      localStorage.removeItem("register-info");
      return handleNextStep(2);
    }
    if (professionalRegisterPage && registerValues?.userInfo.name) {
      setValue("name", registerValues.userInfo.name);
      setValue("last", registerValues.userInfo.lastname);
      setValue("usuario", registerValues.userInfo.usuario);
      setValue("contrasena", registerValues.userInfo.contrasena);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (allCategories.length === 0) {
      dispatch(getProsProfessions());
    }
  }, []);

  return (
    <div className={styles.login}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {currentStep === 2 ? (
          <div className={styles.phoneStep}>
            <Text
              tag="p"
              size={isMobile ? "m" : "l-1"}
              weight="semibold"
              align="left"
              customStyles={{ marginBottom: "var(--spacing-small)" }}
            >
              Dejanos tu número para que los Pros puedan contactarte
            </Text>
            <Field
              phonenumber
              label="Número de teléfono"
              placeholder="Ej: 1112345678"
              name="phone"
              type="number"
              inputRef={register({ required: "Campo obligatorio", min: 10 })}
              error={errors.phone}
              customStyles={{ marginBottom: "var(--spacing-medium)" }}
            />
            <div className={styles.phoneButtons}>
              <Button
                isDisabled={!values.phone || values.phone === ""}
                type="submit"
                customStyles={{
                  maxWidth: isMobile ? "100%" : "max-content",
                  marginBottom: "var(--spacing-small)",
                }}
              >
                Crear cuenta
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.login_main}>
            <SocialButton
              type="Google"
              handleClick={() => handleGoogleLogin()}
            />
            <SocialButton
              type="Facebook"
              handleClick={() => handleFacebookLogin()}
            />
            <h4 className={styles.termsAndConditions}>
              Al apretar en “ingresar con Facebook/Google” estás aceptando
              nuestros
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://conditions.zenpros.work/Terminos-Condiciones-Zenpros.pdf"
              >
                {" "}
                Términos y Condiciones
              </a>{" "}
              y
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://conditions.zenpros.work/assets/Politica-de-Privacidad-Zenpros.pdf"
              >
                {" "}
                Políticas de privacidad
              </a>
            </h4>
            <div className={styles.loginSeparator}>
              <span className={styles.separator} />
              O
              <span className={styles.separator} />
            </div>
            {(registerPage || professionalRegisterPage) && (
              <div className={styles.registerFields}>
                <Field
                  type="text"
                  name="name"
                  label="Nombre"
                  placeholder="Ingresá tu nombre"
                  inputRef={register({ required: "Campo obligatorio" })}
                  error={errors.name}
                  autoComplete="given-name"
                />
                <Field
                  type="text"
                  name="lastname"
                  label="Apellido"
                  placeholder="Ingresá tu apellido"
                  inputRef={register({ required: "Campo obligatorio" })}
                  error={errors.lastname}
                  autoComplete="family-name"
                />
              </div>
            )}
            <Field
              type="email"
              name="usuario"
              label="Email"
              placeholder="tuemail@email.com"
              inputRef={register({
                required: "Ingresá un email válido para poder avanzar",
                pattern: /^\S+@\S+$/i,
              })}
              error={errors.usuario}
            />
            <Field
              type="password"
              name="contrasena"
              label="Contraseña"
              placeholder="Ej: C8oP6asI0ñC3"
              inputRef={register({
                required: "Campo obligatorio",
                minLength: {
                  value: 8,
                  message: "La contraseña debe tener al menos 8 caracteres",
                },
                pattern: {
                  value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/,
                  message: "La contraseña debe tener caracteres alfamuméricos",
                },
              })}
              error={errors.contrasena}
              customStyles={{ marginTop: "2.4rem" }}
            />
            <AlertMessage
              message="La contraseña deberá tener al menos 8 caracteres alfamuméricos"
              icon="alert"
            />
            {!registerPage && !professionalRegisterPage && (
              <Link className={styles.forgotPassword} to="/olvide-contrasena">
                ¿Olvidaste tu contraseña?
              </Link>
            )}
            {(registerPage || professionalRegisterPage) && (
              <h4 className={styles.termsAndConditions}>
                Al avanzar estás aceptando nuestros
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://conditions.zenpros.work/Terminos-Condiciones-Zenpros.pdf"
                >
                  {" "}
                  Términos y Condiciones
                </a>{" "}
                y
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://conditions.zenpros.work/assets/Politica-de-Privacidad-Zenpros.pdf"
                >
                  {" "}
                  Políticas de privacidad
                </a>
              </h4>
            )}
            {professionalRegisterPage ? (
              <div className={styles.registerButtons}>
                <Button
                  customStyles={{ maxWidth: "12.4rem" }}
                  buttonStyle="terciary"
                  // onClickHandler={() => handleRegisterComponentClick(false)}
                  onClickHandler={() => handlePrevStep()}
                >
                  Atrás
                </Button>
                <Button
                  type="submit"
                  isDisabled={
                    !values.name ||
                    !values.lastname ||
                    !values.usuario ||
                    !values.contrasena
                  }
                  customStyles={{ maxWidth: "12.4rem" }}
                >
                  Siguiente
                </Button>
              </div>
            ) : (
              <Button
                isDisabled={
                  registerPage
                    ? !values.name ||
                      values.name === "" ||
                      values.lastname === "" ||
                      values.usuario === "" ||
                      values.contrasena === ""
                    : values.usuario === "" || values.contrasena === ""
                }
                customStyles={{ maxWidth: "unset" }}
                type="submit"
                // onClickHandler={() => {
                //   if (registerPage) {
                //     history.push("/registro-telefono");
                //   }
                // }}
              >
                {registerPage ? "Crear cuenta" : "Iniciar sesión"}
              </Button>
            )}
            <div className={styles.bottomLink}>
              {registerPage ? (
                <Text
                  tag="p"
                  size="m"
                  weight="regular"
                  color="secondary-blue"
                  onClick={() => handleClickBottomLink()}
                >
                  Si ya tenés cuenta. <b>Iniciá sesión</b>
                </Text>
              ) : !professionalRegisterPage ? (
                <Text
                  tag="p"
                  size="m"
                  weight="regular"
                  color="secondary-blue"
                  onClick={() => handleClickBottomLink()}
                >
                  ¿Aún no tenés cuenta? <b>Registrate</b>
                </Text>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default Login;
