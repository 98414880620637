import React from 'react'
import styles from './Modal.module.scss'
import { ReactComponent as CloseIcon } from '@assets/svg/close.svg'

const Modal = ({ children, closeAction }) => {
  return (
    <>
      <div
        className={styles.background}
        onClick={() => closeAction && closeAction()}
      />
      <div className={styles.wrapper}>
        {closeAction && (
          <CloseIcon onClick={() => closeAction()} className={styles.close} />
        )}
        {children}
      </div>
    </>
  )
}

export default Modal
