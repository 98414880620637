import { existToken } from '@utils/tokenService'
import * as actionTypes from '../actions/types'

const INITIAL_STATE = {
  isAuth: existToken(),
  redirectTo: '',
  hasForgotError: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CLEAN:
      return {
        ...state,
        ...INITIAL_STATE
      }
    case actionTypes.REDIRECT:
      return {
        ...state,
        redirectTo: action.payload
      }
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true
      }
    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        isAuth: false
      }

    case actionTypes.FORGOT_SUCCESS:
      return {
        ...state,
        hasForgotError: false
      }
    case actionTypes.FORGOT_ERROR:
      return {
        ...state,
        hasForgotError: true
      }
    default:
      return state
  }
}
