import React, { useEffect, useState } from 'react'
import styles from './UserWorkRequest.module.scss'
import { ReactComponent as CalendarIcon } from '@assets/svg/calendar.svg'
import { ReactComponent as PhoneIcon } from '@assets/svg/phone.svg'
import { ReactComponent as EmailIcon } from '@assets/svg/envelope.svg'
import { ReactComponent as StarIcon } from '@assets/svg/star.svg'
import Button from '@atoms/Button/Button'
import Text from '@atoms/Text/Text'
import useScreenDetect from '@hooks/useScreenDetect'
// import moment from 'moment'
// import 'moment/locale/es'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { useDispatch, useSelector } from 'react-redux'
import { removeNotification, setJobReadStatus } from '@actions/profileActions'

const UserWorkRequest = ({
  work,
  noStatus,
  handleViewDetail,
  qualificationAction,
  finishedTab
}) => {
  const { professionalProfile } = work
  const contactTime = work.contactTime
  const hideData =
    work.jobStatus === 'PENDING' || work.jobStatus === 'REJECTED'
  const fullName = `${professionalProfile.user.name} ${professionalProfile.user.lastName}`
  const { isMobile } = useScreenDetect()
  const { unreadMessages } = useSelector((state) => state.profile)
  const [messages, setMessages] = useState({})
  const dispatch = useDispatch()
  const qualificationValue = (value) => {
    if (value) {
      switch (value) {
        case 1:
          return 'Mala'

        case 2:
          return 'Regular'

        case 3:
          return 'Buena'

        case 4:
          return 'Muy buena'

        case 5:
          return 'Excelente'

        default:
          return ''
      }
    }
  }

  const spanishDate = () => {
    dayjs.locale('es')
    const dateCreated = dayjs(work.created).format(
      'dddd D [de] MMMM HH:mm [hs]'
    )
    return dateCreated
  }

  useEffect(() => {
    const alreadyIncluded = unreadMessages.find(
      (message) => message.jobId === work.id
    )
    setMessages(alreadyIncluded)
  }, [messages])

  const setReadMessage = () => {
    dispatch(setJobReadStatus(work.id, 'READ'))
  }

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <div className={styles.nameContactType}>
          <h5 className={styles.name}>
            {professionalProfile.fantasy ? professionalProfile.fantasy:fullName}
          </h5>
          {contactTime.length > 0
            ? (
              <span
                className={`${styles.contactType} ${styles['contacto-telefonico']}`}
              >
                CONTACTO TELEFÓNICO
              </span>
              )
            : (
              <span
                className={`${styles.contactType} ${styles['contacto-por-mensaje']}`}
              >
                CONTACTO POR MENSAJE
              </span>
              )}
        </div>
        {!noStatus && work.jobStatus && (
          <span
            className={`${styles.status} ${styles[work.jobStatus.toLowerCase()]
              }`}
          >
            {work.jobStatus === 'PENDING'
              ? 'Pendiente'
              : work.jobStatus === 'REJECTED'
                ? 'NO DISPONIBLE'
                : 'Aprobado'}
          </span>
        )}    
      </div>
      {finishedTab && work.review.length > 0 && (
        <div className={styles.qualification}>
          <div className={styles.qualificationValue}>
            <p>{qualificationValue(work.review[0].evaluation)}</p>
            <StarIcon />
            <p className={styles.value}>{work.review[0]?.evaluation}</p>
          </div>
        </div>
      )}
      <Text tag='p' size='l' customStyles={{ opacity: '0.4' }} align='left'
        style={{marginTop: '2.5rem'}}>
        {professionalProfile.title}
      </Text>
      <div className={styles.bottom}>
        <div className={styles.bottomInfo}>
          <div className={styles.row}>
            <CalendarIcon className={styles.icon} />
            <p className={styles.dataText}>{spanishDate()}</p>
          </div>
          <div className={styles.row}>
            <PhoneIcon className={styles.icon} />
            <p className={styles.dataText}>
              {professionalProfile.user.phone && (hideData
                ? `...${professionalProfile.user.phone.slice(-5)}`
                : professionalProfile.user.phone)}
              {!professionalProfile.user.phone && '......'}
            </p>
          </div>
          <div className={styles.row}>
            <EmailIcon className={styles.icon} />
            <p className={styles.dataText}>
              {hideData
                ? `...@...${professionalProfile.user.email.split('.')[
                professionalProfile.user.email.split('.').length - 1
                ]
                }`
                : professionalProfile.user.email}
            </p>
          </div>
        </div>
        <div
          className={`${styles.buttons} ${qualificationAction &&
            styles.qualificationTab}`}
        >
          <Button
            buttonStyle='primary'
            onClickHandler={() => {
              dispatch(removeNotification('work', `workid${work.id}`))
              setReadMessage()
              handleViewDetail(work)
            }}
            customStyles={{ maxWidth: isMobile ? '100%' : 'max-content' }}
          >
            Ver detalle
            {(work.jobStatusNewRead === 'NOTIFY_USER' || messages?.messages > 0) && (
              <span
                className={styles.worksLength}
                style={messages?.messages > 0 || work.jobStatusNewRead === 'NOTIFY_USER' ? { backgroundColor: 'var(--border-error)' } : null}
                key={work.id}
              >
                {work.jobStatusNewRead === 'NOTIFY_USER' ? (messages?.messages > 0 ? messages?.messages + 1 : 1) : (messages?.messages > 0 ? messages?.messages : '1')}
              </span>
            )}
          </Button>
          {qualificationAction && (
            <Button
              onClickHandler={() => qualificationAction(work)}
              customStyles={{
                marginLeft: isMobile ? '0' : 'var(--spacing-medium)',
                marginTop: isMobile ? 'var(--spacing-medium)' : '0',
                maxWidth: isMobile ? '100%' : 'max-content',
                transition: 'none'
              }}
            >
              Calificar profesional
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserWorkRequest
