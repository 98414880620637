import React from "react";
import ShadowBox from "@atoms/ShadowBox/ShadowBox";
import styles from "./ProfileInfoCard.module.scss";
import IconText from "@atoms/IconText/IconText";
import ImageUpload from "@atoms/ImageUpload/ImageUpload";
import Icon from "@atoms/Icon/Icon";
import { ReactComponent as UserIcon } from "@assets/svg/user-empty.svg";
import Button from "@atoms/Button/Button";
import { getReputationFromRate } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { editProfileData } from "@actions/profileActions";
import { useParams } from "react-router-dom";
import { ReactComponent as share } from "@assets/svg/share.svg";
import Modal from "@atoms/Modal/Modal";
import { setModal } from "@actions/generalActions";

const ProfileInfoCard = ({
  proProfileData,
  isMobile,
  handleEditAction,
  publicMode,
  userMode,
  isProLogged,
}) => {
  const {
    rating,
    residenceArea,
    reviews,
    referencePrice,
    referencePaymentType,
    user,
    title,
    avgReviews,
    professionalProfileWorkType,
    professionalProfileCategory,
  } = proProfileData;

  const { id } = useParams();

  const { profileData } = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  const handleCallButton = () => {
    handleEditAction(
      professionalProfileCategory.length > 2
        ? "callProRequest"
        : "callProRequestWithoutServices"
    );
  };

  const handleEdit = () => {
    if (userMode) return handleEditAction("userProfileEdit");
    handleEditAction("generalInfo");
  };

  const handleMessageButton = () => {
    handleEditAction(
      professionalProfileCategory.length > 2
        ? "messageProRequest"
        : "messageProWithoutServices"
    );
  };

  const handleUploadImage = (file) => {
    const form = new FormData();
    form.append("file", file);
    dispatch(editProfileData(form, false, true, false, true));
  };

  const name = userMode
    ? `${proProfileData.name} ${proProfileData.lastName}`
    : `${user.name} ${user.lastName}`;
  const phone = userMode ? proProfileData.phone : user.phone;
  const email = userMode ? proProfileData.email : user.email;

  const reputation = (avgReviews && getReputationFromRate(avgReviews)) || null;

  const closeModal = (e) => {
    e.preventDefault();
  };

  const showModal = (e) => {
    e.preventDefault();
    dispatch(setModal(true, "shareProfile"));
  };

  return (
    <ShadowBox
      width={isMobile ? "91.5vw" : "64rem"}
      customStyles={{
        minWidth: isMobile ? "100%" : "80rem",
        padding: isMobile ? "var(--spacing-small)" : "var(--spacing-medium)",
        border: "1px solid rgba(11, 26, 50, 0.1)",
        boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.05)",
        borderRadius: "10px",
      }}
    >
      <div
        className={`${styles.profileInfoCard} ${isProLogged &&
          styles.isProLogged} ${publicMode && styles.publicMode} ${userMode &&
          styles.userMode} `}
      >
        <div className={styles.image}>
          {publicMode ? (
            user.photo ? (
              <img src={user.photo} alt={name} />
            ) : (
              <div className={styles.noImage}>
                <UserIcon className={styles.icon} />
              </div>
            )
          ) : (
            <ImageUpload
              keyId="perfil"
              handleImageChange={handleUploadImage}
              customStyles={{
                background: "rgba(222, 222, 222, 0.5)",
                width: "100%",
                height: "100%",
                margin: "0",
              }}
              defaultImage={userMode ? proProfileData.photo : user.photo}
              withoutDelete
            />
          )}
        </div>
        <div className={styles.nameRating}>
          <div>
            <h1 className={styles.alias}>
              {proProfileData.fantasy ? proProfileData.fantasy : name}
            </h1>
            {!userMode && rating && (
              <span
                className={`${styles.rating} ${styles[rating.toLowerCase()]}`}
              >
                {rating}
              </span>
            )}
            <h2
              className={styles.name}
              style={name.length > 15 ? { paddingBottom: "2rem" } : {}}
            >
              {id ? null : proProfileData.fantasy ? name : null}
            </h2>
          </div>
        </div>
        {!publicMode && (
          <div className={styles.editIcon} onClick={() => handleEdit()}>
            <Icon icon="edit" />
          </div>
        )}
        {!userMode && (
          <>
            <div className={styles.iconContainer}>
              <IconText icon="location" text={residenceArea || "- - -"} />
            </div>
            <div className={styles.workModality}>
              {professionalProfileWorkType ? (
                professionalProfileWorkType.length > 1 ? (
                  <>
                    <IconText
                      icon="location"
                      text="Remoto"
                      customStyles={{
                        fontSize: "var(--font-m)",
                        marginRight: "1rem",
                      }}
                    />
                    <IconText
                      icon="homework"
                      text="Presencial"
                      customStyles={{
                        fontSize: "var(--font-m)",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <IconText
                        icon={
                          professionalProfileWorkType[0]?.workType ===
                            "FACETOFACE"
                            ? "homework"
                            : "location"
                        }
                        text={
                          professionalProfileWorkType[0]?.workType ===
                            "FACETOFACE"
                            ? "Presencial"
                            : "Remoto"
                        }
                        customStyles={{
                          fontSize: "var(--font-m)",
                        }}
                      />
                      <div>
                        <a
                          href="#"
                          className={`${styles.shareProfile}`}
                          onClick={(e) => showModal(e)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08ZM18 4C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6C17.45 6 17 5.55 17 5C17 4.45 17.45 4 18 4ZM6 13C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11C6.55 11 7 11.45 7 12C7 12.55 6.55 13 6 13ZM18 20.02C17.45 20.02 17 19.57 17 19.02C17 18.47 17.45 18.02 18 18.02C18.55 18.02 19 18.47 19 19.02C19 19.57 18.55 20.02 18 20.02Z"
                              fill="var(--secondary-blue)"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </>
                )
              ) : (
                <></>
              )}
            </div>
          </>
        )}
        {!!avgReviews ? (
          <div className={styles.proReputationContainer}>
            <h6 className={styles.proReputation}>{reputation}</h6>
            <h6 className={styles.proReputationNumber}>
              {avgReviews.toFixed(2)}
              <b>({reviews.length})</b>
            </h6>
          </div>
        ) : (
          <span> </span>
        )}
        {!userMode && (
          <>
            <h5 className={styles.proProfession} style={{}}>
              {title}
            </h5>
            <h6 className={`${styles.proPrice} ${styles.averagePrice}`}>
              {referencePaymentType === "A convenir"
                ? "A convenir"
                : `$${referencePrice || ""}/${referencePaymentType || ""}`}
            </h6>
          </>
        )}
        {publicMode && user.id !== profileData?.id && !isProLogged ? (
          <div className={styles.publicButtons}>
            <Button
              onClickHandler={() => handleMessageButton()}
              buttonStyle="terciary"
              customStyles={{
                maxWidth: isMobile ? "100%" : "48%",
                maxHeight: "5.6rem",
                height: "5.6rem",
              }}
            >
              Enviar un mensaje
            </Button>
            <Button
              onClickHandler={() => handleCallButton()}
              customStyles={{
                maxWidth: isMobile ? "100%" : "48%",
                maxHeight: "5.6rem",
                height: "5.6rem",
              }}
            >
              Quiero que me llamen
            </Button>
          </div>
        ) : (
          <>
            <h6 className={styles.tel}>{phone}</h6>
            <h6 className={styles.email}>{email}</h6>
          </>
        )}
      </div>
    </ShadowBox>
  );
};

export default ProfileInfoCard;
