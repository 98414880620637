import { setHeaderMobileGoBack, setModal } from '@actions/generalActions'
import {
  getWorkDetail,
  getUserWorkDetail,
  setCurrentDetailWork,
  setUserWorkStatus,
  setJobReadStatus,
  getUserWorksRequests
} from '@actions/profileActions'
import GoBackButton from '@atoms/GoBackButton/GoBackButton'
import Text from '@atoms/Text/Text'
import useScreenDetect from '@hooks/useScreenDetect'
import WorkRequestFinished from '@molecules/WorkRequest/WorkRequestFinished'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import styles from './WorkDetailsContainer.module.scss'
const WorkDetailsContainer = () => {
  const { workDetails, profileData } = useSelector((state) => state.profile)
  
  const { isMobile } = useScreenDetect()
  const { id } = useParams()

  const dispatch = useDispatch()
  const history = useHistory()

  const handleGoToChat = (work) => {
    history.push(`/chat/${work.id}`)
  }

  const handleGoToSearch = (work) => {
    history.push(`/search/${work.categoryTreeBranch.categories[1].id}`)
  }

  const handleNeedHelp = () => {
    dispatch(setModal(true, 'contactZenPros'))
  }

  const handleFinishWork = (work) => {
    const successModalValues = {
      title: '¡Listo!',
      subtitle: 'Actualizaste el trabajo correctamente.',
      buttonText: 'Volver a mis trabajos',
      urlToRedirect: '/trabajos-usuario'
    }
    dispatch(
      setUserWorkStatus(
        work.id,
        'NOT_FINISHED',
        successModalValues,
        'Rechazado',
        'NOTIFY_PRO'
      )
    )
  }

  useEffect(() => {
    if (!workDetails) {
      profileData.isPro
        ? dispatch(getWorkDetail(id))
        : dispatch(getUserWorkDetail(id))
    }
    dispatch(setHeaderMobileGoBack(true))
    return () => {
      dispatch(setCurrentDetailWork(false))
      dispatch(setHeaderMobileGoBack(false))
    }
  }, [])

  // useEffect(() => {
  //   if(workDetails && workDetails.jobStatusNewRead !== 'READ'){
  //     // dispatch(setJobReadStatus(workDetails.id, 'READ'));
  //     // dispatch(getUserWorksRequests());
  //   }
  // }, [workDetails])

  return (
    <div className={styles.container}>
      {!isMobile && <GoBackButton />}
      <Text
        tag='p'
        size={isMobile ? 'l-1' : 'xl-1'}
        weight='bold'
        align='left'
        customStyles={{
          marginTop: isMobile ? '0' : '6rem',
          marginBottom: 'var(--spacing-medium)'
        }}
      >
        Detalle del trabajo
      </Text>
      {workDetails && (
        <WorkRequestFinished
          work={workDetails}
          opened
          isMobile={isMobile}
          fromWorkDetail
          goToChatAction={handleGoToChat}
          needHelpAction={handleNeedHelp}
          handleGoToSearch={handleGoToSearch}
          rejectWork={handleFinishWork}
        />
      )}
    </div>
  )
}
export default WorkDetailsContainer
