import React from 'react';
import styles from './process.module.scss';
import Text from '@atoms/Text/Text';
import Funciona_1 from '@assets/img/Como_funciona_1.jpg';
import Funciona_2 from '@assets/img/Como_funciona_2.jpg';
import Funciona_3 from '@assets/img/Como_funciona_3.jpg';
import Funciona_1_mobile from '@assets/img/Como_funciona_1_mobile.jpg';
import Funciona_2_mobile from '@assets/img/Como_funciona_2_mobile.jpg';
import Funciona_3_mobile from '@assets/img/Como_funciona_3_mobile.jpg';
import HowItem from './HowItem';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import useScreenDetect from '@hooks/useScreenDetect';

const Process = () => {
  const imagenes = [Funciona_1, Funciona_2, Funciona_3]
  const imagenesMobile = [
    Funciona_1_mobile,
    Funciona_2_mobile,
    Funciona_3_mobile
  ]

  const { isMobile } = useScreenDetect()

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    // speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }

  return (
    <>
      <div className={styles.containerProcess}>
        <Text
          tag='h1'
          weight='bold'
          size='xxl'
          align='center'
          customStyle={styles.title}
        >
          Cómo funciona?
        </Text>
        <div className={styles.fondo}>
          <Slider {...settings}>
            {!isMobile &&
              imagenes.map((item, i) => (
                <HowItem className={styles.carouselItem} img={item} key={i} />
              ))}
            {isMobile &&
              imagenesMobile.map((item, i) => (
                <HowItem className={styles.carouselItem} img={item} key={i} />
              ))}
          </Slider>
        </div>
      </div>
    </>
  )
};

function SampleNextArrow(props) {
  const { isMobile } = useScreenDetect()
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={
        !isMobile
          ? {
            ...style,
            display: 'block',
            background: 'transparent',
            marginRight: '30rem',
            backgroundColor: 'transparent',
            borderRadius: '10px',
            filter: 'drop-shadow( 0px 0px 7px rgba(0, 0, 0, .7))'
          }
          : {
            ...style,
            display: 'block',
            background: 'transparent',
            marginRight: '30px',
            backgroundColor: 'transparent',
            borderRadius: '10px',
            filter: 'drop-shadow( 0px 0px 7px rgba(0, 0, 0, .7))'
          }
      }
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { isMobile } = useScreenDetect()
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={
        !isMobile
          ? {
            ...style,
            display: 'block',
            background: 'transparent',
            marginLeft: '30rem',
            zIndex: '5',
            borderRadius: '10px',
            backgroundColor: 'transparent',
            filter: 'drop-shadow( 0px 0px 7px rgba(0, 0, 0, .7))'
          }
          : {
            ...style,
            display: 'block',
            background: 'transparent',
            marginLeft: '30px',
            backgroundColor: 'transparent',
            borderRadius: '10px',
            zIndex: '5',
            filter: 'drop-shadow( 0px 0px 7px rgba(0, 0, 0, .7))'
          }
      }
      onClick={onClick}
    />
  )
}

export default Process
