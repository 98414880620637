export const users = [
  {
    name: 'Roberto Salomon',
    image:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS7I7HyE4katbJHadjFl6NyQF9oFqqnBHbGGA&usqp=CAU',
    phone: '+54 1155223365'
  },
  {
    name: 'Carla Martinez',
    image:
      'https://socialtools.me/wp-content/uploads/2016/04/foto-de-perfil.jpg',
    phone: '+54 1144556633'
  },
  {
    name: 'Esteban Quito',
    image: 'https://cdnb.20m.es/sites/112/2019/04/cara22-620x620.jpg',
    phone: '+54 1122003355'
  },
  {
    name: 'Julieta Perez',
    image:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQORMmf_hP8nuiqNKA0hely-zOf02P9mxCWLQ&usqp=CAU',
    phone: '+54 1184202562'
  }
]

export const barriosGBANorte = [
  { name: 'Olivos', id: 49 },
  { name: 'San isidro', id: 50 },
  { name: 'Martinez', id: 51 },
  { name: 'La lucila', id: 52 }
]
export const barriosGBASur = [
  { name: 'Avellaneda', id: 53 },
  { name: 'Quilmes', id: 54 },
  { name: 'Lanús', id: 55 },
  { name: 'Florencio Varela', id: 56 }
]
export const barriosGBAOeste = [
  { name: 'Polvorines', id: 57 },
  { name: 'San miguel', id: 58 },
  { name: 'Bella vista', id: 59 },
  { name: 'Hurlingham', id: 60 }
]

export const barriosCABA = [
  { name: 'AGRONOMIA', id: 1 },
  { name: 'ALMAGRO', id: 2 },
  { name: 'BALVANERA', id: 3 },
  { name: 'BARRACAS', id: 4 },
  { name: 'BELGRANO', id: 5 },
  { name: 'BOCA', id: 6 },
  { name: 'BOEDO', id: 7 },
  { name: 'CABALLITO', id: 8 },
  { name: 'CHACARITA', id: 9 },
  { name: 'COGHLAN', id: 10 },
  { name: 'COLEGIALES', id: 11 },
  { name: 'CONSTITUCION', id: 12 },
  { name: 'FLORES', id: 13 },
  { name: 'FLORESTA', id: 14 },
  { name: 'LINIERS', id: 15 },
  { name: 'MATADEROS', id: 16 },
  { name: 'MONSERRAT', id: 17 },
  { name: 'MONTE CASTRO', id: 18 },
  { name: 'NUEVA POMPEYA', id: 19 },
  { name: 'NUÑEZ', id: 20 },
  { name: 'PALERMO', id: 21 },
  { name: 'PARQUE AVELLANEDA', id: 22 },
  { name: 'PARQUE CHACABUCO', id: 23 },
  { name: 'PARQUE CHAS', id: 24 },
  { name: 'PARQUE PATRICIOS', id: 25 },
  { name: 'PATERNAL', id: 26 },
  { name: 'PUERTO MADERO', id: 27 },
  { name: 'RECOLETA', id: 28 },
  { name: 'RETIRO', id: 29 },
  { name: 'SAAVEDRA', id: 30 },
  { name: 'SAN CRISTOBAL', id: 31 },
  { name: 'SAN NICOLAS', id: 32 },
  { name: 'SAN TELMO', id: 33 },
  { name: 'VELEZ SARSFIELD', id: 34 },
  { name: 'VERSALLES', id: 35 },
  { name: 'VILLA CRESPO', id: 36 },
  { name: 'VILLA DEL PARQUE', id: 37 },
  { name: 'VILLA DEVOTO', id: 38 },
  { name: 'VILLA GRAL. MITRE', id: 39 },
  { name: 'VILLA LUGANO', id: 40 },
  { name: 'VILLA LURO', id: 41 },
  { name: 'VILLA ORTUZAR', id: 42 },
  { name: 'VILLA PUEYRREDON', id: 43 },
  { name: 'VILLA REAL', id: 44 },
  { name: 'VILLA RIACHUELO', id: 45 },
  { name: 'VILLA SANTA RITA', id: 46 },
  { name: 'VILLA SOLDATI', id: 47 },
  { name: 'VILLA URQUIZA', id: 48 }
]

export const localidades = [
  { name: 'CABA, Argentina', id: 1, barrios: barriosCABA },
  { name: 'GBA NORTE, Argentina', id: 2, barrios: barriosGBANorte },
  { name: 'GBA SUR, Argentina', id: 3, barrios: barriosGBASur },
  { name: 'GBA OESTE, Argentina', id: 4, barrios: barriosGBAOeste }
  // { name: "Buenos Aires Interior, Argentina", id: 234, barrios: [] },
  // { name: "Catamarca, Argentina", id: 235, barrios: [] },
  // { name: "Chaco, Argentina", id: 236, barrios: [] },
  // { name: "Chubut, Argentina", id: 237, barrios: [] },
  // { name: "Córdoba, Argentina", id: 238, barrios: [] },
  // { name: "Corrientes, Argentina", id: 239, barrios: [] },
  // { name: "Entre Ríos, Argentina", id: 240, barrios: [] },
  // { name: "Formosa, Argentina", id: 241, barrios: [] },
  // { name: "Jujuy, Argentina", id: 242, barrios: [] },
  // { name: "La Pampa, Argentina", id: 243, barrios: [] },
  // { name: "La Rioja, Argentina", id: 244, barrios: [] },
  // { name: "Mendoza, Argentina", id: 245, barrios: [] },
  // { name: "Misiones, Argentina", id: 246, barrios: [] },
  // { name: "Neuquén, Argentina", id: 247, barrios: [] },
  // { name: "Río Negro, Argentina", id: 248, barrios: [] },
  // { name: "Salta, Argentina", id: 249, barrios: [] },
  // { name: "San Juan, Argentina", id: 250, barrios: [] },
  // { name: "San Luis, Argentina", id: 251, barrios: [] },
  // { name: "Santa Cruz, Argentina", id: 252, barrios: [] },
  // { name: "Santa Fe, Argentina", id: 253, barrios: [] },
  // { name: "Santiago del Estero, Argentina", id: 254, barrios: [] },
  // { name: "Tierra del Fuego, Argentina", id: 255, barrios: [] },
  // { name: "Tucumán, Argentina", id: 256, barrios: [] },
]

export const paymentMethods = [
  { name: 'Efectivo', id: 1, value: 'CASH' },
  { name: 'Tarjeta de Crédito', id: 2, value: 'CREDIT_CARD' },
  { name: 'Tarjeta de Débito', id: 3, value: 'DEBIT_CARD' },
  { name: 'Transferencia bancaria', id: 4, value: 'BANK_TRANSFER' },
  { name: 'Rapipago/Pago fácil', id: 5, value: 'PAYMENT_SUCURSAL' },
  { name: 'A convenir', id: 6, value: 'TO_BE_AGREED' },
  { name: 'MercadoPago', id: 7, value: 'MERCADO_PAGO' },
  { name: 'PayPal', id: 8, value: 'PAYPAL' }
]

export const paymentModalities = [
  { name: 'Por trabajo', id: 1 },
  { name: 'Por hora', id: 2 },
  { name: 'A convenir', id: 3 }
]

export const workModalities = [
  { name: 'Presencial', id: 1, idValue: 'FACETOFACE' },
  { name: 'Remoto', id: 2, idValue: 'REMOTE' }
]

export const horariosContacto = [
  { name: '8 a 10 horas', id: 1, idValue: 'EIGHT_TO_TEN' },
  { name: '10 a 12 horas', id: 2, idValue: 'TEN_TO_TWELVE' },
  { name: '12 a 14 horas', id: 3, idValue: 'TWELVE_TO_FOURTEEN' },
  { name: '14 a 16 horas', id: 4, idValue: 'FOURTEEN_TO_SIXTEEN' },
  { name: '16 a 18 horas', id: 5, idValue: 'SIXTEEN_TO_EIGHTEEN' },
  { name: 'Todo el día', id: 6, idValue: 'ALL_DAY' }
]
