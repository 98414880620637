import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Text from "@atoms/Text/Text";
import Lottie from "react-lottie";
import loaderData from "@assets/lotties/loading.json";
import BorderedItem from "@atoms/BorderedItem/BorderedItem";
import styles from "./Profile.module.scss";
import EditBox from "@molecules/EditBox/EditBox";
import ProfileWorkDone from "@molecules/ProfileWorkDone/ProfileWorkDone";
import {
  setModal,
  getBarrios,
  getProsCategories,
  getProfessionalData,
  postRequestContact,
  getPrivateProfile,
  getProfessionalExternalReviews,
  sendEmailToPro,
  setProfileMode,
} from "@actions/index";
import {
  getFirstLogin,
  footerOnboarding,
  setReadyText,
} from "@actions/generalActions";
import ProfileProgressMessage from "@molecules/ProfileProgressMessage/ProfileProgressMessage";
import ProfileInfoCard from "@molecules/ProfileInfoCard/ProfileInfoCard";
import ViewMoreText from "@atoms/ViewMoreText/ViewMoreText";
import ProfileValorations from "@molecules/ProfileValorations/ProfileValorations";
import Icon from "@atoms/Icon/Icon";
import ProfileServices from "@molecules/ProfileServices/ProfileServices";
import GoBackButton from "@atoms/GoBackButton/GoBackButton";
import useScreenDetect from "@hooks/useScreenDetect";
import { useHistory, useParams } from "react-router";
import { getPaymentMethodName, getPaymentPaymentIcon } from "./constants";
import RequestValorationMessage from "@molecules/RequestValorationMessage/RequestValorationMessage";
import ProfileZones from "@molecules/ProfileZones/ProfileZones";
import showNotification from "@services/NotificationService";
import { toast } from "react-toastify";
import CloseCross from "@assets/svg/close_cross.svg";
import FalseProfile from "@molecules/FalseProfile/FalseProfile";
import MetaDecorator from "../../../utils/functions/metaDecorator";
import { FacebookShareButton } from "react-share";
import { WhatsappShareButton } from "react-share";
import { EmailShareButton } from "react-share";
import { FacebookIcon } from "react-share";
import { WhatsappIcon } from "react-share";
import { EmailIcon } from "react-share";
import { AiOutlineCopy } from "react-icons/ai";
import { axiosGet } from "@config/api";

const SECTIONS = [
  // "shareProfile",
  "aboutme",
  "services",
  "paymentMethods",
  "zones",
  "workDone",
  "requestCommentaries",
  "commentaries",
];

const Profile = ({ publicMode }) => {
  const { id } = useParams();
  const {
    professionalProfile,
    profileData,
    privateProfile,
    proExternalReviews,
  } = useSelector((state) => state.profile);

  const { modal, modalType } = useSelector((state) => state["general"]);

  const { isAuth } = useSelector((state) => state.auth);

  const { loading } = useSelector((state) => state.general);

  const firstLogin = useSelector((state) => state["general"]["firstLogin"]);

  const dispatch = useDispatch();
  const history = useHistory();

  const profile = publicMode ? professionalProfile : privateProfile;
  const { isMobile } = useScreenDetect();

  const handleRequestReviews = () => {
    dispatch(setModal(true, "requestReviews"));
  };

  const [modalDestacado, setModalDestacado] = useState(false);

  useEffect(() => {
    if (isAuth && !publicMode && profileData.isPro) {
      axiosGet("/users/me").then((response) => {
        let res = response.data;
        setModalDestacado(res.outStanding);
      });
    }
  }, []);

  useEffect(() => {
    let meta = document.querySelectorAll("meta");
    meta[3].removeAttribute("name");
    meta[3].removeAttribute("content");
    meta[3].name = "Facu-Electricista";
    meta[3].content = "El mejor servicio";

    return () => {
      meta[3].removeAttribute("name");
      meta[3].removeAttribute("content");
      meta[3].name = "Zenpros";
      meta[3].content = "Zenpros | Trabajá haciendo lo que más te gusta";
    };
  }, []);

  useEffect(() => {
    if (publicMode) {
      dispatch(getProfessionalData(id));
    } else {
      dispatch(getPrivateProfile());
    }
  }, [id]);

  useEffect(() => {
    if (!publicMode && profile && proExternalReviews.length < 1) {
      dispatch(getProfessionalExternalReviews(profile.id));
    }
    // eslint-disable-next-line
  }, [profile]);

  useEffect(() => {
    const userHasEmailed = localStorage.getItem("email-info");
    if (!userHasEmailed) return;
    if (!profileData) return;
    const parsedData = JSON.parse(userHasEmailed);
    const { message, professionalId } = parsedData;
    dispatch(sendEmailToPro(message, professionalId, profileData.id));
  }, [profileData]);

  useEffect(() => {
    const hasUserContacted = localStorage.getItem("contact-info");
    if (!hasUserContacted) return;
    if (profileData) {
      const parsedData = JSON.parse(hasUserContacted);
      const dataToSend = {
        ...parsedData,
        clientId: Number(profileData.id),
        jobStatus: "PENDING",
      };
      dispatch(postRequestContact(dataToSend, profile.services[2].id));
    }
    // eslint-disable-next-line
  }, [profile]);

  useEffect(() => {
    if (
      publicMode &&
      profileData?.isPro &&
      profileData.id !== Number(id) &&
      localStorage.getItem("admin") !== "true"
    ) {
      const Msg = () => (
        <>
          <div className={styles.closeCrossContainer}>
            <img
              className={styles.closeCross}
              src={CloseCross}
              alt="Cerrar"
              style={{ width: "30px", height: "30px" }}
              onClick={() => toast.dismiss("proContactingPro")}
            />
          </div>
          <div
            onClick={() => {
              dispatch(setProfileMode("USER", history));
            }}
          >
            <Text
              tag="p"
              size="s"
              align="justify"
              customStyles={{
                fontFamily: "Montserrat",
              }}
            >
              Si querés contratar a otro profesional, cambiá al modo{" "}
              <b style={{ color: "var(--color-item)" }}>Contratá</b> clickeando
              el siguiente botón, ubicado en el menú:
            </Text>

            <FalseProfile />

            <Text tag="p" size="s" align="justify">
              O haciendo click acá.
            </Text>
          </div>
        </>
      );
      showNotification(<Msg />, "proContactingPro", "info", 0);
    }
  }, [profileData?.isPro, isMobile]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getBarrios());
    dispatch(getProsCategories());
    // eslint-disable-next-line
  }, []);

  let showToast = JSON.parse(localStorage.getItem("toast"));

  useEffect(() => {
    if (modalType !== "firstLogin") {
      if (profileData?.isPro && !publicMode) {
        if (profile && !profile.user.phone) {
          const Msg = () => (
            <Text
              tag="p"
              size="s"
              align="justify"
              customStyles={{
                fontFamily: "Montserrat",
              }}
            >
              Completá tu{" "}
              <b style={{ color: "var(--secondary-blue)" }}>
                número de teléfono
              </b>{" "}
              en el encabezado, así los clientes pueden contactarte y enviarte
              solicitudes de servicios.
            </Text>
          );
          if (
            !firstLogin &&
            modalType !== "generalInfo" &&
            modalType !== "successModal"
          ) {
            showNotification(<Msg />, "profilePhoneIncomplete", "warning");
          }
        } else {
          toast.dismiss("profilePhoneIncomplete");
        }

        if (profile && profile.services && profile.services.length <= 2) {
          const Msg = () => (
            <Text
              tag="p"
              size="s"
              align="justify"
              customStyles={{
                fontFamily: "Montserrat",
              }}
            >
              Seleccioná los servicios que vas a ofrecer en la sección{" "}
              <b style={{ color: "var(--secondary-blue)" }}>
                Servicios que ofrezco
              </b>
              , así los clientes pueden encontrarte en nuestra galería de
              profesionales.
            </Text>
          );
          if (
            !firstLogin &&
            modalType !== "generalInfo" &&
            modalType !== "successModal"
          ) {
            showNotification(<Msg />, "profileServicesIncomplete", "warning");
          }
        } else {
          toast.dismiss("profileServicesIncomplete");
        }
      }
    }
  }, [profile, profileData?.isPro, publicMode, modalType, modal, firstLogin]);

  const copy = (e, value) => {
    e.preventDefault();
    let copy = document.getElementById("copiar");
    copy.style.display = "block";

    var aux = document.createElement("input");
    aux.setAttribute("value", value);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");

    setTimeout(() => {
      copy.style.display = "none";
    }, 1500);

    document.body.removeChild(aux);
  };

  if (!profile) return <div />;

  const handleEditAction = (value) => {
    dispatch(setModal(true, value));
  };

  const modifyServices = (services) => {
    const auxServices = [...services];
    const aux2 = auxServices.slice(2);
    let aux3 = [];
    aux2.map((auxService) => {
      aux3 = [
        ...aux3,
        {
          name: auxService.name,
          id: auxService.id,
          categories: [
            ...auxService.categories.map((aux) => {
              return { ...aux, ...aux.category };
            }),
          ],
        },
      ];
    });
    return aux3;
  };

  const contentData = (value) => {
    let contentValues = {};
    switch (value) {
      case "shareProfile":
        contentValues = {
          title: "Compartir perfil",
          component: (
            <div style={{ marginBottom: "0.5rem" }}>
              <section className={styles.shareButtons}>
                <div>
                  <FacebookShareButton
                    url="https://development.zenpros.work/perfil/550"
                    quote={"Visita nuestro sitio https://zenpros.work/"}
                    hashtag="#ZenprosWork"
                  >
                    <FacebookIcon
                      logoFillColor="white"
                      round={true}
                    ></FacebookIcon>
                  </FacebookShareButton>
                </div>
                <div>
                  <WhatsappShareButton
                    url="https://development.zenpros.work"
                    title={"Visita nuestro sitio"}
                  >
                    <WhatsappIcon
                      logoFillColor="white"
                      round={true}
                    ></WhatsappIcon>
                  </WhatsappShareButton>
                </div>
                <div>
                  <EmailShareButton
                    url="https://development.zenpros.work"
                    title={"Visita nuestro sitio"}
                  >
                    <EmailIcon logoFillColor="white" round={true}></EmailIcon>
                  </EmailShareButton>
                </div>
                <div>
                  <AiOutlineCopy
                    className={`${styles.iconCopy}`}
                    onClick={(e) => copy(e, "HOLA")}
                  />
                  <span
                    id="copiar"
                    className={`${styles.copySpan}`}
                    style={{ display: "none" }}
                  >
                    copiado!
                  </span>
                </div>
              </section>
            </div>
          ),
          editHandlerValue: "shareProfile",
          editLabel: "",
          action: handleEditAction,
          dontShow: publicMode && !profile.description,
        };
        break;
      case "aboutme":
        contentValues = {
          title: "Más sobre mí",
          component: (
            <div style={{ marginBottom: "0.5rem" }}>
              <ViewMoreText
                text={
                  profile.description
                    ? profile.description
                    : "Ingresá una breve descripción sobre vos."
                }
                isMobile={isMobile}
              />
            </div>
          ),
          editHandlerValue: "aboutme",
          editLabel: "Editar información sobre mí",
          action: handleEditAction,
          dontShow: publicMode && !profile.description,
        };
        break;
      case "services":
        contentValues = {
          title: "Servicios que ofrezco",
          component: (
            <div style={{ minHeight: "15px" }}>
              <ProfileServices
                services={modifyServices(profile.services)}
                isMobile={isMobile}
              />
            </div>
          ),
          editHandlerValue: "services",
          editLabel: "Añadir nuevo servicio",
          action: handleEditAction,
        };
        break;
      case "paymentMethods":
        contentValues = {
          title: "Medios de pago",
          component: (
            <div className={styles.serviceList}>
              {profile.professionalProfilePaymentMethod.length !== 0 ? (
                profile.professionalProfilePaymentMethod?.map(
                  (method, index) => {
                    const methodName = getPaymentMethodName(
                      method.paymentMethod
                    );
                    const iconType = getPaymentPaymentIcon(
                      method.paymentMethod
                    );
                    return (
                      <BorderedItem key={index}>
                        <Icon icon={iconType} style={{ marginRight: "1rem" }} />
                        <Text tag="p" size="m">
                          {methodName}
                        </Text>
                      </BorderedItem>
                    );
                  }
                )
              ) : window.location.pathname !== "/perfil-profesional" ? (
                <div>
                  <Text tag="p" size="m" align="left">
                    Este profesional aún no especificó que medios de pago
                    acepta.
                  </Text>
                </div>
              ) : (
                ""
              )}
            </div>
          ),
          editHandlerValue: "paymentMethods",
          editLabel: "Editar medios de pago",
          action: handleEditAction,
        };
        break;
      case "zones":
        contentValues = {
          title: "Zona de cobertura",
          component: (
            <div className={styles.serviceList}>
              <ProfileZones
                professionalNeighborhood={profile.neighborhoodsTree}
              />
            </div>
          ),
          editHandlerValue: "zones",
          editLabel: "Editar zonas de cobertura",
          action: handleEditAction,
          dontShow: !!(
            profile.professionalProfileWorkType &&
            profile.professionalProfileWorkType.length < 2 &&
            profile.professionalProfileWorkType[0]?.workType === "REMOTE"
          ),
        };
        break;
      case "workDone":
        contentValues = {
          title: "Trabajos realizados",
          component: (
            <ProfileWorkDone
              works={profile.professionalProfileFile}
              profileData={profile}
              isMobile={isMobile}
            />
          ),
          editHandlerValue: "workDone",
          editLabel: "Añadir nuevo trabajo",
          action: handleEditAction,
          dontShow: publicMode && profile.professionalProfileFile.length < 1,
        };
        break;
      case "requestCommentaries":
        contentValues = {
          dontShow: publicMode,
          ownComponent: true,
          component: (
            <div key="uniqueKey" className={styles.section}>
              <RequestValorationMessage
                requestValorations={handleRequestReviews}
              />
            </div>
          ),
        };
        break;
      case "commentaries":
        contentValues = {
          title: "Valoraciones",
          component: (
            <ProfileValorations
              comments={profile.reviews}
              professionalProfile={profile}
              isMobile={isMobile}
            />
          ),
          editHandlerValue: "externalReviews",
          editLabel: "Editar valoraciones",
          action: proExternalReviews.length > 0 && handleEditAction,
        };
        break;

      default:
        break;
    }
    return contentValues;
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const showModal = (e) => {
    e.preventDefault();
    dispatch(setModal(true, "shareProfile"));
  };

  return loading ? (
    <Lottie
      options={{ animationData: loaderData, ...defaultOptions }}
      style={{
        marginTop: "0px",
        marginBottom: "1.6rem",
        paddingTop: "0px",
        paddinBottom: "0px",
      }}
      height={150}
      width={150}
    />
  ) : (
    <>
      <div className={`${styles.headerCard}`}>
        {publicMode && !isMobile && <GoBackButton />}
        {!isMobile && window.location.pathname.includes("/perfil/") && (
          <div>
            <a
              href="#"
              className={`${styles.shareProfile}`}
              onClick={(e) => showModal(e)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08ZM18 4C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6C17.45 6 17 5.55 17 5C17 4.45 17.45 4 18 4ZM6 13C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11C6.55 11 7 11.45 7 12C7 12.55 6.55 13 6 13ZM18 20.02C17.45 20.02 17 19.57 17 19.02C17 18.47 17.45 18.02 18 18.02C18.55 18.02 19 18.47 19 19.02C19 19.57 18.55 20.02 18 20.02Z"
                  fill="var(--secondary-blue)"
                />
              </svg>
            </a>
          </div>
        )}
      </div>
      <div className={styles.profileContainer}>
        <div className={styles.section}>
          <ProfileInfoCard
            handleEditAction={handleEditAction}
            proProfileData={profile}
            isMobile={isMobile}
            publicMode={publicMode}
            isProLogged={profileData?.isPro}
          />
        </div>
        {!publicMode && modalDestacado && (
          <div className={styles.section}>
            <ProfileProgressMessage isMobile={isMobile} />
          </div>
        )}
        {SECTIONS.map((section, index) => {
          const bodyData = contentData(section);
          if (bodyData.dontShow) {
            return <React.Fragment key={index} />;
          }
          if (bodyData.ownComponent) return bodyData.component;
          return (
            <div key={index} className={styles.section}>
              <EditBox
                title={bodyData.title}
                // share={bodyData.title === "Compartir perfil" ? true : false}
                enableAction={publicMode ? null : bodyData.action}
                type={bodyData.editHandlerValue}
                editLabel={bodyData.editLabel}
                isMobile={isMobile}
              >
                {bodyData.component}
              </EditBox>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Profile;
