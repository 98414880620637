import React, { useState, useEffect } from "react";
import styles from "./ProfileEditModal.module.scss";
import Text from "@atoms/Text/Text";
import CurrencyInput from "react-currency-input-field";
import Select from "@atoms/Select/Select";
import { paymentModalities, workModalities } from "@utils/mock-data";
import CheckboxGroup from "@molecules/CheckboxGroup/CheckboxGroup";
import Button from "@atoms/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { editProfileData } from "@actions/profileActions";
import AlertMessage from "@atoms/AlertMessage/AlertMessage";
import { toast } from "react-toastify";

const ProfileEditInfoCard = ({ profileData, isMobile, handleSubmit }) => {
  const { barriosLocalidades } = useSelector((state) => state.general);

  // useEffect(() => {
  //   toast.dismiss("profileServicesIncomplete");
  //   toast.dismiss("profilePhoneIncomplete");
  //   localStorage.setItem("toast", false);
  // }, [])
  
  // generalInfo
  const [selectedValues, setSelectedValues] = useState({
    address: profileData.address,
    phone: profileData.user.phone,
    referencePaymentType: paymentModalities.find(
      (modality) => modality.name === profileData.referencePaymentType
    ),
    workType:
      profileData.professionalProfileWorkType.length < 1
        ? []
        : profileData.professionalProfileWorkType.length > 1
        ? workModalities
        : [
            workModalities.find(
              (modality) =>
                profileData.professionalProfileWorkType[0].workType ===
                modality.idValue
            ),
          ],
    fantasy: profileData.fantasy,
  });

  const dispatch = useDispatch();

  const handleCheckItem = (item) => {
    let included = false;
    selectedValues.workType.map((service) => {
      if (service.id === item.id) return (included = true);
      return false;
    });
    if (included) {
      if (selectedValues.workType.length === 1) return;
      return handleSelectValue("workType", [
        ...selectedValues.workType.filter((i) => i.id !== item.id),
      ]);
    }
    return handleSelectValue("workType", [...selectedValues.workType, item]);
  };

  const handleSelectValue = (key, value) => {
    if (key.includes("referencePaymentType") && value === "A convenir") {
      return setSelectedValues({
        ...selectedValues,
        referencePrice: 1,
        [key]: value,
      });
    }
    if (key.includes("referencePrice")) {
      if (value === 0) {
        return setSelectedValues({
          ...selectedValues,
          [key]: 0,
        });
      }
      if (!value) {
        return;
      }
      return setSelectedValues({
        ...selectedValues,
        [key]: value,
      });
    }
    setSelectedValues({
      ...selectedValues,
      [key]: value,
    });
  };

  const handleSave = () => {
    const newPhone = selectedValues.phone;
    if (newPhone !== profileData.user.phone) {
      dispatch(editProfileData({ phone: newPhone }));
    }
    delete selectedValues.phone;
    const objectValue = {
      ...selectedValues,
      referencePaymentType: selectedValues.referencePaymentType?.name
        ? selectedValues.referencePaymentType.name
        : selectedValues.referencePaymentType,
      workType:
        selectedValues.workType.length > 1
          ? "BOTH"
          : selectedValues.workType[0].idValue,
    };
    handleSubmit(objectValue);
  };

  return (
    <>
      <div className={styles.infoContainer}>
        <div className={styles.row} style={{ zIndex: 9 }}>
          <Text tag="p" size="m">
            ¿Cuál es tu precio de referencia?
          </Text>
          <div className={styles.currency}>
            <div
              className={`${selectedValues.referencePaymentType &&
                (selectedValues.referencePaymentType === "A convenir" ||
                  selectedValues.referencePaymentType.name === "A convenir") &&
                styles.inputcurrencyhide}`}
            >
              <CurrencyInput
                style={{
                  fontSize: "var(--font-m)",
                  backgroundColor: "rgba(222, 222, 222, 0.2)",
                  color: "var(--main-blue)",
                  WebkitAppearance: "none",
                  padding: "0.2rem 1rem",
                  borderRadius: "4px",
                  border: "unset",
                  fontWeight: "var(--w-regular)",
                  height: isMobile ? "3rem" : "5.6rem",
                  position: "relative",
                  fontFamily: "Open Sans, sans-serif",
                  outline: "none",
                  pointerEvents: "auto",
                  opacity: "1",
                  maxWidth: isMobile ? "12rem" : "15rem",
                  margin: isMobile ? "0 0.8rem 0 0" : "0 0.8rem 0 auto",
                }}
                prefix="$"
                name="referencePrice"
                defaultValue={profileData.referencePrice}
                value={selectedValues.referencePrice}
                onChange={(value) =>
                  handleSelectValue(
                    "referencePrice",
                    Number(value.target.value.replaceAll(".", "").split("$")[1])
                  )
                }
                placeholder="$5.000"
                // ref={props.register}
                groupSeparator="."
                //   decimalSeparator=','
              />
            </div>
            <Select
              placeholder="Modalidad"
              options={paymentModalities}
              defaultSelected={selectedValues.referencePaymentType}
              handleSelectAction={(value) =>
                handleSelectValue("referencePaymentType", value.name)
              }
              customStyles={{
                marginRight: "0.8rem",
                height: isMobile ? "3rem" : "5.6rem",
              }}
            />
          </div>
        </div>
        <AlertMessage
          message="Este valor aparecerá en tu perfil como información de referencia para el usuario."
          icon="alert"
          customStyles={{ marginTop: "0", width: "100%" }}
        />
        <div
          className={styles.column}
          style={{ zIndex: 6, marginBottom: "var(--spacing-large)" }}
        >
          <Text
            tag="p"
            size="m"
            align="left"
            customStyles={{
              marginBottom: "0.5rem",
            }}
          >
            Número de teléfono
          </Text>
          <div className={styles.phoneNumber}>
            <Text tag="p" size="l" customStyles={{ margin: "0 0.8rem" }}>
              <span role="img" aria-label="Bandera Argentina">
                &#x1F1E6;&#x1F1F7;
              </span>
            </Text>
            <Text
              tag="p"
              size="m"
              customStyles={{
                opacity: "0.4",
                marginRight: "var(--spacing-small)",
              }}
            >
              +54
            </Text>
            <input
              type="number"
              name="phone"
              className={styles.input}
              value={selectedValues.phone ?? ""}
              onChange={(e) => handleSelectValue("phone", e.target.value)}
            />
          </div>
        </div>
        <div className={styles.column} style={{ zIndex: 7 }}>
          <Text
            tag="p"
            size="m"
            align="left"
            customStyles={{
              marginBottom: "0.5rem",
            }}
          >
            Nombre de Fantasía
          </Text>
          <input
            name="fantasy"
            className={styles.input}
            value={selectedValues.fantasy ?? ""}
            onChange={(e) => handleSelectValue("fantasy", e.target.value)}
          />
        </div>
        <div
          className={styles.column}
          style={{ zIndex: 6, marginBottom: "var(--spacing-small)" }}
        >
          <Text tag="p" size="m" align="left">
            Modalidad de trabajo
          </Text>
          <CheckboxGroup
            options={workModalities}
            selectedItems={selectedValues.workType}
            selectItemAction={handleCheckItem}
          />
        </div>
        <div
          className={styles.column}
          style={{ zIndex: 8, marginTop: "var(--spacing-small)" }}
        >
          <Text tag="p" size="m" align="left">
            Zona de residencia
          </Text>
          <Select
            placeholder="Seleccioná la zona"
            options={barriosLocalidades}
            defaultSelected={barriosLocalidades.find(
              (localidad) => localidad.name === profileData.residenceArea
            )}
            handleSelectAction={(value) =>
              handleSelectValue("residenceArea", value.name)
            }
            customStyles={{
              marginTop: "0.8rem",
              height: "5.6rem",
              width: "100%",
            }}
          />
        </div>
        <div className={styles.column} style={{ zIndex: 7 }}>
          <Text
            tag="p"
            size="m"
            align="left"
            customStyles={{
              marginBottom: "0.5rem",
            }}
          >
            Dirección fiscal
          </Text>
          <input
            name="direccion"
            className={styles.input}
            value={selectedValues.address ?? ""}
            onChange={(e) => handleSelectValue("address", e.target.value)}
          />
        </div>
      </div>
      <div className={styles.modalFooter}>
        <Button
          customStyles={{ maxWidth: isMobile ? "100%" : "11.5rem" }}
          onClickHandler={() => handleSave()}
        >
          Guardar
        </Button>
      </div>
    </>
  );
};

export default ProfileEditInfoCard;
