import React from "react";
import { ReactComponent as Logo } from "@assets/svg/footerMobile.svg";
import { ReactComponent as LogoColor } from "@assets/svg/logo.svg";
import Linkedin from "@assets/svg/lkdCircle.svg";
import Instagram from "@assets/svg/igCircle.svg";
import Facebook from "@assets/svg/fbCircle.svg";
import { ReactComponent as Paisanos } from "@assets/svg/logo_paisanos.svg";
import styles from "./footerMobile.module.scss";
import { useSelector } from "react-redux";
import {
  getFirstLogin,
  footerOnboarding,
  setReadyText,
} from "@actions/generalActions";
import { axiosGet, axiosPost, axiosPut } from "@config/api";
import { setModal } from "@actions/";
import { useDispatch } from "react-redux";
import useScreenDetect from "@hooks/useScreenDetect";
import DimeMas from "./DimeMas";

const Footer = () => {
  const { noDistractionsMode } = useSelector((state) => state.general);
  const actualYear = new Date().getFullYear();
  const { isMobile } = useScreenDetect();

  const dispatch = useDispatch();
  let textReady = useSelector((state) => state["general"]["textReady"]);

  const linkConfig = { target: "_blank", rel: "noopener noreferrer" };

  const showModal = async (e) => {
    e.preventDefault();
    dispatch(footerOnboarding(true));
    localStorage.setItem("oneTime", true);
    // TODO: Se repite a proposito 2 veces la funcion "setReadyText"
    dispatch(setReadyText(!textReady));
    dispatch(setReadyText(!textReady));
    await axiosPut(`/users/restartFirstLogin`, {});
    await axiosGet("/users/me").then((response) => {
      dispatch(getFirstLogin(response.data.firstLogin));
    });
    dispatch(setModal(true, "firstLogin"));
  };

  return (
    <footer
      className={`${styles.container} ${noDistractionsMode &&
        styles.noDistractionsMode}`}
    >
      <section className={styles.firstSection}>
        <div>
          <LogoColor className={`${styles.logo}`} />
        </div>
        <div className={styles.redes}>
          <a
            href="https://www.linkedin.com/company/zenpros-work"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Linkedin}
              alt="Linkedin"
              style={{ width: "30px", height: "30px" }}
            />
          </a>
          <a
            href="https://www.facebook.com/Zenpros-107952351385216"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Facebook}
              alt="Facebook"
              style={{ width: "30px", height: "30px", marginLeft: "1rem" }}
            />
          </a>
          <a
            href="https://www.instagram.com/zenproswork/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Instagram}
              alt="Instagram"
              style={{ width: "30px", height: "30px", marginLeft: "1rem" }}
            />
          </a>
        </div>
      </section>
      <section className={styles.secondSection}>
        <div className={styles.column}>
          <ul className={`${styles.list} ${styles.opened}`}>
            <li>
              <a
                className={styles.footerLink}
                href="http://conditions.zenpros.work/Terminos-Condiciones-Zenpros.pdf"
                {...linkConfig}
              >
                <h3>Términos y Condiciones</h3>
              </a>
            </li>
            <li>
              <a
                className={styles.footerLink}
                href="http://conditions.zenpros.work/assets/Politica-de-Privacidad-Zenpros.pdf"
                {...linkConfig}
              >
                <h3>Privacidad</h3>
              </a>
            </li>
          </ul>
        </div>
        <div className={`${styles.column}`}>
          <h2 id={styles.titlePros}>PROFESIONALES</h2>
          <ul className={`${styles.list}`}>
            <li>
              <a
                className={styles.footerLink}
                href="https://www.notion.so/Centro-de-ayuda-para-Profesionales-3464103393c5436097362dd93d9ddcde"
                {...linkConfig}
              >
                <h3>Centro de ayuda para Profesionales</h3>
              </a>
            </li>
            <li>
              <a
                className={styles.footerLink}
                href="https://www.notion.so/C-mo-funciona-Zenpros-ddaf7b894385459a83e46fcfffae54f0"
                {...linkConfig}
              >
                <h3>¿Cómo funciona Zenpros?</h3>
              </a>
            </li>
            <li>
              <a
                className={styles.footerLink}
                href="https://www.notion.so/Registrate-f02e26a7fb2e451ab71fe64aa4de9bff"
                {...linkConfig}
              >
                <h3>¿Cómo me registro?</h3>
              </a>
            </li>
            <li>
              <a
                className={styles.footerLink}
                href="https://www.notion.so/Preguntas-Frecuentes-16edf8ff244141d6b5845db7d1f50d0f"
                {...linkConfig}
              >
                <h3>Preguntas frecuentes</h3>
              </a>
            </li>
            {localStorage.getItem("isPro") === "true" && (
              <li>
                <a
                  className={styles.footerLink}
                  href="#"
                  onClick={(e) => showModal(e)}
                  {...linkConfig}
                >
                  <h3>Onboarding</h3>
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className={`${styles.column}`}>
          <h2 id={styles.titleUsers}>CLIENTES</h2>
          <ul className={`${styles.list}`}>
            <li>
              <a
                className={styles.footerLink}
                href="https://www.notion.so/Centro-de-ayuda-para-Usuarios-b3691eb5da0f4042b64ddc114d2683e7"
                {...linkConfig}
              >
                <h3>Centro de ayuda para Clientes</h3>
              </a>
            </li>
            <li>
              <a
                className={styles.footerLink}
                href="https://www.notion.so/C-mo-contacto-a-un-profesional-ad4e81c1f92b45998fc03708e4da1202"
                {...linkConfig}
              >
                <h3>¿Cómo contacto a un profesional?</h3>
              </a>
            </li>
            <li>
              <a
                className={styles.footerLink}
                href="https://www.notion.so/Registrarse-como-usuario-ea7aef8ab4304170ba1f1fea51b460e2"
                {...linkConfig}
              >
                <h3>¿Cómo me registro?</h3>
              </a>
            </li>
            <li>
              <a
                className={styles.footerLink}
                href="https://www.notion.so/Preguntas-Frecuentes-6c6773d5626842fea7cecbc87803a377"
                {...linkConfig}
              >
                <h3>Preguntas frecuentes</h3>
              </a>
            </li>
          </ul>
        </div>
        <div className={`${styles.column} ${styles.columnDimeMas}`}>
          <DimeMas />
        </div>
      </section>
      <section className={styles.thirdSection}>
        <div className={styles.bottomContent}>
          <h3>© {actualYear} - Todos los derechos reservados a Zenpros</h3>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
