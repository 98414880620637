import RegisterSteps from '@organism/RegisterSteps/RegisterSteps'
import { getFromSwitchFromLS } from '@utils/tokenService'
import React from 'react'
import styles from './styles.module.scss'

const ClientRegisterSteps = () => {
  return (
    <div className={styles.registerSteps}>
      <h1 id='register-title'>Creá tu perfil {getFromSwitchFromLS() && 'profesional'}</h1>
      <RegisterSteps />
    </div>
  )
}

export default ClientRegisterSteps
