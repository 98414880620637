import React from 'react'
import RecoverPassword from '@organism/RecoverPassword/RecoverPassword'
import styles from './styles.module.scss'

const RecoverPasswordPage = () => {
  return (
    <>
      <main className={styles.recoverPasswordContainer}>
        <h1 className={styles.recoverPasswordTitle}>Recuperá tu contraseña</h1>
        <RecoverPassword />
      </main>
    </>
  )
}

export default RecoverPasswordPage
