import React from 'react'
import Input from '@atoms/TextInput/TextInput'
import Label from '@atoms/Label/Label'
import Text from '@atoms/Text/Text'
import styles from './InputTextBox.module.scss'

const InputTextBox = ({
  label,
  name,
  placeholder,
  inputRef,
  type,
  error,
  customStyles,
  phonenumber,
  disabled,
  autoComplete,
  autoFocus = false
}) => {
  const isValid = !error
  return (
    <div
      className={`${styles.inputTextBox} ${disabled && styles.disabled}`}
      style={customStyles}
    >
      <Label htmlFor={name}>{label}</Label>
      <div className={styles.row}>
        {phonenumber && (
          <>
            <Text tag='p' size='l' customStyles={{ margin: '0 0.8rem' }}>
              <span role='img' aria-label='Bandera Argentina'>
                &#x1F1E6;&#x1F1F7;
              </span>
            </Text>
            <Text
              tag='p'
              size='m'
              customStyles={{
                opacity: '0.4',
                marginRight: 'var(--spacing-small)'
              }}
            >
              +54
            </Text>
          </>
        )}
        <Input
          name={name}
          type={type}
          placeholder={placeholder}
          isValid={isValid}
          inputRef={inputRef}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
        />
      </div>
      {error && (
        <Text
          data-testid={`error-${name}`}
          tag='span'
          color='error'
          size='12'
        >
          {error.message}
        </Text>
      )}
    </div>
  )
}

export default InputTextBox
