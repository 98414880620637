import Button from '@atoms/Button/Button'
import Text from '@atoms/Text/Text'
import Input from '@atoms/Input/Input'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as ContactZenprosIcon } from '@assets/svg/finished-work.svg'
import styles from './WorksModals.module.scss'
import { requestHelpZenPros } from '@actions/generalActions'
const WorkContactZenPros = () => {
  const [contactMessage, setContactMessage] = useState('')

  const dispatch = useDispatch()

  const handleContactZenPros = () => {
    if (!contactMessage) return
    dispatch(requestHelpZenPros(contactMessage))
  }

  const handleContactMessageChange = (value) => {
    setContactMessage(value)
  }

  return (
    <div className={styles.workModal}>
      <ContactZenprosIcon className={styles.workIcon} />
      <Text
        tag='p'
        size='l'
        weight='bold'
        customStyles={{ marginBottom: 'var(--spacing-small)' }}
      >
        ¿En qué te podemos ayudar?
      </Text>
      <Input
        className={styles.contactInput}
        placeholder='Contanos brevemente sobre tu consulta'
        value={contactMessage}
        onChangeHandler={(e) => handleContactMessageChange(e.target.value)}
      />

      <div className={styles.workButtons}>
        <Button
          onClickHandler={() => handleContactZenPros()}
          customStyles={{
            maxWidth: 'max-content',
            margin: 'auto'
          }}
        >
          Enviar consulta
        </Button>
      </div>
    </div>
  )
}
export default WorkContactZenPros
