import useScreenDetect from '@hooks/useScreenDetect'
import React, { useEffect } from 'react'
import styles from './ShadowBox.module.scss'

const ShadowBox = ({
  width,
  height,
  children,
  customStyles,
  noShadow
}) => {
  
  return (
    <div
      className={`${styles.boxShadow} ${noShadow && styles.noShadow}`}
      style={{ maxWidth: width || '64rem', height: height, ...customStyles }}
    >
      {children}
    </div>
  )
}

export default ShadowBox
