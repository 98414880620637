import Text from '@atoms/Text/Text'
import ValorationComment from '@molecules/ValorationComment/ValorationComment'
import React, { useState } from 'react'
import styles from './ProfileValorations.module.scss'
import { ReactComponent as ChevronIcon } from '@assets/svg/down-chevron.svg'
import { ReactComponent as ValoracionesEmpty } from '@assets/svg/valorations-empty.svg'
import { getReputationFromRate } from '@molecules/ProfileInfoCard/constants'

const ProfileValorations = ({ comments, professionalProfile, isMobile }) => {
  const [viewAllComments, setViewAllComments] = useState(false)
  const handleViewAll = () => {
    setViewAllComments(!viewAllComments)
  }
  const { avgReviews } = professionalProfile || ''
  const reputation = (avgReviews && getReputationFromRate(avgReviews)) || null
  return (
    <div className={styles.container}>
      {!!reputation && (
        <div className={styles.proReputationContainer}>
          <h6 className={styles.proReputation}>{reputation}</h6>
          <h6 className={styles.proReputationNumber}>
            {avgReviews.toFixed(2)}
            <b>({comments.length})</b>
          </h6>
        </div>
      )}
      {comments && comments.length > 0
        ? (
            comments.map((comment, index) => {
              if ((index > 1 && !viewAllComments) || !comment.show) return false
              return (
                <ValorationComment
                  key={index}
                  comment={comment}
                  isMobile={isMobile}
                />
              )
            })
          )
        : (
          <div className={styles.emptyState}>
            <ValoracionesEmpty />
            <Text tag='p' size='xm'>
              No hay valoraciones para mostrar
            </Text>
          </div>
          )}
      {comments && comments.length > 0 && (
        <div
          onClick={() => handleViewAll()}
          className={styles.viewAllContainer}
        >
          <p className={styles.viewAll}>
            {`${!viewAllComments ? 'Ver' : 'Ocultar'}`} todas las valoraciones
          </p>
          <ChevronIcon
            className={`${styles.icon} ${viewAllComments && styles.open}`}
          />
        </div>
      )}
    </div>
  )
}

export default ProfileValorations
