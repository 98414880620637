import WorkDetailsContainer from '@organism/WorkDetailsContainer/WorkDetailsContainer'
import React from 'react'
import styles from './styles.module.scss'
const WorkDetails = () => {
  return (
    <div className={styles.container}>
      <WorkDetailsContainer />
    </div>
  )
}
export default WorkDetails
