import React, { useEffect, useRef } from "react";
import RadioButtonGroup from "@molecules/RadioButtonGroup/RadioButtonGroup";
import styles from "./ContactPro.module.scss";
import Spinner from "@atoms/Spinner";
import Calendar from "@molecules/Calendar/Calendar";
import TextArea from "@atoms/TextArea/TextArea";
import Button from "@atoms/Button/Button";
import { useForm } from "react-hook-form";
import Form from "@atoms/Form/Form";
import CheckboxGroup from "@molecules/CheckboxGroup/CheckboxGroup";
import dayjs from "dayjs";

const ContactPro = ({
  proServices,
  steps,
  handleSaveForm,
  setMessage,
  handlePreviousStep,
  handleNextStep,
  isMobile,
  modalType,
  horariosContacto,
  isLoading,
  messageFromParent,
}) => {
  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    errors,
  } = useForm({
    defaultValues: {
      service: {},
      subService: {},
      message: "",
      // date: new Date(),
      date: dayjs(),
      time: [],
    },
  });
  const values = watch(["service", "subService", "time"]);
  const getvalues = getValues();
  // const valuesRef = useRef(null);

  const fromMessage =
    modalType === "messageProRequest" ||
    modalType === "messageBulk" ||
    modalType === "messageProWithoutServices" ||
    "callProRequest";

  const handleChangeDate = (value) => {
    setValue("date", value);
  };

  const handleRadioSelect = (key, value) => {
    setValue(key, value);
  };

  useEffect(() => {
    let localValue = JSON.parse(localStorage.getItem("selectItems"));
    if (localValue && localValue.length === 1) {
      let item = proServices.filter(
        (service) => service.id === localValue[0].id
      );

      localStorage.setItem("optionsTwo", JSON.stringify(item));
      setTimeout(() => {
        setValue("service", item[0]);
      }, 50);

      if (modalType !== "messageBulk") handleNextStep();
    }
  }, []);

  useEffect(() => {
    register({ name: "service" });
    register({ name: "subService" });
    register({ name: "date" });
    register({ name: "time" });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (messageFromParent) setValue("message", messageFromParent);
  }, [steps]);

  useEffect(() => {
    if (getvalues.message && getvalues.message !== "") {
      setMessage(getvalues.message);
    }
    // eslint-disable-next-line
  }, [getvalues.message]);

  const handleCheckAction = (value) => {
    let included = false;
    values.time.map((time) => {
      if (time.id === value.id) return (included = true);
      return false;
    });
    if (included) {
      return setValue("time", [
        ...values.time.filter((i) => i.id !== value.id),
      ]);
    }
    return setValue("time", [...values.time, value]);
  };

  const checkDisabled = () => {
    let disabled = true;
    switch (steps) {
      case 1:
        if (getvalues.service?.name) disabled = false;
        break;
      case 2:
        if (getvalues.subService?.name) disabled = false;
        break;
      case 3:
        disabled = false;
        break;
      case 4:
        disabled = false;
        break;
      case 5:
        if (getvalues.time.length > 0) disabled = false;
        break;

      default:
        break;
    }
    return disabled;
  };
  const StepComponent = () => {
    switch (steps) {
      case 1:
        return (
          <>
            <div className={styles.serviceContent}>
              <RadioButtonGroup
                options={proServices}
                handleSelect={(value) => handleRadioSelect("service", value)}
                selectedValue={values.service}
                customStyles={{
                  marginBottom: 0,
                  paddingBottom: 0,
                  border: "unset",
                }}
                mode="noMaxHeight"
              />
            </div>
            <div className={styles.footer}>
              <Button
                isDisabled={checkDisabled()}
                customStyles={{
                  maxWidth: isMobile ? "100%" : "max-content",
                  marginLeft: "auto",
                }}
                onClickHandler={() => handleNextStep()}
              >
                Siguiente
              </Button>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className={styles.serviceContent}>
              <RadioButtonGroup
                options={values.service.categories}
                handleSelect={(value) => handleRadioSelect("subService", value)}
                selectedValue={values.subService}
                steps={steps}
                customStyles={{
                  marginBottom: 0,
                  paddingBottom: 0,
                  border: "unset",
                }}
                mode="noMaxHeight"
              />
            </div>
            <div className={styles.footer}>
              <Button
                customStyles={{
                  maxWidth: isMobile ? "100%" : "max-content",
                  marginBottom: isMobile ? "var(--spacing-small)" : "0",
                }}
                buttonStyle="terciary"
                onClickHandler={() => handlePreviousStep()}
              >
                Atrás
              </Button>
              <Button
                type="button"
                isDisabled={checkDisabled()}
                customStyles={{
                  maxWidth: isMobile ? "100%" : "max-content",
                  marginLeft: "auto",
                }}
                onClickHandler={() => handleNextStep()}
              >
                Siguiente
              </Button>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <TextArea
              placeholder="Ej: Necesito asesoría por un accidente de tránsito que tuve hace unos días."
              name="message"
              register={register({ required: true })}
            />
            {errors.message && (
              <h3 className={styles.errorMessage}>
                No puedes enviar un mensaje vacío
              </h3>
            )}
            <div className={styles.footer}>
              {modalType !== "messageBulk" &&
              modalType !== "messageProWithoutServices" &&
              modalType !== "callProRequestWithoutServices" ? (
                <Button
                  customStyles={{
                    maxWidth: isMobile ? "100%" : "max-content",
                    marginBottom: isMobile ? "var(--spacing-small)" : "0",
                  }}
                  buttonStyle="terciary"
                  onClickHandler={() => handlePreviousStep()}
                >
                  Atrás
                </Button>
              ) : null}
              {fromMessage !== "callProRequest" ? (
                <button
                  type="submit"
                  className={`${styles.button} ${isLoading && styles.loading}`}
                  style={{
                    maxWidth: isMobile
                      ? "100%"
                      : isLoading
                      ? "12.3rem"
                      : "max-content",
                    marginLeft: "auto",
                  }}
                >
                  {isLoading ? <Spinner /> : "Enviar mensaje"}
                </button>
              ) : (
                <Button
                  type="button"
                  isDisabled={checkDisabled()}
                  customStyles={{
                    maxWidth: isMobile ? "100%" : "max-content",
                    marginLeft: "auto",
                  }}
                  onClickHandler={() => handleNextStep()}
                >
                  Siguiente
                </Button>
              )}
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className={styles.dateContainer}>
              <Calendar
                handleDateChange={handleChangeDate}
                dateSelected={getvalues.date}
              />
            </div>
            <div className={styles.footer}>
              <Button
                isDisabled={checkDisabled()}
                customStyles={{
                  maxWidth: isMobile ? "100%" : "max-content",
                  marginBottom: isMobile ? "var(--spacing-small)" : "0",
                }}
                buttonStyle="terciary"
                onClickHandler={() => handlePreviousStep()}
              >
                Atrás
              </Button>
              <Button
                isDisabled={checkDisabled()}
                customStyles={{
                  maxWidth: isMobile ? "100%" : "max-content",
                  marginLeft: "auto",
                }}
                onClickHandler={() => handleNextStep()}
              >
                Siguiente
              </Button>
            </div>
          </>
        );
      case 5:
        return (
          <div>
            <CheckboxGroup
              options={horariosContacto}
              selectItemAction={handleCheckAction}
              selectedItems={values.time}
            />
            <div className={styles.footer}>
              <Button
                customStyles={{
                  maxWidth: isMobile ? "100%" : "max-content",
                  marginBottom: isMobile ? "var(--spacing-small)" : "0",
                }}
                buttonStyle="terciary"
                onClickHandler={() => handlePreviousStep()}
              >
                Atrás
              </Button>
              <Button
                isLoading={isLoading}
                type="submit"
                isDisabled={checkDisabled() || isLoading}
                customStyles={{
                  maxWidth: isMobile
                    ? "100%"
                    : isLoading
                    ? "12.3rem"
                    : "max-content",
                  marginLeft: "auto",
                }}
              >
                Siguiente
              </Button>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(handleSaveForm)}>
        <div className={styles.container}>
          <StepComponent />
        </div>
      </Form>
    </>
  );
};

export default ContactPro;
