import React from 'react'
import ExternalReviewContainer from '@organism/ExternalReviewContainer/ExternalReviewContainer'
import styles from './styles.module.scss'

const ExternalReview = () => {
  return (
    <div className={styles.container}>
      <ExternalReviewContainer />
    </div>
  )
}
export default ExternalReview
