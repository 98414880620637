import { setModal } from "@actions/generalActions";
import Modal from "@atoms/Modal/Modal";
import useScreenDetect from "@hooks/useScreenDetect";
import ContactProModal from "@molecules/ContactProModal/ContactProModal";
import ShareProfileModal from "@molecules/ShareProfileModal/ShareProfileModal";
import FirstLoginModal from "@molecules/FirstLogin/FirstLoginModal";
import EmailToPro from "@organism/EmailToPro/EmailToPro";
import ProfileEditModal from "@molecules/ProfileEditModal/ProfileEditModal";
import ReviewModal from "@molecules/ReviewModal/ReviewModal";
import SuccessModal from "@molecules/SuccessModal/SuccessModal";
import UserProfileEdit from "@molecules/UserProfileEdit/UserProfileEdit";
import UserWorkFinished from "@molecules/WorksModals/UserWorkFinished";
import WorkContactZenPros from "@molecules/WorksModals/WorkContactZenPros";
import WorkDecline from "@molecules/WorksModals/WorkDecline";
import WorkFinished from "@molecules/WorksModals/WorkFinished";
import WorkRequestReviews from "@molecules/WorksModals/WorkRequestReviews";
import WorkSort from "@molecules/WorksModals/WorkSort";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalPanel from "@molecules/ModalPanel/ModalPanel";

const ModalComponent = ({
  modalType,
  profileData,
  handleCloseModal,
  professionalProfile,
  currentWork,
  mobileFilter,
  userProfileData,
}) => {
  const { isMobile } = useScreenDetect();

  switch (modalType) {
    case "userProfileEdit":
      return (
        <UserProfileEdit
          handleClose={handleCloseModal}
          profileData={userProfileData}
        />
      );
    case "successModal":
      return <SuccessModal />;
    case "starsReview":
      return <ReviewModal currentWork={currentWork} />;
    case "starsReviewUser":
      return <ReviewModal currentWork={currentWork} fromUser />;
    case "errorModal":
      return <SuccessModal error />;
    case "callProRequest":
      return (
        <ContactProModal
          modalType={modalType}
          professionalProfile={professionalProfile}
          profileData={profileData}
          isMobile={isMobile}
        />
      );
    case "callProRequestWithoutServices":
      return (
        <ContactProModal
          modalType={modalType}
          professionalProfile={professionalProfile}
          profileData={profileData}
          isMobile={isMobile}
          withoutServices
        />
      );
    case "messageProRequest":
      return (
        <ContactProModal
          modalType={modalType}
          profileData={profileData}
          professionalProfile={professionalProfile}
          isMobile={isMobile}
        />
      );
    case "messageProWithoutServices":
      return (
        <ContactProModal
          modalType={modalType}
          profileData={profileData}
          professionalProfile={professionalProfile}
          isMobile={isMobile}
          withoutServices
        />
      );
    case "messageBulk":
      return (
        <ContactProModal
          modalType={modalType}
          profileData={profileData}
          professionalProfile={professionalProfile}
          isMobile={isMobile}
          withoutServices
        />
      );
    case "emailToProWithoutServices":
      return (
        <EmailToPro
          isMobile={isMobile}
          profileData={profileData}
          professionalProfile={professionalProfile}
        />
      );
    case "shareProfile":
      return (
        <ShareProfileModal
          modalType={modalType}
          profileData={profileData}
          professionalProfile={professionalProfile}
          isMobile={isMobile}
          withoutServices
        />
      );
    case "firstLogin":
      return (
        <FirstLoginModal
          modalType={modalType}
          profileData={profileData}
          professionalProfile={professionalProfile}
          isMobile={isMobile}
          withoutServices
        />
      );
    case "modalPanel":
      return (
        <ModalPanel
          modalType={modalType}
          profileData={profileData}
          professionalProfile={professionalProfile}
          isMobile={isMobile}
          withoutServices
        />
      );

    case "generalInfo":
    case "aboutme":
    case "paymentMethods":
    case "services":
    case "addService":
    case "zones":
    case "workDone":
    case "commentaries":
    case "externalReviews":
      return (
        <ProfileEditModal
          modalType={modalType}
          profileData={profileData}
          isMobile={isMobile}
        />
      );
    case "declineWork":
      return <WorkDecline currentWork={currentWork} />;
    case "finishWork":
      return <WorkFinished currentWork={currentWork} />;
    case "finishWorkUser":
      return <UserWorkFinished currentWork={currentWork} />;
    case "contactZenPros":
      return <WorkContactZenPros />;
    case "sortFilterPending":
    case "sortFilterFinished":
      return <WorkSort mobileFilter={mobileFilter} type={modalType} />;
    case "requestReviews":
      return <WorkRequestReviews professionalId={profileData.id} />;

    default:
      return <></>;
  }
};

const ModalLayout = () => {
  const { modal, modalType } = useSelector((state) => state.general);
  const {
    privateProfile,
    professionalProfile,
    currentWork,
    mobileFilter,
    userProfileData,
  } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(setModal(false));
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) return handleCloseModal();
  };

  useEffect(() => {
    if (modal) {
      document.addEventListener("keydown", escFunction, false);
      document.body.style.overflowY = "hidden";
    }
    return () => {
      if (modal) {
        document.removeEventListener("keydown", escFunction, false);
        document.body.style.overflowY = "auto";
      }
    };
    // eslint-disable-next-line
  }, [modal]);

  if (!modal) return null;
  return (
    <Modal closeAction={handleCloseModal}>
      <ModalComponent
        modalType={modalType}
        profileData={privateProfile}
        userProfileData={userProfileData}
        professionalProfile={professionalProfile}
        handleCloseModal={handleCloseModal}
        currentWork={currentWork}
        mobileFilter={mobileFilter}
      />
    </Modal>
  );
};

export default ModalLayout;
