import React from 'react'
import {useSelector} from 'react-redux'
import PersonSVG from '@assets/svg/person_white.svg'
import MoneySVG from '@assets/svg/money_white.svg'
import SlideCheckbox from '@atoms/SlideCheckbox/SlideCheckbox'
import useScreenDetect from '@hooks/useScreenDetect'

const Slider = ({ handleSlider, isOn }) => {
  const { isMobile } = useScreenDetect()

  const { profileData } = useSelector(
    (state) => state.profile
  )
  
  return (
    <SlideCheckbox
      name={
        <span>

          <b
            style={{
              marginLeft: '5px',
              color:
                localStorage.getItem('isPro') === 'false'
                  ? '#C4C4C4'
                  : '#FFFFFF'
            }}
          >
            Ofrecé
          </b>
        </span>
      }
      name2={
        <span>
          <b
            style={{
              color:
                localStorage.getItem('isPro') === 'false'
                  ? 'white'
                  : 'darkGray'
            }}
          >
            Contratá
          </b>
        </span>
      }
      isOn={isOn}
      reverse
      onChange={handleSlider}
      backgroundColor={
        profileData.profileMode === 'PRO'
          ? 'var(--secondary-blue)'
          : 'var(--color-item)'
      }
      uncheckedBackgroundOpacity={1}
      customStyles={{ marginTop: isMobile ? '1.6rem' : '0' }}
      beforeIcon={MoneySVG}
      afterIcon={PersonSVG}
    />
  )
}

export default Slider
