import React from 'react'
import styles from './ValorationComment.module.scss'
import ViewMoreText from '@atoms/ViewMoreText/ViewMoreText'
import { motion } from 'framer-motion'
import { getNamingDate } from './constants'
import { ReactComponent as UserIcon } from '@assets/svg/user-empty.svg'

const ValorationComment = ({ comment, isMobile, charLimit }) => {
  const date = getNamingDate(comment.created)
  return (
    <motion.div
      initial={{ height: '0' }}
      animate={{ height: 'auto' }}
      className={styles.valoration}
    >
      <div className={styles.left}>
        {comment.evaluating_user?.photo
          ? (
            <img
              src={comment.evaluating_user.photo}
              alt={comment.evaluating_user.name}
              className={styles.image}
            />
            )
          : (
            <div className={styles.noImage}>
              <UserIcon className={styles.icon} />
            </div>
            )}
        <h6 className={styles.name}>{`${comment.evaluating_user?.name ||
          comment.name} ${comment.evaluating_user?.lastName ||
          comment.lastName}`}
        </h6>
        <div className={styles.row}>
          <p className={styles.value}>{comment.evaluation}</p>
          <p className={styles.date}>{date}</p>
        </div>
      </div>
      <div className={styles.right}>
        <ViewMoreText
          text={comment.comment}
          charLimit={charLimit}
          isMobile={isMobile}
        />
      </div>
    </motion.div>
  )
}

export default ValorationComment
