import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import Text from '@atoms/Text/Text'
import { ReactComponent as ChevronIcon } from '@assets/svg/down-chevron.svg'
import styles from './ProfileZones.module.scss'

const ProfileZones = ({ professionalNeighborhood }) => {
  const [openLists, setOpenLists] = useState([0])

  const handleOpenList = (list) => {
    if (openLists.includes(list)) { return setOpenLists(openLists.filter((i) => i !== list)) }
    return setOpenLists([...openLists, list])
  }
  return (
    <div className={styles.listContainer}>
      {professionalNeighborhood.map((localidad, index) => {
        return (
          <div
            key={index}
            className={`${styles.optionsContainer} ${openLists.includes(
              index
            ) && styles.opened}`}
          >
            <div className={styles.optionsHeader}>
              <h6 className={styles.optionName}>{localidad.name}</h6>
              <div
                onClick={() => handleOpenList(index)}
                className={styles.optionsOpenHandler}
              >
                <p>{openLists.includes(index) ? 'Ocultar' : 'Ver'} barrios</p>
                <ChevronIcon
                  className={`${styles.icon} ${openLists.includes(index) &&
                    styles.open}`}
                />
              </div>
            </div>
            <AnimatePresence>
              {openLists.includes(index) && (
                <motion.div
                  initial={{ height: 0, overflow: 'hidden' }}
                  animate={{ height: 'max-content', overflow: 'auto' }}
                  exit={{ height: 0, overflow: 'hidden' }}
                >
                  <div
                    className={`${styles.subServiceList} ${openLists.includes(
                      index
                    ) && styles.open}`}
                  >
                    {localidad.neighborhood.map((barrio, index) => (
                      <div key={index} className={styles.subService}>
                        <Text
                          key={index}
                          tag='p'
                          size='m'
                          align='left'
                          customStyles={{ padding: '1rem 0' }}
                        >
                          {barrio.name}
                        </Text>
                        {!barrio.available && (
                          <span className={styles.optionSoon}>
                            Próximamente
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )
      })}
    </div>
  )
}
export default ProfileZones
