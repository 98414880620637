const DATES = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]
export const getNamingDate = (date) => {
  const fecha = date.split('T')[0].split('-')
  const month = DATES[Number(fecha[1]) - 1]
  return `${fecha[2]} ${month} ${fecha[0]}`
}
