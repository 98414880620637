import React, { useEffect } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  setNextStep,
  setPrevStep,
  setRegisterType,
  suggestCategory,
  getBarrios,
  setRegisterServicesValues,
  setStep,
  zenprosRegisterUser,
  setNoDistractionMode,
  getProsVerticals,
  cleanProRegister,
  setRegisterUserInfo,
  clearProfileData,
  editProfileData
} from '@actions/index'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step4 from './Steps/Step4'
import { deleteToken, getFromSwitchFromLS } from '@utils/tokenService'
import { successRegister } from '@actions/generalActions';

import BusinessStep from './Steps/BusinessStep'

const RegisterSteps = () => {
  const { currentStep, registerInfo } = useSelector(
    (state) => state.register
  )
  const { profileData } = useSelector((state) => state.profile)
  const { isAuth } = useSelector((state) => state.auth)
  const { verticalProsCategories, barriosLocalidades, loading } = useSelector(
    (state) => state.general
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (currentStep === 1 && sessionStorage.getItem('registerStep') && sessionStorage.getItem('registerStep') !== '1') {
      const step = sessionStorage.getItem('registerStep')
      dispatch(setStep(parseInt(step)))
    }
    if (!registerInfo) {
      const value = {
        name: profileData.name,
        lastName: profileData.lastName,
        email: profileData.email
      }
      dispatch(setRegisterUserInfo(value))
    }
  }, [currentStep])

  useEffect(() => {
    if (barriosLocalidades.length < 1) dispatch(getBarrios())
    dispatch(setNoDistractionMode(true))
    if (verticalProsCategories.length < 1) dispatch(getProsVerticals())
    return () => {
      dispatch(setNoDistractionMode(false))
      dispatch(cleanProRegister())
      if (!getFromSwitchFromLS()) {
        dispatch(clearProfileData())
        deleteToken()
      }
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // eslint-disable-next-line
  }, [currentStep]);

  useEffect(() => {
    const LSfromRegister = JSON.parse(
      localStorage.getItem('professional-register-info')
    )
    if (LSfromRegister && isAuth) {
      dispatch(setRegisterType(LSfromRegister.type))
      dispatch(setStep(2)) // Fix
      localStorage.removeItem('professional-register-info')
    }
    // eslint-disable-next-line
  }, []);

  const handleNextStep = () => {
    dispatch(setNextStep())
  }
  const handlePrevStep = () => {
    dispatch(setPrevStep())
  }

  const history = useHistory()
  const handleCreateUser = (value) => {
    dispatch(setRegisterUserInfo(value))
    dispatch(
      zenprosRegisterUser(
        {
          name: value.name,
          lastName: value.lastname,
          email: value.usuario,
          password: value.contrasena,
          isBusiness: false // registerInfo.type.name === "persona" ? false : true,
        },
        true, history
      )
    )
    return history.push('/registro-profesionales-vertical')
  }

  const handleStepAction = (value) => {
    dispatch(successRegister(true));
    if (!value) return handlePrevStep()
    switch (currentStep) {
      case 1:
        return handleCreateUser(value) // SSO / DATOS BASICOS
      case 2:
        dispatch(setRegisterServicesValues(registerInfo)) // SELECCION AREA (PROFESION)
        history.push('/registro-profesionales-tipo')
        break
      case 3:
        dispatch(setRegisterType(value)) // PERSONA / EMPRESA
        if (value.name === 'empresa') {
          history.push('/registro-profesionales-empresa')
        }
        break
      case 4:
        dispatch(editProfileData({
          isBusiness: true
        }))
        break
      default:
        break
    }
    handleNextStep()
  }

  const handleSuggestAction = (value) => {
    let objectValue
    let successModalTexts = {}
    switch (currentStep) {
      case 3:
        objectValue = {
          email:
            registerInfo.userInfo.usuario || (profileData && profileData.email),
          suggestion_category: value.name,
          suggestion_subcategory: value.description
        }
        successModalTexts = {
          title: '¡Listo!',
          subtitle:
            'Te vamos a avisar por mail una vez que la profesión/oficio sugerido esté disponible.',
          buttonText: 'Volver al inicio'
        }
        dispatch(suggestCategory(objectValue, successModalTexts))
        break
      case 2:
        objectValue = {
          email:
            registerInfo.userInfo.usuario || (profileData && profileData.email),
          suggestion_category: registerInfo.category.name,
          categoryId: registerInfo.category.id,
          suggestion_subcategory: value.name
        }
        successModalTexts = {
          title: '¡Listo!',
          subtitle:
            'Te vamos a avisar por mail una vez que la profesión/oficio sugerido esté disponible.',
          buttonText: 'Volver al inicio'
        }
        dispatch(suggestCategory(objectValue, successModalTexts))
        break
      case 5:
        objectValue = {
          email: registerInfo.userInfo.usuario,
          suggestion_category: registerInfo.area.name,
          categoryId: registerInfo.area.id,
          suggestion_subcategory: value.name
        }
        successModalTexts = {
          title: '¡Listo!',
          subtitle:
            'Te vamos a avisar por mail una vez que el servicio sugerido esté disponible'
        }
        dispatch(suggestCategory(objectValue, successModalTexts))
        break
      default:
        break
    }
  }

  const StepComponent = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step2
            handleClick={handleStepAction}
            registerInfo={registerInfo}
            type='persona'
            loading={loading}
          /> // SSO / DATOS BASICOS
        )
      case 2:
        return (
          <Step4
            handleClick={handleStepAction}
            suggestAction={handleSuggestAction}
            registerInfo={registerInfo}
          /> // SELECCION AREA (PROFESION)
        )
      case 3:
        return <Step1 handleClick={handleStepAction} /> // PERSONA / EMPRESA
      case 4:
        return registerInfo.type.name === 'persona'
          ? (getFromSwitchFromLS() ? <Redirect to='/conversion-profesional-ok' /> : <Redirect to='/registro-profesional-ok' />)
          : <BusinessStep
            handleClick={handleStepAction}
            registerInfo={registerInfo}
            type='empresa'
            loading={loading}
          />
      case 5:
        return getFromSwitchFromLS() ? <Redirect to='/conversion-profesional-ok' /> : <Redirect to='/registro-profesional-ok' />
      default:
        return <div>Continuará...</div>
    }
  }

  return (
    barriosLocalidades.length > 0 &&
    verticalProsCategories.length > 0 && <StepComponent />
  )
}

export default RegisterSteps
