import Button from '@atoms/Button/Button'
import Text from '@atoms/Text/Text'
import ModalTitle from '@atoms/ModalTitle/ModalTitle'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './WorksModals.module.scss'
import AddMailsToShare from '@molecules/AddMailsToShare/AddMailsToShare'
import Separator from '@atoms/Separator/Separator'
import useScreenDetect from '@hooks/useScreenDetect'
import { requestValorations } from '@actions/registerActions'

const WorkRequestReviews = ({ professionalId }) => {
  const [emails, setEmails] = useState([])
  const { isMobile } = useScreenDetect()
  const dispatch = useDispatch()

  const handleInputSubmit = (value) => {
    if (emails.includes(value)) return false
    setEmails((prevEmails) => [...prevEmails, value])
  }

  const handleDeleteEmail = (emailToDelete) => {
    const auxEmails = emails.filter((email) => email !== emailToDelete)
    setEmails(auxEmails)
  }

  const handleSend = () => {
    if (emails.length < 1) return
    dispatch(requestValorations({ id: professionalId, emails: emails }, true))
  }

  return (
    <>
      <ModalTitle
        title='Solicitar una recomendación'
        customStyles={{ marginBottom: isMobile ? '2rem' : '3.2rem' }}
      />
      <div className={styles.requestModal}>
        <div style={{ display: 'flex' }}>
          <Text
            tag='p'
            size='l-1'
            weight='semibold'
            align='left'
            customStyles={{ marginBottom: 'var(--spacing-medium)' }}
          >
            Potenciá tu perfil sumando recomendaciones
          </Text>
        </div>
        <Text
          tag='p'
          size='m'
          align='left'
          customStyles={{ lineHeight: '2.4rem' }}
        >
          Podrás subír hasta 10 recomendaciones.
          <br />
          Si contás con más, no te preocupes que te daremos la posibilidad de
          elegir las que más te interesen.
        </Text>
        <Text
          tag='p'
          size='m'
          customStyles={{
            marginTop: 'calc(var(--spacing-small) * 2)',
            marginBottom: '0.8rem'
          }}
          align='left'
        >
          Envíale un correo electrónico a antiguos clientes.
        </Text>
        <AddMailsToShare
          emails={emails}
          handleAddEmail={handleInputSubmit}
          handleDeleteEmail={handleDeleteEmail}
        />
        <Separator large='100%' horizontal margin={isMobile ? '1' : '3.2'} />
        <Button
          isDisabled={emails.length < 1}
          onClickHandler={() => handleSend()}
          customStyles={{
            maxWidth: isMobile ? '100%' : '13.2rem',
            marginLeft: 'auto'
          }}
        >
          Enviar
        </Button>
      </div>
    </>
  )
}
export default WorkRequestReviews
