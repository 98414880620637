import React, { createRef, useEffect } from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import routes from "@config/routes";
import "animate.css";

import { Provider } from "react-redux";
import { store } from "@store";
import Header from "@organism/Header/Header";
import Footer from "@organism/Footer/Footer";
import MobileFooter from "@organism/Footer/mobile.js";
import ModalLayout from "@organism/ModalLayout/ModalLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import useScreenDetect from "@hooks/useScreenDetect";
import { Sticky, StickyProvider } from "react-stickup";
import ReactGA from "react-ga";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const container = createRef();
  const { isMobile } = useScreenDetect();

  useEffect(() => {
    //ReactGA.initialize("AW-10870553377");
    ReactGA.initialize("AW-417421308");
  }, []);

  let pathPanel = window.location.pathname.includes("/panel-administracion");

  return (
    <Provider store={store}>
      <StickyProvider>
        <BrowserRouter>
          <div className="App" ref={container}>
            {isMobile ? (
              pathPanel ? (
                <Header />
              ) : (
                <Sticky style={{ zIndex: 1000 }} container={container}>
                  <Header />
                </Sticky>
              )
            ) : (
              <Header />
            )}
            <Switch>
              {routes.map((config, index) => (
                <Route {...config} key={`${config.path}${index}`} />
              ))}
              <Redirect from="*" to="/" />
            </Switch>
            {localStorage.getItem("role") !== "ADMIN" ? (
              isMobile ? (
                <MobileFooter />
              ) : (
                <Footer />
              )
            ) : null}
            <ModalLayout />

            <ToastContainer
              position="top-right"
              autoClose={0}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              icon={false}
              limit={5}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </BrowserRouter>
      </StickyProvider>
    </Provider>
  );
}

export default App;
