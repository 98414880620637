import React from 'react'
import ForgotPassword from '@organism/ForgotPassword/ForgotPassword'
import styles from './styles.module.scss'

const ForgotPasswordPage = () => {
  return (
    <>
      <main className={styles.forgotPasswordContainer}>
        <h1 className={styles.forgotPasswordTitle}>Recuperá tu contraseña</h1>
        <ForgotPassword />
      </main>
    </>
  )
}

export default ForgotPasswordPage
