import Checkbox from '@atoms/Checkbox/Checkbox'
import React, { useState } from 'react'
import styles from './FiltersMenuMobile.module.scss'
import { ReactComponent as ChevronIcon } from '@assets/svg/down-chevron.svg'
import { AnimatePresence, motion } from 'framer-motion'

const FilterContent = ({ filter, selectedOptions, handleSelect }) => {
  const [viewMore, setViewMore] = useState(false)
  const [openLists, setOpenLists] = useState([])

  const handleOpenList = (list) => {
    if (openLists.includes(list)) { return setOpenLists(openLists.filter((i) => i !== list)) }
    return setOpenLists([...openLists, list])
  }

  const handleViewMore = (value) => {
    setViewMore(value)
  }
  return (
    <div className={styles.filterContent}>
      <p className={styles.filterTitle}>{filter.name}</p>
      {filter.name === 'Zona de cobertura1'//Para usar barrios se deberá dejar Zona de cobertura sin 1.
        ? filter.options.map((fil, index) => (
          <div
            key={index}
            className={`${styles.optionsContainer} ${index > 5 &&
                !viewMore &&
                styles.hidden}`}
          >
            <div className={styles.optionsHeader}>
              <h6 className={styles.optionName}>{fil.name}</h6>
              <div
                onClick={() => handleOpenList(index)}
                className={styles.optionsOpenHandler}
              >
                <p>{openLists.includes(index) ? 'Ocultar' : 'Ver'} barrios</p>
                <ChevronIcon
                  className={`${styles.icon} ${openLists.includes(index) &&
                      styles.open}`}
                />
              </div>
            </div>
            <AnimatePresence>
              {openLists.includes(index) && (
                <motion.div
                  initial={{ height: 0, overflow: 'hidden' }}
                  animate={{
                    height:
                        fil.neighborhood.length > 6 ? '200px' : 'max-content',
                    overflow: 'auto'
                  }}
                  exit={{ height: 0, overflow: 'hidden' }}
                  className={styles.barrios}
                >
                  {fil.neighborhood.map((category, index2) => {
                    const checked = !!selectedOptions.find(
                      (selectedOption) => selectedOption?.id === category.id
                    )
                    return (
                      <div
                        key={index2}
                        className={`${styles.filter} ${!category.available &&
                            styles.soon}`}
                      >
                        <Checkbox
                          option={category}
                          handleCheck={handleSelect}
                          checked={checked}
                          enableSoon
                        />
                      </div>
                    )
                  })}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          ))
        : filter.options &&
          filter.options.map((category, index2) => {
            const checked = !!selectedOptions.find(
              (selectedOption) => selectedOption?.id === category.id
            )
            return (
              <div
                key={index2}
                className={`${styles.filter} ${index2 > 5 &&
                  !viewMore &&
                  styles.hidden}`}
              >
                <Checkbox
                  option={category}
                  handleCheck={handleSelect}
                  checked={checked}
                />
              </div>
            )
          })}
      {filter.options?.length > 6 && (
        <span
          className={styles.viewMore}
          onClick={() =>
            viewMore ? handleViewMore(false) : handleViewMore(true)}
        >
          {viewMore ? 'ver menos' : 'ver más'}
        </span>
      )}
    </div>
  )
}

export default FilterContent
