import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import styles from "./Select.module.scss";

const Select = ({
  options,
  defaultSelected,
  placeholder = "Seleccioná",
  handleSelectAction,
  width = "15rem",
  height = "3rem",
  customStyles,
  panel,
  selectOption,
  delProOption,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultSelected || null);

  const selectRef = useRef();

  const handleSelectOption = (value) => {
    setIsOpen(false);
    setSelectedValue(value);
    handleSelectAction(value);
  };

  const handleOpenList = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, []);

  const handleClick = (e) => {
    if (selectRef.current.contains(e.target)) {
      return;
    }
    setIsOpen(false);
  };

  return (
    <div
      className={styles.container}
      ref={selectRef}
      style={{ width: width, height: height, ...customStyles }}
    >
      <p
        className={`${styles.name} ${isOpen && styles.opened}`}
        onClick={() => handleOpenList()}
      >
        {!panel && (selectedValue ? selectedValue.name : placeholder)}
        {panel && "(elegir)"}
      </p>
      {isOpen && (
        <div className={styles.list}>
          {options?.map((option, index) => (
            <motion.div
              initial={{ height: 0 }}
              key={index}
              className={styles.option}
              onClick={() =>
                !panel
                  ? handleSelectOption(option)
                  : option.name === "Ver detalle"
                  ? selectOption()
                  : delProOption()
              }
              animate={{ height: height }}
              name={options[index]}
            >
              <span>{option.name}</span>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
