import React, { useState } from 'react'
import styles from './ViewMoreText.module.scss'
import Text from '@atoms/Text/Text'

const ViewMoreText = ({ text, isMobile, charLimit, share=false }) => {
  const [showAllText, setShowAllText] = useState(false)
  const limit = charLimit || 290
  let limitedText = ''
  const showMore = () => {
    setShowAllText(true)
  }
  if (text) {
    if (showAllText || text.length <= limit) {
      limitedText = (
        <Text
          tag='p'
          size='m'
          align='left'
          customStyles={{ lineHeight: '24px', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
        >
          {text}
        </Text>
      )
    } else {
      limitedText = (
        <Text
          tag='p'
          size='m'
          align='left'
          customStyles={{ lineHeight: '24px', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
        >
          {text.substring(0, limit) + '... '}
          <span onClick={() => showMore()} className={styles.viewMore}>
            Ver más
          </span>
        </Text>
      )
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>{limitedText}</div>
    </div>
  )
}

export default ViewMoreText
