import ShadowBox from '@atoms/ShadowBox/ShadowBox'
import React, { useEffect, useState } from 'react'
import styles from './WorkRequest.module.scss'
import { ReactComponent as ZenProsCoin } from '@assets/svg/zencoins.svg'
import { ReactComponent as CalendarIcon } from '@assets/svg/calendar.svg'
import { ReactComponent as PhoneIcon } from '@assets/svg/phone.svg'
import { ReactComponent as EmailIcon } from '@assets/svg/envelope.svg'
import { ReactComponent as ChevronIcon } from '@assets/svg/down-chevron.svg'
import { ReactComponent as StarIcon } from '@assets/svg/star.svg'
import { ReactComponent as AlertIcon } from '@assets/svg/not-available.svg'
import BorderedItem from '@atoms/BorderedItem/BorderedItem'
// import { motion } from "framer-motion";
import Button from '@atoms/Button/Button'
import Text from '@atoms/Text/Text'
// import moment from 'moment'
// import 'moment/locale/es'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { useDispatch, useSelector } from 'react-redux'
import { removeUnreadMessage, setJobReadStatus } from '@actions/profileActions'
import { useParams } from 'react-router-dom';


const WorkRequestFinished = ({
  work,
  qualificateAction,
  viewConversationAction,
  opened,
  isMobile,
  handleOpenWork,
  fromWorkDetail,
  needHelpAction,
  goToChatAction,
  handleGoToSearch,
  rejectWork
}) => {
  const { id } = useParams();
  
  const [personalData, setPersonalData] = useState({})
  const dispatch = useDispatch()
  const { unreadMessages, profileData } = useSelector((state) => state.profile)
  const [messages, setMessages] = useState({})
  const notAvailable = fromWorkDetail && work.jobStatus === 'REJECTED'
  const qualificationValue = (value) => {
    if (value) {
      switch (value) {
        case 1:
          return 'Mala'

        case 2:
          return 'Regular'

        case 3:
          return 'Buena'

        case 4:
          return 'Muy buena'

        case 5:
          return 'Excelente'

        default:
          return ''
      }
    }
  }

  const {
    client,
    categoryTreeBranch,
    description,
    contactTime,
    contactDate
  } = work

  const handlePersonalData = () => {
    if (
      (work.jobStatus === 'REJECTED' || work.jobStatus === 'PENDING') &&
      !fromWorkDetail
    ) {
      return {
        fullName: `${client.name} ${client.lastName.charAt(0)}.`,
        phone: client.phone ? `...${client.phone.slice(-5)}` : '-',
        email: `...@...${client.email.split('.')[client.email.split('.').length - 1]
          }`
      }
    }
    if (fromWorkDetail) {
      if (work.jobStatus === 'REJECTED' || work.jobStatus === 'PENDING') {
        return {
          fullName: `${work.professionalProfile.user.name} ${work.professionalProfile.user.lastName}`,
          phone: work.professionalProfile.user.phone
            ? `...${work.professionalProfile.user.phone.slice(-5)}`
            : '-',
          email: `...@...${work.professionalProfile.user.email.split('.')[
            work.professionalProfile.user.email.split('.').length - 1
          ]
            }`
        }
      }
      return {
        fullName: `${work.professionalProfile.user.name} ${work.professionalProfile.user.lastName}`,
        phone: work.professionalProfile.user.phone
          ? work.professionalProfile.user.phone
          : '-',
        email: work.professionalProfile.user.email
      }
    }
    return {
      fullName: `${client.name} ${client.lastName}`,
      phone: client.phone ? client.phone : '-',
      email: client.email
    }
  }

  const servicesToHire = () => {
    if(categoryTreeBranch){
      const auxServices = [...categoryTreeBranch.categories]
      const aux2 = auxServices.splice(2)
      return aux2
    }
    return null;
  }

  const servicesToMap = servicesToHire()

  const spanishDate = () => {
    dayjs.locale('es')
    const dateCreated = dayjs(work.created).format(
      'dddd D [de] MMMM HH:mm [hs]'
    )
    return dateCreated
  }

  const messageToHtml = description?.split('\n').map((messagePart, index) => (
    <span key={index}>
      {messagePart}
      {!(index === description?.split('\n').length - 1) && (
        <>
          <br />
          <br />
        </>
      )}
    </span>
  ))
  const handleRejectWork = (work) => {
    rejectWork(work)
  }

  const statusHandler = (status) => {
    switch (status) {
      case 'REJECTED':
        return 'NO DISPONIBLE'
      case 'PENDING':
        return 'PENDIENTE'
      case 'APPROVED':
        return 'Aprobado'
      default:
        return ''
    }
  }

  useEffect(() => {
    setPersonalData(handlePersonalData())
  }, [])

  useEffect(() => {
    const alreadyIncluded = unreadMessages.find(
      (message) => message.jobId === work.id
    )
    setMessages(alreadyIncluded)
  }, [messages])

  useEffect(() => {
    if (work.jobStatusNewRead === 'NOTIFY_PRO') {
      dispatch(setJobReadStatus(work.id, 'READ'))
    }
  }, [])

  // const [alias, setAlias] = useState(null);

  // useEffect(() => {
  //   if (work.professionalProfile.fantasy) setAlias(work.professionalProfile.fantasy);
  // }, [work.professionalProfile.fantasy]);
  

  return (
    // <motion.div initial={{ opacity: 1 }} animate={{ opacity: 1 }}>
    <div>
      {notAvailable && (
        <div className={styles.notAvailable}>
          <div className={styles.row}>
            <AlertIcon />
            <div className={styles.column}>
              <Text
                tag='p'
                size={isMobile ? 'm' : 'l'}
                weight='bold'
                color='white'
                align='left'
                customStyles={{ marginBottom: '.8rem' }}
              >
                El profesional actualmente no se encuentra disponible.
              </Text>
              {!isMobile && (
                <Text tag='p' size='m' color='white' align='left'>
                  Regresá a la búsqueda y probá con otro profesional
                </Text>
              )}
            </div>
          </div>
          <Button
            customStyles={{
              border: '1px solid var(--main-white)',
              background: 'transparent',
              maxWidth: isMobile ? '100%' : 'max-content'
            }}
            onClickHandler={() => handleGoToSearch(work)}
          >
            Volver a la búsqueda
          </Button>
        </div>
      )}
      <ShadowBox
        width='100%'
        customStyles={{
          margin: notAvailable ? '0' : 'var(--spacing-medium) 0',
          padding: isMobile
            ? 'var(--spacing-small)'
            : '3.2rem 3.2rem 1.5rem 3.2rem',
          borderRadius: notAvailable ? '0px 0px 10px 10px' : '10px'
        }}
      >
        <div className={styles.container}>
          <div className={styles.topRow}>
            <div className={styles.nameContactType}>
              <h5 className={styles.name}>
                {id ? work.professionalProfile.fantasy:personalData.fullName}
              </h5>
              {!fromWorkDetail &&
                (work.jobStatus === 'FINISHED' ||
                  work.jobStatus === 'WORK_COMPLETED')
                ? (
                    !work.reviewOk
                      ? (
                        <span
                          className={`${styles.contactType} ${styles['contacto-telefonico']}`}
                        >
                          PENDIENTE DE CALIFICACIÓN
                        </span>
                        )
                      : (
                        <span
                          className={`${styles.contactType} ${styles['contacto-por-mensaje']}`}
                        >
                          CALIFICADO
                        </span>
                        )
                  )
                : contactTime.length > 0
                  ? (
                    <span
                      className={`${styles.contactType} ${styles['contacto-telefonico']}`}
                    >
                      CONTACTO TELEFÓNICO
                    </span>
                    )
                  : (
                    <span
                      className={`${styles.contactType} ${styles['contacto-por-mensaje']}`}
                    >
                      CONTACTO POR MENSAJE
                    </span>
                    )}
            </div>
            {fromWorkDetail ? (
              (work.jobStatus === 'APPROVED' ||
                work.jobStatus === 'REJECTED' ||
                work.jobStatus === 'PENDING') && (
                  <span
                    className={`${styles.status} ${styles[work.jobStatus.toLowerCase()]
                    }`}
                  >
                    {statusHandler(work.jobStatus)}
                  </span>
              )
            ) : (
              <div className={`${styles.row} ${styles.zenCoin}`}>
                <ZenProsCoin className={styles.coin} />
                <p className={styles.coinValue}>{1} contacto</p>
              </div>
            )}
          </div>
          <div className={styles.row}>
            <CalendarIcon className={styles.icon} />
            <p className={styles.dataText}>{spanishDate()}</p>
          </div>
          <div className={styles.row}>
            <PhoneIcon className={styles.icon} />
            <p className={styles.dataText}>{personalData.phone ?? 'El usuario no indicó su número de teléfono.'}</p>
          </div>
          <div className={styles.row}>
            <EmailIcon className={styles.icon} />
            <p className={styles.dataText}>{personalData.email}</p>
          </div>
          {opened && (
            <div>
              {contactDate && contactTime.length > 0 && (
                <div className={styles.servicesList}>
                  <p>Días y horarios en que prefiero ser contactado:</p>
                  <div className={`${styles.row} ${styles.wrap}`}>
                    <BorderedItem customStyles={{ width: 'max-content' }}>
                      <p className={styles.service}>
                        {dayjs(contactDate).format('dddd D [de] MMMM')}
                      </p>
                    </BorderedItem>
                    {contactTime.map((day, index) => (
                      <div key={index}>
                        <BorderedItem customStyles={{ width: 'max-content' }}>
                          <p className={styles.service}>{day.name}</p>
                        </BorderedItem>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className={styles.servicesList}>
                <p>Servicios a contratar:</p>
                {categoryTreeBranch ?
                <>                  
                  <div className={`${styles.row} ${styles.wrap}`}>
                    {servicesToMap.map((serviceToHire, index) => (
                      <div key={index}>
                        <BorderedItem customStyles={{ width: 'max-content' }}>
                          <p className={styles.service}>{serviceToHire.name}</p>
                        </BorderedItem>
                      </div>
                    ))}
                  </div>
                </>
                :
                <>
                   <Text tag='p' size='m' weight='bold' align='left'>
                    {profileData.isPro ? 
                      'No especificaste que servicios ofrecés.'
                        :
                      'El profesional no especificó qué servicios ofrece.'}
                  </Text>
                </>
                }
              </div>
              {work.review && work.review[0] && work.review.length > 0 && (
                <div className={styles.qualification}>
                  <p>Calificación recibida:</p>
                  <div className={styles.qualificationValue}>
                    <p>{qualificationValue(work.review[0]?.evaluation)}</p>
                    <StarIcon />
                    <p className={styles.value}>{work.review[0]?.evaluation}</p>
                  </div>
                  <div className={styles.message}>
                    <p>{work.review[0]?.comment}</p>
                  </div>
                </div>
              )}
              {fromWorkDetail &&
                (work.jobStatus === 'APPROVED' ||
                  work.jobStatus === 'REJECTED' ||
                  work.jobStatus === 'PENDING') && (
                    <div className={styles.messages}>
                      <p>Mensaje:</p>
                      <div className={styles.messageContent}>
                        <p>{messageToHtml}</p>
                      </div>
                    </div>
              )}
            </div>
          )}
          <div className={`${styles.row} ${styles.buttons}`}>
            {fromWorkDetail
              ? (
                <>
                  <Button
                    buttonStyle='terciary'
                    customStyles={{
                      marginLeft: 'auto',
                      maxWidth: isMobile ? '100%' : 'max-content',
                      marginBottom: isMobile ? 'var(--spacing-small)' : '0'
                    }}
                    onClickHandler={() => needHelpAction(work)}
                  >
                    Necesito ayuda
                  </Button>
                  {work.jobStatus === 'REJECTED' && (
                    <Button
                      customStyles={{
                        maxWidth: isMobile ? '100%' : 'max-content',
                        marginLeft: isMobile ? '0' : 'var(--spacing-small)'
                      }}
                      onClickHandler={() => handleRejectWork(work)}
                    >
                      Descartar
                    </Button>
                  )}
                  {work.jobStatus !== 'REJECTED' && work.jobStatus !== 'PENDING' && (
                    <Button
                      customStyles={{
                        maxWidth: isMobile ? '100%' : 'max-content',
                        marginLeft: isMobile ? '0' : 'var(--spacing-small)'
                      }}
                      onClickHandler={() => {
                        dispatch(removeUnreadMessage(work.id))
                        goToChatAction(work)
                      }}
                    >
                      Ver conversación
                      {messages?.messages > 0 && (
                        <span
                          className={styles.worksLength}
                        >
                          {messages?.messages ?? ''}
                        </span>
                      )}
                    </Button>
                  )}
                </>
                )
              : (
                <>
                  <Button
                    buttonStyle='terciary'
                    customStyles={{
                      marginLeft: 'auto',
                      maxWidth: isMobile ? '100%' : 'max-content',
                      marginBottom: isMobile ? 'var(--spacing-small)' : '0'
                    }}
                    onClickHandler={() => viewConversationAction(work)}
                  >
                    Ver conversación
                  </Button>
                  {!work.reviewOk && (
                    <Button
                      customStyles={{
                        maxWidth: isMobile ? '100%' : 'max-content',
                        marginLeft: isMobile ? '0' : 'var(--spacing-small)'
                      }}
                      onClickHandler={() => qualificateAction(work)}
                    >
                      Calificar usuario
                    </Button>
                  )}
                </>
                )}
          </div>
          {!fromWorkDetail && (
            <div
              className={styles.openController}
              onClick={() => handleOpenWork(work)}
            >
              <p>{opened ? 'Ocultar' : 'Mostrar'} detalle</p>
              <ChevronIcon
                className={`${styles.icon} ${opened && styles.opened}`}
              />
            </div>
          )}
        </div>
      </ShadowBox>
    </div>
  )
}

export default WorkRequestFinished
