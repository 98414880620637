import React, { useEffect, useState } from 'react'
import Field from '@molecules/InputTextBox/InputTextBox'
import { useForm } from 'react-hook-form'
import styles from './CompanyRegister.module.scss'
import AlertMessage from '@atoms/AlertMessage/AlertMessage'
import Button from '@atoms/Button/Button'
import Filter from '@atoms/Filter/Filter'
import { validateCUILCUIT } from '@utils/functions/validateCUILCUIT'
import Login from '@organism/Login/Login'
import { useDispatch } from 'react-redux'
import { editProfileData } from '@actions/profileActions'
import { setTaxConditionToUser } from '@actions/registerActions'

const CompanyRegister = ({ handleRegisterComponentClick, registerInfo }) => {
  const [step, setStep] = useState(1)
  const [selectedValues, setSelectedValues] = useState({
    condicionIva: '',
    employeesNumber: ''
  })
  const { register, errors, setError, setValue, getValues } = useForm({
    mode: 'onBlur'
  })
  const values = getValues()

  const dispatch = useDispatch()

  const handleSubmit = (values) => {
    if (!validateCUILCUIT(values.cuit)) { return setError('cuit', { message: 'Verificá el número ingresado' }) }
    const objectValues = {
      ...values,
      ...selectedValues,
      isBusiness: registerInfo.type.name !== 'persona'
    }
    dispatch(editProfileData({
      companyName: values.nombreEmpresa
    }))
    dispatch(setTaxConditionToUser(registerInfo.professionalInfo.id, selectedValues.condicionIva))
    handleRegisterComponentClick(objectValues)
  }

  const handleNextStep = (values) => {
    setSelectedValues({ ...selectedValues, ...values })
    setStep(2)
  }

  const handleSelect = (key, value) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [key]: value
    }))
  }

  useEffect(() => {
    if (registerInfo.userInfo.nombreEmpresa) {
      setValue('nombreEmpresa', registerInfo.userInfo.nombreEmpresa)
      setValue('razonSocialEmpresa', registerInfo.userInfo.razonSocialEmpresa)
      setValue('cuit', registerInfo.userInfo.cuit)
      setSelectedValues({
        condicionIva: registerInfo.userInfo.condicionIva,
        employeesNumber: registerInfo.userInfo.employeesNumber
      })
    }
    // eslint-disable-next-line
  }, []);

  return step === 4
    ? (
      <Login
        type='professionalRegister'
        registerValues={registerInfo}
        handleRegisterComponentClick={handleNextStep}
      />
      )
    : (
      <div className={styles.companyRegisterContainer}>
        <div className={styles.form}>
          <Field
            type='text'
            name='nombreEmpresa'
            label='Nombre de la empresa/agencia'
            placeholder='Ingresá el nombre de tu empresa/agencia'
            inputRef={register({ required: 'Campo obligatorio' })}
            error={errors.nombreEmpresa}
            customStyles={{ marginBottom: '1.6rem' }}
          />
          <Field
            type='text'
            name='razonSocialEmpresa'
            label='Razón Social de la empresa/agencia'
            placeholder='Ingresá la razón social'
            inputRef={register({ required: 'Campo obligatorio' })}
            error={errors.razonSocialEmpresa}
            customStyles={{ marginBottom: '1.6rem' }}
          />
          <Field
            type='text'
            name='cuit'
            label='CUIT'
            placeholder='Ej: 30020101017'
            inputRef={register({
              required: 'El cuit es incorrecto. Debe contener 11 dígitos.',
              minLength: 11,
              maxLength: 11
            })}
            error={errors.cuit}
            customStyles={{ marginBottom: '1.6rem' }}
          />
          <AlertMessage
            message='El CUIT deberá ser ingresado sin guiones ni espacios.'
            type='alert'
          />
          <div className={styles.dropdownContainer}>
            <label className={styles.dropdownLabel}>
              ¿Cuál es tu condición ante IVA?
            </label>
            <Filter
              name='Seleccioná'
              options={[
                { name: 'Responsable Inscripto', id: 1 },
                { name: 'Monotributo', id: 2 },
                { name: 'Inscripción en trámite', id: 5 }
              ]}
              handleSelect={(selectedOption) => {
                handleSelect('condicionIva', selectedOption)
                sessionStorage.setItem('taxConditionId', selectedOption.id)
              }}
              selectedOptions={[selectedValues.condicionIva]}
              customStyles={{ width: '100%' }}
            />
          </div>
          <div className={styles.dropdownContainer}>
            <label className={styles.dropdownLabel}>
              ¿Cuántos empleados tiene? (opcional)
            </label>
            <Filter
              name='Seleccioná'
              options={[
                { name: '1 empleado', id: 1 },
                { name: '2 - 10 empleados', id: 2 },
                { name: '11 - 50 empleados', id: 3 },
                { name: '51 - 200 empleados', id: 4 },
                { name: '201 - 500 empleados', id: 5 },
                { name: '+ 501 empleados', id: 6 }
              ]}
              handleSelect={(selectedOption) =>
                handleSelect('employeesNumber', selectedOption)}
              selectedOptions={[selectedValues.employeesNumber]}
              customStyles={{ width: '100%' }}
            />
          </div>
        </div>
        <div className={styles.registerButtons}>
          <Button
            isDisabled={
            !values.nombreEmpresa ||
            !values.razonSocialEmpresa ||
            !values.cuit ||
            selectedValues.condicionIva === ''
          }
            customStyles={{ maxWidth: '12.4rem' }}
            onClickHandler={() => handleSubmit(values)}
          >
            Siguiente
          </Button>
        </div>
      </div>
      )
}

export default CompanyRegister
