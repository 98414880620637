import React from "react";
import GeneralSearch from "@molecules/GeneralSearch/GeneralSearch";
import SearchResult from "@organism/SearchResult/SearchResult";
import styles from "./styles.module.scss";

const Search = () => {
  return (
    <div className={styles.searchPage}>
      <div className={styles.searchContainer}>
        <GeneralSearch />
      </div>
      <div className={styles.prosSearchContainer}>
        <SearchResult />
      </div>
    </div>
  );
};

export default Search;
