import Home from '@pages/Home'
import Login from '@pages/Login'
import Register from '@pages/Register'
import RecoverPassword from '@pages/RecoverPassword'
import ForgotPassword from '@pages/ForgotPassword'
import Search from '@pages/Search'
import ClientRegisterSteps from '@pages/ClientRegisterSteps'
import ProfessionalProfile from '@pages/ProfessionalProfile'
import AdministrationPanelNew from '@pages/AdministrationPanelNew'
import PublicProfile from '@pages/PublicProfile'
import Works from '@pages/Works'
import Chat from '@pages/Chat'
import UserProfile from '@pages/UserProfile'
import UserWorks from '@pages/UserWorks'
import WorkDetails from '@pages/WorkDetails'
import AuthGoogle from '@pages/AuthGoogle'
import AuthFacebook from '@pages/AuthFacebook'
import ConfirmEmail from '@pages/ConfirmEmail'
import ValidateEmail from '@pages/ValidateEmail'
import ExternalReview from '@pages/ExternalReview'
import SuggestCategory from '@pages/SuggestCategory'
import RegisterSuccess from '@pages/RegisterSuccess'

export default [
  {
    path: '/',
    component: Home,
    exact: true
  },
  {
    path: '/iniciar-sesion',
    component: Login,
    exact: true
  },
  {
    path: '/auth-google',
    component: AuthGoogle,
    exact: true
  },
  {
    path: '/auth-facebook',
    component: AuthFacebook,
    exact: true
  },
  {
    path: ['/registro', '/registro-telefono'],
    component: Register,
    exact: true
  },
  {
    path: '/olvide-contrasena',
    component: ForgotPassword,
    exact: true
  },
  {
    path: '/recuperar-contrasena/:token',
    component: RecoverPassword,
    exact: true
  },
  {
    path: '/search/:categorySearchId',
    component: Search,
    exact: true
  },
  {
    path: ['/registro-profesionales', '/registro-profesionales-vertical', '/registro-profesionales-tipo', '/registro-profesionales-empresa'],
    component: ClientRegisterSteps,
    exact: true
  },
  {
    path: ['/registro-profesional-ok', '/conversion-profesional-ok'],
    component: RegisterSuccess,
    exact: true
  },
  {
    path: '/perfil-profesional',
    component: ProfessionalProfile,
    exact: true
  },
  {
    path: ['/panel-administracion', '/panel-administracion/:id'],
    component: AdministrationPanelNew,
    exact: true
  },
  {
    path: '/perfil-usuario',
    component: UserProfile,
    exact: true
  },
  {
    path: '/perfil/:id',
    component: PublicProfile,
    exact: true
  },
  {
    path: '/trabajos',
    component: Works,
    exact: true
  },
  {
    path: '/trabajos-usuario',
    component: UserWorks,
    exact: true
  },
  {
    path: '/detalle-trabajo/:id',
    component: WorkDetails,
    exact: true
  },
  {
    path: '/chat/:id',
    component: Chat,
    exact: true
  },
  {
    path: '/confirmar-email',
    component: ConfirmEmail,
    exact: true
  },
  {
    path: '/validar-email/:token',
    component: ValidateEmail,
    exact: true
  },
  {
    path: '/review-externa/:token',
    component: ExternalReview,
    exact: true
  },
  {
    path: '/sugerir-categoria',
    component: SuggestCategory,
    exact: true
  }
]
