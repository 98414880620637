export default function (baseApi) {
  return {
    intervals: {
      logout: 3600
    },
    baseURL: `${baseApi}`,
    ENV: 'production',
    isProduction: true,
    isDevelopment: false
  }
}
