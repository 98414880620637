import React, { useEffect, useState } from "react";
import Button from "@atoms/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import styles from "./SearchResult.module.scss";
import Filters from "@molecules/Filters/Filters";
import ProsCard from "@molecules/ProsCard/ProsCard";
import useScreenDetect from "@hooks/useScreenDetect";
import {
  getBarrios,
  getProfesionalResults,
  getCategorySons,
  getProfessionalData,
  setNewValues,
} from "@actions/";
import { useParams } from "react-router";
import { clearProfessionalResults, setModal } from "@actions/generalActions";
import LabelStories from "@atoms/Label/Label.stories";
import { axiosGet, axiosPost, axiosPut } from "@config/api";

const SearchResult = () => {
  const [searchValues, setSearchValues] = useState({});
  const {
    professionalsResult,
    barriosLocalidades,
    currentSearch,
    categorySons,
  } = useSelector((state) => state.general);
  const { professionalProfile, profileData } = useSelector(
    (state) => state.profile
  );

  const dispatch = useDispatch();
  const { categorySearchId } = useParams();
  const { isMobile } = useScreenDetect();
  const [loading, setLoading] = React.useState(true);

  const handleContactRequest = (type, proData) => {
    if (professionalProfile && professionalProfile.id === proData.id) {
      return dispatch(setModal(true, type));
    }
    dispatch(getProfessionalData(proData.user.id, type));
  };

  const handleBulkContactRequest = (type, professionalsResult) => {
    dispatch(getProfessionalData(professionalsResult.pros[0].user.id, type));
  };

  const makeNewSearchData = (key, value) => {

    if (!localStorage.getItem("currentSearch")) {

      return
    }
    let current;

    current = typeof localStorage.getItem("currentSearch") === 'string' ? JSON.parse(localStorage.getItem("currentSearch")) : null;



    if (current?.parentcategory && current.parentcategory.parentcategory === undefined) {
      // si filtre por VERTICAL
      value = [...new Set(value)]; // elimino repetidos (necesario para que filtre bien)
    }

    setSearchValues((prevSearchValues) => ({
      ...prevSearchValues,
      [key]: value,
    }));
  };

  const searchMobileProResults = (searchValues) => {
    dispatch(getProfesionalResults(searchValues));
  };

  useEffect(() => {
    dispatch(getCategorySons(Number(categorySearchId)));
    dispatch(getBarrios());
    dispatch(clearProfessionalResults());
    window.scrollTo({ top: 0, behavior: "smooth" });
    return () => {
      dispatch(clearProfessionalResults());
    };
    // eslint-disable-next-linef
  }, [categorySearchId]);

  useEffect(() => {


    makeNewSearchData("categoryIds", [Number(categorySearchId)]); //esta busqueda ena aún cuando localstorage está vacío
  }, [categorySearchId]);

  useEffect(() => {
    // Si lo siguiente es TRUE entonces es una vertical, SI TIENE ALGO ES NIVEL1
    if (localStorage.getItem("currentSearch") && localStorage.getItem("currentSearch").parentcategory) {
      let nivelOne = localStorage.getItem("currentSearch") !== null ?
        JSON.parse(localStorage.getItem("currentSearch")).parentcategory : 0
          .parentcategory === undefined;
      let id = localStorage.getItem("currentSearch")!== null ? JSON.parse(localStorage.getItem("currentSearch")).id : 0; //TODO Revisar si 0 funciona

      let values = {
        categoryIds:
          Object.keys(currentSearch).length === 0
            ? !nivelOne
              ? [Number(categorySearchId)]
              : [id]
            : [currentSearch.id],
      };
      setSearchValues(values);
    }

  }, [currentSearch]);

  useEffect(() => {


    if (searchValues.categoryIds) {
      dispatch(
        getProfesionalResults(
          !searchValues.categoryIds
            ? { categoryIds: categorySearchId }
            : searchValues
        )
      );
    }
    // eslint-disable-next-line
  }, [searchValues]);

  // const prosTotal =
  //   (professionalsResult && professionalsResult.count) ||
  //   "No se han encontrado";
  const prosTotal = React.useMemo(() => {
    if (professionalsResult.count) setLoading(false);
    return professionalsResult.count;
  }, [professionalsResult]);

  return (
    <div className={styles.searchResultContent}>
      <div className={styles.sendBulkMessage}>
        <div className={styles.bulkButtonContainer}>
          <div className={styles.bulkButton}>
            <Button
              buttonStyle="primary"
              customStyles={{
                maxWidth: isMobile ? "100%" : "max-content",
                fontWeight: "bold",
              }}
              onClickHandler={() =>
                handleBulkContactRequest("messageBulk", professionalsResult)
              }
              hoverText="Hover text"
            >
              ¿Qué necesitás? ⚡
            </Button>
          </div>
          <div className={styles.tooltip}>
            <p>
              Contanos qué necesitás y te ponemos en contacto con los mejores
              perfiles para ayudarte con tu pedido.
            </p>
          </div>
        </div>
        <h4>
          {!loading ? (
            <>
              {prosTotal
                ? prosTotal === 1
                  ? prosTotal + " profesional"
                  : prosTotal + " profesionales"
                : "No se han encontrado profesionales"}
            </>
          ) : null}
        </h4>
      </div>
      <Filters
        barrios={barriosLocalidades}
        categories={currentSearch.sons || categorySons}
        handleFilter={makeNewSearchData}
        handleMobileFilter={searchMobileProResults}
        setSearchValues={setSearchValues}
      />
      <div className={styles.searchResultGallery}>
        {localStorage &&
          professionalsResult?.pros?.map((pro, index) => (
            <ProsCard
              key={index}
              pro={pro}
              handleContactRequest={handleContactRequest}
              isProLogged={profileData?.isPro}
            />
          ))}
      </div>
    </div>
  );
};

export default SearchResult;
