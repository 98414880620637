import * as actionTypes from "../actions/types";
import { onboarding } from "../onboarding";

const INITIAL_STATE = {
  isMobile: false,
  noDistractionsMode: false,
  modal: false,
  modalType: null,
  prosCategories: [],
  verticalProsCategories: [],
  barriosLocalidades: [],
  currentSearch: {},
  categorySons: [],
  categoryBranch: [],
  professionalsResult: [],
  successModal: {},
  loading: false,
  horariosContacto: [],
  headerMobileGoBack: false,
  hashData: {},
  suggestValue: false,
  allCategories: [],
  allCategoriesSearch: [],
  mostSearched: [],
  successRegister: false,
  firstLogin: false,
  textReady: false,
  footerOnboarding: false,
  watchedVideo: 0,
  finishedVideos: false,
  dataOnboarding: onboarding,
  newValues: [],
  globalValuesSelected: {}
};

const spreadArraySon = (array, sonKey) => {
  let aux = [];
  array.map((item) => {
    return (aux = [...aux, ...item[sonKey]]);
  });
  return aux;
};
const spreadArrayVerticals = (array) => {
  let aux = [];
  array.map((item) => {
    return (aux = [...aux, { name: item.name, id: item.id, sons: item.sons }]);
  });
  return aux;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ONBOARDING:
      return {
        ...state,
        dataOnboarding: action.payload,
      };
    case actionTypes.FINISHED_VIDEOS:
      return {
        ...state,
        finishedVideos: action.payload,
      };
    case actionTypes.WATCHED_VIDEO:
      return {
        ...state,
        watchedVideo: state.watchedVideo + 1,
      };
    case actionTypes.SET_FOOTER_ONBOARDING:
      return {
        ...state,
        footerOnboarding: action.payload,
      };
    case actionTypes.SET_READY_TEXT:
      return {
        ...state,
        textReady: action.payload,
      };
    case actionTypes.SET_FIRSTLOGIN:
      return {
        ...state,
        firstLogin: action.payload,
      };
    case actionTypes.SET_GLOBAL_VALUES_SELECTED:
      return {
        ...state,
        globalValuesSelected: action.payload,
      };
    case actionTypes.SUCCESS_REGISTER:
      return {
        ...state,
        successRegister: action.payload,
      };
    case actionTypes.GET_PROS_CATEGORIES:
      return {
        ...state,
        prosCategories: spreadArraySon(action.payload, "sons"),
        verticalProsCategories: spreadArrayVerticals(action.payload),
      };
    case actionTypes.GET_PROS_PROFESSIONS:
      return {
        ...state,
        allCategories: action.payload,
      };
    case actionTypes.GET_PROS_PROFESSIONS_SEARCH:
      return {
        ...state,
        allCategoriesSearch: action.payload,
      };
    case actionTypes.GET_PROS_VERTICALS:
      return {
        ...state,
        verticalProsCategories: action.payload,
      };
    case actionTypes.GET_CATEGORY_SONS:
      return {
        ...state,
        categorySons: action.payload,
      };
    case actionTypes.GET_MULTI_CATEGORY_SONS:
      return {
        ...state,
        categorySons: action.payload,
      };
    case actionTypes.GET_CATEGORY_BRANCH:
      return {
        ...state,
        categoryBranch: action.payload,
      };
    case actionTypes.SET_PROFESSIONAL_RESULTS:
      return {
        ...state,
        professionalsResult: action.payload,
      };
    case actionTypes.SET_NO_DISTRACTION_MODE:
      return {
        ...state,
        noDistractionsMode: action.payload,
      };
    case actionTypes.SET_SUGGEST_VALUE:
      return {
        ...state,
        suggestValue: action.payload,
      };
    case actionTypes.SET_HEADER_MOBILE_GO_BACK:
      return {
        ...state,
        headerMobileGoBack: action.payload,
      };
    case actionTypes.SET_HASH_DATA:
      return {
        ...state,
        hashData: action.payload,
      };
    case actionTypes.GET_BARRIOS:
      return {
        ...state,
        barriosLocalidades: action.payload,
      };
    case actionTypes.GET_HORARIOS:
      return {
        ...state,
        horariosContacto: action.payload,
      };
    case actionTypes.SET_CURRENT_SEARCH:
      return {
        ...state,
        currentSearch: action.payload,
      };
    case actionTypes.SET_SUCCESS_MODAL:
      return {
        ...state,
        successModal: action.payload,
      };
    case actionTypes.SET_MOBILE_DEVICE:
      return {
        ...state,
        isMobile: true,
      };
    case actionTypes.SET_MODAL:
      return {
        ...state,
        modal: action.payload,
        modalType: action.modalType,
      };
    case actionTypes.CLEAN:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.MOST_SEARCHED:
      return {
        ...state,
        mostSearched: action.payload,
      };
    case actionTypes.SET_NEW_VALUES:
      return {
        ...state,
        newValues: action.payload,
      };

    default:
      return state;
  }
};
