import React, { useEffect } from 'react'
import Text from '@atoms/Text/Text'
import ConfirmEmailContent from '@organism/ConfirmEmail/ConfirmEmail'
import styles from './styles.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { setNoDistractionMode } from '@actions/generalActions'
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import { successRegister } from '@actions/generalActions';

const ConfirmEmail = () => {
  const success = useSelector(state => state['general']['successRegister']);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if(!success) history.push('/');
    return () => {
      dispatch(successRegister(false));
    }
  }, [])

  useEffect(() => {
    dispatch(setNoDistractionMode(true))

    return () => {
      dispatch(setNoDistractionMode(false))
    }
  }, [])

  useEffect(() => {
    const scriptPro = document.createElement("script");
    scriptPro.innerHTML =
      // "gtag('event', 'conversion', {'send_to': 'AW-417421308/nQbfCJeMno4DEPyvhccB'});";
      //"gtag('event', 'conversion', {'send_to': 'AW-10870553377/EK7MCL-sirUDEKH2vb8o'})";
    "gtag('event', 'conversion', {'send_to': 'AW-417421308/g1BmCIb06I0DEPyvhccB'})";  
    document.body.appendChild(scriptPro);
  }, []);
  
  return success ? (
    <div className={styles.container}>
      {/* FB PIXEL */}
      <img height='1' width='1' style={{ display: 'none' }} src='https://www.facebook.com/tr?id=303991264632997&ev=Contact' alt='FB PIXEL' />
      <Text
        tag='p'
        size='xl-1'
        weight='bold'
        customStyles={{ marginBottom: '4.8rem' }}
      >
        Validá tu cuenta
      </Text>
      <ConfirmEmailContent />;
    </div>
  ):null
}

export default ConfirmEmail
