import * as actionTypes from '../actions/types'
import { axiosDelete, axiosGet, axiosPost, axiosPut } from '@config/api'
import { setLoading, setModal, setNoDistractionMode, setSuccessModal } from './generalActions'
import {
  setIdInLS,
  setIsProInLS,
  setProfessionalProfileInLS,
  setProfileDataInLS
} from '@utils/tokenService'
import { setUserAfterCreate } from './registerActions'
import { store } from '../index'


export const setCurrentWorkData = (data) => {
  return {
    type: actionTypes.SET_CURRENT_WORK_DATA,
    payload: data
  }
}

export const setMobileFilter = (data) => {
  return {
    type: actionTypes.SET_MOBILE_FILTER,
    payload: data
  }
}
export const setWorkTypeSelectedRDX = (data) => {
  return {
    type: actionTypes.SET_WORK_TYPE,
    payload: data
  }
}

export const cleanMobileFilter = () => {
  return {
    type: actionTypes.CLEAN_MOBILE_FILTER
  }
}

export const addNotification = (type, content) => {
  const data = {
    type: type,
    content: content
  }
  return {
    type: actionTypes.ADD_NOTIFICATION,
    payload: data
  }
}

export const addNotificationForPro = (type, content) => {
  const data = {
    type: type,
    content: content
  }
  return {
    type: actionTypes.ADD_NOTIFICATION_FOR_PRO,
    payload: data
  }
}

export const removeNotification = (type, content) => {
  const data = {
    type: type,
    content: content
  }
  return {
    type: actionTypes.REMOVE_NOTIFICATION,
    payload: data
  }
}
export const removeNotificationForPro = (type, content) => {
  const data = {
    type: type,
    content: content
  }
  return {
    type: actionTypes.REMOVE_NOTIFICATION_FOR_PRO,
    payload: data
  }
}

export const setProfessionalProfileData = (data) => {
  return {
    type: actionTypes.SET_PROFESSIONAL_PROFILE,
    payload: data
  }
}
export const setPrivateProfile = (data) => {
  return {
    type: actionTypes.SET_PRIVATE_PROFILE,
    payload: data
  }
}

export const getProfileData = (userProfile, fromLogin = false, history) => {
  return (dispatch) => {
    axiosGet('/users/me')
      .then((response) => {
        if (userProfile) {
          dispatch({
            type: actionTypes.SET_USER_PROFILE,
            payload: response.data
          })
          dispatch({
            type: actionTypes.SET_PROFILE_DATA,
            payload: response.data
          })
        } else {
          dispatch({
            type: actionTypes.SET_PROFILE_DATA,
            payload: response.data
          })
        }
        const { role, isPro, firstLogin } = response.data
        setIdInLS(response.data.id)
        setProfileDataInLS(JSON.stringify(response.data))
        setIsProInLS(JSON.stringify(isPro))
        if (response.data.isPro) {
          axiosGet('/professional/me')
            .then((response) => {
              setProfessionalProfileInLS(JSON.stringify(response.data))
            })
            .catch((error) => {
              console.log(error.response)
            })
        }

        if (fromLogin) {
          if (role === 'ADMIN') {
            localStorage.setItem('admin', true);
            history.push('/panel-administracion')
          } else {
            if (isPro) {
              history.push('/perfil-profesional')
              if (firstLogin) {
                setTimeout(() => {
                  dispatch(setModal(true, 'firstLogin'))
                }, 300)
              }
            } else {
              history.push('/')
            }
          }
          window.scrollTo({ top: 0, behavior: 'auto' })
        }
      })
      .catch((error) => {
        console.log(error.response)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const clearProfileData = () => {
  return {
    type: actionTypes.SET_CLEAR_PROFILE
  }
}

export const editProfileData = (
  data,
  successModal,
  refreshData,
  fromProRegister,
  withoutLoading
) => {
  return (dispatch) => {
    if (!withoutLoading) dispatch(setLoading(true))
    axiosPut('/users/me', data)
      .then((response) => {
        if (successModal) {
          dispatch(getProfileData(true))
          dispatch(setModal(true, 'successModal'))
          dispatch(
            setSuccessModal({
              title: '¡Listo!',
              subtitle: 'Modificaste correctamente tus datos'
            })
          )
        }
        if (fromProRegister) dispatch(setUserAfterCreate())
        if (refreshData) dispatch(getProfileData())
      })
      .catch((error) => {
        console.log(error.response)
      })
      .finally(() => {
        if (!withoutLoading) dispatch(setLoading(false))
      })
  }
}
export const uploadProfessionalFile = (id, file) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    axiosPost(`/professional/${id}/file`, file)
      .then((response) => {
        dispatch(getPrivateProfile())
        dispatch(setModal(true, 'successModal'))
        dispatch(
          setSuccessModal({
            title: '¡Listo!',
            subtitle: 'Modificaste correctamente tus datos'
          })
        )
      })
      .catch((error) => {
        console.log(error.response)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}
export const deleteProfessionalFile = (id, files) => {
  const objectValue = {
    professionalProfileId: Number(id),
    professionalFiles: files
  }
  return (dispatch) => {
    dispatch(setLoading(true))
    axiosDelete('/professional/files', { data: { ...objectValue } })
      .then((response) => {
        dispatch(getPrivateProfile())
        dispatch(setModal(true, 'successModal'))
        dispatch(
          setSuccessModal({
            title: '¡Listo!',
            subtitle: 'Modificaste correctamente tus datos'
          })
        )
      })
      .catch((error) => {
        console.log(error.response)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const setUserToProReview = (data) => {
  const objectData = {
    evaluatedUserId: data.userId,
    evaluation: data.valoration,
    comment: data.message,
    professionalProfileId: data.professionalId,
    jobId: data.workId
  }
  return (dispatch) => {
    axiosPost('/users/review/internal', objectData)
      .then((response) => {
        dispatch(setModal(true, 'successModal'))
        dispatch(
          setSuccessModal({
            title: '¡Listo!',
            subtitle: 'Enviaste la calificación con éxito',
            buttonText: 'Volver a Solicitudes',
            withoutRedirect: true
          })
        )
        dispatch(getUserWorksRequests())
      })
      .catch((error) => {
        console.log(error.response)
      })
  }
}

export const setProToUserReview = (data) => {
  const objectData = {
    evaluatedUserId: data.userId,
    evaluation: data.valoration,
    comment: data.message,
    professionalProfileId: data.professionalId,
    jobId: data.workId
  }
  return (dispatch) => {
    axiosPost('/professional/review/internal', objectData)
      .then((response) => {
        dispatch(setModal(true, 'successModal'))
        dispatch(
          setSuccessModal({
            title: '¡Listo!',
            subtitle: 'Enviaste la calificación con éxito',
            buttonText: 'Volver a Solicitudes',
            withoutRedirect: true
          })
        )
        dispatch(getWorksRequests())
      })
      .catch((error) => {
        console.log(error.response)
      })
  }
}

export const setProfessionalWorkStatus = (
  id,
  status,
  reason,
  jobStatusNewRead
) => {
  const objectData = {
    jobStatus: status,
    reason: reason || '',
    jobStatusNewRead: jobStatusNewRead
  }
  return (dispatch) => {
    dispatch(setLoading(true))
    axiosPut(`/professional/jobs/${id}`, objectData)
      .then((response) => {
        dispatch(setModal(true, 'successModal'))
        dispatch(
          setSuccessModal({
            title: '¡Listo!',
            subtitle: 'La solicitud fue aceptada con éxito',
            buttonText: 'Volver a Solicitudes',
            withoutRedirect: true
          })
        )
        dispatch(getWorksRequests())
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const setUserWorkStatus = (
  id,
  status,
  successModalValues,
  reason,
  jobStatusNewRead
) => {
  const objectData = {
    jobStatus: status,
    reason: reason || '',
    jobStatusNewRead: jobStatusNewRead
  }
  return (dispatch) => {
    dispatch(setLoading(true))
    axiosPut(`/users/jobs/${id}`, objectData)
      .then((response) => {
        dispatch(getUserWorksRequests())
        if (successModalValues) {
          dispatch(setModal(true, 'successModal'))
          dispatch(setSuccessModal(successModalValues))
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const getWorkDetail = (id) => {
  return (dispatch) => {
    axiosGet('/professional/jobs')
      .then((response) => {
        const allWorks = [
          ...response.data.pending,
          ...response.data.approved,
          ...response.data.finished
        ]
        const workDetail = allWorks.find((work) => work.id === id)
        dispatch(setCurrentDetailWork(workDetail))
      })
      .catch((error) => {
        console.log(error.response)
      })
  }
}
export const getUserWorkDetail = (id) => {
  return (dispatch) => {
    axiosGet('/users/jobs')
      .then((response) => {
        const allWorks = [
          ...response.data.workInProgress,
          ...response.data.pendingReview,
          ...response.data.finished,
          ...response.data.trash
        ]
        const workDetail = allWorks.find((work) => work.id === Number(id))
        dispatch(setCurrentDetailWork(workDetail))
      })
      .catch((error) => {
        console.log(error.response)
      })
  }
}

export const getWorksRequests = () => {
  return (dispatch) => {
    axiosGet('/professional/jobs')
      .then((response) => {
        const workList = [
          {
            name: 'Nuevas solicitudes',
            idName: 'pending',
            id: 1,
            works: response.data.pending
          },
          {
            name: 'En curso',
            idName: 'approved',
            id: 2,
            works: response.data.approved
          },
          {
            name: 'Completado',
            idName: 'finished',
            id: 3,
            works: response.data.finished
          }
        ]
        workList[0].works.forEach((work) => {
          if (work.jobStatusNewRead === 'NOTIFY_PRO') {
            dispatch(addNotification('work', `workid${work.id}`))
          }
        })
        workList[1].works.forEach((work) => {
          if (work.jobStatusNewRead === 'NOTIFY_PRO') {
            dispatch(addNotification('work', `workid${work.id}`))
          }
        })
        workList[2].works.forEach((work) => {
          if (work.jobStatusNewRead === 'NOTIFY_PRO') {
            dispatch(addNotification('work', `workid${work.id}`))
          }
        })
        if (
          !store.getState().profile.worksList ||
          (JSON.stringify(store.getState().profile.worksList).length ?? []) !==
          JSON.stringify(workList).length
        ) {
          dispatch({
            type: actionTypes.SET_WORKS_LIST,
            payload: workList
          })
        }
      })
      .catch((error) => {
        console.log(error.response)
      })
  }
}
export const getUserWorksRequests = () => {
  return (dispatch) => {
    axiosGet('/users/jobs')
      .then((response) => {
        const workList = [
          {
            name: 'En curso',
            idName: 'current',
            id: 1,
            works: response.data.workInProgress
          },
          {
            name: 'Pendientes de calificar',
            idName: 'qualificationPending',
            id: 2,
            works: response.data.pendingReview
          },
          {
            name: 'Completados',
            idName: 'finished',
            id: 3,
            works: response.data.finished
          },
          {
            name: 'Descartados',
            idName: 'rejected',
            id: 4,
            works: response.data.trash
          }
        ]

        workList[0].works.forEach((work) => {
          if (work.jobStatusNewRead === 'NOTIFY_USER') {
            dispatch(addNotification('work', `workid${work.id}`))
          }
        })
        workList[1].works.forEach((work) => {
          if (work.jobStatusNewRead === 'NOTIFY_USER') {
            dispatch(addNotification('work', `workid${work.id}`))
          }
        })
        if (
          !store.getState().profile.worksList ||
          (JSON.stringify(store.getState().profile.worksList).length ?? []) !==
          JSON.stringify(workList).length
        ) {
          dispatch({
            type: actionTypes.SET_USER_WORKS_LIST,
            payload: workList
          })
        }
      })
      .catch((error) => {
        console.log(error.response)
      })
  }
}
export const setCurrentDetailWork = (work) => {
  return {
    type: actionTypes.SET_CURRENT_DETAIL_WORK,
    payload: work
  }
}

export const editProfessionProfile = (
  id,
  data,
  fromRegister = false,
  showModal = true,
  userId
) => {
  return (dispatch) => {
    axiosPut(`/users/professions/${id}`, data)
      .then((response) => {
        dispatch(getPrivateProfile())
        if (!fromRegister && showModal) {
          dispatch(setModal(true, 'successModal'))
          dispatch(
            setSuccessModal({
              title: '¡Listo!',
              subtitle: 'Actualizaste tus datos correctamente.',
              withoutRedirect: true
            })
          )
        }
        dispatch(getProfessionalData(userId))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const editExternalValorations = (data) => {
  return (dispatch) => {
    axiosPut('/professional/review/external', data)
      .then((response) => {
        dispatch(getProfessionalExternalReviews(data.professionalProfileId))
        dispatch(getPrivateProfile())
        dispatch(setModal(true, 'successModal'))
        dispatch(
          setSuccessModal({
            title: '¡Listo!',
            subtitle: 'Actualizaste tus datos correctamente.',
            withoutRedirect: true
          })
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

// professional

export const getProfessionalData = (id, type) => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    // eslint-disable-next-line
    const response = await axiosGet("/guests/professional-profile", {
      data: null,
      params: { id: id }
    })
    dispatch(setProfessionalProfileData(response.data))
    type && dispatch(setModal(true, type))
    dispatch(setLoading(false))
  } catch (error) {
    console.log({ error })
  }
}

export const getPrivateProfile = () => {
  return (dispatch) => {
    dispatch(setLoading(true))
    axiosGet('/professional/me')
      .then((response) => {
        dispatch(setPrivateProfile(response.data))
      })
      .catch((error) => {
        console.log({ error })
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export const cleanProfile = () => {
  return {
    type: actionTypes.SET_PROFILE_DATA,
    payload: {}
  }
}

export const getProfessionalExternalReviews = (id) => {
  return (dispatch) => {
    axiosGet(`/professional/review/external?professionalId=${id}`)
      .then((response) => {
        dispatch({
          type: actionTypes.SET_PROFESSIONAL_EXTERNAL_REVIEWS,
          payload: response.data
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const getUnreadMessagesFromWork = (roomId, userId) => {
  return (dispatch) => {
    axiosGet(`/chat/${roomId}/${userId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_UNREAD_MESSAGE,
          payload: { jobId: roomId, userId: userId, messages: response.data }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const removeUnreadMessage = (jobId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_UNREAD_MESSAGE,
      payload: jobId
    })
  }
}

export const setJobReadStatus = (jobId, status) => {
  return (dispatch) => {
    axiosPut(`/users/jobs/${jobId}/status/${status}`)
      .then((response) => {
        if (status === 'NOTIFY_PRO') {
          dispatch(getWorksRequests())
        } else if (status === 'NOTIFY_USER') {
          dispatch(getUserWorksRequests())
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const setProfileMode = (
  mode,
  history,
  fromRegister = false,
  redirect = false,
  urlToRedirect
) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    axiosPut(`/users/profilemode/${mode}`)
      .then((response) => {
        if (!fromRegister) {
          dispatch(getProfileData(mode === 'USER'))
          if (mode === 'USER') {
            dispatch(getUserWorksRequests())
          } else {
            dispatch(getWorksRequests())
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        if (history) {
          if (history.location.pathname.includes('chat')) {
            history.push('/')
          } else if (history.location.pathname === '/trabajos') {
            dispatch(getUserWorksRequests())
            history.push('/trabajos-usuario')
          } else if (history.location.pathname === '/trabajos-usuario') {
            dispatch(getWorksRequests())
            history.push('/trabajos')
          } else if (history.location.pathname === '/perfil-profesional') {
            dispatch(getUserWorksRequests())
            history.push('/perfil-usuario')
          } else if (history.location.pathname === '/perfil-usuario') {
            dispatch(getWorksRequests())
            history.push('/perfil-profesional')
          }
          if (redirect) {
            history.push(urlToRedirect)
          }
        }
        dispatch(setLoading(false))
      })
  }
}
