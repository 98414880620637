import { AnimatePresence, motion } from 'framer-motion'
import React, {useEffect} from 'react'
import styles from './RadioButton.module.scss'

const RadioButton = ({ value, selected, handleClick, customStyles, oneSelect, steps }) => {
  
  return (
    <div
      className={`${styles.radioButton} ${selected && styles.selected}`}
      onClick={() => handleClick(value)}
      style={customStyles}
    >
      <AnimatePresence>
        {selected
          ? (
            <motion.div
              initial={{
                width: '2.4rem',
                height: '2.4rem',
                border: '1px solid #dedede',
                borderRadius: '50%',
                marginRight: '1.2rem'
              }}
              animate={{
                width: '2.4rem',
                height: '2.4rem',
                border: '7px solid #320fcc',
                borderRadius: '50%',
                marginRight: '1.2rem'
              }}
              transition={{ bounce: 0 }}
            />
            )
          : (
            <span className={styles.circle} />
            )}
      </AnimatePresence>
      <p className={styles.label}>{value.name}</p>
    </div>
  )
}

export default RadioButton
