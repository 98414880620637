export const setToken = (token, name, role, id) => {
  localStorage.setItem('access_token', token)
  localStorage.setItem('name', name)
  localStorage.setItem('role', role)
}

export const setAdminP = () => {
  localStorage.setItem('admin', true);
  localStorage.setItem('role', 'ADMIN');
}

export const setOneTime = () => {
  localStorage.setItem('oneTime', true);
}

export const setIdInLS = (id) => {
  localStorage.setItem('id', id)
}

export const setProfileDataInLS = (data) => {
  localStorage.setItem('profileData', data)
}

export const setProfessionalProfileInLS = (data) => {
  localStorage.setItem('professionalProfile', data)
}

export const setIsProInLS = (data) => {
  localStorage.setItem('isPro', data)
}

export const setFromSwitchInLS = () => {
  localStorage.setItem('fromSwitch', true)
}

export const existToken = () => {
  return localStorage.getItem('access_token') !== null
}

export const getToken = () => {
  return localStorage.getItem('access_token')
}

export const getRole = () => {
  return localStorage.getItem('role')
}

export const getNameFromLS = () => {
  return localStorage.getItem('name')
}
export const getIdFromLS = () => {
  return localStorage.getItem('id')
}

export const getProfileFromLS = () => {
  return JSON.parse(localStorage.getItem('profileData'))
}

export const getProfessionalProfileFromLS = () => {
  return JSON.parse(localStorage.getItem('professionalProfile'))
}

export const getIsProFromLS = () => {
  return JSON.parse(localStorage.getItem('isPro')) ?? false
}

export const getFromSwitchFromLS = () => {
  return JSON.parse(localStorage.getItem('fromSwitch')) ?? false
}

export const deleteToken = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('name')
  localStorage.removeItem('id')
  localStorage.removeItem('role')
  localStorage.removeItem('profileData')
  localStorage.removeItem('professionalProfile')
  localStorage.removeItem('isPro')
  localStorage.removeItem('selectedValue')
  localStorage.removeItem('professional-register-info')
  localStorage.removeItem('fromSwitch')
  localStorage.removeItem('oneTime')
  localStorage.removeItem('admin')
  sessionStorage.clear()
}
