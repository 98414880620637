import React, { useState } from "react";
import Text from "@atoms/Text/Text";
import { CATEGORIES } from "./constants";
import { AnimatePresence, motion } from "framer-motion";
import styles from "../styles.module.scss";
import vectorP from "@assets/svg/Vector-p.svg";
import vectorS from "@assets/svg/Vector-s.svg";
import MobileServiceBanner from "./MobileServiceBanner";

export const MobileCategoriesSection = () => {
  const [openLists, setOpenLists] = useState();
  // const [selectedCatId, setSelectedCatId] = useState(1);
  const [selectedCatSubcategories, setSelectedCatSubcategories] = useState(
    CATEGORIES[0].subcategories
  );
  // const handleOpenList = (list) => {
  //   if (openLists.includes(list))
  //     return setOpenLists(openLists.filter((i) => i !== list));
  //   return setOpenLists([...openLists, list]);
  // };

  const handleSelectCat = (catId) => {
    // setSelectedCatId(catId);
    const auxSelectedCatSubcategories = CATEGORIES.find(
      (category) => category.id === catId
    );
    if (!auxSelectedCatSubcategories) {
      // setSelectedCatId(1);
      setSelectedCatSubcategories(CATEGORIES[0].subcategories);
    } else {
      setSelectedCatSubcategories(auxSelectedCatSubcategories.subcategories);
    }
  };
  return (
    <div className={styles.categoriesSliderContent}>
      <Text
        tag="h2"
        weight="extrabold"
        size="l"
        align="center"
        customStyle={styles.title}
      >
        Conocé todos los servicios que podés encontrar acá
      </Text>
      <Text
        tag="h3"
        size="m"
        align="center"
        weight="semibold"
        customStyle={styles.subtitle}
      >
        Expertos &amp; Profesionales
      </Text>
      {CATEGORIES.map((category, index) => (
        <>
          <div
            key={category.name}
            className={`${styles.categoryContainer} ${openLists === index &&
              styles.active}`}
            onClick={() => {
              openLists === index ? setOpenLists(null) : setOpenLists(index);
              handleSelectCat(category.id);
            }}
          >
            <Text
              tag="h2"
              size="m"
              align="left"
              className={styles.categoryTitle}
            >
              {category.name}
            </Text>
            <img
              src={index % 2 === 0 ? vectorP : vectorS}
              className={`${styles.vector} ${openLists === index &&
                styles.open}`}
              alt=""
            />

            <AnimatePresence>
              {openLists === index && (
                <motion.div
                  initial={{ height: 0, overflow: "hidden" }}
                  animate={{
                    height: "max-content",
                    overflow: "hidden",
                  }}
                  exit={{ height: 0, overflow: "hidden" }}
                  className={styles.subcategoriesContainer}
                >
                  {selectedCatSubcategories.map((subcategory, j) => (
                    <div
                      key={subcategory.title}
                      className={`${styles.subcategoriesBanner}`}
                    >
                      <MobileServiceBanner banner={subcategory} />
                    </div>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </>
      ))}
    </div>
  );
};
