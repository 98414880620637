import { setUserToProReview, setProToUserReview, setJobReadStatus } from '@actions/index'
import Button from '@atoms/Button/Button'
import ModalTitle from '@atoms/ModalTitle/ModalTitle'
import Text from '@atoms/Text/Text'
import TextArea from '@atoms/TextArea/TextArea'
import useScreenDetect from '@hooks/useScreenDetect'
import StarReview from '@molecules/StarReview/StarReview'
import { getIdFromLS } from '@utils/tokenService'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './ReviewModal.module.scss'

const ReviewModal = ({ currentWork, fromUser }) => {
  const [value, setValue] = useState(0)
  const [message, setMessage] = useState('')
  const [step, setStep] = useState(1)
  const fullName = !fromUser
    ? `${currentWork.client.name} ${currentWork.client.lastName}`
    : `${currentWork.professionalProfile.user.name} ${currentWork.professionalProfile.user.lastName}`

  const dispatch = useDispatch()

  const { isMobile } = useScreenDetect()
  const handleClick = () => {
    if (step === 1) return setStep(2)
    if (fromUser) {
      return dispatch(
        setUserToProReview({
          userId: currentWork.professionalProfile.user.id,
          valoration: value,
          message: message,
          workId: currentWork.id,
          professionalId: currentWork.professionalProfile.id
        })
      )
    }
    return (
      dispatch(
        setProToUserReview({
          userId: currentWork.client.id,
          valoration: value,
          message: message,
          workId: currentWork.id,
          professionalId: Number(getIdFromLS())
        })
      ),
      dispatch(setJobReadStatus(currentWork.id, 'READ'))
    )
  }

  const handleSelectValue = (valueSelected) => {
    setValue(valueSelected)
  }

  const handleMessageChange = (e) => {
    if (e.length <= 600) setMessage(e)
    if (e.length > 600) setMessage(e.slice(0, 600))
  }

  return (
    <div className={styles.container}>
      <ModalTitle title='Calificá tu experiencia' />
      {step === 1
        ? (
          <>
            <Text tag='p' size='m'>
              ¿Cuántas estrellas le darías a <b>{fullName}</b>?
            </Text>
            <StarReview handleSelectValue={handleSelectValue} />
          </>
          )
        : (
          <div className={styles.textArea}>
            <TextArea
              name='message'
              placeholder='Contanos como fue tu experiencia'
              value={message}
              onChangeValue={handleMessageChange}
              customStyles={{ minHeight: '144px' }}
            />
            <Text tag='p' size='s' color='secondary-blue' align='right'>
              {message.length}/600
            </Text>
          </div>
          )}
      <div className={`${styles.buttons} ${step === 2 && styles.end}`}>
        <Button
          isDisabled={step === 1 ? value === 0 : message.length === 0}
          onClickHandler={() => handleClick()}
          customStyles={{
            maxWidth: isMobile ? '100%' : 'max-content'
          }}
        >
          Calificar
        </Button>
      </div>
    </div>
  )
}
export default ReviewModal
