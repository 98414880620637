import React, { useEffect, useRef, useState } from "react"
import styles from "../styles.module.scss"
import ServiceBanner from "./ServiceBanner"
import Text from "@atoms/Text/Text"
import { CATEGORIES } from "./constants"
import { axiosGet, axiosPost, axiosPut } from "@config/api"
import MayorAzul from "@assets/svg/mayorAzul.svg"
import MayorNaranja from "@assets/svg/mayorNaranja.svg"

const CategoriesSliderSection = () => {
  const [selectedCatId, setSelectedCatId] = useState(1)
  const [boxWidth, setBoxWidth] = useState(0)
  const [selectedCatSubcategories, setSelectedCatSubcategories] = useState(
    CATEGORIES[0].subcategories
  )
  const [translateX, setTranslateX] = useState(0)

  const node = useRef()

  useEffect(() => {
    if (node.current) setBoxWidth(node.current.offsetWidth)
  }, [node])

  const handleSelectCat = (catId) => {
    setSelectedCatId(catId)
    setTranslateX(0)
    const auxSelectedCatSubcategories = CATEGORIES.find(
      (category) => category.id === catId
    )
    if (!auxSelectedCatSubcategories) {
      setSelectedCatId(1)
      setSelectedCatSubcategories(CATEGORIES[0].subcategories)
    } else {
      setSelectedCatSubcategories(auxSelectedCatSubcategories.subcategories)
    }
  }

  const handleMoveSwipe = () => {
    if ((translateX + 300) * 3.5 > 300 * selectedCatSubcategories.length) {
      return handleSelectCat(selectedCatId + 1)
    }
    boxWidth && setTranslateX(translateX + 300)
  };
  return (
    <div className={styles.categoriesSliderContent}>
      <div className={styles.titleCategories}>
        <Text
          tag="h1"
          weight="bold"
          size="xxl"
          align="left"
          customStyle={styles.title}
        >
          Conocé todos los servicios que podés encontrar acá
        </Text>
        <Text
          tag="h2"
          weight="bold"
          size="xxl"
          align="left"
          customStyle={styles.title}
        >
          Expertos & Profesionales
        </Text>
      </div>
      <div className={styles.categoriesSliderTabs}>
        {CATEGORIES.map((category, index) => (
          <div
            key={index}
            className={`${styles.categoryTab} ${selectedCatId === category.id &&
              styles.active}`}
            onClick={() => handleSelectCat(category.id)}
          >
            <div className={styles.cardCategory}>
              <h3>{category.name}</h3>
              {index <= 3 || (index > 7 && index <= 11) || (index > 15 && index <= 19) ? (
                <img src={MayorAzul} />
              ) : (
                <img src={MayorNaranja} />
              )}
            </div>
          </div>
        ))}
      </div>

      <div className={styles.subTitleCategories}>
        <Text
          tag="h2"
          weight="bold"
          size="xxl"
          align="left"
          customStyle={styles.title}
        >
          Destacados por categoría
        </Text>
      </div>
      <div ref={node} className={styles.subcategoriesWrapper}>
        {selectedCatSubcategories.map((subcategory) => (
          <div
            key={subcategory.title}
            className={`${styles.subcategoriesBanner}`}
            style={{ transform: `translateX(-${translateX}%)` }}
          >
            <ServiceBanner banner={subcategory} />
          </div>
        ))}
        <span className={styles.moveSlider} onClick={() => handleMoveSwipe()} />
      </div>
    </div>
  )
}

export default CategoriesSliderSection
