import React from 'react'
import { motion } from 'framer-motion'
import Lottie from 'react-lottie'
import loaderData from '@assets/lotties/loading.json'
import CompanyRegister from '@organism/CompanyRegister/CompanyRegister'

const BusinessStep = ({ handleClick, type, registerInfo, loading }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  return loading
    ? (
      <Lottie
        options={{ animationData: loaderData, ...defaultOptions }}
        style={{
          marginTop: '0px',
          marginBottom: '1.6rem',
          paddingTop: '0px',
          paddinBottom: '0px'
        }}
        height={150}
        width={150}
      />
      )
    : (
      <motion.div
        initial={{ x: 500 }}
        animate={{ x: 0 }}
        exit={{ x: -300 }}
        transition={{ duration: 0.4 }}
      >
        <CompanyRegister
          handleRegisterComponentClick={handleClick}
          registerInfo={registerInfo}
        />
      </motion.div>
      )
}

export default BusinessStep
