import { getGoogleRedirect } from '@actions/authActions'
import { setLoggedByFaceOrGoogle } from '@actions/registerActions'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

const AuthGoogle = () => {
  const code = useLocation().search
  const history = useHistory()
  const { isAuth } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    const fromProfessionalRegister = localStorage.getItem(
      'professional-register-info'
    )
    const fromRegister = localStorage.getItem('register-info')
    if (isAuth) {
      if (fromRegister) return history.push('/registro-telefono')
      if (fromProfessionalRegister) {
        sessionStorage.setItem('authGoogleOrFacebook', '1')
        dispatch(setLoggedByFaceOrGoogle())
        return history.push('/registro-profesionales-vertical')
      }
      history.push('/')
    }
    // eslint-disable-next-line
  }, [isAuth]);

  useEffect(() => {
    const fromProfessionalRegister = localStorage.getItem(
      'professional-register-info'
    )
    if (fromProfessionalRegister) {
      const { type } = JSON.parse(fromProfessionalRegister)
      return dispatch(getGoogleRedirect(code, type))
    }
    dispatch(getGoogleRedirect(code))
    // eslint-disable-next-line
  }, []);

  return <div />
}
export default AuthGoogle
