import React from "react";
import visto from "../../../assets/svg/done.svg";
import cruz from "../../../assets/svg/cruz.svg";
import styles from "./tables.module.scss";
import Select from "@atoms/Select/Select";
import useScreenDetect from "@hooks/useScreenDetect";
import { useHistory } from "react-router-dom";
import { axiosGet, axiosPost, axiosPut } from "@config/api";
import { setModal } from "@actions/generalActions";
import { useDispatch, useSelector } from "react-redux";
import { professional, setPanelModal } from "@actions/panelActions";

const Professional = ({ data, panel }) => {
  const { isMobile } = useScreenDetect();
  const options = [{ name: "Ver detalle" }, { name: "Eliminar" }];
  const history = useHistory();

  const detailFunction = async (proId) => {
    history.push(`/panel-administracion/${proId}`);
  };
  const dispatch = useDispatch();

  const delProFunction = async ({ id, email }) => {
    await axiosGet(`/admin/users/${id}`).then((response) => {
      dispatch(professional(response.data));
    });
    dispatch(setModal(true, "modalPanel"));
    dispatch(
      setPanelModal({
        title: "Eliminar cuenta",
        modalPanel: "eliminar",
        question: "¿Estás seguro de eliminar definitivamente la cuenta de ",
        email,
      })
    );
  };

  const goProfile = (id) => {
    localStorage.removeItem('role');
    history.push(`/perfil/${id}`);
    localStorage.setItem('role', 'ADMIN');
  }

  const crearRowsPros = () => {
    return data.map((professional) => (
      <tr key={professional.id}>
        <td>{professional.user?.name}</td>
        <td>{professional.user?.lastName}</td>
        <td>{professional.fantasy}</td>
        <td>{professional.user?.email}</td>
        <td>{professional.user?.phone}</td>
        <td>{professional.title}</td>
        <td>{professional.residenceArea}</td>
        <td className={styles.reviews}>{professional.reviews.length}</td>
        <td className={styles.avgReviews}>
          {professional.avgReviews.toFixed(2)}
        </td>
        <td className={styles.servicios}>
          {professional.professionalProfileCategory?.length > 2 ? (
            <img className={styles.done} src={visto} />
          ) : (
            <img src={cruz} />
          )}
        </td>
        <td className={styles.masSobreMi}>
          {professional.description ? (
            <img className={styles.done} src={visto} />
          ) : (
            <img src={cruz} />
          )}
        </td>
        <td className={styles.verPerfil}>
          <a
            // href={`/perfil/${professional.user.id}`}
            // target="_blank"
            style={{cursor: 'pointer'}}
            onClick={()=> goProfile(professional.user.id)}
          >
            Ver Perfil
          </a>
        </td>
        <td>
          <Select
            panel
            placeholder="Modalidad"
            options={options}
            defaultSelected="Selecciona"
            // handleSelectAction={(value) =>
            //   handleSelectValue("", value.name)
            // }
            isMobile={isMobile}
            selectOption={() => detailFunction(professional.user.id)}
            delProOption={() => delProFunction(professional.user)}
            customStyles={{
              marginRight: "0.8rem",
              height: !panel
                ? isMobile
                  ? "3rem"
                  : "5.6rem"
                : isMobile
                ? "3rem"
                : "4rem",
            }}
          />
        </td>
      </tr>
    ));
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Alias</th>
          <th>Email</th>
          <th>Teléfono</th>
          <th>Profesión</th>
          <th>Residencia</th>
          <th>Valoraciones</th>
          <th>Promedio</th>
          <th className={styles.thservicios}>Servicios</th>
          <th className={styles.thmasSobreMi}>Más sobre mí</th>
          <th>Perfil</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>{crearRowsPros()}</tbody>
    </table>
  );
};

export default Professional;
