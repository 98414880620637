import React from 'react'
import { withKnobs, text } from '@storybook/addon-knobs'
import Label from './Label'

export const normal = () => <Label htmlFor='name'>{text('Label String', 'Nombre')}</Label>

export default {
  title: 'atoms/Label',
  component: Label,
  decorators: [withKnobs]
}
