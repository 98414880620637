import Button from '@atoms/Button/Button'
import ImageUpload from '@atoms/ImageUpload/ImageUpload'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './ProfileEditModal.module.scss'
const ProfileEditWorkDone = ({
  profileData,
  isMobile,
  handleSubmit,
  handleDeleteFile
}) => {
  const [works, setWorks] = useState(profileData.professionalProfileFile || [])
  const [deletedWorks, setDeletedWorks] = useState([])

  const { loading } = useSelector((state) => state.general)

  const handleDeleteWork = (work) => {
    setDeletedWorks([...deletedWorks, { id: work.id }])
    const auxWorks = works.filter((i) => i.id !== work.id)
    setWorks(auxWorks)
  }

  const handleImageChange = (file) => {
    const form = new FormData()
    form.append('file', file)
    handleSubmit(form)
  }

  const handleSave = () => {
    if (deletedWorks.length < 1) return
    handleDeleteFile(deletedWorks)
  }

  return (
    <div className={styles.container}>
      <div className={styles.workContent}>
        {works?.map((work, index) => (
          <div key={index} className={styles.work}>
            {work.file_url.includes('.pdf')
              ? (
                <embed
                  src={work.file_url}
                  width='120'
                  height='120'
                  type='application/pdf'
                />
                )
              : (
                <img
                  src={work.file_url}
                  alt={`${profileData.user.name} ${profileData.user.name} trabajo - ${index}`}
                  className={styles.workImage}
                />
                )}
            <p className={styles.delete} onClick={() => handleDeleteWork(work)}>
              Eliminar
            </p>
          </div>
        ))}
        <ImageUpload
          handleImageChange={handleImageChange}
          acceptPDF
          customStyles={{ margin: '0 2.4rem 2.4rem 0' }}
          keyId='modalWorkDone'
        />
      </div>
      <div className={styles.modalFooter}>
        <Button
          isLoading={loading}
          customStyles={{
            maxWidth: isMobile ? '100%' : '11.5rem'
          }}
          onClickHandler={() => handleSave()}
        >
          Guardar
        </Button>
      </div>
    </div>
  )
}
export default ProfileEditWorkDone
