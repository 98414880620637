import React from 'react'
import styles from './MenuIcon.module.scss'

const MenuIcon = ({ open, clickHandler, style }) => {
  return (
    <div
      className={styles.container}
      onClick={() => (open ? clickHandler(false) : clickHandler(true))}
    >
      <span className={`${styles.line} ${open && styles.firstOpened}`} style={style} />
      <span className={`${styles.line} ${open && styles.hideOpened}`} style={style} />
      <span className={`${styles.lastLine} ${open && styles.lastOpened}`} style={style} />
    </div>
  )
}

export default MenuIcon
