import React, { useState, useEffect } from 'react';
import styles from './ChatMessage.module.scss';
// import { useSelector, useDispatch } from "react-redux";
// import {
//   getWorkDetail,
//   getUserWorkDetail,
//   setCurrentDetailWork,
//   setUserWorkStatus,
//   setJobReadStatus,
//   getUserWorksRequests
// } from '@actions/profileActions'
// import {
//   setHeaderMobileGoBack,
//   setNoDistractionMode
// } from '@actions/generalActions'
// import { useParams } from 'react-router'
// import { setRegisterSpecialityAttempt } from '@actions/';


const ChatMessage = ({ name, time, body, customStyles }) => {
  // const dispatch = useDispatch();
  // const { id } = useParams()
  // useEffect(() => {
  //   if (!workDetails) {
  //     profileData.isPro
  //       ? dispatch(getWorkDetail(id))
  //       : dispatch(getUserWorkDetail(id))
  //   }
  //   dispatch(setHeaderMobileGoBack(true))
  //   return () => {
  //     dispatch(setCurrentDetailWork(false))
  //     dispatch(setHeaderMobileGoBack(false))
  //   }
  // }, [])

  // const { workDetails, profileData } = useSelector((state) => state.profile)
  // const [alias, setAlias] = useState(null);

  // useEffect( () => {
  //   if(workDetails) setAlias(workDetails.professionalProfile.fantasy);
  // }, [workDetails])

  return (
    <div className={styles.container} style={customStyles}>
      <div className={styles.row}>
        {name && <h6 className={styles.name}>{name}</h6>}
        {time && <p className={styles.time}>{time}</p>}
      </div>
      <p className={styles.body}>{body}</p>
    </div>
  )
}

export default ChatMessage
