import React from 'react'
import Icon from '@atoms/Icon/Icon'
import { ReactComponent as Arrow } from '@assets/svg/right-arrow-short.svg'
import styles from './OptionCard.module.scss'
const OptionCard = ({
  iconType,
  title,
  subtitle,
  selected,
  customStyles,
  onClick,
  dataForClick,
  minimal
}) => {
  return minimal
    ? (
      <div
        className={`${styles.optionCard} ${styles.minimal} ${selected &&
        styles.selected}`}
        style={customStyles}
        onClick={() => onClick(dataForClick)}
      >
        <div className={styles.icon}>
          <div className={styles.iconPentagon}>
            <Icon icon={iconType} />
          </div>
        </div>
        <div className={styles.text}>
          <h6>{title}</h6>
        </div>
      </div>
      )
    : (
      <div
        className={`${styles.optionCard} ${selected && styles.selected}`}
        style={customStyles}
        onClick={() => onClick(dataForClick)}
      >
        <div className={styles.icon}>
          <div className={styles.iconPentagon}>
            <Icon icon={iconType} />
          </div>
        </div>
        <div className={styles.text}>
          <h6>{title}</h6>
          <p>{subtitle}</p>
        </div>
        <div className={styles.arrow}>
          <Arrow />
        </div>
      </div>
      )
}

export default OptionCard
