import { actions } from "@storybook/addon-actions";
import { getIdFromLS, getProfileFromLS } from "@utils/tokenService";
import * as actionTypes from "../actions/types";

// const defaultProfile = {
//   name: "",
//   id: getIdFromLS(),
//   img: null,
//   tel: "",
//   email: "",
//   rating: "",
//   localidad: "",
//   profession: "",
//   averagePrice: false,
//   price: 0,
//   reputation: { name: "", value: 0, count: 0 },
//   description: "",
//   workModality: [],
//   services: [],
//   workDone: [],
//   valorations: [],
//   zones: [],
//   worksList: [],
// };

const INITIAL_STATE = {
  profileData: getProfileFromLS(),
  profileDataReal: {},
  currentPage: null,
  professionalProfile: null,
  privateProfile: null,
  currentWork: null,
  mobileFilter: [],
  workType: [],
  userProfileData: null,
  proExternalReviews: [],
  unreadNotifications: [],
  unreadNotificationsForPro: [],
  unreadMessages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
        // unreadNotifications: action.payload.filter(
        //   (work) => work.idName === "pending"
        // )[0].works,

      };
    case actionTypes.SET_PROFILE_DATA:
      return {
        ...state,
        profileData: action.payload,
      };
    case actionTypes.SET_CLEAR_PROFILE:
      return {
        ...state,
        profileData: {},
        userProfileData: null,
      };
    case actionTypes.SET_WORKS_LIST:
      return {
      ...state,
      worksList: action.payload,
      unreadNotificationsForPro: action.payload.filter(
        (work) => work.idName === "pending"
      )[0].works,
    };
    case actionTypes.SET_USER_WORKS_LIST:
      return {
        ...state,
        // worksList: state.worksList === action.payload ? state.worksList : action.payload,
        worksList: action.payload,
        unreadNotifications: action.payload.filter(
          (work) => work.idName === "qualificationPending"
        )[0].works,

        
      };
    case actionTypes.SET_CURRENT_DETAIL_WORK:
      return {
        ...state,
        workDetails: action.payload,
      };
    case actionTypes.SET_CURRENT_WORK_DATA:
      return {
        ...state,
        currentWork: action.payload,
      };
    case actionTypes.SET_PROFESSIONAL_EXTERNAL_REVIEWS:
      return {
        ...state,
        proExternalReviews: action.payload,
      };
    case actionTypes.SET_PROFESSIONAL_PROFILE:
      return {
        ...state,
        professionalProfile: action.payload,
      };
    case actionTypes.SET_USER_PROFILE:
      return {
        ...state,
        userProfileData: action.payload,
      };
    case actionTypes.SET_PRIVATE_PROFILE:
      return {
        ...state,
        privateProfile: action.payload,
      };
    case actionTypes.SET_MOBILE_FILTER:
      return {
        ...state,
        mobileFilter: [action.payload],
      };
    case actionTypes.CLEAN_MOBILE_FILTER:
      return {
        ...state,
        mobileFilter: [],
      };
    case actionTypes.SET_WORK_TYPE:
      return {
        ...state,
        workType: action.payload,
      };
    case actionTypes.ADD_NOTIFICATION:
      return {
        ...state,
        unreadNotifications:
          state.unreadNotifications.find(
            (notification) =>
              notification.content === action.payload.content &&
              notification.type === action.payload.type
          ) === undefined
            ? [...state.unreadNotifications, action.payload]
            : state.unreadNotifications,
            
      };
      case actionTypes.ADD_NOTIFICATION_FOR_PRO:
        return {
          ...state,
          unreadNotificationsForPro:
            state.unreadNotificationsForPro.find(
              (notification) =>
                notification.content === action.payload.content &&
                notification.type === action.payload.type
            ) === undefined
              ? [...state.unreadNotificationsForPro, action.payload]
              : state.unreadNotificationsForPro,
              
        };
    case actionTypes.REMOVE_NOTIFICATION:
      return {
        ...state,
        unreadNotifications: state.unreadNotifications.filter(
          (notification) =>
            notification.content !== action.payload.content &&
            notification.type !== action.payload.type
        ),
      };
      case actionTypes.REMOVE_NOTIFICATION_FOR_PRO:
        return {
          ...state,
          unreadNotificationsForPro: state.unreadNotificationsForPro.filter(
            (notification) =>
              notification.content !== action.payload.content &&
              notification.type !== action.payload.type
          ),
        };
    case actionTypes.ADD_UNREAD_MESSAGE:
      return {
        ...state,
        unreadMessages: [...state.unreadMessages, action.payload],
      };
    case actionTypes.REMOVE_UNREAD_MESSAGE:
      return {
        ...state,
        unreadMessages: state.unreadMessages.filter(
          (message) => message.jobId !== action.payload
        ),
      };
      
      
    default:
      return state;
  }
};
