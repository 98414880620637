import { axiosGet, axiosPost, setTokenInAxios } from "@config/api";

export const getPanelUs = async () => {
    await axiosGet("admin/panel/us")
      .then((response) => {
          return response.data;
      })
      .catch((error) => console.error(error));
}


export const options = [{ name: "Misiones" }, { name: "Corrientes" }];

export const jobs = [
    {
        value: 'pending',
        name: 'Pendientes'
    },
    {
        value: 'approved',
        name: 'Aprobados'
    },
    {
        value: 'rejected',
        name: 'Rechazados'
    },
    {
        value: 'work_completed',
        name: 'Completados'
    },
    {
        value: 'work_not_completed',
        name: 'No_completados'
    },
    {
        value: 'finished',
        name: 'Finalizados'
    },
    {
        value: 'all',
        name: 'Todos'
    },
]