import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import styles from './ForgotPassword.module.scss'
import { useForm } from 'react-hook-form'
import Field from '@molecules/InputTextBox/InputTextBox'
import Form from '@atoms/Form/Form'
import Button from '@atoms/Button/Button'
import { forgotPasswordHandler } from '@actions/authActions'
import { setLoading } from '@actions/generalActions'

const ForgotPassword = ({ type, success }) => {
  const { loading } = useSelector((state) => state.general)
  const dispatch = useDispatch()
  const { register, handleSubmit, errors, watch, reset } = useForm()

  const onSubmit = (data) => {
    dispatch(setLoading(true))
    const email = { email: data.usuario }
    dispatch(forgotPasswordHandler(email, reset))
  }

  const recoverPage = type === 'recover'

  //   useEffect(() => {
  //     if (redirectTo && redirectTo !== "")
  //       return (window.location.href = redirectTo);
  //   }, [redirectTo]);

  return (
    <>
      <div className={styles.recoverPassword}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.recoverPassword_main}>
            {!recoverPage && (
              <>
                <Field
                  type='email'
                  name='usuario'
                  label='Email'
                  placeholder='Tuemail@email.com'
                  inputRef={register({
                    required: 'Ingresá un email válido para poder avanzar',
                    pattern: /^\S+@\S+$/i
                  })}
                  error={errors.usuario}
                />{' '}
                <span className={styles.passwordMessage}>
                  Te vamos a enviar un mail para que puedas recuperar la
                  contraseña
                </span>
              </>
            )}

            <Button
              isDisabled={errors.usuario || loading || !watch('usuario')}
              customStyles={{ maxWidth: 'unset' }}
              type='submit'
              isLoading={loading}
            >
              Recuperar
            </Button>
          </div>
        </Form>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({ success: state.general.success })

export default connect(mapStateToProps)(ForgotPassword)
