import * as actionTypes from '../actions/types';
import AuthService from '@services/AuthService';
import { axiosGet, axiosPost, axiosPut, setTokenInAxios } from '@config/api';
import { getProfileData } from './profileActions';
import { setToken, deleteToken, getIsProFromLS, setOneTime } from '@utils/tokenService';
import useScreenDetect from '@hooks/useScreenDetect'
import {
  setLoading,
  setModal,
  setSuccessModal,
  setNoDistractionMode
} from './generalActions';
import { setNextStep, setUserAfterCreate } from './registerActions';
import { setStep } from '.';
import showNotification from '@services/NotificationService';
export const clean = () => ({ type: actionTypes.CLEAN })

// Login
export const loginSuccess = (user) => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload: user
})
export const loginError = () => ({ type: actionTypes.LOGIN_ERROR })

// Forgot
export const forgotSuccess = () => ({
  type: actionTypes.FORGOT_SUCCESS
})
export const forgotError = () => ({
  type: actionTypes.FORGOT_ERROR
})

// Login actions
export const zenprosLogin = (user, history, userAlreadyExist, isMobile) => async (
  dispatch
) => {
  try {
    const response = await AuthService.login(user)
    const { access_token, name, role } = response.data
    dispatch({
      type: actionTypes.LOGIN_SUCCESS
    })
    setTokenInAxios(access_token)
    setToken(access_token, name, role)
    setOneTime()
    if (userAlreadyExist) {
      dispatch(setNextStep())
      // dispatch(getProfileData(undefined, true, history))
    } else {
      dispatch(setLoading(false))
    }
    dispatch(getProfileData(undefined, true, history))
  } catch (error) {
    dispatch(setModal(true, 'errorModal'))
    dispatch(
      setSuccessModal({
        title: '¡Lo siento!',
        subtitle: `${
          error.response.data.message.includes('User not active.')
            ? `Solo falta que valides tu email. Dirígete a tu casilla de
            correo y hacé click en 'Validar email'`
            : error.response?.data?.message || 'Ha ocurrido un error'
        }`
      })
    )
    dispatch(setStep(1))
  }
}

export const zenprosRegisterUser = (user, fromProRegister, history) => {
  const objectBody = { ...user }
  return (dispatch) => {
    dispatch(setLoading(true))
    axiosPost('auth/register', objectBody)
      .then((response) => {
        if (fromProRegister) {
          dispatch(setUserAfterCreate(user))
          dispatch(
            zenprosLogin({
              email: user.email,
              password: user.password,
              validatedEmail: false
            })
          )
        }
        if (!fromProRegister) {
          history.push('/confirmar-email')
        }
        dispatch(setLoading(false))
      })
      .catch((error) => {
        if (
          error.response.data.message.includes(
            'User already exist with name:'
          ) &&
          fromProRegister
        ) {
          showNotification(
            'El email ya se  encuentra registrado, inicia sesión.',
            'alreadyExists',
            'error',
            5000
          )
          history.replace('/iniciar-sesion')
        }
        dispatch(setStep(1))
        dispatch(setLoading(false))
      })
  };
}

export const confirmEmail = (token, history) => {
  return (dispatch) => {
    axiosGet('auth/confirmation', {
      data: null,
      params: { token: token }
    })
      .then((response) => {
        history.push('/iniciar-sesion')
        dispatch(setModal(true, 'successModal'))
        dispatch(
          setSuccessModal({
            title: '¡Felicitaciones!',
            subtitle: 'Ya formas parte de la comunidad Zenpros'
          })
        )
      })
      .catch((error) => {
        dispatch(setModal(true, 'errorModal'))
        dispatch(
          setSuccessModal({
            title: '¡Lo siento!',
            subtitle: `${error.response?.data?.message ||
              'Ha ocurrido un error'}`
          })
        )
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  };
}

export const googleLogin = () => async (dispatch) => {
  try {
    const response = await AuthService.googleLogin()
    dispatch(redirect(response.request.responseURL))
  } catch (error) {
    dispatch(loginError())
    console.log(error)
  }
}

export const facebookLogin = () => async (dispatch) => {
  try {
    const response = await AuthService.facebookLogin()
    dispatch(redirect(response.request.responseURL))
  } catch (error) {
    dispatch(loginError())
    console.log(error)
  }
}

export const getGoogleRedirect = (code, type) => {
  return (dispatch) => {
    axiosGet(
      `/auth/google/redirect${code}${
        type && type.name === 'empresa' ? '&isBusiness=1' : '&isBusiness=0'
      }`
    )
      .then((response) => {
        const { access_token, name, role } = response.data.response
        setTokenInAxios(access_token)
        setToken(access_token, name, role)
        dispatch(getProfileData())
        dispatch({
          type: actionTypes.LOGIN_SUCCESS
        })
      })
      .catch((error) => {
        console.log(error.response)
      })
  };
}

export const getFacebookRedirect = (code, type) => {
  return (dispatch) => {
    axiosGet(
      `/auth/facebook/redirect${code}${
        type && type.name === 'empresa' ? '&isBusiness=1' : '&isBusiness=0'
      }`
    )
      .then((response) => {
        const { access_token, name, role } = response.data.response
        setTokenInAxios(access_token)
        setToken(access_token, name, role)
        dispatch(getProfileData())
        dispatch({
          type: actionTypes.LOGIN_SUCCESS
        })
      })
      .catch((error) => {
        console.log(error.response)
      })
  };
}

export const redirect = (link) => {
  return {
    type: actionTypes.REDIRECT,
    payload: link
  }
};

// forgot password
export const forgotPasswordHandler = (data, reset) => async (dispatch) => {
  try {
    await AuthService.forgotPassword(data)
    reset()
    dispatch(forgotSuccess())
    dispatch(setModal(true, 'successModal'))
    dispatch(
      setSuccessModal({
        title: '¡Felicitaciones!',
        subtitle:
          'Dirígete a tu casilla de correo para restablecer tu contraseña'
      })
    )
  } catch (error) {
    dispatch(setModal(true, 'errorModal'))
    dispatch(
      setSuccessModal({
        title: '¡Lo siento!',
        subtitle: 'Ha ocurrido un error'
      })
    )
  } finally {
    dispatch(setLoading(false))
  }
}

// changePassword

export const changePasswordHandler = (data) => async (dispatch) => {
  try {
    await AuthService.changePassword(data)
    dispatch(setModal(true, 'successModal'))
    dispatch(
      setSuccessModal({
        title: '¡Felicitaciones!',
        subtitle: 'Has cambiado tu contraseña correctamente',
        buttonText: 'Iniciar sesión',
        urlToRedirect: '/iniciar-sesion'
      })
    )
  } catch (error) {
    const errorMessage = error.response.data.message.includes(
      'You must request change password'
    )
      ? 'La contraseña ya fue recuperada, por favor vuelva a recuperarlo'
      : 'Ha ocurrido un error';
    dispatch(setModal(true, 'errorModal'))
    dispatch(
      setSuccessModal({
        title: '¡Lo siento!',
        subtitle: errorMessage
      })
    )
  } finally {
    dispatch(setLoading(false))
  }
}

export const logOut = (history) => {
  deleteToken()
  history.push('/')
  window.location.reload()
};
