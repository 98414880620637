import React from 'react'
import Icon from '@atoms/Icon/Icon'
import styles from './IconText.module.scss'

const IconText = ({ icon, text, customStyles }) => {
  return (
    <div className={styles.container} style={customStyles}>
      <Icon icon={icon} className={styles.icon} />
      <p className={styles.text}>{text}</p>
    </div>
  )
}

export default IconText
