import React from 'react'
import { ReactComponent as Search } from '@assets/svg/search.svg'
import { ReactComponent as Location } from '@assets/svg/location.svg'
import { ReactComponent as Facebook } from '@assets/svg/facebook-color.svg'
import { ReactComponent as Google } from '@assets/svg/google-color.svg'
import { ReactComponent as Person } from '@assets/svg/person.svg'
import { ReactComponent as Company } from '@assets/svg/company.svg'
import { ReactComponent as Money } from '@assets/svg/money.svg'
import { ReactComponent as CreditCard } from '@assets/svg/credit-card.svg'
import { ReactComponent as Bill } from '@assets/svg/bill.svg'
import { ReactComponent as Work } from '@assets/svg/work.svg'
import { ReactComponent as HomeWork } from '@assets/svg/home-work.svg'
import { ReactComponent as Edit } from '@assets/svg/editar.svg'
import { ReactComponent as HandShake } from '@assets/svg/handshake.svg'
import { ReactComponent as MercadoPago } from '@assets/svg/mercadopago.svg'
import { ReactComponent as PayPal } from '@assets/svg/paypal.svg'
import PropTypes from 'prop-types'

const Icon = ({ icon, customStyle, style }) => {
  const handleComponent = () => {
    switch (icon) {
      case 'search':
        return <Search width='20' className={customStyle} styles={style} />
      case 'location':
        return <Location width='24' className={customStyle} style={style} />
      case 'facebook':
        return <Facebook width='24' className={customStyle} style={style} />
      case 'google':
        return <Google width='24' className={customStyle} style={style} />
      case 'person':
        return <Person width='24' className={customStyle} style={style} />
      case 'company':
        return <Company width='24' className={customStyle} style={style} />
      case 'money':
        return <Money width='24' className={customStyle} style={style} />
      case 'work':
        return <Work width='24' className={customStyle} style={style} />
      case 'homework':
        return <HomeWork width='24' className={customStyle} style={style} />
      case 'edit':
        return <Edit width='24' className={customStyle} style={style} />
      case 'credit-card':
        return <CreditCard width='24' className={customStyle} style={style} />
      case 'bill':
        return <Bill width='24' className={customStyle} style={style} />
      case 'handshake':
        return <HandShake width='24' className={customStyle} style={style} />
      case 'mercadopago':
        return <MercadoPago width='24' className={customStyle} style={style} />
      case 'paypal':
        return <PayPal width='24' className={customStyle} style={style} />
      default:
        break
    }
  }
  return handleComponent()
}

Icon.propTypes = {
  icon: PropTypes.oneOf([
    'search',
    'location',
    'google',
    'facebook',
    'person',
    'company',
    'money',
    'work',
    'homework',
    'edit',
    'credit-card',
    'bill',
    'handshake',
    'mercadopago',
    'paypal'
  ]),
  customStyle: PropTypes.object
}

export default Icon
