import React, { useEffect, useState } from 'react'
import styles from './ImageUpload.module.scss'
import { ReactComponent as AddIcon } from '@assets/svg/add.svg'
import { ReactComponent as IdeaIcon } from '@assets/svg/idea.svg'
import useFileUpload from '@hooks/useFileUpload'

const ImageUpload = ({
  customStyles,
  withMessage,
  handleImageChange,
  withoutDelete,
  defaultImage,
  acceptPDF,
  keyId
}) => {
  const [newImage, setNewImage] = useState(null)

  const handleUploadImage = (value) => {
    handleImageChange(value)
    setNewImage(imageFile)
  }

  const [imageFile, imageChangeHandler, fileName, error] = useFileUpload(
    newImage,
    handleUploadImage,
    acceptPDF
  )

  const handleDeleteImage = () => {
    handleImageChange(null)
    setNewImage(null)
  }

  useEffect(() => {
    if (imageFile) setNewImage(imageFile)
  }, [imageFile])

  return (
    <>
      <div
        className={`${styles.imageUploadContainer} ${(newImage ||
          defaultImage) &&
          styles.newImage}`}
        style={customStyles}
      >
        <label className={styles.label} htmlFor={`imageUpload-${keyId}`} />
        <input
          type='file'
          accept={acceptPDF ? '.jpg,.png,.jpeg,.pdf' : '.jpg,.png,.jpeg'}
          id={`imageUpload-${keyId}`}
          hidden='hidden'
          onChange={(e) => imageChangeHandler(e)}
        />
        <AddIcon className={styles.add} />
        {(newImage || defaultImage) && (
          <>
            <img
              className={styles.image}
              src={newImage || defaultImage}
              alt='Nueva imagen'
            />
            {!withoutDelete && (
              <p
                className={styles.deleteImage}
                onClick={() => handleDeleteImage()}
              >
                Eliminar
              </p>
            )}
          </>
        )}
        {error && <p className={styles.errorMessage}>{error}</p>}
      </div>
      {withMessage && (
        <div className={styles.messageContainer}>
          <IdeaIcon className={styles.idea} />
          <div className={styles.text}>
            <p className={styles.paragraph}>
              <b>Tips para destacar tu perfil</b>
            </p>
            <p className={styles.paragraph}>
              - Podrás subir una foto de tu cara o del logo de tu empresa en
              formato jpg o png
            </p>
            <p className={styles.paragraph}>
              - No debe superar el peso máximo de 5MB
            </p>
            <p className={styles.paragraph}>
              - Deberá ser una foto con buena iluminación
            </p>
            <p className={styles.paragraph}>- Tratá de usar fondos lisos</p>
          </div>
        </div>
      )}
    </>
  )
}

export default ImageUpload
