import { getProsCategories } from '@actions/generalActions'
import Button from '@atoms/Button/Button'
import AddService from '@molecules/AddService/AddService'
import { getProfessionalProfileFromLS } from '@utils/tokenService'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './ProfileEditModal.module.scss'

const modifyServices = (services) => {
  const auxServices = [...services]
  const aux2 = auxServices.slice(2)
  let aux3 = []
  aux2.map((auxService) => {
    aux3 = [
      ...aux3,
      {
        name: auxService.name,
        id: auxService.id,
        sons: [
          ...auxService.categories.map((aux) => {
            return { ...aux, ...aux.category }
          })
        ]
      }
    ]
  })
  return aux3
}

const ProfileEditAddService = ({ isMobile, profileData, handleSubmit }) => {
  const [nextStep, setNextStep] = useState(true) //
  const [servicesToAdd, setServicesToAdd] = useState(
    modifyServices(profileData.services)
  )

  const { prosCategories } = useSelector((state) => state.general)

  const dispatch = useDispatch()

  const handleNextStep = () => {
    if (nextStep) return handleSubmit(servicesToAdd)
    setNextStep(true)
  }

  const makeServicesListToAdd = (value) => {
    // let included = false;
    const allCats = sortedList(findProfessionCategories(prosCategories))
    const filteredCats = allCats.map((cat) => {
      return {
        id: cat.id,
        name: cat.name,
        sons: []
      }
    })

    const reFilteredCats = filteredCats.map((x) => {
      const fdfdf = servicesToAdd.find((y) => y.id === x.id)
      return fdfdf || x
    })

    return setServicesToAdd(reFilteredCats)

    // ORIGINAL
    // servicesToAdd.map((service) => {
    //   if (service.id === value.id) return (included = true);
    //   return false;
    // });
    // if (included) {
    //   return setServicesToAdd([
    //     ...servicesToAdd.filter((i) => i.id !== value.id),
    //   ]);
    // }
    // return setServicesToAdd([
    //   ...servicesToAdd,
    //   { name: value.name, id: value.id, sons: [] },
    // ]);
  }

  const findProfessionCategories = (professions) => {
    if (professions.length < 1) return []
    // let professionToFind = profileData.services[1].id;
    const professionalProfile = getProfessionalProfileFromLS()
    const professionToFind = professionalProfile.title
    const professionCategory = professions.find(
      (profession) => profession.name === professionToFind
    )
    const formatedCategory = [
      ...professionCategory.sons.map((proCat) => {
        return { name: proCat.name, id: proCat.id, sons: proCat.sons }
      })
    ]
    return formatedCategory
  }

  const sortedList = (list) => {
    const sortedListById = list?.slice().sort((a, b) => {
      if (a.id < b.id) {
        return -1
      }
      if (a.id > b.id) {
        return 1
      }
      return 0
    })
    return sortedListById
  }

  const handleAddSubService = (subService, categoryId) => {
    const servicesNotEdited = servicesToAdd.filter((i) => i.id !== categoryId)
    if (!Array.isArray(subService)) {
      servicesToAdd.map((service, index) => {
        if (service.id === categoryId) {
          let included = false
          service.sons.map((auxSubService) => {
            if (auxSubService.id === subService.id) {
              included = true
              return false
            }
            return false
          })
          if (included) {
            const subServicesToSet = [
              ...servicesToAdd[index].sons.filter((i) => i.id !== subService.id)
            ]
            return setServicesToAdd(
              sortedList([
                ...servicesNotEdited,
                {
                  name: service.name,
                  id: service.id,
                  sons: subServicesToSet
                }
              ])
            )
          }

          const subServicesToSet = [...servicesToAdd[index].sons, subService]
          return setServicesToAdd(
            sortedList([
              ...servicesNotEdited,
              {
                name: service.name,
                id: service.id,
                sons: subServicesToSet
              }
            ])
          )
        }
        return false
      })
    } else {
      const category = servicesToAdd.filter((i) => i.id === categoryId)
      return setServicesToAdd(
        sortedList([
          ...servicesNotEdited,
          {
            name: category[0].name,
            id: category[0].id,
            sons: subService
          }
        ])
      )
    }
  }

  useEffect(() => {
    if (prosCategories.length < 1) dispatch(getProsCategories())
  }, [])

  return (
    <div className={styles.container}>
      <AddService
        isMobile={isMobile}
        categories={sortedList(findProfessionCategories(prosCategories))}
        selectedCategories={sortedList(servicesToAdd)}
        disabledItems={modifyServices(profileData.services)}
        handleCheckboxItems={makeServicesListToAdd}
        nextStep={nextStep}
        disabledMode
        handleSelectSubService={handleAddSubService}
      />
      <div className={styles.modalFooter}>
        <Button
          customStyles={{ maxWidth: isMobile ? '100%' : '12.2rem' }}
          onClickHandler={() => handleNextStep()}
        >
          {nextStep ? 'Guardar' : 'Siguiente'}
        </Button>
      </div>
    </div>
  )
}
export default ProfileEditAddService
