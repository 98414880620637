import Button from '@atoms/Button/Button'
import Text from '@atoms/Text/Text'
import { ReactComponent as EnvelopeIcon } from '@assets/svg/request-review.svg'
import React from 'react'
import styles from './RequestValorationMessage.module.scss'
import useScreenDetect from '@hooks/useScreenDetect'
const RequestValorationMessage = ({ requestValorations }) => {
  const { isMobile } = useScreenDetect()
  const handleRequest = () => {
    requestValorations()
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text tag='p' size='l' weight='bold' align='left'>
          Sumá valoraciones y destacá tu perfil
        </Text>
      </div>
      <div className={styles.body}>
        <EnvelopeIcon className={styles.icon} />
        <Text tag='p' size='m' align='left'>
        Para tener más chances de que te contacten futuros clientes es clave
        que tengas comentarios sobre vos y tu trabajo! 
        Ingresá acá la dirección de mail de clientes anteriores para que automáticamente
        te puedan calificar y dejar un comentario en 2 pasos. Podés mandar esta solicitud
        hasta a 10 personas!
        </Text>        
        
        <Button
          onClickHandler={() => handleRequest()}
          customStyles={{
            marginLeft: isMobile ? '0' : 'var(--spacing-medium)',
            maxWidth: isMobile ? '100%' : 'max-content',
            background: 'transparent',
            color: 'var(--secondary-blue)',
            border: '1px solid rgba(11, 26, 50, 0.4) '
          }}
        >
          Solicitar valoración
        </Button>
      </div>
    </div>
  )
}
export default RequestValorationMessage
