import {
  getDataFromHash,
  sendExternalReview,
  setNoDistractionMode
} from '@actions/generalActions'
import Button from '@atoms/Button/Button'
import Form from '@atoms/Form/Form'
import Label from '@atoms/Label/Label'
import ShadowBox from '@atoms/ShadowBox/ShadowBox'
import Text from '@atoms/Text/Text'
import TextArea from '@atoms/TextArea/TextArea'
import useScreenDetect from '@hooks/useScreenDetect'
import InputTextBox from '@molecules/InputTextBox/InputTextBox'
import StarReview from '@molecules/StarReview/StarReview'
import SuccessModal from '@molecules/SuccessModal/SuccessModal'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import styles from './ExternalReviewContainer.module.scss'
const ExternalReviewContainer = () => {
  const [step, setStep] = useState(0)
  const [starValue, setStarValue] = useState(0)
  const [message, setMessage] = useState('')
  const { hashData } = useSelector((state) => state.general)

  const { token } = useParams()
  const dispatch = useDispatch()
  const { isMobile } = useScreenDetect()

  const { register, handleSubmit, errors, watch } = useForm({ mode: 'onBlur' })

  const values = watch()

  const handleSelectStarValue = (value) => {
    setStarValue(value)
  }

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1)
  }

  const handleSaveStep = (data) => {
    const objectData = {
      ...data,
      evaluation: starValue,
      comment: message
    }
    dispatch(sendExternalReview(objectData, token))
  }
  const handleMessageChange = (value) => {
    setMessage(value)
  }

  useEffect(() => {
    dispatch(setNoDistractionMode(true))
    dispatch(getDataFromHash(token))
    return () => {
      dispatch(setNoDistractionMode(false))
    }
  }, [])

  const StepTitle = () => {
    switch (step) {
      case 0:
      case 1:
        return (
          <Text
            tag='p'
            size='xl-1'
            weight='bold'
            customStyles={{
              marginBottom: '4rem',
              fontFamily: 'Montserrat, sans-serif'
            }}
          >
            Valorá al profesional
          </Text>
        )
      default:
        return <></>
    }
  }

  return (
    <div className={styles.container}>
      <StepTitle />
      <AnimatePresence>
        {step === 0
          ? (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <ShadowBox customStyles={{ minWidth: isMobile ? '100%' : '54rem' }}>
                <div className={styles.starReview}>
                  <Text tag='p' size='l-1'>
                    Según tu experiencia cuántas
                    <br /> estrellas le das a
                    <b>{` ${hashData.name} ${hashData.lastName}`}</b>
                  </Text>
                  <StarReview handleSelectValue={handleSelectStarValue} />
                  <Button
                    isDisabled={starValue === 0 || !starValue}
                    customStyles={{
                      marginTop: 'var(--spacing-small)',
                      maxWidth: isMobile ? '100%' : 'max-content'
                    }}
                    onClickHandler={() => handleNextStep()}
                  >
                    Calificar
                  </Button>
                </div>
              </ShadowBox>
            </motion.div>
            )
          : step === 1
            ? (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <ShadowBox customStyles={{ minWidth: isMobile ? '100%' : '54rem' }}>
                  <Form
                    onSubmit={handleSubmit(handleSaveStep)}
                    className={styles.form}
                  >
                    <InputTextBox
                      label='Nombre'
                      placeholder='Ingresá tu nombre'
                      name='name'
                      type='text'
                      inputRef={register({ required: 'Campo obligatorio' })}
                      error={errors.name}
                      customStyles={{ marginBottom: 'var(--spacing-medium)' }}
                    />
                    <InputTextBox
                      label='Apellido'
                      placeholder='Ingresá tu apellido'
                      name='lastName'
                      type='text'
                      inputRef={register({ required: 'Campo obligatorio' })}
                      error={errors.lastName}
                      customStyles={{ marginBottom: 'var(--spacing-medium)' }}
                    />
                    <div className={styles.messageContent}>
                      <Label htmlFor='message'>
                        Contanos como fue tu experiencia
                      </Label>
                      <div className={styles.textArea}>
                        <TextArea
                          name='message'
                          placeholder='Contanos como fue tu experiencia'
                          value={message}
                          onChangeValue={handleMessageChange}
                          customStyles={{ minHeight: '144px' }}
                        />
                        <Text tag='p' size='s' color='secondary-blue' align='right'>
                          {message.length}/600
                        </Text>
                      </div>
                    </div>
                    <Button
                      isDisabled={!values.name || !values.lastName || !message}
                      type='submit'
                      customStyles={{
                        maxWidth: isMobile ? '100%' : 'max-content',
                        marginLeft: 'auto'
                      }}
                    >
                      Calificar
                    </Button>
                  </Form>
                </ShadowBox>
              </motion.div>
              )
            : (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <ShadowBox customStyles={{ maxWidth: isMobile ? '100%' : '54rem' }}>
                  <div className={styles.successReview}>
                    <SuccessModal />
                  </div>
                </ShadowBox>
              </motion.div>
              )}
      </AnimatePresence>
    </div>
  )
}
export default ExternalReviewContainer
