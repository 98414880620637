import CheckboxGroup from '@molecules/CheckboxGroup/CheckboxGroup'
import SearchService from '@molecules/SearchService/SearchService'
import React, { useEffect } from 'react'
import styles from './AddService.module.scss'

const AddService = ({
  categories,
  selectedCategories,
  nextStep,
  handleCheckboxItems,
  disabledItems,
  disabledMode,
  handleSelectSubService
}) => {
  const handleSelectItem = (item) => {
    handleCheckboxItems(item)
  }
  useEffect(() => {
    handleCheckboxItems()
  }, [])
  return (
    <div className={styles.container}>
      {nextStep
        ? (
          <SearchService
            categories={categories}
            selectedCategories={selectedCategories}
            disabledItems={disabledItems}
            disabledMode={disabledMode}
            handleSelectSubService={handleSelectSubService}
          />
          )
        : (
          <CheckboxGroup
            options={categories}
            selectedItems={selectedCategories}
            disabledItems={disabledItems}
            disabledMode={disabledMode}
            selectItemAction={handleSelectItem}
          />
          )}
    </div>
  )
}

export default AddService
