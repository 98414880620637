import React from 'react'
import ShadowBox from '@atoms/ShadowBox/ShadowBox'
import Text from '@atoms/Text/Text'
import styles from './EditBox.module.scss'
import SlideButton from '@atoms/SlideButton/SlideButton'

const EditBox = ({
  children,
  type,
  title,
  enableAction,
  editLabel,
  isMobile,
  share
}) => {
  const handleEdit = (data) => {
    enableAction(data)
  }
  
  return (
    <ShadowBox
      width={isMobile ? '91.5vw' : '64rem'}
      customStyles={{
        minWidth: isMobile ? '100%' : '80rem',
        padding: isMobile ? 'var(--spacing-small)' : 'var(--spacing-medium)',
        border: '1px solid rgba(11, 26, 50, 0.1)',
        boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.05)',
        borderRadius: '10px'
      }}
    >
      <div className={styles.header}>
        <Text tag='p' size={isMobile ? 'm' : 'l'} weight='bold' align='left'>
          {title}
        </Text>
        {enableAction && (
          <SlideButton
            text={editLabel}
            icon='edit'
            data={type}
            width={10}
            iconWidth={2.4}
            iconHeight={2.4}
            action={handleEdit}
          />
        )}
      </div>
      <div className={styles.children}>{children}</div>
    </ShadowBox>
  )
}

export default EditBox
