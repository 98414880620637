// GENERAL
export const CLEAN = '@GENERAL/CLEAN'
export const GET_PROS_CATEGORIES = '@GENERAL/GET_PROS_CATEGORIES'
export const GET_CATEGORY_SONS = '@GENERAL/GET_CATEGORY_SONS'
export const GET_CATEGORY_BRANCH = '@GENERAL/GET_CATEGORY_BRANCH'
export const SET_PROFESSIONAL_RESULTS = '@GENERAL/SET_PROFESSIONAL_RESULTS'
export const GET_BARRIOS = '@GENERAL/GET_BARRIOS'
export const GET_HORARIOS = '@GENERAL/GET_HORARIOS'
export const SET_CURRENT_SEARCH = '@GENERAL/SET_CURRENT_SEARCH'
export const SET_MODAL = '@GENERAL/SET_MODAL'
export const SET_MOBILE_DEVICE = '@GENERAL/SET_MOBILE_DEVICE'
export const SET_SUCCESS_MODAL = '@GENERAL/SET_SUCCESS_MODAL'
export const SET_LOADING = '@GENERAL/SET_LOADING'
export const SET_NO_DISTRACTION_MODE = '@GENERAL/SET_NO_DISTRACTION_MODE'
export const SET_HEADER_MOBILE_GO_BACK = '@GENERAL/SET_HEADER_MOBILE_GO_BACK'
export const SET_HASH_DATA = '@GENERAL/SET_HASH_DATA'
export const GET_PROS_VERTICALS = '@GENERAL/GET_PROS_VERTICALS'
export const GET_PROS_PROFESSIONS = '@GENERAL/GET_PROS_PROFESSIONS'
export const GET_MULTI_CATEGORY_SONS = '@GENERAL/GET_MULTI_CATEGORY_SONS'
export const SET_SUGGEST_VALUE = '@GENERAL/SET_SUGGEST_VALUE'
export const MOST_SEARCHED = '@GENERAL/MOST_SEARCHED'
export const SUCCESS_REGISTER = '@GENERAL/SUCCESS_REGISTER'
export const SET_FIRSTLOGIN = '@GENERAL/SET_FIRSTLOGIN'
export const SET_READY_TEXT = '@GENERAL/SET_READY_TEXT'
export const SET_FOOTER_ONBOARDING = '@GENERAL/SET_FOOTER_ONBOARDING'
export const WATCHED_VIDEO = '@GENERAL/WATCHED_VIDEO'
export const FINISHED_VIDEOS = '@GENERAL/FINISHED_VIDEOS'
export const UPDATE_ONBOARDING = '@GENERAL/UPDATE_ONBOARDING'
export const GET_PROS_PROFESSIONS_SEARCH = '@GENERAL/GET_PROS_PROFESSIONS_SEARCH'
export const SET_NEW_VALUES = '@GENERAL/SET_NEW_VALUES'
export const SET_GLOBAL_VALUES_SELECTED = '@GENERAL/SET_GLOBAL_VALUES_SELECTED'


// AUTH
export const LOGIN_SUCCESS = '@AUTH/LOGIN_SUCCESS'
export const LOGIN_ERROR = '@AUTH/LOGIN_ERROR'
export const FORGOT_SUCCESS = '@AUTH/FORGOT_SUCCESS'
export const FORGOT_ERROR = '@AUTH/FORGOT_ERROR'
export const LOGOUT = '@AUTH/LOGOUT'
export const REDIRECT = '@AUTH/REDIRECT'

// REGISTER
export const SET_NEXT_STEP = '@REGISTER/SET_NEXT_STEP'
export const SET_PREVIOUS_STEP = '@REGISTER/SET_PREVIOUS_STEP'
export const SET_STEP = '@REGISTER/SET_STEP'
export const SET_REGISTER_TYPE = '@REGISTER/SET_REGISTER_TYPE'
export const SET_REGISTER_USER_INFO = '@REGISTER/SET_REGISTER_USER_INFO'
export const SET_REGISTER_CATEGORY = '@REGISTER/SET_REGISTER_CATEGORY'
export const SET_REGISTER_AREA = '@REGISTER/SET_REGISTER_AREA'
export const SET_REGISTER_SPECIALITY = '@REGISTER/SET_REGISTER_SPECIALITY'
export const SET_REGISTER_SPECIALITY_ATTEMPT = '@REGISTER/SET_REGISTER_SPECIALITY_ATTEMPT'
export const RESET_REGISTER_STEPS = '@REGISTER/RESET_REGISTER_STEPS'
export const SET_REGISTER_VALUES = '@REGISTER/SET_REGISTER_VALUES'
export const SET_REGISTER_REVIEW_REQUEST_EMAILS = '@REGISTER/SET_REGISTER_REVIEW_REQUEST_EMAILS'
export const SET_REGISTER_CATEGORIES_VALUES = '@REGISTER/SET_REGISTER_CATEGORIES_VALUES'
export const SET_REGISTER_PROFESSIONAL_INFO = '@REGISTER/SET_REGISTER_PROFESSIONAL_INFO'
export const SET_LOGGED_BY_FACE_OR_GOOGLE = '@REGISTER/SET_LOGGED_BY_FACE_OR_GOOGLE'
export const CLEAN_PRO_REGISTER = '@REGISTER/CLEAN_PRO_REGISTER'

// PROFILE
export const SET_PROFILE_DATA_REAL = '@PROFILE/SET_PROFILE_DATA_REAL'
export const SET_PROFILE_DATA = '@PROFILE/SET_PROFILE_DATA'
export const SET_CURRENT_PAGE = '@PROFILE/SET_CURRENT_PAGE'
export const SET_WORKS_LIST = '@PROFILE/SET_WORKS_LIST'
export const SET_USER_WORKS_LIST = '@PROFILE/SET_USER_WORKS_LIST'
export const SET_CURRENT_DETAIL_WORK = '@PROFILE/SET_CURRENT_DETAIL_WORK'
export const SET_PROFESSIONAL_PROFILE = '@PROFILE/SET_PROFESSIONAL_PROFILE'
export const SET_PRIVATE_PROFILE = '@PROFILE/SET_PRIVATE_PROFILE'
export const SET_CURRENT_WORK_DATA = '@PROFILE/SET_CURRENT_WORK_DATA'
export const SET_MOBILE_FILTER = '@PROFILE/SET_MOBILE_FILTER'
export const CLEAN_MOBILE_FILTER = '@PROFILE/CLEAN_MOBILE_FILTER'
export const SET_USER_PROFILE = '@PROFILE/SET_USER_PROFILE'
export const SET_WORK_TYPE = '@PROFILE/SET_WORK_TYPE'
export const SET_PROFESSIONAL_EXTERNAL_REVIEWS = '@PROFILE/SET_PROFESSIONAL_EXTERNAL_REVIEWS'
export const SET_CLEAR_PROFILE = '@PROFILE/SET_CLEAR_PROFILE'
export const ADD_NOTIFICATION = '@PROFILE/ADD_NOTIFICATION'
export const REMOVE_NOTIFICATION = '@PROFILE/REMOVE_NOTIFICATION'
export const ADD_NOTIFICATION_FOR_PRO = '@PROFILE/ADD_NOTIFICATION_FOR_PRO'
export const REMOVE_NOTIFICATION_FOR_PRO = '@PROFILE/REMOVE_NOTIFICATION_FOR_PRO'
export const ADD_UNREAD_MESSAGE = '@PROFILE/ADD_UNREAD_MESSAGE'
export const REMOVE_UNREAD_MESSAGE = '@PROFILE/REMOVE_UNREAD_MESSAGE'

// PANEL
export const GET_PROFESSIONALS = '@PANEL/GET_PROFESSIONALS';
export const GET_PROFESSIONAL = '@PANEL/GET_PROFESSIONAL';
export const SET_PANEL_MODAL = '@PANEL/SET_PANEL_MODAL';


