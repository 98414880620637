import React from 'react'
import styles from './Separator.module.scss'

const Separator = ({ large, horizontal, margin }) => {
  return (
    <span
      className={`${styles.Separator}`}
      style={{
        margin: horizontal ? `${margin}rem 0` : `0 ${margin}rem`,
        width: horizontal ? large : '2px',
        height: horizontal ? '2px' : large
      }}
    />
  )
}

export default Separator
