import React, { useEffect, useState } from "react";
import styles from "./DetailPro.module.scss";
import back from "./assets/back.svg";
import layOff from "./assets/layOff.svg";
import deleteUser from "./assets/deleteUser.svg";
import active from "./assets/active.svg";
import { useHistory, useParams } from "react-router-dom";
import { axiosGet, axiosPut } from "@config/api";
import { useDispatch } from "react-redux";
import { professional, setPanelModal } from "@actions/panelActions";
import { setModal } from "@actions/generalActions";

const DetailPro = ({ user }) => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const delProFunction = async ({ id, email }) => {
    await axiosGet(`/admin/users/${id}`).then((response) => {
      dispatch(professional(response.data));
    });
    dispatch(setModal(true, "modalPanel"));
    dispatch(
      setPanelModal({
        title: "Eliminar cuenta",
        modalPanel: "eliminar",
        question: "¿Estás seguro de eliminar definitivamente la cuenta de ",
        email,
        id: params.id,
        currentIsActive: isActive,
      })
    );
  };

  const [isActive, setIsActive] = useState(true);


  const layOffProFunction = async ({ id, email }) => {
    await axiosPut(`/admin/users/${id}`).then((response) => {
      dispatch(professional(response.data));
    });
    dispatch(setModal(true, "modalPanel"));
    dispatch(
      setPanelModal({
        title: "Suspender cuenta",
        modalPanel: "suspender",
        question: isActive ? "¿Estás seguro de suspender la cuenta de " : "¿Estás seguro de activar la cuenta de ",
        email,
        id: params.id,
        currentIsActive: isActive,
      })
    );
  };

  useEffect(() => {
    layOffGet(params.id);
  }, [params.id]);

  const layOffGet = async (id) => {
    await axiosGet(`admin/users/isActive/${id}`)
      .then((response) => {
        let { isActive } = response.data;
        setIsActive(isActive);
      })
      .catch((error) => console.error(error));
  };

  const backButton = () => {
    history.push("/panel-administracion");
  };

  return (
    <div className={styles.containerDetailPro}>
      <section className={styles.firstSection}>
        <img src={back} alt="" onClick={() => backButton()} />
      </section>

      <section className={styles.secondSection}>
        <div className={styles.firstLine}>
          <h3>Detalle</h3>
          <div className={styles.buttons}>
            {isActive ? (
              <img
                src={layOff}
                alt=""
                onClick={() => layOffProFunction(user)}
              />
            ) : (
              <img
                src={active}
                alt=""
                onClick={() => layOffProFunction(user)}
              />
            )}
            <img src={deleteUser} alt="" onClick={() => delProFunction(user)} />
          </div>
        </div>
        <div className={styles.secondLine}>
          <p>
            <h5>ID</h5>
            <h6>{user.id}</h6>
          </p>

          <p>
            <h5>Nombre</h5>
            <h6>{user.name}</h6>
          </p>

          <p>
            <h5>Apellido</h5>
            <h6>{user.lastName}</h6>
          </p>

          <p>
            <h5>Email</h5>
            <h6>{user.email}</h6>
          </p>

          <p>
            <h5>Teléfono</h5>
            <h6>{user.phone}</h6>
          </p>
        </div>
      </section>
    </div>
  );
};

export default DetailPro;
