import React, { useState, useEffect } from 'react'
import styles from './FiltersMenuMobile.module.scss'
import { ReactComponent as CloseIcon } from '@assets/svg/close.svg'
import Button from '@atoms/Button/Button'
import FilterContent from './FilterContent'
import { useParams } from 'react-router'
import { axiosGet, axiosPost, axiosPut } from "@config/api";

const FiltersMenuMobile = ({
  opened,
  handleOpenFilter,
  filters,
  sendFilter,
  handleFilter
}) => {
  const { categorySearchId } = useParams()
  const [selectedFilters, setSelectedFilters] = useState({
    categoryIds: [Number(categorySearchId)]
  })
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([])
  const [selectedWorkModality, setSelectedWorkModality] = useState([])
  const [selectedNeighborhoods, setSelectedNeighborhoods] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  let [value, setValue] = useState([]);

  const handleSelectItem = (value, array, action, key) => {
    setValue(value); // SE AGREGO EL 17/03/2022 11:55hs
    let included = false
    array.map((service) => {
      if (service.id === value.id) return (included = true)
      return false
    })
    if (included) {
      let selIts = JSON.parse(localStorage.getItem("selectItems"));
      let cs = JSON.parse(localStorage.getItem("currentSearch"));
      if(selIts && selIts.length === 1) {
        setSelectedCategories([])
        setSelectedFilters({ categoryIds: [Number(categorySearchId)] })

        let allCurrent = [cs.id]
        
        handleFilter(key, allCurrent);
        
      }
      handleFilterValue(key, [...array.filter((i) => i.id !== value.id)])
      return action([...array.filter((i) => i.id !== value.id)])
    }
    handleFilterValue(key, [...array, value])
    return action([...array, value])
  }

  // TODO: esto es necesario para que funcione bien el buscador y los botones de "Enviar mensaje" y "Quiero que me llamen"
  let [selectId, setSelectId] = useState([]);

  useEffect(() => {
    axiosGet('/guests/category-verticals').then(response => {
      let data = response.data.map(item => item.id);
      setSelectId(data);
    })
  }, [localStorage])
  // TODO:======================================================================================================

  useEffect(() => {
    if (
      selectedCategories &&
      selectedCategories.length > 0 &&
      !selectId.includes(selectedCategories[0].parentcategory?.id) // si es un NIVEL 1, entonces...
    ) {
      value = [value];
      localStorage.setItem("selectItems", JSON.stringify(selectedCategories));
    } else {
      let selIts = selectedCategories.filter((element, index) => index > 0);
      localStorage.setItem("selectItems", JSON.stringify(selIts));
    }
  }, [selectedCategories, localStorage]);

  useEffect(() => {
    let value = JSON.parse(localStorage.getItem("currentSearch"));

    if (value) {
      handleSelectItem(
        value,
        selectedCategories,
        setSelectedCategories,
        "categoryIds"
      );
    }
  }, []);


  const handleCleanFilters = () => {
    setSelectedPaymentMethods([])
    setSelectedWorkModality([])
    setSelectedNeighborhoods([])
    setSelectedCategories([])
    setSelectedFilters({ categoryIds: [Number(categorySearchId)] })
  }

  const handleSendFilters = () => {
    sendFilter(selectedFilters)
  }

  const handleFilterValue = (key, value) => {
    switch (key) {
      case 'categoryIds':
        setSelectedFilters({
          ...selectedFilters,
          [key]: [Number(categorySearchId), ...value.map((val) => val.id)]
        })
        break
      case 'neighborhoodIds':
        setSelectedFilters({
          ...selectedFilters,
          [key]: [...value.map((val) => val.id)]
        })
        break
      case 'paymentMethod':
        setSelectedFilters({
          ...selectedFilters,
          [key]: [...value.map((val) => val.value)]
        })
        break
      case 'workType':
        setSelectedFilters({
          ...selectedFilters,
          [key]: [...value.map((val) => val.value)]
        })
        break
      default:
        return null
    }
  }

  const handleDelegateAction = (selectedItem, name) => {
    switch (name) {
      case 'Especialización':
        return handleSelectItem(
          selectedItem,
          selectedCategories,
          setSelectedCategories,
          'categoryIds'
        )

      case 'Zona de cobertura':
        return handleSelectItem(
          selectedItem,
          selectedNeighborhoods,
          setSelectedNeighborhoods,
          'neighborhoodIds'
        )

      case 'Medio de pago':
        return handleSelectItem(
          selectedItem,
          selectedPaymentMethods,
          setSelectedPaymentMethods,
          'paymentMethod'
        )

      case 'Modalidad':
        return handleSelectItem(
          selectedItem,
          selectedWorkModality,
          setSelectedWorkModality,
          'workType'
        )

      default:
        return ''
    }
  }

  const searchArray = (name) => {
    switch (name) {
      case 'Especialización':
        return selectedCategories
      case 'Zona de cobertura':
        return selectedNeighborhoods
      case 'Medio de pago':
        return selectedPaymentMethods
      case 'Modalidad':
        return selectedWorkModality
      default:
        return []
    }
  }
  

  return (
    <div className={`${styles.filtersMenuMobile} ${opened && styles.opened}`}>
      <div className={styles.filtersMenuMobileHeader}>
        <p>Filtros</p>
        <CloseIcon onClick={() => handleOpenFilter(false)} />
      </div>
      <div className={styles.filtersContainer}>
        {filters &&
          filters.map((filter, index) => (
            <FilterContent
              handleSelect={(selectedItem) =>
                handleDelegateAction(selectedItem, filter.name)}
              selectedOptions={searchArray(filter.name)}
              filter={filter}
              key={index}
            />
          ))}
      </div>
      <div className={styles.fixedBottom}>
        <div className={styles.bottomButton}>
          <p onClick={() => handleCleanFilters()}>Limpiar</p>
        </div>
        <div className={styles.bottomButton}>
          <Button onClickHandler={() => handleSendFilters()}>Aplicar</Button>
        </div>
      </div>
    </div>
  )
}

export default FiltersMenuMobile
