import React, { useEffect } from "react";
import ProfileContainer from "@organism/Profile/Profile";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";

const PublicProfile = () => {
  return (
    <div className={styles.publicProfileContainer}>
      <ProfileContainer publicMode />
    </div>
  );
};

export default PublicProfile;
