import Button from "@atoms/Button/Button";
import React, { useEffect, useRef, useState } from "react";
import styles from "./ProsCard.module.scss";
import { motion } from "framer-motion";
import { withRouter } from "react-router-dom";
import { ReactComponent as StarIcon } from "@assets/svg/star.svg";
import { ReactComponent as UserIcon } from "@assets/svg/user-empty.svg";
import IconText from "@atoms/IconText/IconText";
import useScreenDetect from "@hooks/useScreenDetect";
import { getReputationFromRate } from "@molecules/ProfileInfoCard/constants";
import { useDispatch, useSelector } from "react-redux";
import { setProfileMode } from "@actions/profileActions";
import { setModal } from "@actions/generalActions";

const ProsCard = ({ pro, history, handleContactRequest, isProLogged }) => {
  const {
    user,
    rating,
    residenceArea,
    professionalProfileWorkType,
    title,
    referencePrice,
    referencePaymentType,
    avgReviews,
    reviews,
    description,
    professionalProfileCategory,
    fantasy,
  } = pro;


  const { profileData } = useSelector((state) => state.profile);

  const { isMobile } = useScreenDetect();

  const dispatch = useDispatch();

  const buttonsRef = useRef(null);
  const cardRef = useRef(null);

  const handleViewProfile = (id) => {
    history.push(`/perfil/${id}`);
  };

  const handleClick = (e) => {
    if (cardRef.current.contains(e.target)) {
      if (
        !isProLogged &&
        buttonsRef &&
        buttonsRef?.current &&
        buttonsRef?.current.contains(e.target)
      ) {
        return false;
      } else {
        handleViewProfile(user.id);
      }
    }
  };

  const reputation = (avgReviews && getReputationFromRate(avgReviews)) || null;

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, [user]);

  const showModal = (e) => {
    e.preventDefault();
    dispatch(setModal(true, "shareProfile"));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div
        className={`${styles.proCardContainer} ${isProLogged &&
          styles.isProLogged}`}
        ref={cardRef}
      >
        <div className={styles.proCardImage}>
          {user.photo ? (
            <img src={user.photo} alt={`${user.name} ${user.lastName}`} />
          ) : (
            <div className={styles.noImage}>
              <UserIcon className={styles.icon} />
            </div>
          )}
        </div>

        <div className={styles.proCardInfo}>
          <div className={styles.nameRating}>
            <h4 className={styles.alias}>
              {pro.fantasy ? pro.fantasy : `${user.name} ${user.lastName}`}
            </h4>
            {rating && (
              <span
                className={`${styles.rating} ${styles[rating.toLowerCase()]}`}
              >
                {rating}
              </span>
            )}
          </div>
          {residenceArea && (
            <div className={styles.location}>
              <IconText
                icon="location"
                text={residenceArea}
                customStyles={{
                  fontSize: isMobile ? "var(--font-xs)" : "var(--font-m)",
                }}
              />
            </div>
          )}
          {!!avgReviews && (
            <div className={styles.qualification}>
              <div className={styles.qualificationValue}>
                <p>{reputation}</p>
                <StarIcon />
                <p className={styles.value}>
                  {avgReviews.toFixed(2).replace(/\.00$/, "")}
                  <b>({reviews.length})</b>
                </p>
              </div>
            </div>
          )}

          <div className={styles.workModality}>
            {professionalProfileWorkType &&
            professionalProfileWorkType.length > 0 ? (
              professionalProfileWorkType.length > 1 ? (
                <>
                  <IconText
                    icon="location"
                    text="Remoto"
                    customStyles={{
                      fontSize: isMobile ? "var(--font-xs)" : "var(--font-m)",
                      marginRight: "1rem",
                    }}
                  />
                  <IconText
                    icon="homework"
                    text="Presencial"
                    customStyles={{
                      fontSize: isMobile ? "var(--font-xs)" : "var(--font-m)",
                    }}
                  />
                </>
              ) : (
                <>
                  <IconText
                    icon={
                      professionalProfileWorkType[0].workType === "FACETOFACE"
                        ? "homework"
                        : "location"
                    }
                    text={
                      professionalProfileWorkType[0].workType === "FACETOFACE"
                        ? "Presencial"
                        : "Remoto"
                    }
                    customStyles={{
                      fontSize: isMobile ? "var(--font-xs)" : "var(--font-m)",
                    }}
                  />
                </>
              )
            ) : null}
          </div>
          <h5 className={styles.proProfession}>{title}</h5>
          <h6
            className={`${styles.proPrice} ${referencePaymentType !==
              "A convenir" && styles.averagePrice}`}
          >
            {`${
              referencePrice <= 1 ? "" : `$${referencePrice}/`
            }${referencePaymentType}`}
          </h6>
          <div className={styles.proCardBottomLeft}>
            <p className={styles.proDescription}>{description}</p>
          </div>
          {!isProLogged && pro.user.id !== profileData?.id && (
            <div className={styles.proCardBottomRight} ref={buttonsRef}>
              <Button
                buttonStyle="terciary"
                customStyles={{
                  marginRight: isMobile ? "0" : "var(--spacing-small)",
                  marginBottom: isMobile ? "var(--spacing-small)" : "0",
                  maxWidth: isMobile ? "unset" : "186px",
                }}
                onClickHandler={() =>
                  handleContactRequest(
                    professionalProfileCategory.length > 2
                      ? "messageProRequest"
                      : "messageProWithoutServices",
                    pro
                  )
                }
              >
                Enviar mensaje
              </Button>
              <Button
                onClickHandler={() =>
                  handleContactRequest(
                    professionalProfileCategory.length > 2
                      ? "callProRequest"
                      : "callProRequestWithoutServices",
                    pro
                  )
                }
              >
                Quiero que me llamen
              </Button>
            </div>
          )}
          {!isProLogged && pro.user.id === profileData?.id && (
            <div className={styles.proCardBottomRight} ref={buttonsRef}>
              <Button
                onClickHandler={() => {
                  dispatch(
                    setProfileMode(
                      "PRO",
                      history,
                      false,
                      true,
                      "/perfil-profesional"
                    )
                  );
                }}
              >
                Ir a mi perfil profesional
              </Button>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default withRouter(ProsCard);
