import React, { useState } from 'react'
import Text from '@atoms/Text/Text'
import styles from './SlideButton.module.scss'
import Icon from '@atoms/Icon/Icon'
import useScreenDetect from '@hooks/useScreenDetect'

export const SlideButton = ({
  action,
  icon,
  data,
  width = 8,
  text,
  iconHeight = 2.4,
  iconWidth = 2.4,
  ...props
}) => {
  const [onHover, setOnHover] = useState(false)
  const { isMobile } = useScreenDetect()

  const handleHover = (value) => {
    setOnHover(value)
  }

  return (
    <div
      className={styles.slideButton}
      onClick={() => action(data)}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <Text
        tag='p'
        size='m'
        children={text}
        customStyles={{
          width: onHover && !isMobile ? '250px' : '0',
          transition: 'all 200ms ease-in-out',
          opacity: onHover && !isMobile ? '0.5' : '0',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          marginRight: 'var(--spacing-m)'
        }}
      />
      <Icon
        icon={icon}
        style={{
          height: `${iconHeight}rem`,
          width: `${iconWidth}rem`,
          fill: onHover ? '#320FCC' : '#0B1A32',
          opacity: onHover ? '1' : '0.5',
          transition: 'all 200ms ease-in-out'
        }}
        className={styles.icon}
      />
    </div>
  )
}

export default SlideButton
