import React from "react";
import Text from "@atoms/Text/Text";
import styles from "./ModalTitle.module.scss";
import logo from "../../../assets/img/insights.svg";
import Tool from "../../atoms/IconTooltip/IconTooltip";

const ModalTitle = ({ title, customStyles, firstLogin = false, isMobile }) => {
  return !firstLogin ? (
    <div className={styles.modalTitle} style={customStyles}>
      <div className={`${styles.titles}`}>
        <Text tag="p" size="l" weight="bold" align="left">
          {title}
        </Text>
      </div>
    </div>
  ) : (
    <div
      className={`${styles.modalTitle} ${styles.modalTitleFirstLogin}`}
      style={customStyles}
    >
      <div className={`${styles.logo}`}>
        <img src={logo} alt="" />
      </div>
      <div className={`${styles.titles}`}>
        <Text tag="p" size="l" weight="bold" align="left">
          {title}
        </Text>

        <div className={`${styles.subTitle}`}>
          <h2 className={`${styles.firstLogin}`}>
            Completalo en <span>4 simples pasos</span>
          </h2>
          {!isMobile && (
            <Tool
              text="Volvé a ver este tutorial cuando quieras en nuestra sección de Ayuda > Profesionales > Onboarding."
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalTitle;
