import React from 'react'
import styles from './CheckboxGroup.module.scss'
import Checkbox from '@atoms/Checkbox/Checkbox'
import Text from '@atoms/Text/Text'
import BorderedItem from '@atoms/BorderedItem/BorderedItem'

const CheckboxGroup = ({
  options,
  selectedItems,
  disabledItems,
  disabledMode,
  selectItemAction,
  mode,
  enableSoon
}) => {
  return (
    <div className={`${styles.container} ${styles[mode]}`}>
      {options?.map((option, index) => {
        let selected
        let disabled = false
        selectedItems.map((item) => {
          if (option.id === item.id) return (selected = true)
          return false
        })
        if (disabledMode) {
          disabledItems &&
            disabledItems.map((item) => {
              if (option.id === item.id) return (disabled = true)
              return false
            })
        }
        if (mode === 'editProfileSubservices') {
          return (
            <div
              key={index} className={styles.subServiceContainer}
              onClick={() => {
              // if(!disabled){
                selectItemAction(option)
              // }
              }}
            >
              <BorderedItem
                mode={selected ? 'selectedSubService' : 'subService'}
                // key={index}
              >
                <Text tag='p' size='m' className={styles.subServiceText}>{option.name}</Text>
              </BorderedItem>
            </div>
          )
        } else {
          return (
            <div key={index} className={styles.optionValue}>
              <Checkbox
                option={option}
                checked={selected}
                handleCheck={selectItemAction}
                disabled={disabled}
                enableSoon={enableSoon}
              />
            </div>
          )
        }
      })}
    </div>
  )
}

export default CheckboxGroup
