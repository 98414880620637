import React from 'react'
import styles from './BuyZenCoinsBanner.module.scss'
import { ReactComponent as ZenProsCoin } from '@assets/svg/zencoins.svg'

const BuyZenCoinsBanner = ({ totalZenCoins }) => {
  return (
    <div className={styles.container}>
      <ZenProsCoin className={styles.icon} />
      <h3 className={styles.title}>Contactos ilimitados por lanzamiento</h3>
      <p className={styles.subtitle}>
        Probalo <b>6 meses</b> sin compromiso y comenzá a recibir clientes
        ahora.
      </p>
    </div>
  )
}

export default BuyZenCoinsBanner
