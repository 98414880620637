import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getUserWorksRequests,
  setCurrentDetailWork,
  setCurrentWorkData,
  setModal,
  setWorkTypeSelectedRDX
} from '@actions/index'
import styles from './UserWorks.module.scss'
import ContactZenPros from '@molecules/ContactZenPros/ContactZenPros'
import ShadowBox from '@atoms/ShadowBox/ShadowBox'
import Text from '@atoms/Text/Text'
import UserWorkRequest from '@molecules/UserWorkRequest/UserWorkRequest'
import { ReactComponent as ChevronIcon } from '@assets/svg/down-chevron.svg'
import { useHistory } from 'react-router'
// import moment from 'moment'
import dayjs from 'dayjs'
import { getUnreadMessagesFromWork } from '@actions/profileActions'

const UserWorks = () => {
  const [selectedWorkType, setSelectedWorkType] = useState([])
  const [selectedWorks, setSelectedWorks] = useState([])
  const [viewAllWorks, setViewAllWorks] = useState(false)

  const { worksList, workType, profileData } = useSelector((state) => state.profile)

  const dispatch = useDispatch()
  const history = useHistory()

  const handleChangeWorksView = (newWork) => {
    sessionStorage.setItem('currentTab', JSON.stringify(newWork))
    dispatch(setWorkTypeSelectedRDX(newWork))
    setSelectedWorkType(newWork)
  }

  const handleViewAllWorks = () => {
    setViewAllWorks(!viewAllWorks)
  }

  const handleQualification = (work) => {
    dispatch(setCurrentWorkData(work))
    dispatch(setModal(true, 'finishWorkUser'))
  }
  const handleViewDetail = (work) => {
    dispatch(setCurrentDetailWork(work))
    history.push(`/detalle-trabajo/${work.id}`)
  }

  const WorkComponent = ({ work, index }) => {
    switch (selectedWorkType.idName) {
      case 'current':
        if (index > 2 && !viewAllWorks) return false
        return (
          <UserWorkRequest work={work} handleViewDetail={handleViewDetail} />
        )
      case 'qualificationPending':
        if (index > 2 && !viewAllWorks) return false
        return (
          <UserWorkRequest
            work={work}
            noStatus
            qualificationAction={handleQualification}
            handleViewDetail={handleViewDetail}
          />
        )
      case 'finished':
        if (index > 2 && !viewAllWorks) return false
        return (
          <UserWorkRequest
            work={work}
            noStatus
            handleViewDetail={handleViewDetail}
            finishedTab
          />
        )
      case 'rejected':
        if (index > 2 && !viewAllWorks) return false
        return (
          <UserWorkRequest
            work={work}
            noStatus
            handleViewDetail={handleViewDetail}
          />
        )

      default:
        return <></>
    }
  }

  const sortWorksByDate = (works) => {
    if (works) {
      const auxFilteredSortedByDate = works.sort((a, b) => {
        if (dayjs(b.created) < dayjs(a.created)) {
          return -1
        }
        if (dayjs(b.created) > dayjs(a.created)) {
          return 1
        }
        return 0
      })
      return auxFilteredSortedByDate
    }
    return works
  }

  useEffect(() => {
    const auxList = worksList?.find((work) => work.id === selectedWorkType.id)
      ?.works

    setSelectedWorks(sortWorksByDate(auxList))
    // eslint-disable-next-line
  }, [selectedWorkType]);

  useEffect(() => {
    if (workType.id) {
      return handleChangeWorksView(
        worksList?.find((work) => work.id === workType.id)
      )
    }
    if (worksList) {
      const hasNews = worksList[1].works.length > 0
      if (hasNews) {
        setSelectedWorkType(worksList[1])
        setSelectedWorks(sortWorksByDate(worksList[1]?.works))
      } else {
        const currentTab = JSON.parse(sessionStorage.getItem('currentTab'))
        if (currentTab) {
          setSelectedWorkType(currentTab)
          setSelectedWorks(sortWorksByDate(currentTab?.works))
        } else {
          setSelectedWorkType(worksList[0])
          setSelectedWorks(sortWorksByDate(worksList[0]?.works))
        }
      }
    }
  }, [worksList])

  useEffect(() => {
    dispatch(getUserWorksRequests());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedWorks?.length > 0) {
      selectedWorks.map((work) => {
        dispatch(getUnreadMessagesFromWork(work.id, profileData.id))
      })
    }
  }, [selectedWorks])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.tabContent}>
          <div className={styles.tabs}>
            {worksList?.map((work, index) => (
              <div
                key={index}
                className={`${styles.tab} ${work.id === selectedWorkType.id &&
                  styles.active}`}
                onClick={() => handleChangeWorksView(work)}
              >
                <p>{work?.name}</p>
                <span className={styles.worksLength}>{work?.works?.length}</span>
              </div>
            ))}
          </div>
          <ShadowBox
            width='100%'
            customStyles={{
              marginBottom: 'var(--spacing-medium)',
              padding:
                'var(--spacing-medium) var(--spacing-medium) var(--spacing-small)'
            }}
          >
            <div className={styles.worksList}>
              {selectedWorks?.length > 0
                ? (
                    selectedWorks.map((selectedWork, index) => (
                      <WorkComponent
                        key={index}
                        work={selectedWork}
                        index={index}
                      />
                    ))
                  )
                : (
                  <Text
                    tag='p'
                    size='l'
                    weight='bold'
                    customStyles={{ margin: 'var(--spacing-medium) 0' }}
                  >
                    Todavía no tenes trabajos
                  </Text>
                  )}
            </div>
            {selectedWorks?.length > 3 && (
              <div
                className={styles.viewAllWorks}
                onClick={() => handleViewAllWorks()}
              >
                <Text tag='p' size='m' color='secondary-blue'>
                  {viewAllWorks ? 'Ocultar' : 'Ver'} todos los trabajos
                </Text>
                <ChevronIcon
                  className={`${styles.icon} ${viewAllWorks && styles.open}`}
                />
              </div>
            )}
          </ShadowBox>
          <ContactZenPros />
        </div>
      </div>
    </div>
  )
}

export default UserWorks
