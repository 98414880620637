import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import Text from '@atoms/Text/Text'
import styles from '../styles.module.scss'
import { sumCategory } from '@utils/functions/sumCategory'
import { useDispatch, useSelector } from 'react-redux'
import { getProsProfessionsSearch } from '@actions/'

export default function ServiceBanner ({ banner }) {
  const { allCategoriesSearch } = useSelector(
    (state) => state.general
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProsProfessionsSearch())
  }, [])

  const filterInMostSearch = (title) => {
    const cs = allCategoriesSearch.filter((item) => item.name === title)

    if (cs.length > 0) { localStorage.setItem('currentSearch', JSON.stringify(cs[0])) }
    sumCategory(title)
  }

  useEffect(() => {
    filterInMostSearch(banner.title)
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{ height: '100%' }}
      className={styles.serviceBanner}
      whileHover={{ scale: 1.1 }}
      transition={{ type: 'spring' }}
      onClick={() => filterInMostSearch(banner.title)}
    >
      <Link to={banner.url}>
        <div className={styles.card}>
          <img src={banner.img} alt={banner.title} />

          <Text tag='h4' size='l' align='center' weight='bold' className={styles.titleCard}>
            {banner.title.toUpperCase()}
          </Text>
        </div>
      </Link>
    </motion.div>
  )
}
