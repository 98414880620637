import Checkbox from "@atoms/Checkbox/Checkbox";
import CheckboxGroup from "@molecules/CheckboxGroup/CheckboxGroup";
import { motion } from "framer-motion";
import React, { useRef, useState, useEffect } from "react";
import styles from "./Filter.module.scss";

const Filter = ({
  checkbox,
  name,
  options,
  handleSelect,
  customStyles,
  selectedOptions,
  optionsWidthAuto,
  checkboxGroup,
  enableSoon,
}) => {
  const [openOptions, setOpenOptions] = useState(false);

  const filterRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, []);

  const handleClick = (e) => {
    if (filterRef.current.contains(e.target)) {
      return;
    }
    setOpenOptions(false);
  };

  const handleSelectOption = (option) => {
    if (!checkbox) setOpenOptions(false);
    handleSelect(option);
  };

  const handleOpenList = (value) => {
    setOpenOptions(value);
  };

  return (
    <div ref={filterRef} className={styles.filter} style={customStyles}>
      <div
        className={`${styles.selectedOption} ${openOptions && styles.opened}`}
        onClick={() => handleOpenList(!openOptions)}
      >
        <p>
            {selectedOptions && selectedOptions[0]?.name
              ? (selectedOptions[selectedOptions.length-1]?.parentcategory?.parentcategory === undefined ? name:selectedOptions[selectedOptions.length-1].name)
              : name}
          </p>
      </div>
      <div
        className={`${styles.optionsList} ${optionsWidthAuto &&
          styles.optionsWidthAuto}`}
      >
        {openOptions &&
          options.map((option, index) => {
            if (checkboxGroup) {
              return (
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: "max-content" }}
                  key={index}
                >
                  <div className={styles.checkboxGroupOptions}>
                    <h6 className={styles.optionName}>{option.name}</h6>
                    <CheckboxGroup
                      options={option.neighborhood}
                      selectedItems={selectedOptions}
                      selectItemAction={handleSelect}
                      mode="noMaxHeight"
                      enableSoon={enableSoon}
                    />
                  </div>
                </motion.div>
              );
            }
            if (checkbox) {
              const checked = !!selectedOptions.find(
                (selectedOption) => selectedOption?.id === option.id
              );
              return (
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: "max-content" }}
                  key={index}
                >
                  <div className={styles.filterOption}>
                    <Checkbox
                      option={option}
                      handleCheck={handleSelectOption}
                      checked={checked}
                    />
                  </div>
                </motion.div>
              );
            }
            return (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: "max-content" }}
                key={index}
                onClick={() => handleSelectOption(option)}
              >
                <div className={styles.filterOption}>
                  <label title={option.name}>{option.name}</label>
                </div>
              </motion.div>
            );
          })}
      </div>
    </div>
  );
};

export default Filter;
