import React from 'react'
import styles from './GoBackButton.module.scss'
import Button from '@atoms/Button/Button'
import { ReactComponent as ChevronIcon } from '@assets/svg/down-chevron.svg'
import { withRouter } from 'react-router-dom'

const GoBackButton = ({ history, mobile }) => {
  const handleClick = () => {
    history.goBack()
  }
  return (
    <div className={styles.container}>
      {mobile
        ? (
          <div className={styles.mobileGoBack} onClick={() => handleClick()}>
            <ChevronIcon className={styles.mobileIcon} />
          </div>
          )
        : (
          <Button
            buttonStyle='terciary'
            customStyles={{
              display: 'flex',
              alignItems: 'center',
              maxWidth: '12.2rem',
              padding: '8px 16px',
            }}
            onClickHandler={() => handleClick()}
          >
            <ChevronIcon className={styles.icon} />
            <p className={styles.text}>Volver</p>
          </Button>
          )}
    </div>
  )
}

export default withRouter(GoBackButton)
