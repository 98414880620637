import React, { useEffect, useState } from "react";
import ContactPro from "@molecules/ContactPro/ContactPro";
import ModalTitle from "@atoms/ModalTitle/ModalTitle";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ModalPanel.module.scss";
import { withRouter, useParams } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/es";
import {
  getHorariosContacto,
  getFirstLogin,
  setReadyText,
  footerOnboarding,
  finishedVideos,
  updateDataOnboarding,
  setModal,
  setSuccessModal,
} from "@actions/generalActions";
import { getIdFromLS } from "@utils/tokenService";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "@config/api";
import { toast } from "react-toastify";
import imageDel from "./assets/imageDel.svg";
import imageNo from "./assets/no.svg";
import imageSiDelete from "./assets/siDelete.svg";
import imageSiLayOff from "./assets/siLayOff.svg";
import imageSiActive from "./assets/siActive.svg";
import { professional } from "@actions/panelActions";

const ModalPanel = ({
  isMobile,
  modalType,
  history,
  professionalProfile,
  withoutServices = false,
  panelModal,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.panel.professional);

  let { title, modalPanel, question, email, id, currentIsActive } = useSelector(
    (state) => state.panel.panelModal
  );

  const negative = () => {
    dispatch(setModal(false));
    dispatch(professional({}));
    if (window.location.pathname.includes("panel-administracion/"))
      history.go(-1);
  };

  const affirmative = ({ id, email }) => {
    if (modalPanel === "eliminar") return delSi(id, email);
    if (modalPanel === "suspender") return putActive(id, currentIsActive);
  };

  const delSi = async (id, email) => {
    await axiosDelete(`admin/users/${id}`)
      .then((response) => {
        dispatch(setModal(true, "successModal"));
        dispatch(
          setSuccessModal({
            title: "¡Listo!",
            subtitle: `Eliminaste la cuenta de ${email} con éxito`,
            buttonText: "Volver al panel",
            urlToRedirect: "/panel-administracion",
            withoutRedirect: false,
            panel: true,
          })
        );
        history.push('/panel-administracion');
      })
      .catch((error) => console.error(error));
  };

  // const [isActive, setIsActive] = useState(true);

  // const layOffGet = async (id) => {
  //   await axiosGet(`admin/users/isActive/${id}`)
  //     .then((response) => {
  //       let { isActive } = response.data;
  //       setIsActive(isActive);
  //     })
  //     .catch((error) => console.error(error));
  // };

  const layOffSi = async (id, email) => {
    await axiosGet(`admin/users/isActive/${id}`)
      .then((response) => {
        let { isActive } = response.data;
        return isActive;
      })
      .catch((error) => console.error(error));
  };

  const putActive = async (id, currentIsActive) => {
    await axiosPut(`admin/users/isActive/${id}`, { currentIsActive })
      .then((response) => {
        dispatch(setModal(true, "successModal"));
        dispatch(
          setSuccessModal({
            title: "¡Listo!",
            subtitle: currentIsActive
              ? `Suspendiste la cuenta de ${email} con éxito`
              : `Activaste la cuenta de ${email} con éxito`,
            buttonText: "Volver al panel",
            urlToRedirect: "/panel-administracion",
            withoutRedirect: false,
            panel: true,
          })
        );
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <div className={styles.container}>
        <ModalTitle
          title={title}
          customStyles={{ marginBottom: "var(--spacing-small)" }}
          isMobile={isMobile}
        />
        <div className={`${styles.infoCenter} divSc`} id="divSc">
          <section className={styles.imageDel}>
            <img src={imageDel} alt="" />
          </section>
          <h2>
            {question}
            <strong>{email}</strong>?
          </h2>
          <div className={styles.buttons}>
            <img
              src={imageNo}
              alt=""
              className={styles.buttonSi}
              onClick={() => negative()}
            />
            <img
              src={modalPanel === "eliminar" ? imageSiDelete : (modalPanel === 'suspender' && currentIsActive) ? imageSiLayOff : imageSiActive}
              alt=""
              className={styles.buttonNo}
              onClick={() => affirmative(user)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ModalPanel);
