import React, { useEffect, useState } from "react";
import Button from "@atoms/Button/Button";
import styles from "./Works.module.scss";
import BuyZenCoinsBanner from "@molecules/BuyZenCoinsBanner/BuyZenCoinsBanner";
import { useDispatch, useSelector } from "react-redux";
import {
  getWorksRequests,
  setModal,
  setProfessionalWorkStatus,
  setCurrentWorkData,
  setWorkTypeSelectedRDX,
  cleanMobileFilter,
} from "@actions/";
import Filter from "@atoms/Filter/Filter";
import Text from "@atoms/Text/Text";
import WorkRequest from "@molecules/WorkRequest/WorkRequest";
import useScreenDetect from "@hooks/useScreenDetect";
import { ReactComponent as SortIcon } from "@assets/svg/sort.svg";
import WorkRequestAccepted from "@molecules/WorkRequest/WorkRequestAccepted";
import { withRouter } from "react-router-dom";
import WorkRequestFinished from "@molecules/WorkRequest/WorkRequestFinished";
import ContactZenPros from "@molecules/ContactZenPros/ContactZenPros";
// import moment from 'moment'
import dayjs from "dayjs";
import {
  getUnreadMessagesFromWork,
  removeNotification,removeNotificationForPro,
  setJobReadStatus,
} from "@actions/profileActions";

const WorksLayout = ({ history }) => {
  const {
    worksList,
    mobileFilter,
    workType,
    profileData,
    currentWork,
  } = useSelector((state) => state.profile);
  const [selectedWorkType, setSelectedWorkType] = useState([]);
  const [selectedWorks, setSelectedWorks] = useState([]);
  const [filteredWorks, setFilteredWorks] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [openedWork, setOpenedWork] = useState();

  const { isMobile } = useScreenDetect();

  const dispatch = useDispatch();

  const handleChangeWorksView = (newWork) => {
    sessionStorage.setItem("currentTab", JSON.stringify(newWork));
    dispatch(setWorkTypeSelectedRDX(newWork));
    setSelectedWorkType(newWork);
  };

  const handleOpenWork = (work) => {
    if (
      openedWork?.id === work.id ||
      Number(localStorage.getItem("currentWorkId")) === work.id
    ) {
      localStorage.setItem("currentWorkId", null);
      return setOpenedWork({ id: null });
    }
    setOpenedWork(work);
    localStorage.setItem("currentWorkId", null);
  };

  const handleDeclineWork = (work) => {
    dispatch(setCurrentWorkData(work));
    if (profileData.profileMode == "PRO"){
      dispatch(removeNotificationForPro("work", `workid${work.id}`));
//      dispatch(setJobReadStatus(work.id, "NOTIFY_PRO"));
    }else {
      
      dispatch(removeNotification("work", `workid${work.id}`));
      dispatch(setJobReadStatus(work.id, "NOTIFY_USER"));
    }
    
    
    dispatch(setModal(true, "declineWork"));
  };
  const handleReplyMessage = (work) => {
    history.push(`/chat/${work.id}`);
  };

  const handleSortModal = () => {
    switch (selectedWorkType.idName) {
      case "pending":
        dispatch(setModal(true, "sortFilterPending"));
        break;
      case "finished":
        dispatch(setModal(true, "sortFilterFinished"));
        break;
      default:
        break;
    }
  };

  const handleFinishWorkModal = (work) => {
    dispatch(setJobReadStatus(work.id, "NOTIFY_USER"));
    dispatch(setCurrentWorkData(work));
    dispatch(setModal(true, "finishWork"));
  };

  const handleAcceptWork = (work) => {
    dispatch(removeNotification("work", `workid${work.id}`));
    dispatch(
      setProfessionalWorkStatus(work.id, "APPROVED", undefined, "NOTIFY_USER")
    );
    localStorage.setItem("currentWorkId", work.id);
    dispatch(getWorksRequests());
  };

  const handleQualificateWork = (work) => {
    dispatch(setCurrentWorkData(work));
    dispatch(setModal(true, "starsReview"));
  };

  const sortWorksByDate = (works) => {
    if (works) {
      const auxFilteredSortedByDate = works.sort((a, b) => {
        if (dayjs(b.created) < dayjs(a.created)) {
          return -1;
        }
        if (dayjs(b.created) > dayjs(a.created)) {
          return 1;
        }
        return 0;
      });
      return auxFilteredSortedByDate;
    }
    return works;
  };

  const filterWorks = () => {
    let auxFiltered = [];
    switch (selectedWorkType.idName) {
      case "pending":
        if (!selectedFilter[0] || selectedFilter.length > 1) {
          return sortWorksByDate(selectedWorks);
        }
        auxFiltered = selectedWorks.filter((selectedWork) =>
          selectedWork.jobStatusNewRead.includes(selectedFilter[0].idValue)
        );
        return sortWorksByDate(auxFiltered);
      case "finished":
        if (!selectedFilter[0] || selectedFilter.length > 1) {
          return sortWorksByDate(selectedWorks);
        }
        if (selectedFilter[0].id === 1) {
          auxFiltered = selectedWorks.filter(
            (selectedWork) => !selectedWork.reviewOk
          );
        } else {
          auxFiltered = selectedWorks.filter(
            (selectedWork) => selectedWork.reviewOk
          );
        }
        return sortWorksByDate(auxFiltered);
      default:
        return sortWorksByDate(selectedWorks);
    }
  };
  const handleFilter = (value) => {
    let included = false;
    selectedFilter.map((filter) => {
      if (filter.id === value.id) return (included = true);
      return false;
    });
    if (included) {
      return setSelectedFilter([
        ...selectedFilter.filter((i) => i.id !== value.id),
      ]);
    }
    return setSelectedFilter([...selectedFilter, value]);
  };

  const filterOptions = () => {
    switch (selectedWorkType.idName) {
      case "pending":
        return [
          { name: "Nuevo", id: 1, idValue: "NOTIFY_PRO" },
          { name: "Leído", id: 2, idValue: "READ" },
        ];
      case "finished":
        return [
          { name: "Pendiente de calificación", id: 1 },
          { name: "Calificado", id: 2 },
        ];

      default:
        return null;
    }
  };

  const WorkComponent = ({ work }) => {
    switch (selectedWorkType.idName) {
      case "pending":
        return (
          <WorkRequest
            work={work}
            isMobile={isMobile}
            declineAction={handleDeclineWork}
            acceptAction={handleAcceptWork}
            opened={openedWork?.id === work.id}
            handleOpenWork={handleOpenWork}
          />
        );
      case "approved":
        return (
          <WorkRequestAccepted
            work={work}
            isMobile={isMobile}
            replyMessageAction={handleReplyMessage}
            acceptAction={handleFinishWorkModal}
            opened={
              Number(localStorage.getItem("currentWorkId")) === work.id ||
              openedWork?.id === work.id
            }
            handleOpenWork={handleOpenWork}
          />
        );
      case "finished":
        return (
          <WorkRequestFinished
            work={work}
            isMobile={isMobile}
            viewConversationAction={handleReplyMessage}
            qualificateAction={handleQualificateWork}
            opened={openedWork?.id === work.id}
            handleOpenWork={handleOpenWork}
          />
        );

      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (workType.id) {
      return handleChangeWorksView(
        worksList?.find((work) => work.id === workType.id)
      );
    }
  }, [worksList]);

  useEffect(() => {
    if (worksList) {
      if (worksList) {
        const hasNews = worksList[0].works.length > 0;
        if (hasNews) {
          setSelectedWorkType(worksList[0]);
          setSelectedWorks(sortWorksByDate(worksList[0]?.works));
          setFilteredWorks(sortWorksByDate(worksList[0].works));
        } else {
          const currentTab = JSON.parse(sessionStorage.getItem("currentTab"));
          if (currentTab) {
            setSelectedWorkType(currentTab);
            setSelectedWorks(sortWorksByDate(currentTab?.works));
            setSelectedWorks(sortWorksByDate(currentTab?.works));
          } else {
            setSelectedWorkType(worksList[1]);
            setFilteredWorks(sortWorksByDate(worksList[1].works));
            setFilteredWorks(sortWorksByDate(worksList[1].works));
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [worksList]);

  useEffect(() => {
    if (selectedWorks?.length > 0) {
      selectedWorks.map((work) => {
        dispatch(getUnreadMessagesFromWork(work.id, profileData.id));
      });
    }
  }, [selectedWorks]);

  useEffect(() => {
    const auxList = worksList?.find((work) => work.id === selectedWorkType.id)
      ?.works;

    setSelectedWorks(auxList);
    setFilteredWorks(sortWorksByDate(auxList));
    dispatch(cleanMobileFilter());
    // auxList && setOpenedWork(auxList[0]);
    // eslint-disable-next-line
  }, [selectedWorkType]);

  useEffect(() => {
    setFilteredWorks(filterWorks());
    // eslint-disable-next-line
  }, [selectedFilter]);

  useEffect(() => {
    if (mobileFilter.length > 0) setSelectedFilter(mobileFilter);
    // eslint-disable-next-line
  }, [mobileFilter]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.topContent}>
          <BuyZenCoinsBanner totalZenCoins={200} />
          {isMobile && (
            <div className={styles.sortContent}>
              <Button
                buttonStyle="terciary"
                onClickHandler={() => handleSortModal()}
                customStyles={{ maxWidth: "100%" }}
              >
                <SortIcon className={styles.sortIcon} />
                Ordenar
              </Button>
            </div>
          )}
        </div>
        <div className={styles.tabContent}>
          <div className={styles.tabs}>
            {worksList?.map((work, index) => (
              <div
                key={index}
                className={`${styles.tab} ${work.id === selectedWorkType.id &&
                  styles.active}`}
                onClick={() => handleChangeWorksView(work)}
              >
                <p>{work?.name ?? ""}</p>
                <span className={styles.worksLength}>
                  {work?.works?.length}
                </span>
              </div>
            ))}
            {!isMobile && selectedWorkType.idName !== "approved" && (
              <Filter
                name="Todas"
                checkbox
                options={filterOptions()}
                selectedOptions={selectedFilter}
                handleSelect={handleFilter}
                customStyles={{
                  marginLeft: "auto",
                  marginRight: "0",
                  width:
                    selectedWorkType.idName === "pending" ? "22rem" : "29rem",
                }}
              />
            )}
          </div>
          <div className={styles.worksList}>
            {filteredWorks.length > 0 ? (
              filteredWorks.map((selectedWork, index) => (
                <WorkComponent key={index} work={selectedWork} />
              ))
            ) : (
              <Text
                tag="p"
                size="l"
                weight="bold"
                customStyles={{ margin: "var(--spacing-medium) 0" }}
              >
                Todavía no tenes trabajos
              </Text>
            )}
            <ContactZenPros />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(WorksLayout);
