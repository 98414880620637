import IconTooltip from "@atoms/IconTooltip/IconTooltip";
import RemovableElement from "@atoms/RemovableElement/RemovableElement";
import useScreenDetect from "@hooks/useScreenDetect";
import React, { useState } from "react";
import styles from "./AddMailsToShare.module.scss";

const AddMailsToShare = ({ emails, handleAddEmail, handleDeleteEmail }) => {
  const [inputValue, setInputValue] = useState("");

  const { isMobile } = useScreenDetect();

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    if (e.key === "Enter" && inputValue !== "" && inputValue.includes("@")) {
      handleAddEmail(inputValue);
      setInputValue("");
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {emails.map((email, index) => (
          <RemovableElement
            text={email}
            key={index}
            handleDeleteElement={handleDeleteEmail}
          />
        ))}
        <input
          className={styles.input}
          type="email"
          value={inputValue}
          placeholder="tuemail@email.com"
          onChange={(e) => handleInputChange(e)}
          onKeyPress={(e) => handleSubmit(e)}
        />
      </div>
      {!isMobile && (
        <div className={styles.icon}>
          <IconTooltip
            customStyles={{ alignItems: isMobile ? "center" : "flex-start" }}
            text="Apretá la tecla enter para ir agregando emails"
          />
        </div>
      )}
    </div>
  );
};

export default AddMailsToShare;
