import { confirmEmail } from '@actions/authActions'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'

const ValidateEmail = () => {
  const { token } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(confirmEmail(token, history))
    // eslint-disable-next-line
  }, []);

  return <div />
}
export default ValidateEmail
