import React, { useEffect } from "react";
import styles from "./ProfileServices.module.scss";
import BorderedItem from "@atoms/BorderedItem/BorderedItem";
import Text from "@atoms/Text/Text";
import { ReactComponent as ChevronIcon } from "@assets/svg/down-chevron.svg";
import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { motion } from "framer-motion";
import CurrencyInput from "react-currency-input-field";
import Select from "@atoms/Select/Select";

const ServiceList = ({
  serviceCategory,
  opened,
  handleOpenList,
  indexFromParent,
  isMobile,
  editMode,
  deleteServiceAction,
  fromRegister,
  handleChangeItem,
  register,
  values,
  onModal,
}) => {
  const handleOpen = () => {
    handleOpenList(serviceCategory);
  };

  useEffect(() => {
    if (editMode) {
      if (fromRegister) {
        serviceCategory.sons.map((subService, indexAux) =>
          createInputToForm(subService.id, indexAux)
        );
      } else {
        serviceCategory.categories.map((subService, indexAux) => {
          createInputToForm(subService.id, indexAux, subService);
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!editMode && indexFromParent === 0) handleOpen(serviceCategory);
  }, [serviceCategory]);

  const createInputToForm = (id, indexAux, subService) => {
    register(
      {
        name: `professionalCategories[${indexFromParent}].cats[${indexAux}].value`,
        value: subService ? "$" + Number(subService.value).toFixed(0) : "",
      },
      { required: "Campo obligatorio" }
    );
    register(
      {
        name: `professionalCategories[${indexFromParent}].cats[${indexAux}].paymentTypeId`,
        value: subService ? Number(subService.paymentType.id) : "",
      },
      { required: "Campo obligatorio" }
    );
    register({
      name: `professionalCategories[${indexFromParent}].cats[${indexAux}].categoryId`,
      value: id,
    });
  };

  const handleDeleteService = (service) => {
    deleteServiceAction(service);
  };

  const serviceCategoryToMap = fromRegister
    ? serviceCategory.sons
    : serviceCategory.categories;

  return (
    serviceCategoryToMap.length > 0 && (
      <div>
        {editMode && (
          <div className={styles.modalServiceHeader}>
            <Text
              tag="p"
              size={isMobile ? "s" : "m"}
              className={styles.serviceCategoryName}
              customStyles={{
                // color: 'var(--secondary-gray)',
                // fontWeight: 'var(--w-bold)',
                fontSize: isMobile ? "var(--font-s)" : "var(--font-xm)",
                // display: isMobile ? 'inline' : 'inline',
                width: "100%",
                textAlign: "left",
              }}
            >
              {serviceCategory.name}
            </Text>
            <Text
              style={{ marginLeft: "1.5rem" }}
              tag="p"
              size={isMobile ? "s" : "m"}
              customStyles={{
                marginTop: "0.5rem",
                color: "var(--color-item)",
                display: isMobile ? "inline" : "inline",
                float: "right",
              }}
            >
              Todas
            </Text>
            <CurrencyInput
              style={{
                fontSize: "var(--font-m)",
                backgroundColor: "rgba(222, 222, 222, 0.2)",
                color: "var(--main-blue)",
                WebkitAppearance: "none",
                padding: "0.2rem 1rem",
                borderRadius: "4px",
                border: "unset",
                fontWeight: "var(--w-regular)",
                height: isMobile ? "4rem" : "3rem",
                position: "relative",
                fontFamily: "Open Sans, sans-serif",
                outline: "none",
                opacity: "1",
                maxWidth: isMobile ? "15rem" : "10rem",
                margin: isMobile ? "0 0.8rem 0 0" : "0 0.8rem 0 auto",
                width: "100%",
                float: "right",
                marginLeft: "var(--spacing-small)",
                display: isMobile ? "inline" : "inline",
              }}
              prefix="$"
              name="generalPrice"
              onChange={(value) => {
                serviceCategory.categories.map((subService, index) => {
                  handleChangeItem(
                    `professionalCategories[${indexFromParent}].cats[${index}].value`,
                    value.target.value.split("$")[1]
                  );
                });
              }}
              placeholder="$"
              groupSeparator="."
              autoComplete="off"
            />
            <Select
              placeholder="Modalidad"
              options={[
                { name: "Por trabajo", id: 1 },
                { name: "Por hora", id: 2 },
                { name: "A convenir", id: 3 },
              ]}
              handleSelectAction={(value) =>
                serviceCategory.categories.map((subService, index) => {
                  handleChangeItem(
                    `professionalCategories[${indexFromParent}].cats[${index}].paymentTypeId`,
                    value.id
                  );
                })
              }
              customStyles={{
                marginRight: "var(--spacing-small)",
                height: isMobile ? "4rem" : "3rem",
                // zIndex: 10
              }}
            />
          </div>
        )}
        <div
          className={styles.serviceCategoryContainer}
          style={{
            border: editMode ? "1px solid var(--main-gray)" : "",
            borderRadius: "15px",
            padding: editMode ? "5px" : "",
            marginTop: editMode ? "var(--spacing-small)" : "",
          }}
        >
          <div className={styles.serviceCategoryHeader}>
            {!editMode && (
              <BorderedItem
                customStyles={
                  !isMobile
                    ? {
                        backgroundColor: "var(--color-item)",
                        paddingTop: "6px",
                        marginBottom: "var(--spacing-extrasmall)",
                      }
                    : {
                        backgroundColor: "var(--color-item)",
                        paddingTop: "6px",
                        marginBottom: "var(--spacing-extrasmall)",
                        maxWidth: "fit-content", // antes estaba en 140px pero asi se corrige
                      }
                }
                onClick={() => handleOpen()}
              >
                <Text
                  tag="p"
                  size={isMobile ? "s" : "m"}
                  className={styles.serviceCategoryName}
                >
                  {serviceCategory.name}
                </Text>
              </BorderedItem>
            )}

            {!editMode && (
              <div
                onClick={() => handleOpen()}
                className={styles.serviceCategoryOpenHandler}
              >
                <p>{opened ? "Ocultar" : "Ver"} servicios</p>
                <ChevronIcon
                  className={`${styles.icon} ${opened && styles.open}`}
                />
              </div>
            )}
          </div>
          {(opened || editMode) && (
            <div
              className={`${styles.subServiceList} ${(opened || editMode) &&
                styles.open}`}
            >
              {serviceCategoryToMap?.map((subService, index) => (
                <motion.div
                  initial={{ height: onModal ? "" : 0 }}
                  animate={{
                    height: editMode && isMobile ? "85px" : "max-content",
                  }}
                  key={index}
                  className={`${styles.subService} ${fromRegister &&
                    styles.fromRegister}`}
                  style={{
                    zIndex: serviceCategoryToMap.length - index,
                    position: "relative",
                    display: "inline-flex",
                  }}
                >
                  <BorderedItem
                    customStyles={{
                      margin: isMobile ? "0" : "0 var(--spacing-small)",
                      // marginLeft: '0.7rem',
                      marginRight: isMobile ? "0" : onModal ? "8rem" : "0",
                      backgroundColor: "var(--color-item-background)",
                      border: "2px solid var(--color-item)",
                      minWidth: onModal ? "185px" : isMobile ? "50%" : "",
                      width: onModal ? "100%" : isMobile ? "60%" : "",
                      display: "flex",
                      justifyContent: "center",
                      float: "right",
                    }}
                  >
                    <Text
                      tag="p"
                      size={isMobile ? "s" : "m"}
                      align="center"
                      customStyles={{
                        color: "var(--color-item)",
                        fontWeight: "var(--w-bold)",
                        maxWidth: editMode
                          ? isMobile
                            ? "100%"
                            : "30rem"
                          : isMobile
                          ? "90%"
                          : "40vw",
                        minWidth: editMode ? (isMobile ? "" : "25rem") : ""
                      }}
                    >
                      {subService.name}
                    </Text>
                  </BorderedItem>
                  
                  {editMode ? (
                    <div className={styles.editValues}>
                      <div
                        className={`${!fromRegister &&
                          subService.paymentType.id === 3 &&
                          !values.professionalCategories &&
                          styles.inputcurrencyhide}
                            ${((fromRegister &&
                              !values.professionalCategories) ||
                              (values.professionalCategories &&
                                (values.professionalCategories[indexFromParent]
                                  .cats[index].paymentTypeId === 3 ||
                                  values.professionalCategories[indexFromParent]
                                    .cats[index].paymentTypeId === ""))) &&
                              styles.inputcurrencyhide}`}
                      >
                        <CurrencyInput
                          style={{
                            fontSize: "var(--font-m)",
                            backgroundColor: "rgba(222, 222, 222, 0.2)",
                            color: "var(--main-blue)",
                            WebkitAppearance: "none",
                            padding: "0.2rem 1rem",
                            borderRadius: "4px",
                            border: "unset",
                            fontWeight: "var(--w-regular)",
                            height: isMobile ? "4rem" : "3rem",
                            position: "relative",
                            fontFamily: "Open Sans, sans-serif",
                            outline: "none",
                            opacity: "1",
                            maxWidth: isMobile ? "15rem" : "10rem",
                            margin: isMobile
                              ? "0 0.8rem 0 0"
                              : "0 0.8rem 0 auto",
                            // marginLeft: "9rem",
                            width: isMobile ? "100%" : "100%",
                            float: "right",
                          }}
                          prefix="$"
                          name={`professionalCategories[${indexFromParent}].cats[${index}].value`}
                          defaultValue={
                            (!fromRegister &&
                              Number(subService.value).toFixed(0)) ||
                            ""
                          }
                          value={
                            values.professionalCategories &&
                            fromRegister &&
                            values.professionalCategories[indexFromParent].cats[
                              index
                            ].value
                          }
                          onChange={(value) =>
                            handleChangeItem(
                              `professionalCategories[${indexFromParent}].cats[${index}].value`,
                              value.target.value.split("$")[1]
                            )
                          }
                          placeholder="Ej: $15.000"
                          // ref={props.register}
                          groupSeparator="."
                        />
                      </div>
                      <Select
                        placeholder="Modalidad"
                        options={[
                          { name: "Por trabajo", id: 1 },
                          { name: "Por hora", id: 2 },
                          { name: "A convenir", id: 3 },
                        ]}
                        defaultSelected={subService.paymentType}
                        handleSelectAction={(value) =>
                          handleChangeItem(
                            `professionalCategories[${indexFromParent}].cats[${index}].paymentTypeId`,
                            value.id
                          )
                        }
                        customStyles={{
                          marginRight: "0.8rem",
                          height: isMobile ? "4rem" : "3rem",
                        }}
                      />
                      {!fromRegister && (
                        <CloseIcon
                          style={{ opacity: 0.5, cursor: "pointer" }}
                          onClick={() => handleDeleteService(subService)}
                        />
                      )}
                    </div>
                  ) : (
                    <BorderedItem
                      customStyles={{
                        margin: isMobile ? "0" : "0 var(--spacing-small)",
                        // marginLeft: isMobile ? "6rem" : "",
                        marginLeft: "0.7rem",
                        backgroundColor: "var(--main-white)",
                        border: "2px solid var(--main-gray)",
                        minWidth: "100px",
                        marginRight: "0px",
                      }}
                    >
                      <Text
                        tag="p"
                        size={isMobile ? "s" : "m"}
                        customStyles={{
                          paddingLeft: "6px",
                          color: "var(--color-item)",
                        }}
                      >
                        {`${
                          subService.value && subService.paymentType.id !== 3
                            ? `$${Number(subService.value)}`
                            : ""
                        } ${
                          subService.paymentType.id !== 3
                            ? subService.paymentType.name.toLowerCase()
                            : subService.paymentType.name
                        }`}
                      </Text>
                    </BorderedItem>
                  )}
                </motion.div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default ServiceList;
