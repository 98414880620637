import React, { useEffect, useState } from 'react';
import styles from './testimonials.module.scss';
import Text from '@atoms/Text/Text';
import 'pure-react-carousel/dist/react-carousel.es.css';
import test01 from '@assets/img/test_abril_31.jpg';
import test02 from '@assets/img/test_abril_32.jpg';
import test03 from '@assets/img/test_abril_33.jpg';
import test04 from '@assets/img/test_abril_34.jpg';
import test05 from '@assets/img/test_abril_35.jpg';
import test06 from '@assets/img/test_abril_36.jpg';
import test07 from '@assets/img/test_abril_37.jpg';
import test08 from '@assets/img/test_abril_38.jpg';
import useScreenDetect from '@hooks/useScreenDetect';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const Testimonials = () => {
  // const imagenesMobile = [test01, test02, test03, test04];
  // const imagenes = [test05, test06, test07, test08];
  const { isMobile } = useScreenDetect()

  const [imagenes, setImagenes] = useState([test01, test02, test03, test04])

  useEffect(() => {
    if (isMobile) {
      setImagenes([test01, test02, test03, test04])
    } else {
      setImagenes([test05, test06, test07, test08])
    }
  }, [isMobile])

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    // speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <>
      <div className={styles.containerTestimonials}>
        <Text
          tag='h1'
          weight='bold'
          size='xxl'
          align='center'
          customStyle={styles.title}
        >
          Testimonios
        </Text>
        <div className={styles.fondo}>

          <div className={styles.carouselContainer}>
            <Slider {...settings}>
              {imagenes.map((item, i) => (
                <HowItem key={i} item={item} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  )
}

export const HowItem = ({ item }) => {
  return (
    <div className={styles.sonsCarousel}>
      <img src={item} />
    </div>
  )
}

export default Testimonials
