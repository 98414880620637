import React from 'react'
import styles from './Text.module.scss'
import PropTypes from 'prop-types'

const TextPrimary = ({
  priority,
  tag,
  children,
  customStyle,
  size = 's',
  weight = 'regular',
  color,
  align = 'center',
  customStyles,
  ...props
}) => {
  const CustomTag = priority ? `h${priority}` : tag
  return (
    <CustomTag
      className={`
      ${styles.TextPrimary} ${styles[color]}  ${styles[weight]}  ${
        styles[size]
      }   ${customStyle || ''}`}
      style={{ textAlign: align, ...customStyles }}
      {...props}
    >
      {children}
    </CustomTag>
  )
}

TextPrimary.propTypes = {
  priority: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  tag: PropTypes.oneOf(['span', 'p']),
  size: PropTypes.string,
  weight: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  customStyle: PropTypes.object
}

export default TextPrimary
