import Button from '@atoms/Button/Button'
import Form from '@atoms/Form/Form'
import TextArea from '@atoms/TextArea/TextArea'
import React from 'react'
import { useForm } from 'react-hook-form'
import styles from './ProfileEditModal.module.scss'
const ProfileEditAboutMe = ({ isMobile, profileData, handleSubmitDesc }) => {
  const { handleSubmit, register, watch } = useForm({
    description: profileData.description
  })

  const values = watch()

  const handleSubmitDescription = (data) => {
    handleSubmitDesc(data)
  }

  return (
    <div className={styles.container}>
      <Form onSubmit={handleSubmit(handleSubmitDescription)}>
        <TextArea
          placeholder='Ingresá una breve descripción sobre vos.'
          name='description'
          defaultValue={profileData.description}
          register={register({ required: true })}
        />
        <div className={styles.modalFooter}>
          <Button
            isDisabled={
              values.description === profileData.description ||
              !values.description
            }
            type='submit'
            customStyles={{
              maxWidth: isMobile ? '100%' : '11.5rem'
            }}
          >
            Guardar
          </Button>
        </div>
      </Form>
    </div>
  )
}
export default ProfileEditAboutMe
