import { combineReducers } from 'redux'
import generalReducer from './generalReducer'
import authReducer from './authReducer'
import registerReducer from './registerReducer'
import profileReducer from './profileReducer'
import panelReducer from './panelReducer'

export default combineReducers({
  general: generalReducer,
  auth: authReducer,
  register: registerReducer,
  profile: profileReducer,
  panel: panelReducer
})
