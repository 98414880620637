import React, { useState } from "react";
import styles from "./ProfileProgressMessage.module.scss";
import Text from "@atoms/Text/Text";
import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { ReactComponent as Trophy } from "@assets/svg/trophy.svg";
import { axiosGet, axiosPost, axiosPut } from "@config/api";

const ProfileProgressMessage = ({ isMobile }) => {
  const [closed, setClosed] = useState(false);
  const closeAction = () => {
    axiosPut(`/users/closeOutstanding`, {}).then(() => {
      setClosed(true);
    });
  };

  return (
    <div className={`${styles.messageContainer} ${closed && styles.closed}`}>
      <div className={styles.header}>
        <Text
          tag="p"
          size="l"
          weight="bold"
          align={isMobile ? "left" : "center"}
        >
          Destacá en las búsquedas y pasá al siguiente nivel
        </Text>
        <CloseIcon onClick={() => closeAction()} className={styles.close} />
      </div>
      <div className={styles.body}>
        <Trophy className={styles.trophy} />
        <div className={styles.checkList}>
          <p className={styles.checkItem}>Mantené actualizado tu perfil</p>
          <p className={styles.checkItem}>
            Dejá recomendaciones a tus clientes
          </p>
          <p className={styles.checkItem}>
            Subí fotos actualizadas de tus trabajos
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileProgressMessage;
